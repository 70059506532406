import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { IncomeBilag } from 'domain/entity/documnets/CommonBilag';
import { incomeBilagSlice } from 'data/store/incomeBilagReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import Input from 'components/controls/Input/Input';
import schema from './validation';
import { Content, ActionsButton, ErrorMessage } from './styled';

type PropsT = {
  defaultValues?: IncomeBilag;
};

const View: FC<PropsT> = (props) => {
  const { defaultValues } = props;
  const formData = useForm<{ sum: string }>({
    resolver: yupResolver(schema)
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = formData;
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const dispatch = useAppDispatch();
  const { changeRentIncomeBilag } = incomeBilagSlice.actions;
  const { error } = useAppSelector((state) => state.incomeBilag);

  const onSubmit = (data: { sum: string }) => {
    dispatch(changeRentIncomeBilag({ id: defaultValues?.id || NaN, sum: data.sum }));
  };

  return (
    <FormProvider {...formData}>
      <Content>
        <Input withoutPrompt variant={'outline'} label={f('monthlyRentSet')} disabled value={defaultValues?.sum} />
        <Input
          register={register('sum')}
          withoutPrompt
          type={'number'}
          variant={'outline'}
          label={f('monthlyRentChanges')}
          required
          error={errors.month_resignation}
        />
      </Content>
      <ErrorMessage>{error}</ErrorMessage>
      <ActionsButton>
        <Button onClick={handleSubmit(onSubmit)}>{a('save')}</Button>
      </ActionsButton>
    </FormProvider>
  );
};

export default View;

import styled, { css } from 'styled-components';
import { mq } from '../../constant/mediaqueries';
import { Link as BaseLink } from 'react-router-dom';

export const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 20px 0;
  gap: 60px;
  width: 100%;

  ${mq.lowerXs} {
    gap: 30px;
  }
`;

export const logoIconCss = css`
  width: 60px;
  fill: #4a90e2;
`;

export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray01};
  margin: 0 20px;
  font-size: 30px;
  max-width: 600px;

  ${mq.lowerXs} {
    font-size: 20px;
  }
`;

export const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.white01};
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`;

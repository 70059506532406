import React, { ChangeEventHandler, Dispatch, FC, FocusEventHandler, SetStateAction, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { Input } from 'components/ui';
import DateField from 'components/controls/DateField';
import { ActionsButton, Content, Title, InputContainer } from './styled';
import Button from 'components/controls/Button';
import { StepEnum } from '../data';
import INITIAL_PROFILE_DATA, { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import { useData } from '../index';
import { checkIconCss, StyledSignUpLink, StyledSuccessImg } from '../../../Registration/View/styled';
import { ROUTE_LOGIN } from '../../../../constant/routes';
import Icon from '../../../../components/common/Icon';
import checkIcon from 'components/svg/green_check_icon.svg?sprite';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { registrationSlice } from '../../../../data/store/registrationReducer';
import PhoneField from '../../../../components/controls/PhoneField';
import { profileSchemaWithEmail } from '../../../../data/validations/profileValidation';
import Select from '../../../../components/FormControls/Select';
import { contractSlice } from '../../../../data/store/rentContractReducer';
import i18n from 'i18next';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const LandlordInformation: FC<PropsT> = (props) => {
  const { setStep } = props;
  const { setLandlordValues, dataLandlord } = useData();
  const dispatch = useAppDispatch();
  const { checkEmail, setInitialData } = registrationSlice.actions;
  const { getCountryList } = contractSlice.actions;
  const { checkEmailError, pendingCheckEmail, successEmail } = useAppSelector((state) => state.registration);
  const { countryOptions, noCountryOptions } = useAppSelector((state) => state.contract);
  const language = i18n.language;
  const formData = useForm<ProfileFormT>({
    resolver: yupResolver(profileSchemaWithEmail),
    defaultValues: { ...INITIAL_PROFILE_DATA, ...dataLandlord }
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors }
  } = formData;
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { t: r } = useGlobalTranslation('registration');
  const { g } = useGlobalTranslation('login');

  const handleEmailBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if (!successEmail && getValues('email')) dispatch(checkEmail({ email: getValues('email') }));
    await register('email').onBlur(e);
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    dispatch(setInitialData());
    await register('email').onChange(e);
    setValue('email', e.target.value.replace(/\s+/g, ''));
  };

  const onSubmit = (data: ProfileFormT) => {
    if (Boolean(successEmail)) {
      setLandlordValues(data);
      setStep(StepEnum.HOUSE_INFO);
    }
  };

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  return (
    <FormProvider {...formData}>
      <Content>
        <Title>{f('landlordInform')}</Title>
        <InputContainer>
          <Input
            maxLength={50}
            register={register('firstName')}
            variant={'outline'}
            label={f('firstName')}
            required
            error={errors.firstName}
          />
          <Input
            maxLength={50}
            register={register('lastName')}
            variant={'outline'}
            label={f('lastName')}
            required
            error={errors.lastName}
          />
          <Input
            maxLength={50}
            required
            label={f('email')}
            register={register('email')}
            variant={'outline'}
            onBlur={handleEmailBlur}
            onChange={handleEmailChange}
            error={errors['email'] || (checkEmailError === 'errors.emailExistsToLogin' && checkEmailError)}
            serverError={
              checkEmailError === 'errors.emailExistsToLogin' ? (
                <>
                  {g(checkEmailError)} <StyledSignUpLink to={ROUTE_LOGIN}>{r('logIn')}</StyledSignUpLink>{' '}
                  {g('errors.emailExistsToLoginNoAddition')}
                </>
              ) : undefined
            }
            errorMessage={errors['email']?.message}
            isLoading={pendingCheckEmail}
            isSuccess={successEmail}
            endAdornment={
              successEmail && (
                <StyledSuccessImg>
                  <Icon icon={checkIcon} css={checkIconCss} />
                </StyledSuccessImg>
              )
            }
          />
          <PhoneField
            initialValue={`${dataLandlord.phone_code || ''}${dataLandlord.phone || ''}`}
            error={errors.phone}
            required
            type={'tel'}
            variant={'outline'}
            label={f('phone')}
          />
          <Select
            control={control}
            name={'country'}
            label={g(`inputs.country`)}
            options={language === 'en' ? countryOptions : noCountryOptions}
            isRequired
            error={errors.country}
          />
          <Input
            maxLength={60}
            required
            register={register('city')}
            variant={'outline'}
            label={g(`inputs.city`)}
            error={errors.city}
          />
          <Input
            maxLength={100}
            required
            register={register('address')}
            variant={'outline'}
            label={g(`inputs.address`)}
            error={errors.address}
          />
          <Input
            maxLength={50}
            required
            register={register('postcode')}
            variant={'outline'}
            label={g(`inputs.postcode`)}
            error={errors.postcode}
          />
          <Input required register={register('ssn')} variant={'outline'} label={g(`inputs.ssn`)} error={errors.ssn} />
          <DateField
            register={register('birthday')}
            value={getValues('birthday')}
            variant={'outline'}
            label={f('dateOfBirth')}
            error={errors.birthday}
          />
        </InputContainer>
        <ActionsButton>
          <Button
            type={'button'}
            disabled={(pendingCheckEmail && Boolean(checkEmailError)) || false}
            onClick={handleSubmit(onSubmit)}
          >
            {t('next')}
          </Button>
        </ActionsButton>
      </Content>
    </FormProvider>
  );
};

export default LandlordInformation;

import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react';
import Spinner from 'components/ui/Spinner/Spinner';
import { StyledContainer, VariantT, ColorT } from './styled';

export type PropsT = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: VariantT;
  color?: ColorT;
  isLoading?: boolean;
  social?: 'email' | 'fb' | 'google';
  className?: string;
  href?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
};

const Button: FC<PropsT> = (props) => {
  const { children, social, disabled, isLoading, ...restProps } = props;

  const touchDisabled = isLoading || disabled;

  return (
    <StyledContainer {...restProps} {...{ social, disabled: touchDisabled }}>
      {(isLoading && <Spinner />) || children}
    </StyledContainer>
  );
};

export default Button;

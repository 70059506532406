import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IncludingFormData } from 'domain/entity/rentContract/RentContract';
import { including } from 'data/validations/contractValidation';
import { useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Input from 'components/controls/Input/Input';
import { StepEnum } from 'components/layouts/Modals/NewRentContract/View/data';
import { useData } from 'components/layouts/Modals/NewRentContract/View';
import Textarea from 'components/controls/Textarea/Input';
import {
  Content,
  Span,
  StyledButton,
  TwoActionsButton,
  StyledLabel,
  StyledCheckbox,
  StyledDescriptionContainer
} from 'components/layouts/Modals/NewRentContract/styled';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const Including: FC<PropsT> = (props) => {
  const { data, setIsDirty, setValues } = useData();
  const formData = useForm<IncludingFormData>({
    resolver: yupResolver(including),
    defaultValues: data
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty }
  } = formData;
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { error } = useAppSelector((state) => state.contract);
  const { setStep } = props;

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [isDirty]);

  return (
    <FormProvider {...formData}>
      <Span />
      <Content>
        <Input
          register={register('keys_count')}
          withoutPrompt
          variant={'outline'}
          label={f('numberOfKeysHandedOut')}
          type={'number'}
          required
          error={errors.keys_count}
        />
      </Content>
      <StyledLabel>{f('including')}</StyledLabel>
      <Content style={{ marginTop: 0 }}>
        <div>
          <StyledCheckbox register={register('comforts')} value={'electricity'}>
            {f('electricity')}
          </StyledCheckbox>
          <StyledCheckbox register={register('comforts')} value={'heating'}>
            {f('heating')}
          </StyledCheckbox>
          <StyledCheckbox register={register('comforts')} value={'water-drain'}>
            {f('waterDrain')}
          </StyledCheckbox>
          <StyledCheckbox register={register('comforts')} value={'tv-subscription'}>
            {f('tVSubscription')}
          </StyledCheckbox>
          <StyledCheckbox register={register('comforts')} value={'internet'}>
            {f('internet')}
          </StyledCheckbox>
        </div>
        <div>
          <StyledCheckbox register={register('pets_allowed')}>{f('petsAllowed')}</StyledCheckbox>
          <StyledCheckbox register={register('smoking_allowed')}>{f('smokingAllowed')}</StyledCheckbox>
        </div>
      </Content>
      <Content>
        <StyledDescriptionContainer>
          <Textarea rows={5} register={register('other')} withoutPrompt variant={'outline'} label={f('other')} />
        </StyledDescriptionContainer>
      </Content>
      <TwoActionsButton>
        <StyledButton
          type={'button'}
          variant={'outline'}
          onClick={() => {
            setValues(getValues());
            setStep(StepEnum.TENANCY_TERMS);
          }}
        >
          {f('back')}
        </StyledButton>
        <StyledButton
          type={'button'}
          variant={'fill'}
          onClick={handleSubmit((formData) => {
            setValues(formData);
            setStep(StepEnum.DEPOSIT_ACCOUNT);
          })}
        >
          {t('next')}
        </StyledButton>
      </TwoActionsButton>
    </FormProvider>
  );
};

export default Including;

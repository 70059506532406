import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from '../../domain/entity/house/HouseData';

export interface FileState {
  pending?: boolean;
  documents?: any[];
  error?: string;
}

const initialState: FileState = {
  pending: undefined,
  documents: [],
  error: ''
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    uploadFiles(state: FileState, _: PayloadAction<File>) {
      state.pending = true;
    },
    uploadFilesSuccess(state: FileState, { payload }: PayloadAction<any[]>) {
      state.pending = false;
      state.documents = payload;
    },
    uploadFilesError(state: FileState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    }
  }
});

const selectDocuments = (state: RootState): FileState => state.file;

export const fileSelector = createSelector([selectDocuments], (file) => file);

export const { uploadFiles, uploadFilesError, uploadFilesSuccess } = fileSlice.actions;

export default fileSlice.reducer;

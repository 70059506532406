// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { authSlice } from '../../data/store/authReducer';
import { useHistory } from 'react-router';
import { ROUTE_ALTINN_INDEX, ROUTE_TEST_SKATTEETATEN } from '../../constant/routes';
import { Wrapper, Text } from './styled';
import Logo from '../../components/ui/Logo/Logo';
import Button from '../../components/controls/Button';
import { APP_STORE, GOOGLE_PLAY } from '../../constant/externalLinks';
import Icon from '../../components/common/Icon';
import { ActionContainer, LinkApp, storeIconCss } from '../ContactUs/styled';
import googleIcon from 'components/svg/googlePlay.svg?sprite';
import appStoreIcon from 'components/svg/appStore.svg?sprite';
import Spinner from '../../components/ui/Spinner/Spinner';
import useGlobalTranslation from '../../hooks/useGlobalTranlation';
const env = process.env;
//const env = runtimeEnv();

const DIFIToken: FC = () => {
  const { t } = useGlobalTranslation('scatteetaten');
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const history = useHistory();
  const code = params.get('code');
  const state = params.get('state');
  const dispatch = useAppDispatch();
  const { difiToken } = useAppSelector(({ auth }) => auth);
  const { fetchDifiToken } = authSlice.actions;
  const SCATTETATEN_ENABLED = env.REACT_APP_SCATTETATEN_ENABLED;

  console.log(Boolean(SCATTETATEN_ENABLED));

  useEffect(() => {
    if (code && state) {
      dispatch(fetchDifiToken({ code, state }));
    }
  }, [code, state]);

  if (difiToken && !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    history.push(SCATTETATEN_ENABLED === 'true' ? ROUTE_ALTINN_INDEX : ROUTE_TEST_SKATTEETATEN);

    return null;
  }

  if (difiToken && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return (
      <Wrapper>
        <Logo />
        <Text>{t('success')}</Text>
        <Button
          onClick={() => {
            history.push(SCATTETATEN_ENABLED === 'true' ? ROUTE_ALTINN_INDEX : ROUTE_TEST_SKATTEETATEN);
          }}
        >
          {t('button')}
        </Button>
        <ActionContainer>
          <LinkApp href={GOOGLE_PLAY} target={'_blank'}>
            <Icon icon={googleIcon} css={storeIconCss} />
          </LinkApp>
          <LinkApp href={APP_STORE} target={'_blank'}>
            <Icon icon={appStoreIcon} css={storeIconCss} />
          </LinkApp>
        </ActionContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Spinner blue />
    </Wrapper>
  );
};

export default DIFIToken;

import React, { FC } from 'react';
import { APP_STORE, FB, GOOGLE_PLAY, INSTAGRAM, MESSAGE_US_URL } from 'constant/externalLinks';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import messengerIcon from 'components/svg/messenger.svg?sprite';
import emailIcon from 'components/svg/email.svg?sprite';
import fbIcon from 'components/svg/fb.svg?sprite';
import googleIcon from 'components/svg/googlePlay.svg?sprite';
import appStoreIcon from 'components/svg/appStore.svg?sprite';
import instagramIcon from 'components/svg/instagram.svg?sprite';
import {
  Wrapper,
  Text,
  Content,
  ActionContainer,
  Button,
  messengerIconCss,
  emailIconCss,
  SocialLinks,
  fbIconCss,
  instagramIconCss,
  LinkApp,
  storeIconCss
} from './styled';

const ContactUs: FC = () => {
  const { t } = useGlobalTranslation('contactUs');

  return (
    <Wrapper>
      <Content>
        <Text>{t('contactUs')}</Text>
        <Button color={'black01'} href={MESSAGE_US_URL} target={'_blank'}>
          <Icon icon={messengerIcon} css={messengerIconCss} />
          {t('messegeUs')}
        </Button>
        <Button href={'mailto:post@utleieappen.com'} color={'black01'}>
          <Icon icon={emailIcon} css={emailIconCss} />
          post@utleieappen.com
        </Button>
        <Text style={{ marginTop: 60 }}>{t('followUs')}</Text>
        <SocialLinks>
          <Button
            href={FB}
            target={'_blank'}
            style={{ width: 48, borderRadius: '50%' }}
            color={'black01'}
            aria-label={'fb'}
          >
            <Icon icon={fbIcon} css={fbIconCss} />
          </Button>
          <Button
            href={INSTAGRAM}
            target={'_blank'}
            style={{ width: 48, borderRadius: '50%' }}
            color={'black01'}
            aria-label={'instagram'}
          >
            <Icon icon={instagramIcon} css={instagramIconCss} />
          </Button>
        </SocialLinks>
      </Content>
      <ActionContainer>
        <LinkApp href={GOOGLE_PLAY} target={'_blank'}>
          <Icon icon={googleIcon} css={storeIconCss} />
        </LinkApp>
        <LinkApp href={APP_STORE} target={'_blank'}>
          <Icon icon={appStoreIcon} css={storeIconCss} />
        </LinkApp>
      </ActionContainer>
    </Wrapper>
  );
};

export default ContactUs;

import React, { FC, useEffect, useState } from 'react';
import { BilagFormDataT, InitialBilagValues } from 'domain/entity/bilag/BilagFormData';
import { modalsSlice } from 'data/store/modalReducer';
import { supplierSlice } from 'data/store/supplierReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Modal from 'components/common/Modal';
import { COST_TYPE, getBilagToBilagData, REPEAT } from './View/data';
import View from './View';

const ManualBilag: FC = () => {
  const { selectedHouse } = useAppSelector((state) => state.house);
  const initialValues: BilagFormDataT = {
    ...InitialBilagValues,
    houseId: Number(selectedHouse?.id),
    cost_type: String(COST_TYPE[0].value),
    repeat: String(REPEAT[0].value),
    franchise: 100
  };
  const {
    manualBilag,
    bilagInfo: { currentBilag }
  } = useAppSelector((state) => state.modals);
  const { t: t } = useGlobalTranslation('titles');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { closeManualBilag, openInfoBilag, openDiscardChange } = modalsSlice.actions;
  const { fetchSupplier } = supplierSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (manualBilag) {
      dispatch(fetchSupplier());
    }
  }, [manualBilag]);

  const closeModal = () => {
    dispatch(closeManualBilag());

    if (currentBilag?.type) {
      dispatch(openInfoBilag());
    }
  };

  if (!manualBilag) {
    return null;
  }

  return (
    <Modal
      isOpen={manualBilag}
      title={t('manualBilag')}
      onClose={() => {
        isDirty ? dispatch(openDiscardChange(closeModal)) : closeModal();
      }}
      withoutAnimation={isDirty}
    >
      <View
        setIsDirty={setIsDirty}
        defaultValues={currentBilag?.type === 'bilag' ? getBilagToBilagData(currentBilag.bilag) : initialValues}
      />
    </Modal>
  );
};

export default ManualBilag;

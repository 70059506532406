import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from '../../domain/entity/house/HouseData';
import { AltinnDataT } from '../../domain/entity/altinn/AltinnBilag';
import AltinnLoginData from '../../domain/entity/altinn/AltinLoginData';
import { SendAltinnDataT } from '../driver/altinn/Altinn';
import AltinFormT from '../../domain/entity/altinn/AltinForm';
import { BilagFormDataT } from '../../domain/entity/bilag/BilagFormData';
import { CommonBilag } from '../../domain/entity/documnets/CommonBilag';
import { useAppSelector } from '../../hooks/redux';

export interface BilagState {
  pending?: boolean;
  deletePending?: boolean;
  error?: string;
  current?: BilagFormDataT;
}

const initialState: BilagState = {
  pending: false,
  error: ''
};

export const bilagSlice = createSlice({
  name: 'bilag',
  initialState,
  reducers: {
    createManualBilag(state: BilagState, _: PayloadAction<{ data: BilagFormDataT; currentHouse?: HouseData }>) {
      state.pending = true;
    },
    createManualBilagSuccess(state: BilagState) {
      state.pending = false;
      state.error = '';
    },
    createManualBilagError(state: BilagState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    deleteManualBilag(state: BilagState, _: PayloadAction<number>) {
      state.deletePending = true;
      state.error = '';
    },
    restoreBilagFetch(state: BilagState, _: PayloadAction<{ id: number; type: string; houseId: number }>) {
      state.pending = true;
      state.error = '';
    },
    restoreBilagSuccess(state: BilagState) {
      state.pending = false;
      state.error = '';
    },
    deleteManualBilagSucces(state: BilagState) {
      state.deletePending = false;
      state.error = '';
    },
    deleteManualBilagError(state: BilagState, { payload }: PayloadAction<string>) {
      state.deletePending = false;
      state.error = payload;
    },
    startStopBilag(state: BilagState, _: PayloadAction<{ id: number; isStopped: boolean; currentHouse: HouseData }>) {
      state.deletePending = true;
      state.error = '';
    },
    startStopBilagSuccess(state: BilagState) {
      state.deletePending = false;
      state.error = '';
    },
    startStopBilagError(state: BilagState, { payload }: PayloadAction<string>) {
      state.deletePending = false;
      state.error = payload;
    }
  }
});

const selectBilag = (state: RootState): BilagState => state.bilag;

export const bilagSelector = createSelector([selectBilag], (bilag) => bilag);

export const {
  createManualBilag,
  createManualBilagError,
  createManualBilagSuccess,
  restoreBilagFetch,
  restoreBilagSuccess,
  deleteManualBilag,
  deleteManualBilagError,
  deleteManualBilagSucces,
  startStopBilagError,
  startStopBilagSuccess,
  startStopBilag
} = bilagSlice.actions;

export default bilagSlice.reducer;

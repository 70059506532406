import React, { Dispatch, FC, SetStateAction } from 'react';
import format from 'date-fns/format';
import setMonth from 'date-fns/setMonth';
import en from 'date-fns/locale/en-US';
import { ViewVariantT } from 'components/controls/DatePicker/Header';
import { Wrapper, MonthButton } from './styled';

type PropsT = {
  selectedMonth: Date;
  setSelectedMonth: Dispatch<SetStateAction<Date>>;
  setViewVariant: Dispatch<SetStateAction<ViewVariantT>>;
};

const Months: FC<PropsT> = (props) => {
  const { selectedMonth, setSelectedMonth, setViewVariant } = props;

  return (
    <Wrapper>
      {Array(12)
        .fill(0)
        .map((_, index) => {
          const formattedMonth = format(setMonth(selectedMonth, index), 'LLLL', {
            locale: en
          });

          return (
            <MonthButton
              key={formattedMonth}
              type="button"
              variant="text"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedMonth(setMonth(selectedMonth, index));
                setViewVariant('days');
              }}
            >
              {formattedMonth}
            </MonthButton>
          );
        })}
    </Wrapper>
  );
};

export default Months;

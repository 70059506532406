import React, { FC } from 'react';
import Modal from 'components/common/Modal';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import View from './View';
import useGlobalTranlation from '../../../../hooks/useGlobalTranlation';

type PropsT = {
  index?: number;
};

const ChangeRent: FC<PropsT> = (props) => {
  const { t: f } = useGlobalTranlation('fields');
  const dispatch = useAppDispatch();
  const { closeChangeRent } = modalsSlice.actions;
  const {
    changeRent,
    bilagInfo: { currentBilag }
  } = useAppSelector((state) => state.modals);

  if (!changeRent && !currentBilag?.bilag) {
    return null;
  }

  return (
    <Modal isOpen={changeRent} onClose={() => dispatch(closeChangeRent())} title={f('changeRentThisMonth')}>
      <View defaultValues={currentBilag?.type === 'income-bilag' ? currentBilag.bilag : undefined} />
    </Modal>
  );
};

export default ChangeRent;

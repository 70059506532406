import { CREATE_BILAG, EDIT_BILAG, RESTORE_BILAG } from 'constant/apiRoutes';
import { httpWithAuth } from 'data/driver/Api';
import { BilagFormDataT } from 'domain/entity/bilag/BilagFormData';
import formatISO from 'date-fns/formatISO';
import { formatDateForApi } from '../../../helpers/date';
import { Bilag } from '../../../domain/entity/documnets/CommonBilag';

export const createManualBilagApi = async (formData: BilagFormDataT): Promise<Bilag> => {
  const { data } = await httpWithAuth.post(`${CREATE_BILAG}/${formData.id || ''}`, {
    ...formData,
    documents: formData.documents,
    date: formatDateForApi(formData.date) || new Date().toISOString(),
    supplierId: Number(formData.supplierId),
    supplier_name: formData.supplier_name || null,
    supplier_address: formData.supplier_address || null,
    supplier_city: formData.supplier_city || null,
    supplier_postcode: formData.supplier_postcode || null
  });

  return data;
};

export const editManualBilagApi = async (formData: BilagFormDataT): Promise<Bilag> => {
  const { data } = await httpWithAuth.put(`${EDIT_BILAG(formData.id)}`, {
    ...formData,
    documents: formData.documents,
    date: formatDateForApi(formData.date),
    supplierId: Number(formData.supplierId),
    id: undefined
  });

  return data;
};

export const deleteManualBilagApi = async (id: number) => {
  await httpWithAuth.delete(`${CREATE_BILAG}/${id}`);
};

export const startStopBilagApi = async ({ id, isStopped }: { id: number; isStopped: boolean }) => {
  await httpWithAuth.post(`${CREATE_BILAG}/${id}/repeat/${isStopped ? 'stop' : 'start'}`);
};

export const restoreBilagApi = async ({ id, type = 'bilag' }: { id: number; type: string }) => {
  await httpWithAuth.put(RESTORE_BILAG(id, type));
};

import { CommonBilag } from '../../../domain/entity/documnets/CommonBilag';
import { InfoFieldT } from '../../../domain/entity/common';

export const getBilagFields = (fullBilag: CommonBilag): InfoFieldT[] => {
  const { type, bilag } = fullBilag;

  switch (type) {
    case 'bilag':
      return [
        {
          value: (bilag && `options.cost.${bilag?.cost_type}`) || '',
          label: 'inputs.costType',
          type: 'text'
        },
        {
          value: (bilag && bilag?.supplier && bilag?.supplier.name) || '',
          label: 'inputs.supplier',
          type: 'text'
        },
        {
          value: (bilag && bilag?.description) || '',
          label: 'inputs.description',
          type: 'text'
        },
        {
          value: (bilag && bilag?.supplier && bilag?.supplier.address) || '',
          label: 'inputs.address',
          type: 'text'
        },
        {
          value: (bilag && bilag?.supplier && bilag?.supplier.postcode) || '',
          label: 'inputs.postcode',
          type: 'text'
        },
        {
          value: (bilag && bilag?.supplier && bilag?.supplier.city) || '',
          label: 'inputs.city',
          type: 'text'
        },
        {
          value: (bilag && bilag?.date) || '',
          label: 'inputs.date',
          type: 'date'
        },
        {
          value: (bilag && bilag?.sum) || '',
          label: 'inputs.sum',
          type: 'currency'
        }
      ];
    case 'income-bilag':
      if (bilag?.contractId) {
        return [
          {
            value: (bilag && bilag?.start_date) || '',
            label: 'inputs.dateOfPayment',
            type: 'date'
          },
          {
            value: (bilag && bilag?.start_date) || '',
            label: 'inputs.start',
            type: 'date'
          },
          {
            value: (bilag && bilag?.sum) || '',
            label: 'inputs.rentalPayment',
            type: 'currency'
          },
          {
            value: (bilag && bilag?.end_date) || '',
            label: 'inputs.end',
            type: 'date'
          }
        ];
      }
      return [
        {
          value: (bilag && bilag?.start_date) || '',
          label: 'inputs.dateOfPayment',
          type: 'date'
        },
        {
          value: (bilag && bilag?.sum) || '',
          label: 'inputs.rentalPayment',
          type: 'currency'
        }
      ];
    case 'depreciation-bilag':
      return [
        {
          value: (bilag && bilag?.name) || '',
          label: 'inputs.item',
          type: 'text'
        },
        {
          value: (bilag && `options.depreciationRadio.${(bilag?.lifetime <= 3 && '1') || '2'}`) || '',
          label: 'inputs.lifetime',
          type: 'text'
        },
        {
          value: (bilag && bilag?.sum) || '',
          label: 'inputs.cost',
          type: 'currency'
        },
        {
          value: (bilag && bilag?.date) || '',
          label: 'inputs.date',
          type: 'date'
        }
      ];
    default:
      return [];
  }
};

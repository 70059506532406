import styled, { css } from 'styled-components';

type PropsT = {
  isOpen: boolean;
};

export const StyledButton = styled.button<PropsT>`
  cursor: pointer;
  justify-self: start;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  border: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  border-radius: 3px;
  color: ${({ isOpen, theme }) => (isOpen ? theme.colors.blue01 : 'inherit')};
  transition: color 0.25s;

  &:focus-visible {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const StyledItem = styled.label`
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  padding: 12px 25px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  transition: background-color 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray08};
  }
`;

export const filterIconCss = css`
  width: 22px;
  fill: currentColor;
`;

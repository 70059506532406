import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_LOGIN } from 'constant/routes';
import { setCredentials } from 'data/driver/Api';
import { authSlice } from 'data/store/authReducer';
import { profileSlice } from 'data/store/profileReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const LoginByUrlAndRedirectDifi = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { profileFetching } = profileSlice.actions;
  const { logout, tokenFetchingSuccess, fetchAuthProviders } = authSlice.actions;
  const [externalAuth, setExternalAuth] = useState<string | undefined>('');
  const { error, pending } = useAppSelector((state) => state.profile);
  const { token, authProviders, pending: loadingProviders } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    const externalAuthType = urlParams.get('externalAuthType');
    if (externalAuthType) {
      setExternalAuth(externalAuthType);
      dispatch(fetchAuthProviders());
    }
    setCredentials(urlToken || '');
    dispatch(tokenFetchingSuccess({ accessToken: urlToken || '' }));
    dispatch(profileFetching());
  }, []);

  useEffect(() => {
    if (!pending && externalAuth && token && authProviders && (authProviders?.length || 0) > 0 && error.length < 1) {
      window.location.href = authProviders.find(({ name }) => name.toLowerCase() === externalAuth)?.authUrl;
    } else {
      if (error?.length > 0) {
        dispatch(logout());
        history.push({ pathname: ROUTE_LOGIN });
      }
    }
  }, [token, pending, externalAuth, authProviders, loadingProviders]);

  return null;
};

export default LoginByUrlAndRedirectDifi;

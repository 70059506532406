import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTE_DASHBOARD } from 'constant/routes';

const Home: FC = () => {
  const { replace } = useHistory();

  useEffect(() => {
    replace(ROUTE_DASHBOARD);
  }, []);

  return <></>;
};

export default Home;

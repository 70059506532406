import { useMemo } from 'react';
import { compose, reduce } from 'ramda';
import { Bilag, CommonBilag, DepreciationBilag, IncomeBilag } from 'domain/entity/documnets/CommonBilag';
import { numberWithSpaces } from 'helpers/number';

export type TableDataT = {
  id: number;
  isTrashed?: boolean;
  type: string | false | 0;
  name: string;
  sum: number;
  franchise: number | null;
  colorSum: string;
  date: string | null;
  isArchivedBilag: boolean;
  isAutomaticallyBilag: number | boolean;
  isAutomaticallyDepreciation: boolean;
};

export type TypeT = 'income-bilag' | 'manual-bilag' | 'archived-bilag' | 'depreciation' | 'rent-contract';

export const TAX_DEPRECIATION_AMOUNT = 15000;

const getBilagsSum = (bilags: (Bilag | IncomeBilag)[]) => reduce((acc, { sum }) => acc + (sum || 0), 0, bilags);

const getTaxableIncomeBilagsSum = (bilags: IncomeBilag[], findTaxable = true) =>
  getBilagsSum(bilags.filter(({ taxable }) => taxable === findTaxable));

const getBilagsExpenseSum = (bilags: Bilag[]) =>
  reduce((acc, { sum, franchise }) => acc + Math.round(sum * (franchise / 100)), 0, bilags);

const getDepreciationBilagsExpenseSum = (bilags: DepreciationBilag[]) =>
  reduce((acc, { expensed }) => acc + Math.round(expensed || 0), 0, bilags);

const truncNumber = (x: number) => Math.round(x);

export const formatNumber = compose(numberWithSpaces, truncNumber);

export const useHomeOverview = (commonBilags: CommonBilag[]) => {
  // todo: maybe move filtering "isTrashed" to separate function
  const bilags = useMemo(
    () =>
      (commonBilags
        ?.filter((item) => item.bilag && item.type === 'bilag' && !item.bilag.archived && !item.bilag.isTrashed)
        .map(({ bilag }) => bilag) || []) as Bilag[],
    [commonBilags]
  );

  const incomeBilags = useMemo(
    () =>
      (commonBilags
        ?.filter((item) => item.type === 'income-bilag' && item.bilag && !item.bilag.isTrashed)
        .map(({ bilag }) => bilag) || []) as IncomeBilag[],
    [commonBilags]
  );

  const depreciationBilags = useMemo(
    () =>
      (commonBilags
        ?.filter((item) => item.type === 'depreciation-bilag' && item.bilag && !item.bilag.isTrashed)
        .map(({ bilag }) => bilag) || []) as DepreciationBilag[],
    [commonBilags]
  );

  const depreciationPersonalBilags = useMemo(
    () => commonBilags?.filter((item) => item.type === 'depreciation-personal-bilag'),
    [commonBilags]
  );

  const depreciationPersonalBilagsSum = depreciationPersonalBilags.reduce(
    (acc, { bilag: { sum } }) => acc + (sum || 0),
    0
  );

  const expenseSum = useMemo(
    () =>
      getBilagsExpenseSum(bilags || []) +
      depreciationPersonalBilagsSum +
      getDepreciationBilagsExpenseSum(depreciationBilags || []),
    [bilags, depreciationBilags]
  );
  const expenseSumString = useMemo(() => formatNumber(expenseSum), [expenseSum]);

  const excludingTaxSum = useMemo(
    // todo:
    () => getTaxableIncomeBilagsSum(incomeBilags || [], false), // --- for income bilags where taxable - true
    [incomeBilags]
  );

  const excludingTaxSumString = useMemo(() => formatNumber(excludingTaxSum), [excludingTaxSum]);

  const incomeSum = useMemo(
    // todo:
    () => getTaxableIncomeBilagsSum(incomeBilags || []), // --- for income bilags where taxable - true
    [incomeBilags]
  );

  const incomeSumString = useMemo(() => formatNumber(incomeSum), [incomeSum]);

  const taxSum = useMemo(() => (incomeSum - expenseSum) * 0.22, [expenseSum, incomeSum]);
  const taxSumString = useMemo(() => formatNumber(taxSum), [taxSum]);

  //todo delete
  const taxFreeSum = useMemo(() => 0, []); // todo:
  const taxFreeSumString = useMemo(() => formatNumber(taxFreeSum), [taxFreeSum]);

  return {
    incomeSumString,
    expenseSumString,
    taxSumString,
    taxFreeSumString,
    excludingTaxSumString
  };
};

export const getRenderItemValues = (item: CommonBilag): TableDataT => {
  const name =
    (item.type === 'bilag' && item.bilag?.supplier.name) ||
    (item.type === 'income-bilag' && item.bilag?.title) ||
    (item.type === 'depreciation-bilag' && item.bilag?.name) ||
    (item.type === 'depreciation-personal-bilag' && 'inPersonalUse') ||
    'unnamed';
  const sum =
    (item.type === 'bilag' && item.bilag?.sum) ||
    (item.type === 'income-bilag' && item.bilag?.sum) ||
    (item.type === 'depreciation-bilag' && item.bilag?.expensed) ||
    (item.type === 'depreciation-personal-bilag' && item.bilag?.sum) ||
    0;

  const franchise = (item.type === 'bilag' && item.bilag?.franchise) || null;
  const date =
    (item.type === 'bilag' && item.bilag?.date) ||
    (item.type === 'income-bilag' && item.bilag?.start_date) ||
    (item.type === 'depreciation-bilag' && item.bilag?.date) ||
    (item.type === 'depreciation-personal-bilag' && item.bilag?.date) ||
    null;
  const colorSum =
    (item.type === 'bilag' && 'red01') ||
    (item.type === 'depreciation-bilag' && 'red01') ||
    (item.type === 'depreciation-personal-bilag' && 'red01') ||
    (item.type === 'income-bilag' && item.bilag?.separatedTaxable && 'green01') ||
    (item.type === 'income-bilag' && item.bilag?.taxable && 'green01') ||
    'green01';

  const isArchivedBilag = (item?.type === 'bilag' && item?.bilag?.archived) || false;

  const isAutomaticallyDepreciation =
    (item?.bilag &&
      (item?.bilag?.sum || 0) >= TAX_DEPRECIATION_AMOUNT &&
      (item?.type === 'depreciation-bilag' && item?.bilag?.lifetime) >= 3) ||
    item?.type === 'depreciation-personal-bilag' ||
    false;
  const isAutomaticallyBilag =
    (item.type === 'bilag' && item.bilag?.isRepeated) ||
    (item.type === 'income-bilag' && item?.bilag?.contractId) ||
    false;
  const isTrashed = item.type !== 'depreciation-personal-bilag' ? item.bilag?.isTrashed : false;

  const type =
    (item.type === 'bilag' && !isArchivedBilag && 'manual-bilag') ||
    (item.type === 'bilag' && isArchivedBilag && 'archived-bilag') ||
    (item.type === 'income-bilag' && !isAutomaticallyBilag && 'income-bilag') ||
    ((item.type === 'depreciation-bilag' || item.type === 'depreciation-personal-bilag') && 'depreciation') ||
    (item.type === 'income-bilag' && isAutomaticallyBilag && 'rent-contract');

  return {
    id: item.bilag?.id | NaN,
    isTrashed,
    type,
    name,
    sum,
    franchise,
    colorSum,
    date,
    isArchivedBilag,
    isAutomaticallyBilag,
    isAutomaticallyDepreciation
  };
};

export type SortT = {
  key: 'string' | 'number' | 'date';
  type: 'ASC' | 'DESC';
};

export const sortedData = (tableData: TableDataT[], sort: SortT | null) => {
  if (sort) {
    if (sort.key === 'string') {
      return tableData?.sort((a, b) => {
        if (a.name > b.name) {
          return sort.type === 'ASC' ? 1 : -1;
        }
        if (a.name < b.name) {
          return sort.type === 'ASC' ? -1 : 1;
        }
        // a должно быть равным b
        return 0;
      });
    }
    if (sort.key === 'number') {
      return tableData?.sort((a, b) => (sort.type === 'ASC' ? a.sum - b.sum : b.sum - a.sum));
    }
    if (sort.key === 'date') {
      return tableData?.sort((a, b) =>
        sort.type === 'ASC'
          ? new Date(a.date || '').getTime() - new Date(b.date || '').getTime()
          : new Date(b.date || '').getTime() - new Date(a.date || '').getTime()
      );
    }
  }

  return tableData;
};

import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useGlobalTranslation from '../../../../hooks/useGlobalTranlation';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema, { INITIAL_VALUES } from './validation';
import { StyledContainer, StyledForm, StyledFormContainer, StyledSubmitWrapper } from '../ChangeEmail/styled';
import { backIconCss, StyledLink, StyledLinkText } from '../../../Landlord/components/LandlordEdit/styled';
import { ROUTE_PROFILE_ACCOUNT_SETTINGS } from '../../../../constant/routes';
import Icon from '../../../../components/common/Icon';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import { Button, Input } from '../../../../components/ui';
import { getErrMsg } from '../../../../helpers/locale';
import { PASSWORD_FIELDS } from './fields';
import { accountSettingsSlice } from '../../../../data/store/accountSettingsReducer';
import ChangePasswordT from '../../../../domain/entity/profile/ChangePasswordT';
import ConfirmationModal from '../../../../components/common/ConfirmationModal';
import { useHistory } from 'react-router';
import SimplePage from '../../../../components/common/SimplePage';
import { confirmationSlice } from '../../../../data/store/confirmationReducer';

const ChangePassword: FC = () => {
  const dispatch = useAppDispatch();
  const { accountSettingsChangePasswordFetching } = accountSettingsSlice.actions;
  const { pending, error } = useAppSelector((state) => state.accountSettings);
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;

  const { t, g } = useGlobalTranslation('profile');
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: INITIAL_VALUES
  });

  const [openAlert, setOpenAlert] = useState(false);
  const history = useHistory();

  const onSubmit = ({ newPassword, oldPassword }: ChangePasswordT) => {
    dispatch(accountSettingsChangePasswordFetching({ newPassword, oldPassword }));
    if (!error) {
      dispatch(
        setConfirmationData({
          show: true,
          confirmButtonProps: {
            onClick: () => {
              dispatch(resetConfirmationData());
              history.push(ROUTE_PROFILE_ACCOUNT_SETTINGS);
            },
            children: 'Ok'
          },
          text: g('successes.passwordChanged'),
          title: g('success'),
          onClose: () => {
            dispatch(resetConfirmationData());
            history.push(ROUTE_PROFILE_ACCOUNT_SETTINGS);
          }
        })
      );
    }
  };

  const { register, handleSubmit, formState, getValues } = form;

  useEffect(() => {
    if (pending !== undefined && !pending && !error) {
      setOpenAlert(!!getValues('oldPassword'));
    }
  }, [pending]);

  return (
    <SimplePage
      header={
        <StyledLink to={ROUTE_PROFILE_ACCOUNT_SETTINGS}>
          <Icon icon={backIcon} css={backIconCss} color={'black01'} />
          <StyledLinkText>{t('change.password')}</StyledLinkText>
        </StyledLink>
      }
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContainer>
          <FormProvider key={name} {...form}>
            {PASSWORD_FIELDS.map(({ name, text, type, serverError, ...restProps }) => (
              <Input
                {...restProps}
                variant={'outline'}
                maxLength={50}
                key={name}
                label={t(text)}
                type={type}
                register={register(name)}
                error={!!formState.errors[name] || (serverError && !!error)}
                errorMessage={getErrMsg(formState.errors, name)}
                serverError={(serverError && error && g(error)) || '' || undefined}
              />
            ))}
          </FormProvider>
        </StyledFormContainer>
        <StyledSubmitWrapper>
          <Button isLoading={pending}>{g('save')}</Button>
        </StyledSubmitWrapper>
      </StyledForm>
    </SimplePage>
  );
};

export default ChangePassword;

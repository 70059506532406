// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';
import axios, { AxiosInstance } from 'axios';
import { logout } from '../store/authReducer';
import { logoutSaga } from '../sagas/auth';
import { HOME_PAGE } from '../../constant/routes';
const env = process.env;
//const env = runtimeEnv();

const CREDENTIALS_STORAGE_NAME = '_auth';
export const API_URL = env.REACT_APP_BACKEND_URL;
let newToken: string | null = null;

const getCredentialsFromStorage = (): string | null => {
  const token: string = localStorage.getItem(CREDENTIALS_STORAGE_NAME) || '';

  return token || newToken || null;
};

const getHttp = (isAuthRequired: boolean): AxiosInstance => {
  const http = axios.create({
    baseURL: API_URL
  });

  if (isAuthRequired) {
    http.interceptors.request.use((request) => {
      const token = getToken();
      if (token) {
        return {
          ...request,
          headers: {
            ...request.headers,
            Authorization: `Bearer ${token}`
          }
        };
      }

      return request;
    }, Promise.reject);

    http.interceptors.response.use(undefined, async ({ ...error }) => {
      try {
        if (error.response.status === 401) {
          resetToken();
          window.location.replace(HOME_PAGE);
        }

        return await Promise.reject(error);
      } catch (e) {
        return Promise.reject(error);
      }
    });
  }

  return http;
};

export const getToken = (): string | null => {
  return getCredentialsFromStorage();
};

export const resetToken = () => {
  localStorage.removeItem(CREDENTIALS_STORAGE_NAME);
};

export const setCredentials = (accessToken: string) => {
  newToken = accessToken;
  localStorage.setItem(CREDENTIALS_STORAGE_NAME, accessToken);
};

export const http = getHttp(false);
export const httpWithAuth = getHttp(true);

import styled, { css } from 'styled-components';
import { hexColorOpacity } from '../../../helpers/color';

type ErrorPropsT = {
  error: boolean;
};

export const StyledContainer = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledCheckbox = styled.input<{ error: boolean }>`
  border-radius: 4px;
  box-shadow: ${({ error, theme }) => (error ? `0 0px 3px ${hexColorOpacity(theme.colors.red01)}` : 'none')};
  transition: box-shadow 0.2s;
`;

export const StyledLabel = styled.span`
  margin-left: 0.5rem;
`;

export const StyledPrompt = styled.div<ErrorPropsT>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.colors.red01};
  font-size: 14px;
  line-height: 1em;
  visibility: ${({ error }) => (error && 'visible') || 'hidden'};
`;

export const errorIconCss = css`
  width: 21px;
  margin-right: 5px;
  fill: currentColor;
`;

import { PropsT as ButtonPropsT } from '../../components/controls/Button';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import { ReactNode } from 'react';

export interface ConfirmationState {
  show: boolean;
  title?: string;
  text?: ReactNode;
  onClose?: () => void;
  cancelButtonProps?: Omit<ButtonPropsT, 'color' | 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: () => void;
  };
  confirmButtonProps: Omit<ButtonPropsT, 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: (handleClose: () => void) => void;
    href?: string;
  };
  isConfirm?: boolean;
}

const initialState: ConfirmationState = {
  show: false,
  confirmButtonProps: { children: 'Ok', onClick: () => {} },
  cancelButtonProps: undefined,
  text: undefined,
  title: undefined
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmationData(state: ConfirmationState, { payload }: PayloadAction<ConfirmationState>) {
      state.show = payload.show;
      state.title = payload?.title;
      state.text = payload.text;
      state.confirmButtonProps = payload.confirmButtonProps;
      state.cancelButtonProps = payload.cancelButtonProps;
      state.onClose = payload.onClose;
      state.isConfirm = payload.isConfirm;
    },
    resetConfirmationData(state: ConfirmationState) {
      state.show = false;
      state.confirmButtonProps = { children: 'Ok', onClick: () => {} };
      state.cancelButtonProps = undefined;
      state.title = undefined;
      state.text = undefined;
      state.onClose = undefined;
    }
  }
});

const selectConfirmation = (state: RootState): ConfirmationState => state.confirmation;

export const confirmationSelector = createSelector([selectConfirmation], (confirmation) => confirmation);

export const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;

export default confirmationSlice.reducer;

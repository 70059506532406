import styled from 'styled-components';
import { containerMixin } from 'mixins';
import { Form } from 'components/ui';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const StyledForm = styled(Form)`
  margin: 2.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const StyledFormContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 1.5rem;
`;

export const Column = styled.div<ColumnT>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems && alignItems) || 'initial'};
`;

import React, { FC, ReactNode } from 'react';
import { LeftWrapper, RightWrapper, StyledContainer } from './styled';
import useGlobalTranlation from 'hooks/useGlobalTranlation';

type Props = {
  name: ReactNode;
  status?: 'active' | 'expired' | 'finished' | 'archived';
  info?: ReactNode;
  onClick?: () => void;
  className?: string;
};

const SimpleRow: FC<Props> = ({ name, status, onClick, info, className }) => {
  const { g } = useGlobalTranlation();

  return (
    <StyledContainer {...{ className, status, onClick }}>
      <LeftWrapper>{name}</LeftWrapper>
      <RightWrapper>
        {(status && g(`options.status.${status}`)) || ('string' === typeof info && g(info)) || info}
      </RightWrapper>
    </StyledContainer>
  );
};

export default SimpleRow;

import format from 'date-fns/format';
import { Bilag } from 'domain/entity/documnets/CommonBilag';
import { BilagFormDataT } from 'domain/entity/bilag/BilagFormData';
import { SelectOptionT } from 'components/controls/Select';

export const COST_TYPE: SelectOptionT[] = [
  { value: 'maintenance', label: 'maintenance' },
  { value: 'other_costs', label: 'otherCosts' },
  { value: 'attachment_fee', label: 'fixingFree' },
  { value: 'municipal_taxes', label: 'municipalTaxes' },
  { value: 'paid_salary', label: 'paidSalary' }
];

export const REPEAT: SelectOptionT[] = [
  { value: 'never', label: 'never' },
  { value: 'weekly', label: 'weekly' },
  { value: 'monthly', label: 'monthly' }
];

export const getBilagToBilagData = (bilag: Bilag): BilagFormDataT => {
  return {
    id: bilag.id,
    date: format(new Date(bilag.date), 'dd.MM.yyyy'),
    franchise: bilag.franchise,
    repeat: bilag.repeat,
    sum: String(bilag.sum || ''),
    cost_type: bilag.cost_type,
    houseId: bilag.houseId,
    archived: bilag.archived,
    description: bilag.description,
    supplierId: bilag.supplier.id,
    supplier_address: bilag.supplier.address,
    supplier_city: bilag.supplier.city,
    supplier_name: bilag.supplier.name,
    supplier_postcode: bilag.supplier.postcode,
    documents: bilag.documents
  };
};

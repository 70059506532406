import React, { FC, forwardRef, InputHTMLAttributes } from 'react';

import { Wrapper, Label, CustomRadio, Checked, Input } from './styled';
import { Control, useWatch } from 'react-hook-form';

type PropsT = InputHTMLAttributes<HTMLInputElement> & {
  control?: Control<any>;
  label?: string;
  isRevert?: boolean;
};

const RadioButton = forwardRef<HTMLInputElement, PropsT>((props, ref) => {
  const { label, isRevert, control, name, className, ...restProps } = props;
  const selectedValue = useWatch({
    control,
    name
  });
  const isSelected = restProps.value === selectedValue;

  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      <CustomRadio isSelected={isSelected}>
        <Checked isSelected={isSelected} />
      </CustomRadio>
      <Input type={'radio'} ref={ref} name={name} {...restProps} />
    </Wrapper>
  );
});

export default RadioButton;

import styled, { css } from 'styled-components';
import { Button } from 'components/ui';
import { hidden, mq } from '../../../../constant/mediaqueries';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}
export const StyledSwitchWrapper = styled.div`
  width: max-content;
`;
export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1620px;
`;

export const StyledButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.2px;
`;

export const StyledActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1.25rem 0 0 0;

  ${hidden.lowerXs}
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div<ColumnT>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems && alignItems) || 'initial'};
`;

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray03};
  letter-spacing: 0.15px;
  line-height: 1.5em;
`;

export const Header = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
`;

export const ActionButtons = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
  gap: 15px;

  ${mq.lowerLg} {
    width: 100%;
    justify-content: end;
    margin: 20px 0 0;
  }

  ${mq.lowerXs} {
    justify-content: space-between;
    justify-items: center;
    grid-auto-flow: initial;
    grid-template-columns: 1fr 1fr;
  }
`;

export const iconButtonCss = css`
  fill: currentColor;
  width: 24px;
`;

export const LeftBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;

  ${mq.lowerLg} {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  ${mq.lowerXs} {
    grid-auto-flow: row;
  }
`;

export const Status = styled.div`
  width: max-content;
  padding: 1px 12px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.gray07};
  color: ${({ theme }) => theme.colors.gray03};
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;

  ${mq.lowerXs} {
    padding: 1px 0;
  }
`;

export const TitleText = styled.span`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { closeDeposit, contractSlice, fetchFinishContract } from '../../../../../data/store/rentContractReducer';
import { confirmationSlice } from '../../../../../data/store/confirmationReducer';
import { actionsSlice } from '../../../../../data/store/actionsReducer';
import { modalsSlice } from '../../../../../data/store/modalReducer';
import { useHistory } from 'react-router';
import useGlobalTranslation from '../../../../../hooks/useGlobalTranlation';
import Button from '../../../../../components/controls/Button';
import { ActionButtons, iconButtonCss, StyledButton } from './styled';
import Icon from '../../../../../components/common/Icon';
import deleteIcon from 'components/svg/delete.svg?sprite';

type Props = {
  isHiddenTop?: boolean;
  isHiddenBottom?: boolean;
};

export const Buttons: FC<Props> = ({ isHiddenTop, isHiddenBottom }) => {
  const { currentContract } = useAppSelector(({ contract }) => contract);
  const { deleteAllIncomeBilags } = contractSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { setActionsData, resetActionsData } = actionsSlice.actions;
  const { openRentContract, openReleaseDeposit } = modalsSlice.actions;
  const { g, t } = useGlobalTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onPressFinishContract = () => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('alerts.finish.contract.buttons.no')
        },
        confirmButtonProps: {
          onClick: () => {
            if (currentContract) dispatch(fetchFinishContract(currentContract.id));
            dispatch(resetConfirmationData());
          },
          children: g('alerts.finish.contract.buttons.yes')
        },
        text: g('alerts.finish.contract.message'),
        title: g('alerts.finish.contract.title')
      })
    );
  };

  const onEditContract = () => {
    dispatch(openRentContract());
  };

  const onPressCloseDeposit = () => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('alerts.closeDeposit.buttons.no')
        },
        confirmButtonProps: {
          onClick: () => {
            if (currentContract) dispatch(closeDeposit(currentContract.id));
            dispatch(resetConfirmationData());
          },
          children: g('alerts.closeDeposit.buttons.yes')
        },
        text: g('alerts.closeDeposit.message'),
        title: g('alerts.closeDeposit.title')
      })
    );
  };

  const onPressDelete = () => {
    dispatch(
      setActionsData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetActionsData());
          },
          children: g('alerts.finish.contract.buttons.no')
        },
        actions: [
          <Button
            variant="text"
            color={'red01'}
            onClick={() => {
              onPressDeleteAllBilags();
              dispatch(resetActionsData());
            }}
          >
            {t('alerts.finish.contract.deleteBilagsOfContract')}
          </Button>
        ],
        title: g('alerts.deleteAll.delete')
      })
    );
  };

  const onPressDeleteAllBilags = () => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('alerts.finish.contract.buttons.no')
        },
        confirmButtonProps: {
          onClick: () => {
            if (currentContract) dispatch(deleteAllIncomeBilags(currentContract.id));
            history.goBack();
            dispatch(resetConfirmationData());
          },
          children: g('alerts.deleteAll.delete')
        },
        text: g('alerts.deleteAll.message'),
        title: g('alerts.deleteAll.title')
      })
    );
  };

  return (
    <ActionButtons isHiddenTop={isHiddenTop} isHiddenBottom={isHiddenBottom}>
      {currentContract && currentContract.status === 'active' && (
        <>
          <StyledButton variant={'text'} color={'red01'} onClick={onPressDelete}>
            <Icon icon={deleteIcon} css={iconButtonCss} />
            {g('alerts.deleteAll.delete')}
          </StyledButton>
          <StyledButton variant={'text'} color={'red01'} onClick={onPressFinishContract}>
            {g('finishContract')}
          </StyledButton>
          {!currentContract.signatureType && (
            <StyledButton variant={'text'} color={'blue01'} onClick={onEditContract}>
              {g('editContract')}
            </StyledButton>
          )}
        </>
      )}
      {currentContract?.signicatInfo?.depositStatus === 'founds' && (
        <StyledButton variant={'text'} color={'blue01'} onClick={() => dispatch(openReleaseDeposit())}>
          {g('releaseDeposit')}
        </StyledButton>
      )}
      {currentContract?.signicatInfo?.depositStatus === 'opened' && (
        <StyledButton variant={'text'} color={'red01'} onClick={onPressCloseDeposit}>
          {g('closeDeposit')}
        </StyledButton>
      )}
    </ActionButtons>
  );
};

import React, { FC } from 'react';
import ArchiveBilag from './ArchiveBilag';
import MoveToTrash from './MoveToTrash';
import DiscardChange from './DiscardChange';
import ManyHouses from './ManyHouses';

const ConfirmationModals: FC = () => {
  return (
    <>
      <ArchiveBilag />
      <MoveToTrash />
      <DiscardChange />
      <ManyHouses />
    </>
  );
};

export default ConfirmationModals;

import React, { FC } from 'react';
import Button, { PropsT as ButtonPropsT } from 'components/controls/Button';
import { Title, Text, ActionContainer, contentCss } from './styled';
import Modal from '../Modal';

type PropsT = {
  isOpen: boolean;
  title?: string;
  text: string;
  cancelButtonProps?: Omit<ButtonPropsT, 'color' | 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: () => void;
  };
  onClose?: () => void;
  confirmButtonProps: Omit<ButtonPropsT, 'color' | 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: (handleClose: () => void) => void;
    href?: string;
  };
  isConfirm?: boolean;
};

const ConfirmationModal: FC<PropsT> = (props) => {
  const { isOpen, title, text, confirmButtonProps, cancelButtonProps, isConfirm, onClose } = props;

  const handleCloseClick = () => {
    (cancelButtonProps && cancelButtonProps.onClick()) || (onClose && onClose());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseClick}
      contentCss={contentCss}
      isConfirm={isConfirm}
      content={(onClose) => (
        <>
          {!!title && <Title>{title}</Title>}
          <Text>{text}</Text>
          <ActionContainer>
            {cancelButtonProps && <Button variant="text" {...cancelButtonProps} onClick={onClose} />}
            {!confirmButtonProps?.href && (
              <Button variant="text" {...confirmButtonProps} onClick={() => confirmButtonProps.onClick(onClose)} />
            )}
            {confirmButtonProps?.href && (
              <Button
                style={{ padding: '1px 6px' }}
                as={'a'}
                variant="text"
                {...confirmButtonProps}
                onClick={() => confirmButtonProps.onClick(onClose)}
                target={'_blank'}
              />
            )}
          </ActionContainer>
        </>
      )}
    />
  );
};

export default ConfirmationModal;

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { getErrMsg } from 'helpers/locale';
import Input, { Props as InputPropsT } from 'components/controls/Input/Input';
import DateField from 'components/controls/DateField';
import PhoneField from '../../controls/PhoneField';
import Select from '../../FormControls/Select';
import { SelectOptionT } from '../../controls/Select';

export type FieldT = InputPropsT & {
  type: string;
  name: string;
  text: string;
  required?: boolean;
  serverError?: boolean;
  initialValue?: string;
  maxLength?: number;
  options?: SelectOptionT[];
};

type PropsT = {
  fields: FieldT[];
  inputProps?: InputPropsT;
};

const HouseFields: FC<PropsT> = (props) => {
  const { fields, inputProps } = props;
  const { g } = useGlobalTranslation();
  const { register, formState, getValues, control } = useFormContext<HouseCreateFormT>();

  return (
    <>
      {fields.map(({ name, text, serverError, type, options, style, ...restProps }) => (
        <React.Fragment key={name}>
          {(type === 'text' || type === 'number') && (
            <Input
              {...inputProps}
              maxLength={50}
              type={type}
              {...restProps}
              label={g('inputs.' + text)}
              register={register(name)}
              error={formState.errors[name]}
              errorMessage={getErrMsg(formState.errors, name)}
              serverError={(serverError && g(formState.errors[name] || '')) || undefined}
              wrapperStyle={style}
            />
          )}
          {type === 'date' && (
            <DateField
              {...inputProps}
              type={'date'}
              value={getValues(name)}
              label={g('inputs.' + text)}
              register={register(name)}
              error={formState.errors[name]}
              errorMessage={getErrMsg(formState.errors, name)}
              serverError={(serverError && g(formState.errors[name] || '')) || undefined}
              wrapperStyle={style}
            />
          )}
          {type === 'phone' && (
            <PhoneField
              {...inputProps}
              label={g(`inputs.${text}`)}
              error={formState.errors.phone}
              wrapperStyle={style}
              {...restProps}
            />
          )}
          {type === 'selectCountry' && (
            <Select
              control={control}
              name={name}
              label={g(`inputs.${text}`)}
              options={options || []}
              isSearchable
              isRequired
              error={formState.errors[name]}
              variant={inputProps?.variant}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default HouseFields;

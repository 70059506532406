export type RegistrationFormT = {
  email?: string;
  password: string;
  repeatPassword: string;
  isAgreePolicy: boolean;
};

const INITIAL_REGISTRATION_FORM_DATA: RegistrationFormT = {
  email: '',
  password: '',
  repeatPassword: '',
  isAgreePolicy: false
};

export default INITIAL_REGISTRATION_FORM_DATA;

import React, { FC } from 'react';
import { InfoFieldT } from 'domain/entity/common';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { Contract } from 'domain/entity/rentContract/RentContract';
import InfoBlock from '../InfoBlock';
import {
  Column,
  StyledHr,
  StyledModalContainer,
  StyledTextButton,
  StyledWrapper,
  Link,
  StyledRouteLink,
  StyledLinkButton,
  SignLinkContainer,
  copyIconCss,
  CopyButton,
  LinkContainer,
  ContentPopup,
  PopupContent,
  MainText,
  Block,
  Line,
  ErrorButton,
  PopupErrorContent,
  Text,
  Status,
  StatusContainer
} from './styled';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { modalsSlice } from '../../../../../data/store/modalReducer';
import Icon from '../../../../../components/common/Icon';
import copyIcon from 'components/svg/copy.svg?sprite';
import Popup from '../../../../../components/common/Popup';
import dayjs from 'dayjs';
import { contractSlice } from '../../../../../data/store/rentContractReducer';
import { makeDownloadFromUrl } from '../helpers';
import { getTextSignicatError } from '../../../../../helpers/signicatErrors';
import useGlobalTranlation from '../../../../../hooks/useGlobalTranlation';
import { hostname } from 'os';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { alertSlice } from '../../../../../data/store/alertReducer';

interface PropsI {
  currentContract?: Contract;
  onClickSignatureBankId: (checkList?: boolean) => void;
  onClickSignature: () => void;
  onClickPictures: () => void;
  onClickPdf: () => void;
  onClickChecklistPdf: () => void;
}

const ContractInfo: FC<PropsI> = ({
  currentContract,
  onClickSignature,
  onClickSignatureBankId,
  onClickPictures,
  onClickPdf,
  onClickChecklistPdf
}) => {
  const generalFields: InfoFieldT[] = [
    {
      value: (currentContract && `${currentContract.tenant_first_name} ${currentContract.tenant_last_name}`) || '',
      label: 'inputs.fullName',
      type: 'text'
    },
    {
      value: (currentContract && `${currentContract.tenant_phone_code || ''}${currentContract.tenant_phone}`) || '',
      label: 'inputs.phone',
      type: 'text'
    },
    {
      value: (currentContract && currentContract.tenant_birthday) || '',
      label: 'inputs.birth',
      type: 'date'
    },
    {
      value: (currentContract && currentContract.tenant_email) || '',
      label: 'inputs.email',
      type: 'text'
    },
    {
      value: (currentContract && currentContract.month_sum) || '',
      label: 'inputs.rent',
      type: 'currency'
    }
  ];

  const depositFields: InfoFieldT[] = [
    {
      hidden: !(currentContract && currentContract.deposit_sum !== null),
      value: (currentContract && currentContract.deposit_sum) || '',
      label: 'inputs.depositAmount',
      type: 'currency'
    },
    {
      hidden: !(currentContract && currentContract.deposit_account),
      value: (currentContract && currentContract.deposit_account) || '',
      label: 'inputs.depositAccountIBAN',
      type: 'text'
    },
    {
      hidden: !(
        currentContract &&
        (currentContract.deposit_payment_deadline || currentContract?.deposit_count_payment_day)
      ),
      value:
        (currentContract &&
          (currentContract.deposit_payment_deadline ||
            dayjs().add(currentContract?.deposit_count_payment_day, 'days').format('YYYY.MM.DD'))) ||
        '',
      label: 'inputs.paymentDeadline',
      type: 'date'
    }
  ];

  const moreDetailsFields: InfoFieldT[] = [
    {
      value: (currentContract && `options.contractType.${currentContract.type}`) || '',
      label: 'inputs.type',
      type: 'text'
    },
    {
      value: (currentContract && currentContract.first_payment_date) || '',
      label: 'inputs.dateOfFirstPayment',
      type: 'date'
    },
    {
      value: (currentContract && currentContract.start_date) || '',
      label: 'inputs.start',
      type: 'date'
    },
    {
      value: (currentContract && currentContract.month_resignation) || '',
      label: 'inputs.numberOfMonthResignation',
      type: 'text'
    },
    {
      value: (currentContract && currentContract.keys_count) || '',
      label: 'inputs.numberOfKeysHandedOut',
      type: 'text'
    },
    {
      value: (currentContract && currentContract.taxable) || false,
      label: 'inputs.taxable',
      type: 'boolean'
    }
  ];
  const optionsFields: InfoFieldT[] = [
    {
      value: (currentContract && currentContract.comforts?.includes('electricity')) || false,
      label: 'inputs.comfortOptions.electricity',
      type: 'boolean'
    },
    { value: (currentContract && currentContract.pets_allowed) || false, label: 'inputs.pets', type: 'boolean' },
    {
      value: (currentContract && currentContract.comforts?.includes('heating')) || false,
      label: 'inputs.comfortOptions.heating',
      type: 'boolean'
    },
    { value: (currentContract && currentContract.smoking_allowed) || false, label: 'inputs.smoking', type: 'boolean' },
    {
      value: (currentContract && currentContract.comforts?.includes('water-drain')) || false,
      label: 'inputs.comfortOptions.waterDrain',
      type: 'boolean'
    },
    {
      value: (currentContract && currentContract.comforts?.includes('tv-subscription')) || false,
      label: 'inputs.comfortOptions.tvSubscription',
      type: 'boolean'
    },
    {
      value: (currentContract && currentContract.comforts?.includes('internet')) || false,
      label: 'inputs.comfortOptions.internet',
      type: 'boolean'
    }
  ];
  const { g, t } = useGlobalTranslation();
  const { t: errors } = useGlobalTranlation('signicatErrors');
  const { t: checklist } = useGlobalTranslation('checklist');
  const { user } = useAppSelector((state) => state.profile);
  const { openUpdatePhoneNumber, openCheckContract } = modalsSlice.actions;
  const { regenerateSignContractByBankID } = contractSlice.actions;
  const { setAlertData } = alertSlice.actions;
  const dispatch = useAppDispatch();

  const status = (() => {
    if (
      currentContract?.signicatInfo?.depositStatus === 'released' &&
      currentContract?.signicatInfo?.releasedCategory === 'SPLIT'
    ) {
      return g('contract.status.releasedDepositSplit', { amount: currentContract?.signicatInfo?.tenantReleaseSum });
    }
    if (currentContract?.signicatInfo?.depositStatus === 'released') {
      return g('contract.status.releasedDeposit');
    }
    if (currentContract?.signicatInfo?.depositStatus === 'opened') {
      return g('contract.status.openedDeposit');
    }
    if (currentContract?.signicatInfo?.depositStatus === 'canceled') {
      return g('contract.status.canceledDeposit');
    }
    if (currentContract?.signicatInfo?.founds === currentContract?.deposit_sum) {
      return g('contract.status.depositFullyFounds', { found: currentContract?.signicatInfo?.founds });
    }
    if (currentContract?.signicatInfo?.founds && currentContract?.signicatInfo?.founds < currentContract?.deposit_sum) {
      return g('contract.status.depositPartiallyFounds', { found: currentContract?.signicatInfo?.founds });
    }
  })();

  const handleCopiedLink = (type: 'tc' | 'tcl') => {
    const textField = document.createElement('input');
    textField.setAttribute(
      'value',
      `${window.location.protocol}//${window.location.host}/r/${type}/${currentContract?.additionalId}` || ''
    );
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    dispatch(setAlertData({ text: g('copied'), variant: 'success' }));
  };

  return (
    <Column>
      <Block>
        <Line>
          <MainText>{g('contractText')}</MainText>
          {currentContract?.signicatInfo?.error && (
            <Popup
              button={(onClick) => <ErrorButton onClick={onClick}>{t('error')}</ErrorButton>}
              content={() => (
                <PopupErrorContent>
                  <Text>{`${g('inputs.date')}: ${dayjs(currentContract?.signicatInfo.error?.date).format(
                    'DD.MM.YYYY HH:mm'
                  )}`}</Text>
                  {getTextSignicatError(currentContract?.signicatInfo?.error.message)
                    .map((key) => errors(key))
                    .join('. ')}
                </PopupErrorContent>
              )}
            />
          )}
        </Line>
        <StyledModalContainer>
          {(currentContract?.signicatInfo?.tenantLink || currentContract?.signicatInfo?.landlordLink) &&
            currentContract.status !== 'finished' &&
            !currentContract?.signicatInfo?.signedDocumentUrl && (
              <LinkContainer>
                {currentContract.signicatInfo?.isSignedTenant && (
                  <StyledRouteLink to={`/r/lc/${currentContract?.additionalId}`}>{t('landlordLink')}</StyledRouteLink>
                )}
                {!currentContract.signicatInfo?.isSignedTenant && (
                  <Popup
                    mode={'hover'}
                    button={<Link disabled>{t('landlordLink')}</Link>}
                    content={() => <ContentPopup>{g('helpersText.signLandlordAfterTenant')}</ContentPopup>}
                  />
                )}
                <SignLinkContainer onClick={() => handleCopiedLink('tc')}>
                  <StyledRouteLink as={'span'}>{t('tenantLink')}</StyledRouteLink>
                  <CopyButton as={'span'}>
                    <Icon icon={copyIcon} css={copyIconCss} />
                  </CopyButton>
                </SignLinkContainer>
              </LinkContainer>
            )}
          <LinkContainer>
            {currentContract?.is_open_deposit_account &&
              !currentContract?.signicatInfo?.isSignedTenant &&
              currentContract.signicatInfo?.tenantLink &&
              currentContract.status !== 'finished' && (
                <StyledTextButton
                  onClick={() =>
                    dispatch(
                      regenerateSignContractByBankID({
                        id: currentContract?.id || NaN
                      })
                    )
                  }
                >
                  {g('regenerate')}
                </StyledTextButton>
              )}
            {(currentContract?.signatureType === null || currentContract?.signatureType === 'manual') &&
              currentContract.status !== 'finished' && (
                <>
                  {(!currentContract.is_open_deposit_account || currentContract.is_open_deposit_account_payed) && (
                    <Popup
                      mode={user?.subscriptionActive ? 'click' : 'hover'}
                      button={
                        <StyledTextButton
                          onClick={() =>
                            user?.phone_code && user?.country
                              ? onClickSignatureBankId()
                              : dispatch(openUpdatePhoneNumber())
                          }
                          disabled={!user?.subscriptionActive}
                        >
                          {g('signatureByBankId')}
                        </StyledTextButton>
                      }
                      content={() => <PopupContent>{g('helpersText.signByBankIdWithoutSubscription')}</PopupContent>}
                    />
                  )}
                  {currentContract.is_open_deposit_account && !currentContract.is_open_deposit_account_payed && (
                    <Popup
                      mode={'hover'}
                      button={
                        <StyledTextButton
                          disabled={!user?.subscriptionActive}
                          onClick={() =>
                            user?.phone_code && user?.country
                              ? onClickSignatureBankId()
                              : dispatch(openUpdatePhoneNumber())
                          }
                        >
                          {g('signatureByBankIdWithDeposit')}
                        </StyledTextButton>
                      }
                      content={() => {
                        console.log(user?.subscriptionActive);

                        return (
                          <PopupContent>
                            {user?.subscriptionActive
                              ? g('helpersText.payOpenDepositAccount')
                              : g('helpersText.openDepositTextWithoutSubscription')}
                          </PopupContent>
                        );
                      }}
                    />
                  )}
                  <StyledTextButton onClick={onClickSignature}>{g('signatureManual')}</StyledTextButton>
                </>
              )}
            {currentContract && currentContract?.documents?.length > 0 && (
              <StyledTextButton onClick={onClickPictures}>{g('pictures')}</StyledTextButton>
            )}
            {!currentContract?.signicatInfo?.signedDocumentUrl && (
              <StyledTextButton onClick={onClickPdf}>{g('contractPdf')}</StyledTextButton>
            )}
            {currentContract?.signicatInfo?.signedDocumentUrl && (
              <StyledTextButton
                onClick={() =>
                  makeDownloadFromUrl(
                    `${currentContract?.tenant_first_name} ${currentContract?.tenant_last_name}`,
                    currentContract?.signicatInfo?.signedDocumentUrl || ''
                  )
                }
              >
                {g('contractSignedPdf')}
              </StyledTextButton>
            )}
          </LinkContainer>
        </StyledModalContainer>
      </Block>
      <Block>
        <MainText>{checklist('checklist')}</MainText>
        <StyledModalContainer>
          {currentContract?.checkList?.signicatInfo?.signedDocumentUrl && (
            <StyledLinkButton href={currentContract?.checkList?.signicatInfo?.signedDocumentUrl} target={'_blank'}>
              {g('checkListSignedPdf')}
            </StyledLinkButton>
          )}
          {currentContract?.checkList?.signicatInfo?.landlordLink &&
            !currentContract?.checkList.signicatInfo.signedDocumentUrl &&
            currentContract.status !== 'finished' && (
              <LinkContainer>
                {currentContract?.checkList?.signicatInfo?.isSignedTenant && (
                  <StyledRouteLink to={`/r/lcl/${currentContract?.additionalId}`}>{t('landlordLink')}</StyledRouteLink>
                )}
                {!currentContract?.checkList?.signicatInfo?.isSignedTenant && (
                  <Popup
                    mode={'hover'}
                    button={<Link disabled>{t('landlordLink')}</Link>}
                    content={() => <ContentPopup>{g('helpersText.signLandlordAfterTenant')}</ContentPopup>}
                  />
                )}
                <SignLinkContainer onClick={() => handleCopiedLink('tcl')}>
                  <StyledRouteLink as={'span'}>{t('tenantLink')}</StyledRouteLink>
                  <CopyButton as={'span'}>
                    <Icon icon={copyIcon} css={copyIconCss} />
                  </CopyButton>
                </SignLinkContainer>
              </LinkContainer>
            )}
          <LinkContainer>
            {currentContract?.checkList &&
              !currentContract?.checkList.signatureType &&
              currentContract.status !== 'finished' && (
                <>
                  <StyledTextButton
                    onClick={() => {
                      onClickSignatureBankId(true);
                    }}
                  >
                    {g('signatureByBankId')}
                  </StyledTextButton>
                  <StyledTextButton onClick={onClickSignature}>{g('signatureManual')}</StyledTextButton>
                </>
              )}
            {currentContract?.checkList && !currentContract?.checkList?.signicatInfo?.signedDocumentUrl && (
              <StyledTextButton onClick={onClickChecklistPdf}>{g('checklistPdf')}</StyledTextButton>
            )}
            {!currentContract?.checkList?.signicatInfo?.signedDocumentUrl && currentContract?.status !== 'finished' && (
              <StyledTextButton onClick={() => dispatch(openCheckContract())}>
                {currentContract?.checkList ? g('editChecklist') : g('createChecklist')}
              </StyledTextButton>
            )}
          </LinkContainer>
        </StyledModalContainer>
      </Block>
      <InfoBlock heading={g('generalInfo')} fields={generalFields} />
      {currentContract?.deposit_sum !== null && (
        <InfoBlock
          heading={
            <StatusContainer>
              {g('inputs.deposit')}
              <Status>{status}</Status>
            </StatusContainer>
          }
          fields={depositFields}
        />
      )}
      <InfoBlock heading={g('moreContractDetails')} fields={moreDetailsFields} />
      <StyledHr />
      <InfoBlock fields={optionsFields} />
      <StyledHr />
      {currentContract && <StyledWrapper>{currentContract.other}</StyledWrapper>}
    </Column>
  );
};

export default ContractInfo;

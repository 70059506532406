import styled, { css } from 'styled-components';

export const Label = styled.label`
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const StyledContainer = styled.div<{ border: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0 0;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 1rem;
  ${(props) => {
    return (
      (props.border &&
        css`
          border: 1px solid ${({ theme }) => theme.colors.gray01};
          border-radius: 15px;
        `) ||
      ''
    );
  }}
`;

export const StyledText = styled.div`
  max-width: 300px;
  text-align: right;
  text-overflow: ellipsis;
`;

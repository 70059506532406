import styled, { css, CSSProp } from 'styled-components';
import { hidden, mq } from 'constant/mediaqueries';

const margin = css`
  margin: 0.5rem 1rem 0.5rem 0;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
`;

export const StyledImg = styled.img<{ imgCss?: CSSProp }>`
  margin: 0.5rem 1rem 0.5rem 0;
  max-width: 136px;
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-radius: 3px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.3));
  }

  &:last-of-type {
    margin: 1rem 0;
  }

  ${({ imgCss }) => imgCss && imgCss}
`;

export const StyledImageLowerXs = styled(StyledImg)`
  ${mq.lowerSm} {
    display: none;
  }
`;

export const ImageLink = styled.a`
  display: none;

  ${mq.lowerSm} {
    display: block;
  }
`;

export const PdfLink = styled.a<{ imgCss?: CSSProp }>`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 136px;
  width: 100%;
  height: 128px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  ${margin}
  :hover {
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.3));
  }

  ${({ imgCss }) => imgCss && imgCss}
`;

export const iconCss = css`
  width: 100%;
`;

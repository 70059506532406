import React from 'react';
import { StyledWrapper, StyledLogo, StyledText } from './styled';
import logoImg from 'styles/images/logo_big.png';
import { ROUTE_DASHBOARD } from '../../../constant/routes';

type Props = {
  full?: boolean;
  className?: string;
};

const Logo = ({ className, full = false }: Props) => {
  return (
    <StyledWrapper to={ROUTE_DASHBOARD}>
      <StyledLogo {...{ className }} src={logoImg} alt={'logo'} />
      <StyledText full={full}>Utleieappen</StyledText>
    </StyledWrapper>
  );
};

export default Logo;

import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  align-content: space-between;
  padding: 50px 0;
  height: calc(100vh - 60px);

  ${mq.lowerXs} {
    padding: 20px 0;
    height: calc(100vh - 150px);
  }
`;

export const Text = styled.p`
  margin: 0 0 5px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
`;

export const Content = styled.div`
  display: grid;
  justify-items: center;
`;

export const ActionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
`;

export const Button = styled.a`
  width: 274px;
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  border-radius: 3px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 1.2px;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`;

export const messengerIconCss = css`
  width: 24px;
  fill: #2196f3;
`;

export const emailIconCss = css`
  width: 24px;
  fill: currentColor;
`;

export const fbIconCss = css`
  width: 9px;
  fill: currentColor;
`;
export const instagramIconCss = css`
  width: 20px;
  fill: currentColor;
`;

export const SocialLinks = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
`;

export const LinkApp = styled.a`
  cursor: pointer;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`;

export const storeIconCss = css`
  width: 155px;
`;

import React, { useState, FC, useEffect, ReactNode } from 'react';
import closeIConSvg from 'components/svg/close_icon.svg?sprite';
import { Wrapper, Fade, Content, Title, ButtonClose, closeIconCss } from './styled';
import Icon from '../Icon';
import { CSSProp } from 'styled-components';

type PropsT = {
  isOpen: boolean;
  title?: string;
  contentCss?: CSSProp;
  isHiddenCloseButton?: boolean;
  onClose?: () => void;
  content?: (onClose: () => void) => ReactNode;
  isConfirm?: boolean;
  isDirty?: boolean;
  withoutAnimation?: boolean;
};

const Modal: FC<PropsT> = (props) => {
  const {
    isOpen,
    title,
    onClose = () => {},
    contentCss,
    children,
    content,
    isConfirm,
    isHiddenCloseButton,
    isDirty,
    withoutAnimation
  } = props;
  const [startHiddenAnimation, setStartHiddenAnimation] = useState(false);

  const handleCloseClick = () => {
    if (!withoutAnimation) {
      setStartHiddenAnimation(true);
      setTimeout(() => {
        setStartHiddenAnimation(false);
        onClose();
      }, 250);
      return;
    }
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const hadScrollPosition = document.body.getAttribute('data-body-scroll-fix');
      if (hadScrollPosition === null) {
        document.body.setAttribute('data-body-scroll-fix', String(scrollPosition));
        document.body.style.overflowY = 'scroll';
        document.body.style.position = 'fixed';
        document.body.style.top = '-' + scrollPosition + 'px';
        document.body.style.left = '0';
        document.body.style.width = '100%';
      }
    }

    return () => {
      if (!isConfirm && isOpen) {
        const scrollPosition = document.body.getAttribute('data-body-scroll-fix');
        document.body.removeAttribute('data-body-scroll-fix');
        document.body.setAttribute('style', 'overflow-x: hidden');
        document.body.style.overflowY = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.left = '';
        document.body.style.width = '';
        window.scroll(0, Number(scrollPosition));
      }
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Wrapper
      isHidden={startHiddenAnimation}
      top={Number(document.body.getAttribute('data-body-scroll-fix')) || document.documentElement.scrollTop}
    >
      <Content css={contentCss}>
        {!isHiddenCloseButton && (
          <ButtonClose variant={'text'} onClick={handleCloseClick}>
            <Icon icon={closeIConSvg} css={closeIconCss} />
          </ButtonClose>
        )}
        {title && <Title>{title}</Title>}
        {content ? content(handleCloseClick) : children}
      </Content>
      <Fade onClick={handleCloseClick} />
    </Wrapper>
  );
};

export default Modal;

import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { DepreciationFormDataT } from '../../domain/entity/depreciation/DepreciationFormData';
import {
  createDepreciationError,
  CreateDepreciationPersonalBilagPayload,
  createDepreciationSuccess,
  deleteDepreciationBilagError,
  deleteDepreciationBilagSucces,
  fetchDepreciationPersonalCalculationError,
  fetchDepreciationPersonalCalculationSuccess
} from '../store/depreciationReducer';
import {
  createDepreciationApi,
  createDepreciationPersonalUseApi,
  deleteDepreciationBilagApi,
  deleteDepreciationPersonalUseBilagApi,
  fetchDepreciationPersonalCalculationApi
} from '../driver/depreciation/Depreciation';
import { uploadFileApi } from '../driver/file/File';
import { closeDepreciation, closeInfoBilag } from '../store/modalReducer';
import HouseData from '../../domain/entity/house/HouseData';
import { setSelectedHouse } from '../store/houseReducer';
import { deleteManualBilagApi } from '../driver/bilag/Bilag';
import { deleteManualBilagError, deleteManualBilagSucces } from '../store/bilagReducer';
import { fetchAllDocuments, setIsTrashTrue } from '../store/documentReducer';

export function* createDepreciationSaga({
  payload: { data, currentHouse }
}: PayloadAction<{ data: DepreciationFormDataT; currentHouse: HouseData }>) {
  try {
    const response = yield call(uploadFileApi, data.documents || []);
    yield call(createDepreciationApi, { ...data, documents: response });
    yield put(fetchAllDocuments({ houseId: Number(data.houseId) }));
    yield put(createDepreciationSuccess());
    yield put(setSelectedHouse({ ...currentHouse }));
    yield put(closeDepreciation());
  } catch (e) {
    yield put(createDepreciationError(e?.response?.data?.message || e?.message));
  }
}

export function* createDepreciationPersonalUseSaga({
  payload: { data, currentHouse }
}: PayloadAction<{ data: DepreciationFormDataT; currentHouse: HouseData }>) {
  try {
    yield call(createDepreciationPersonalUseApi, data);
    yield put(createDepreciationSuccess());
    yield put(
      setSelectedHouse({ ...currentHouse, depreciationYears: [...currentHouse.depreciationYears, Number(data.year)] })
    );
    yield put(closeDepreciation());
  } catch (e) {
    yield put(createDepreciationError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchDepreciationPersonalCalculationSaga({
  payload
}: PayloadAction<CreateDepreciationPersonalBilagPayload>) {
  try {
    const response = yield call(fetchDepreciationPersonalCalculationApi, payload);
    yield put(fetchDepreciationPersonalCalculationSuccess(response));
  } catch (e) {
    yield put(fetchDepreciationPersonalCalculationError(e?.response?.data?.message || e?.message));
  }
}

export function* deleteDepreciationBilagSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(deleteDepreciationBilagApi, payload);
    yield put(deleteDepreciationBilagSucces());
    yield put(setIsTrashTrue(payload));
    yield put(closeInfoBilag());
  } catch (e) {
    yield put(deleteDepreciationBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* deleteDepreciationPersonalUseBilagSaga({
  payload
}: PayloadAction<{ year: number; house: HouseData }>) {
  try {
    yield call(deleteDepreciationPersonalUseBilagApi, { year: payload.year, houseId: payload.house.id });
    yield put(
      setSelectedHouse({
        ...payload.house,
        depreciationYears: payload.house.depreciationYears.filter((year) => year !== payload.year)
      })
    );
    yield put(deleteDepreciationBilagSucces());
    yield put(closeInfoBilag());
  } catch (e) {
    yield put(deleteDepreciationBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

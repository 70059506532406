import ChangePasswordT from '../../domain/entity/profile/ChangePasswordT';
import ChangeEmailT from '../../domain/entity/profile/ChangeEmailT';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export interface AccountSettingsState {
  data?: ChangePasswordT | ChangeEmailT;
  pending?: boolean;
  error: string;
}

const initialState: AccountSettingsState = {
  data: undefined,
  pending: undefined,
  error: ''
};
export const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    accountSettingsChangeEmailFetching(state: AccountSettingsState, _: PayloadAction<ChangeEmailT>) {
      state.pending = true;
    },
    accountSettingsChangePasswordFetching(state: AccountSettingsState, _: PayloadAction<ChangePasswordT>) {
      state.pending = true;
    },
    accountSettingsChangeSuccess(state: AccountSettingsState) {
      state.pending = false;
      state.error = '';
    },
    accountSettingsChangeError(state: AccountSettingsState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    }
  }
});

const selectAccountSettings = (state: RootState): AccountSettingsState => state.accountSettings;

export const accountSettingsSelector = createSelector([selectAccountSettings], (accountSettings) => accountSettings);

export const {
  accountSettingsChangeError,
  accountSettingsChangeEmailFetching,
  accountSettingsChangePasswordFetching,
  accountSettingsChangeSuccess
} = accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;

import styled from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  flex: 1 1 auto;

  ${mq.greaterXs} {
    padding: 3rem 1rem;
  }
`;

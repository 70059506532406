import styled from 'styled-components';

export const TextField = styled.input`
  text-align: center;
  font-size: 20px;
  height: 72px;
  width: 48px;
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  border-radius: 3px;
`;

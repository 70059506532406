import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';

type StyledProps = {
  open: boolean;
};

export const StyledContainer = styled.div<StyledProps>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ open }) => (open && '15%') || '0'};
  z-index: 1;
  visibility: ${({ open }) => (open && 'visible') || 'hidden'};
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 0.25rem 1rem;
  margin: 1rem;
  background-color: ${({ theme }) => theme.colors.white01};
  border-radius: 4px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
  transition: all 0.3s linear;
  opacity: ${({ open }) => (open && 1) || 0};

  ${mq.greaterSm} {
    position: absolute;
    bottom: ${({ open }) => (open && '5%') || '0'};
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: max-content;
  color: ${({ theme }) => theme.colors.gray01};
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin: 0.25rem 1rem 0.25rem 0.75rem;
`;

export const StyledTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: 500;
  line-height: 1.25em;
  margin: 0 0 0.75rem 0;
  display: flex;
  overflow-wrap: break-word;
`;

export const StyledSubtitle = styled.div`
  display: flex;
  width: 100%;
  line-height: 1.25em;
  overflow-wrap: break-word;
`;

export const StyledCloseButton = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const closeIconCss = css`
  width: 24px;
  fill: currentColor;
`;

export const iconCss = css`
  fill: currentColor;
  width: 32px;
`;

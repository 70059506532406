import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from '../../domain/entity/house/HouseData';
import { CommonBilag } from '../../domain/entity/documnets/CommonBilag';

export interface DocumentsState {
  pending?: boolean;
  documents?: CommonBilag[];
  currentDocument?: CommonBilag;
  error?: string;
}

const initialState: DocumentsState = {
  pending: undefined,
  documents: [],
  error: ''
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    fetchAllDocuments(
      state: DocumentsState,
      _: PayloadAction<{ houseId: HouseData['id']; date_from?: string; date_to?: string }>
    ) {
      state.pending = true;
    },
    fetchAllDocumentsSuccess(state: DocumentsState, { payload }: PayloadAction<any[]>) {
      state.pending = false;
      state.documents = payload;
    },
    fetchAllDocumentsError(state: DocumentsState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    setIsTrashTrue(state: DocumentsState, { payload }: PayloadAction<number>) {
      const document = state.documents?.find(({ bilag: { id } }) => id === payload);
      if (
        (document?.type === 'bilag' && !document?.bilag.archived) ||
        (document?.type === 'depreciation-bilag' && !document?.bilag.isTrashed) ||
        (document?.type === 'income-bilag' && !document?.bilag.isTrashed)
      ) {
        state.documents = state.documents?.map(
          (commonBilag) =>
            (commonBilag?.bilag?.id === payload &&
              ({
                ...commonBilag,
                bilag: {
                  ...commonBilag.bilag,
                  isTrashed: true // mark trashed bilag
                }
              } as CommonBilag)) ||
            (commonBilag as CommonBilag)
        );
      } else {
        state.documents = state.documents?.filter((commonBilag) => commonBilag?.bilag?.id !== payload);
      }
    },
    setNewSumForContract(
      state: DocumentsState,
      { payload: { newSum, id } }: PayloadAction<{ id: number; newSum: number }>
    ) {
      state.documents = state.documents?.map(
        (commonBilag) =>
          (commonBilag?.bilag?.id === id &&
            ({
              ...commonBilag,
              bilag: {
                ...commonBilag.bilag,
                sum: newSum // mark trashed bilag
              }
            } as CommonBilag)) ||
          (commonBilag as CommonBilag)
      );
    },
    setCurrentDocument(state: DocumentsState, { payload }: PayloadAction<number>) {
      state.currentDocument = state.documents?.filter(({ bilag }) => bilag.id === payload)[0];
      state.pending = false;
      state.error = '';
    }
  }
});

const selectDocuments = (state: RootState): DocumentsState => state.documents;

export const documentsSelector = createSelector([selectDocuments], (documents) => documents);

export const {
  fetchAllDocuments,
  fetchAllDocumentsError,
  fetchAllDocumentsSuccess,
  setIsTrashTrue,
  setNewSumForContract
} = documentsSlice.actions;

export default documentsSlice.reducer;

import React, { FC, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from 'constant/formats';
import { ROUTE_TRASH_FOLDER_ITEM } from 'constant/routes';
import { ThemeT } from 'context/Theme';
import { houseSelector } from 'data/store/houseReducer';
import { documentsSelector, documentsSlice, fetchAllDocuments } from 'data/store/documentReducer';
import { numberWithSpaces } from 'helpers/number';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { getRenderItemValues, TableDataT } from '../Dashboard/Table/data';
import { ListView } from 'components/common';
import SimplePage from 'components/common/SimplePage';
import { Column, StyledSimpleRow, StyledSum } from './styled';
import { CommonBilag } from '../../domain/entity/documnets/CommonBilag';

export const TrashFolder: FC = () => {
  const { documents } = useAppSelector(documentsSelector);
  const { selectedHouse } = useAppSelector(houseSelector);
  const [trashBilags, setTrashBilags] = useState<TableDataT[]>([]);

  const { setCurrentDocument } = documentsSlice.actions;

  const dispatch = useAppDispatch();
  const { g } = useGlobalTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!documents?.length && selectedHouse) {
      dispatch(fetchAllDocuments({ houseId: selectedHouse.id }));
    }
  }, [dispatch]);

  useEffect(() => {
    setTrashBilags(
      documents
        ?.map((document) => getRenderItemValues(document))
        ?.filter((document) => document.type !== 'depreciation-bilag')
        ?.filter(({ isTrashed }) => isTrashed && isTrashed) || []
    );
  }, [documents]);

  const onRowClick = (id: number) => {
    dispatch(setCurrentDocument(id));
    history.push(generatePath(ROUTE_TRASH_FOLDER_ITEM, { slug: id }), { slug: id });
  };

  const list =
    trashBilags &&
    trashBilags?.length > 0 &&
    trashBilags?.map((document) => {
      return (
        <StyledSimpleRow
          key={document.id}
          onClick={() => onRowClick(document.id)}
          name={
            <Column>
              {document.name}
              <StyledSum color={document.colorSum as unknown as keyof ThemeT['colors']}>{`${numberWithSpaces(
                document.sum
              )} kr`}</StyledSum>
            </Column>
          }
          info={dayjs(document.date).format(DEFAULT_DATE_FORMAT)}
        />
      );
    });
  return (
    <SimplePage header={g('trashFolder')}>
      <ListView variant={'trash'} what={'incomeBilag'}>
        {list}
      </ListView>
    </SimplePage>
  );
};

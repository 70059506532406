import React from 'react';
import { StyledBurger } from './styled';

type Props = {
  onClick: () => void;
};

const Burger = ({ onClick }: Props) => {
  return (
    <StyledBurger onClick={onClick}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;

import styled, { css } from 'styled-components';
import Checkbox from '../../../controls/Checkbox/Checkbox';
import Button from '../../../controls/Button';

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
`;

export const RadiosContent = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 5px;
`;

export const StyledDescriptionContainer = styled.div`
  grid-column: 1/3;
`;

export const TwoActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: repeat(2, 278px);
  gap: 24px;
  margin-top: 20px;
`;

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: repeat(1, 300px);
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  margin: 20px 0 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
  justify-content: space-between;
  padding: 14px 17px 10px 24px;
  font-size: 16px;
  line-height: 1.5;

  letter-spacing: 0.15px;
`;

export const StyledLabel = styled.div`
  margin-top: 26px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const ErrorMessage = styled.div`
  grid-column: 1/3;
  text-align: center;
  color: ${({ theme }) => theme.colors.red01};
  margin-top: 20px;
`;

export const SubTitle = styled.p`
  text-align: center;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  margin: 0;
`;

export const Span = styled.span`
  display: block;
  min-width: 756px;
  width: 100%;
`;

export const Text = styled.p`
  margin-top: 10px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray01};
  max-width: 100%;
  text-align: center;
`;

export const TextButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray01};
  text-transform: initial;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const HelpContainer = styled.div<{ isShowHelp: boolean }>`
  margin: 20px auto 0;
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  overflow: hidden;
  max-height: ${({ isShowHelp }) => (isShowHelp ? '300px' : 0)};
  transition: max-height 0.4s;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const InputContainer = styled.div<{ isNeedUpdatePhone: boolean }>`
  display: grid;
  justify-content: center;
  margin-top: 20px;
  overflow: hidden;
  grid-template-columns: 280px;
  max-height: ${({ isNeedUpdatePhone }) => (isNeedUpdatePhone ? '300px' : 0)};
  transition: max-height 0.4s;
`;

export const infoIconCss = css`
  vertical-align: center;
`;

export const HelpContent = styled.div`
  padding: 15px;
  max-width: 320px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const ButtonContainer = styled.div`
  width: max-content;
`;

export const RadioButtonContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
`;

export const ContentPopup = styled.span`
  width: 200px;
  padding: 8px 12px;
  background-color: #ffffff;
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/controls/Button';
import { Form } from 'components/ui';

export const StyledContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 27rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

export const StyledTitle = styled.div`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.black01};
  text-align: center;
  font-weight: 500;
`;

export const StyledFormContent = styled.div`
  display: grid;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black01};
  text-decoration: underline;
`;

export const StyledSignUpContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray03};
  margin: 2rem 0;
  line-height: 1.5rem;
`;

export const StyledSignUpLink = styled(Link)`
  margin: 0 0.25rem;
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
`;

export const StyledLinksContainer = styled.div`
  // TODO change to flex after implimentation
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledIconLink = styled.a`
  margin: 1rem;
`;

export const StyledButton = styled(Button)`
  justify-self: center;
  margin-top: 48px;
`;

export const StyledForm = styled(Form)`
  display: grid;
`;

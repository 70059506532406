import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { containerMixin, pageHeaderMixin } from 'mixins';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const Column = styled.div<ColumnT>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems && alignItems) || 'initial'};
`;

export const StyledHeader = styled.div`
  ${pageHeaderMixin};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0 0.3rem;
`;

export const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
  font-size: 0.875rem;
`;

export const plusIconCss = css`
  fill: ${({ theme }) => theme.colors.blue01};
  width: 24px;
`;

import React, { FC, useEffect } from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_REGISTRATION } from 'constant/routes';
import StepEnum from 'domain/entity/registration/StepEnum';
import { profileSlice } from 'data/store/profileReducer';
import { registrationSlice } from 'data/store/registrationReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import MainLayout from 'components/layouts/MainLayout';
import { houseSlice } from '../data/store/houseReducer';
import { contractSlice } from '../data/store/rentContractReducer';
import { modalsSlice } from '../data/store/modalReducer';

type PropsT = {
  component: FC<RouteComponentProps>;
  exact?: boolean;
  path: string;
};

const PrivateRoute: FC<PropsT> = ({ component: Component, ...rest }) => {
  const { token } = useAppSelector((state) => state.auth);
  const { user } = useAppSelector((state) => state.profile);
  const { profileFetching } = profileSlice.actions;
  const { registrationSuccess } = registrationSlice.actions;
  const { openUpdateAgreePolicy } = modalsSlice.actions;
  const { fetchHouses } = houseSlice.actions;
  const { getCountryList } = contractSlice.actions;
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push({ pathname: ROUTE_LOGIN });
    }
    if (token && !user) {
      dispatch(profileFetching());
    }
    if (token) {
      dispatch(fetchHouses());
    }
    if (token && user?.is_register_finished === false) {
      dispatch(registrationSuccess(StepEnum.profile));
      history.push({ pathname: ROUTE_REGISTRATION });
    }
  }, [token, user]);

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  useEffect(() => {
    if (user && !user?.isAgreePolicy) dispatch(openUpdateAgreePolicy());
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <MainLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </MainLayout>
  );
};

export default PrivateRoute;

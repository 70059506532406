import Modal from 'components/common/Modal';
import React, { FC } from 'react';
import { StyledContainer, imgCss } from './styled';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { modalsSlice } from '../../../../data/store/modalReducer';

const PicturesModal: FC = () => {
  const dispatch = useAppDispatch();
  const { closePictureModal } = modalsSlice.actions;
  const { currentContract } = useAppSelector((state) => state.contract);
  const { pictureModal } = useAppSelector((state) => state.modals);

  if (!currentContract) {
    return null;
  }

  return (
    <Modal isOpen={pictureModal} onClose={() => dispatch(closePictureModal())}>
      <StyledContainer documents={currentContract.documents} imgCss={imgCss} />
    </Modal>
  );
};

export default PicturesModal;

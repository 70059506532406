export type HouseCreateFormT = {
  name: string;
  address: string;
  postcode: string;
  city: string;
  property_number: string;
  holding_number: string;
  lease_number: string;
  section_number: string;
  share_number: string;
};

const INITIAL_HOUSE_CREATE_DATA = {
  name: '',
  address: '',
  postcode: '',
  city: '',
  property_number: '',
  holding_number: '',
  lease_number: '',
  section_number: '',
  share_number: ''
};

export default INITIAL_HOUSE_CREATE_DATA;

import format from 'date-fns/format';
import { formatDateForField } from '../../../helpers/date';

export type ContractFormData = {
  type: string;
  houseId: number;
  tenant_first_name: string;
  tenant_last_name: string;
  tenant_birthday: string;
  tenant_phone: string;
  tenant_phone_code: string;
  tenant_email: string;
  tenant_nationality: string;
  tenant_address: string;
  tenant_country: string;
  tenant_city: string;
  tenant_postcode: string;
  start_date: string;
  end_date: string;
  month_resignation: string;
  month_sum: string;
  deposit_sum: string;
  first_payment_date: string;
  taxable: boolean;
  keys_count: string;
  comforts: string[];
  pets_allowed: boolean;
  smoking_allowed: boolean;
  other: string;
  documents: (File | string)[];
  deposit_payment_deadline?: string;
  deposit_account?: string;
  is_deposit_account_variant?: string;
  deposit_count_payment_day: number;
};

export const INITIAL_CONTRACT_FORM_DATA = (contract?: Contract): ContractFormData => ({
  houseId: contract?.houseId || NaN,
  comforts: contract?.comforts || [],
  deposit_sum: String(contract?.deposit_sum || ''),
  end_date: formatDateForField(contract?.end_date),
  first_payment_date: formatDateForField(contract?.first_payment_date),
  keys_count: String(contract?.keys_count || ''),
  month_resignation: String(contract?.month_resignation || ''),
  month_sum: String(contract?.month_sum || ''),
  pets_allowed: contract?.pets_allowed || false,
  other: contract?.other || '',
  smoking_allowed: contract?.smoking_allowed || false,
  start_date: formatDateForField(contract?.start_date),
  taxable: contract?.taxable || false,
  tenant_birthday: formatDateForField(contract?.tenant_birthday),
  tenant_email: contract?.tenant_email || '',
  tenant_last_name: contract?.tenant_last_name || '',
  tenant_phone: contract?.tenant_phone || '',
  tenant_phone_code: contract?.tenant_phone_code || '',
  tenant_first_name: contract?.tenant_first_name || '',
  tenant_city: contract?.tenant_city || '',
  tenant_nationality: contract?.tenant_nationality || 'NO',
  tenant_address: contract?.tenant_address || '',
  tenant_country: contract?.tenant_country || 'NO',
  tenant_postcode: contract?.tenant_postcode || '',
  type: contract?.type || 'running',
  documents: contract?.documents || [],
  deposit_account: contract?.deposit_account || '',
  deposit_payment_deadline: formatDateForField(contract?.deposit_payment_deadline),
  is_deposit_account_variant: (contract?.is_open_deposit_account && '1') || (contract?.deposit_account && '2') || '0',
  deposit_count_payment_day: contract?.deposit_count_payment_day || 45
});

export type Contract = {
  id: number;
  additionalId: string;
  signature_tenant: string;
  signature_landlord: string;
  tenant_first_name: string;
  tenant_last_name: string;
  tenant_birthday: string;
  tenant_phone: string;
  tenant_phone_code: string;
  tenant_email: string;
  tenant_city: string;
  tenant_nationality: string;
  tenant_address: string;
  tenant_country: string;
  tenant_postcode: string;
  deposit_account: string;
  deposit_payment_deadline: string;
  deposit_count_payment_day: number;
  isFinished: boolean;
  type: 'running' | 'time-bound';
  other: string;
  start_date: string;
  end_date: string;
  first_payment_date: string;
  month_sum: number;
  deposit_sum: number;
  month_resignation: number;
  keys_count: number;
  taxable: boolean;
  pets_allowed: boolean;
  smoking_allowed: boolean;
  comforts: string[];
  documents: string[];
  houseId: number;
  created_at: Date;
  updated_at: Date;
  payUrl?: string;
  checkList?: CheckContract & {
    signicatInfo: {
      landlordLink?: string;
      tenantLink?: string;
      signedDocumentUrl?: string;
      isSignedLandlord?: boolean;
      isSignedTenant?: boolean;
    };
    signatureType: 'bankid' | 'manual' | 'manual_bankid';
  };
  signicatInfo: {
    error?: any;
    landlordLink?: string;
    tenantLink?: string;
    signedDocumentUrl?: string;
    isSignedLandlord?: boolean;
    isSignedTenant?: boolean;
    founds?: number;
    depositId?: string;
    depositStatus?: 'canceled' | 'opened' | 'released' | 'founds';
    releasedCategory?: 'SPLIT' | 'TENANT' | 'DISPUTE';
    tenantReleaseSum?: number;
  };
  is_open_deposit_account?: boolean;
  is_open_deposit_account_payed?: boolean;
  signatureType: null | 'bankid' | 'manual' | 'bankidWithDeposit';
  status?: 'active' | 'expired' | 'finished';
};

export type RentContractFormData = {
  houseId: number;
  documents: (File | string)[];
};

export const InitialRentContractData: RentContractFormData = {
  houseId: NaN,
  documents: []
};

export type InformAboutTenantFormData = {
  tenant_first_name: string;
  tenant_last_name: string;
  tenant_birthday: string;
  tenant_phone: string;
  tenant_email: string;
  tenant_nationality?: string;
  tenant_country?: string;
  tenant_city?: string;
  tenant_address?: string;
  tenant_postcode?: string;
  address?: string;
};

export const InitialInformAboutTenantData: InformAboutTenantFormData = {
  tenant_birthday: '',
  tenant_email: '',
  tenant_last_name: '',
  tenant_phone: '',
  tenant_first_name: '',
  tenant_nationality: '',
  tenant_country: '',
  tenant_city: '',
  tenant_address: '',
  tenant_postcode: '',
  address: ''
};

export type TenancyTermsFormData = {
  type: string;
  start_date: string;
  end_date: string;
  month_resignation: string;
  month_sum: string;
  first_payment_date: string;
  taxable: boolean;
};

export const InitialTenancyTermsData: TenancyTermsFormData = {
  end_date: '',
  first_payment_date: '',
  month_resignation: '',
  month_sum: '',
  start_date: '',
  taxable: false,
  type: 'running'
};

export type IncludingFormData = {
  keys_count: string;
  comforts: [];
  pets_allowed: boolean;
  smoking_allowed: boolean;
  other: string;
};

export const InitialIncludingData: IncludingFormData = {
  comforts: [],
  keys_count: '',
  pets_allowed: false,
  smoking_allowed: false,
  other: ''
};

export type DepositAccountFormData = {
  deposit_sum: string;
  deposit_payment_deadline?: string;
  is_deposit_account_variant?: string;
  deposit_count_payment_day?: number;
  deposit_account?: string;
};

export const InitialDepositAccountData: DepositAccountFormData = {
  deposit_sum: '',
  deposit_payment_deadline: '',
  is_deposit_account_variant: '0',
  deposit_account: ''
};

export type ReleaseDepositFormData = {
  category?: 'SPLIT' | 'TENANT' | 'DISPUTE';
  amount_tenant: string;
  amount_landlord: string;
};

export const InitialReleaseDepositData: ReleaseDepositFormData = {
  category: 'TENANT',
  amount_tenant: '',
  amount_landlord: ''
};

export type ResponseCountryListT = {
  alpha_2_code: string;
  en_short_name: string;
  no_short_name: string;
  nationality: string;
  no_nationality: string;
};

export type CheckContract = {
  options: string[];
  number_keys: string;
  parking_space: string;
  garage: string;
  fire_extinguisher_count: string;
  stalls_count: string;
};

export const INITIAL_CHECK_LIST = {
  options: [],
  number_keys: '',
  parking_space: '',
  garage: '',
  fire_extinguisher_count: '',
  stalls_count: ''
};

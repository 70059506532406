import React, { FC } from 'react';
import { StyledComingSoon } from '../../controls/TabSwitch/styled';
import useGlobalTranslation from '../../../hooks/useGlobalTranlation';

export const ComingSoon: FC = () => {
  const { g } = useGlobalTranslation();

  return <StyledComingSoon>{g('comingSoon')}</StyledComingSoon>;
};

export default ComingSoon;

import React from 'react';
import closeIcon from 'components/svg/close_icon.svg?sprite';
import {
  StyledContainer,
  StyledIconWrapper,
  StyledCloseButton,
  StyledInnerContainer,
  StyledTitle,
  StyledSubtitle,
  closeIconCss,
  iconCss
} from './styled';
import Icon, { IconT } from '../Icon';

type Props = {
  title: string;
  subtitle: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  icon?: IconT;
};

const Toast = ({ title, subtitle, icon, open, setOpen }: Props) => {
  return (
    <StyledContainer open={open}>
      {icon && (
        <StyledIconWrapper>
          <Icon icon={icon} css={iconCss} />
        </StyledIconWrapper>
      )}
      <StyledCloseButton onClick={() => setOpen(false)}>
        <Icon icon={closeIcon} css={closeIconCss} />
      </StyledCloseButton>
      <StyledInnerContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default Toast;

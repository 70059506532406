import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';
import { ThemeT } from 'context/Theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled.h3`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
`;

export const StyledSubtitle = styled.label`
  color: ${({ theme }) => theme.colors.gray03};
  width: 60%;
  line-height: 1.5em;
  text-align: center;
  margin-top: 15px;
`;

export const StyledDescription = styled.div`
  display: grid;
  justify-content: center;
  margin: 1.5rem 0;

  ${mq.greaterMd} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledCheckRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0 0 1rem 0;

  img {
    margin-right: 1rem;
  }
`;

export const StyledPriceGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0.5rem;
  transition: all 0.3s ease-out;

  ${mq.greaterXs} {
    margin: 1rem;
  }
`;

export const checkIconCss = (theme: ThemeT) => css`
  width: 19px;
  margin-right: 10px;
  fill: ${theme.colors.blue01};
`;

import styled, { css } from 'styled-components';
import Checkbox from '../../../../controls/Checkbox/Checkbox';
import Input from 'components/controls/Input/Input';
import { mq } from '../../../../../constant/mediaqueries';

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;

  ${mq.lowerSm} {
    grid-template-columns: 1fr;
  }
`;

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-template-columns: 278px;
  margin-top: 48px;

  ${mq.lowerSm} {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

export const StyledLabel = styled.div`
  margin-bottom: -30px;
  margin-top: 26px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
  justify-content: space-between;
  padding: 14px 17px 10px 24px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;

  ${mq.lowerSm} {
    padding-left: 0;
  }
`;

export const CheckBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 275px;
  grid-template-columns: 1fr 48px;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  height: 48px;
`;

export const Text = styled.span`
  width: 210px;
  display: flex;
  justify-content: space-between;
`;

export const StyledInput = styled(Input)`
  margin-left: 5px;
  text-align: center;
  font-size: 20px;
  height: 48px;
  width: 48px;
`;

export const inputFieldCss = css`
  padding: 0;
  margin-top: 0;
`;

export const inputStyleCss = css`
  text-align: center;
  padding: 5px;
`;

import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { ReleaseDepositFormData } from 'domain/entity/rentContract/RentContract';
import Input from 'components/controls/Input/Input';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'hooks/redux';
import Button from 'components/controls/Button';
import Icon from 'components/common/Icon';
import Popup from 'components/common/Popup';
import infoIcon from 'components/svg/warning.svg?sprite';
import {
  StyledRadioButton,
  ActionsButton,
  RadiosContent,
  Content,
  RadioButtonContent,
  ContentPopup,
  Span
} from './styled';

export const radios = [
  { id: 0, value: 'TENANT', label: 'inputs.return' },
  { id: 1, value: 'SPLIT', label: 'inputs.split' },
  { id: 2, value: 'DISPUTE', label: 'inputs.dispute' }
];

type PropsT = {
  setStep: Dispatch<SetStateAction<number>>;
};

const ContractIsFinished: FC<PropsT> = (props) => {
  const { setStep } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch
  } = useFormContext<ReleaseDepositFormData>();
  const { currentContract } = useAppSelector((state) => state.contract);
  const { t } = useGlobalTranslation();
  const isCategory = watch('category');
  const amountLandlord = watch('amount_landlord');
  const amountTenant = watch('amount_tenant');

  useEffect(() => {
    if (amountTenant) {
      setValue('amount_landlord', Number(currentContract?.deposit_sum) - Number(amountTenant));
    }
    if (amountTenant > Number(currentContract?.deposit_sum)) {
      setValue('amount_tenant', Number(currentContract?.deposit_sum));
      setValue('amount_landlord', 0);
    }
  }, [amountTenant]);

  useEffect(() => {
    if (amountLandlord) {
      setValue('amount_tenant', Number(currentContract?.deposit_sum) - Number(amountLandlord));
    }
    if (amountLandlord > Number(currentContract?.deposit_sum)) {
      setValue('amount_landlord', Number(currentContract?.deposit_sum));
      setValue('amount_tenant', 0);
    }
  }, [amountLandlord]);

  const infoText: Record<string, string> = {
    TENANT: 'helpersText.tenant',
    SPLIT: 'helpersText.split',
    DISPUTE: 'helpersText.dispute'
  };

  return (
    <>
      <Span />
      <RadiosContent>
        {radios.map(({ value, label, id }) => (
          <RadioButtonContent key={id}>
            <StyledRadioButton control={control} label={t(label)} {...register('category')} value={value} />
            <Popup
              variant={'bottomLeft'}
              mode={'hover'}
              button={<Icon icon={infoIcon} color={'blue01'} />}
              content={() => <ContentPopup>{t(infoText[value])}</ContentPopup>}
            />
          </RadioButtonContent>
        ))}
      </RadiosContent>
      {isCategory === 'SPLIT' && (
        <Content>
          <Input
            required
            register={register('amount_tenant')}
            withoutPrompt
            variant={'outline'}
            label={t('amountTenant')}
            error={errors.amount_tenant}
          />
          <Input
            required
            register={register('amount_landlord')}
            withoutPrompt
            variant={'outline'}
            label={t('amountLandlord')}
            error={errors.amount_landlord}
          />
        </Content>
      )}
      <ActionsButton>
        <Button type={'button'} variant={'fill'} onClick={handleSubmit(() => setStep(1))}>
          {t('releaseDeposit')}
        </Button>
      </ActionsButton>
    </>
  );
};

export default ContractIsFinished;

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 60px;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentIcon = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const iconCss = css`
  margin-top: 30px;
  width: 100%;
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 133%;
`;

export const Message = styled.div`
  margin-top: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 133%;
`;

import React, { FC } from 'react';
import { useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import profileIcon from 'components/svg/profile_icon.svg?sprite';
import { Wrapper, profileIconCss, UserName, Info, Email } from './styled';

const ProfileInfo: FC = () => {
  const { user } = useAppSelector(({ profile }) => profile);

  return (
    <Wrapper>
      <Icon icon={profileIcon} css={profileIconCss} />
      <Info>
        <UserName>
          {user?.firstName} {user?.lastName}
        </UserName>
        <Email aria-label={user?.email}>{user?.email}</Email>
      </Info>
    </Wrapper>
  );
};

export default ProfileInfo;

import React, { FC } from 'react';
import ReactSelect, { GroupBase, PropsValue } from 'react-select';
import arrowIcon from 'components/svg/arrow_down.svg?sprite';
import {
  StyledMenu,
  StyledOption,
  Container,
  arrowIconCss,
  IconContainer,
  StyleValueContainer,
  StyledContainer,
  StyledLabel
} from './styled';
import Icon from '../../common/Icon';
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';

export type SelectOptionT = {
  value: string | number;
  label: string;
};

export interface SelectProps<
  Option = SelectOptionT,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> {
  name?: string;
  label?: string;
  isRequired?: boolean;
  error?: string;
  options?: SelectOptionT[];
  index?: string;
  value?: SelectOptionT;
  defaultValue?: SelectOptionT;
  onChange?: (val: PropsValue<SelectOptionT>) => void;
  inputRef?: any;
  variant?: 'outline' | 'default';
  isSearchable?: boolean;
  disabled?: boolean;
}

const Select: FC<SelectProps> = (props) => {
  const {
    options = [],
    label,
    isRequired,
    error,
    value,
    defaultValue,
    variant = 'outline',
    inputRef,
    isSearchable,
    onChange,
    disabled
  } = props;

  const control = (): SelectComponentsConfig<SelectOptionT, boolean, GroupBase<SelectOptionT>> => {
    return {
      SelectContainer: ({ children, innerProps }) => {
        return (
          /*@ts-ignore*/
          <StyledContainer {...innerProps} isError={error}>
            <StyledLabel>
              {label} {isRequired ? '*' : ''}
            </StyledLabel>
            {children}
          </StyledContainer>
        );
      },
      Option: ({ innerProps, innerRef, children }) => {
        return (
          <StyledOption {...innerProps} ref={innerRef}>
            <span>{children}</span>
          </StyledOption>
        );
      },
      Menu: ({ innerProps, children, innerRef }) => {
        return (
          /*@ts-ignore*/
          <StyledMenu ref={innerRef} {...innerProps}>
            {children}
          </StyledMenu>
        );
      },
      Control: ({ innerProps, innerRef, children }) => {
        return (
          <Container {...innerProps} ref={innerRef} variant={variant}>
            {children}
          </Container>
        );
      },
      IndicatorSeparator: () => null,
      IndicatorsContainer: ({ selectProps: { menuIsOpen } }) => {
        return (
          <IconContainer isOpen={menuIsOpen}>
            <Icon icon={arrowIcon} css={arrowIconCss} />
          </IconContainer>
        );
      },
      ValueContainer: ({ children }) => {
        return <StyleValueContainer>{children}</StyleValueContainer>;
      }
    };
  };

  return (
    <ReactSelect
      components={control()}
      isDisabled={disabled}
      value={value}
      options={options}
      placeholder={''}
      defaultValue={defaultValue}
      ref={inputRef}
      onChange={onChange}
      isSearchable={isSearchable}
    />
  );
};

export default Select;

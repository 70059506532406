import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ROUTE_SUPPLIERS } from 'constant/routes';
import { SupplierCreateFormT } from 'domain/entity/supplier/SupplierCreateFormData';
import { supplierSlice } from 'data/store/supplierReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getErrMsg } from 'helpers/locale';
import Icon from 'components/common/Icon';
import { Button, Input } from 'components/ui';
import DateField from 'components/controls/DateField';
import SimplePage from 'components/common/SimplePage';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import schema from './validation';
import { ADD_SUPPLIER_FIELDS } from './fields';
//TODO: Вытащить и объеденить
import { StyledForm, StyledFormContainer } from '../SupplierInfo/SupplierInfoEdit/styled';
import {
  backIconCss,
  StyledLink,
  StyledLinkText,
  StyledSubmitWrapper
} from '../../../Landlord/components/LandlordEdit/styled';

const AddSupplier: FC = () => {
  const { g, t } = useGlobalTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { createSupplier } = supplierSlice.actions;
  const { pending } = useAppSelector(({ supplier }) => supplier);
  const form = useForm<SupplierCreateFormT>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      address: '',
      postcode: '',
      city: '',
      archived: false
    }
  });
  const { register, handleSubmit, formState, getValues } = form;

  const onSubmit = (data: SupplierCreateFormT) => {
    dispatch(createSupplier({ ...data }));
    history.goBack();
  };
  return (
    <SimplePage
      header={
        <StyledLink to={ROUTE_SUPPLIERS}>
          <Icon icon={backIcon} css={backIconCss} color={'black01'} />
          <StyledLinkText>{t('options.supplier.addNewSupplier')}</StyledLinkText>
        </StyledLink>
      }
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContainer>
          {ADD_SUPPLIER_FIELDS.map(({ name, text, serverError, type, ...restProps }) => (
            <FormProvider {...form}>
              {type !== 'date' && (
                <Input
                  variant={'outline'}
                  maxLength={50}
                  key={name}
                  {...restProps}
                  label={g('inputs.' + text)}
                  register={register(name)}
                  required
                  error={formState.errors[name]}
                  errorMessage={getErrMsg(formState.errors, name)}
                  serverError={(serverError && g(formState.errors[name] || '')) || undefined}
                />
              )}
              {type === 'date' && (
                <DateField
                  variant={'outline'}
                  key={name}
                  type={'date'}
                  value={getValues(name)}
                  label={g('inputs.' + text)}
                  register={register(name)}
                  error={formState.errors[name]}
                  errorMessage={getErrMsg(formState.errors, name)}
                  serverError={(serverError && g(formState.errors[name] || '')) || undefined}
                />
              )}
            </FormProvider>
          ))}
        </StyledFormContainer>
        <StyledSubmitWrapper>
          <Button isLoading={pending}>{g('save')}</Button>
        </StyledSubmitWrapper>
      </StyledForm>
    </SimplePage>
  );
};

export default AddSupplier;

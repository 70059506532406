import * as Yup from 'yup';
import { emailSchema } from './emailValidation';
import { agreePolicySchema } from './agreePolicyValidation';

export const registrationMainSchema = Yup.object().shape({
  password: Yup.string()
    .required('password.required')
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=\S+$).{6,50}$/, 'password.check')
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,50}$/,
      'password.checkLowercase'
    ),
  repeatPassword: Yup.string()
    .required('password.required')
    .oneOf([Yup.ref('password'), null], 'password.match')
});

export const registrationSchema = registrationMainSchema.concat(emailSchema).concat(agreePolicySchema);

import styled from 'styled-components';
import { hidden, mq } from 'constant/mediaqueries';
import { Link } from 'react-router-dom';

type LinkProps = {
  $active: boolean;
};
export const StyledWrapper = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0;

  ${mq.lowerSm} {
    padding: 0;
    align-items: flex-end;
    height: max-content;
  }

  ${mq.greaterXs} {
    margin-right: 10px;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 500;
  transition: all 0.3s ease-out;

  ${mq.lowerXs} {
    width: 2.75rem;
  }

  ${mq.greaterSm} {
  }
`;

export const StyledIcon = styled.img`
  display: flex;

  ${hidden.greaterXs}
`;

export const StyledText = styled.span<LinkProps>`
  display: flex;
  white-space: nowrap;
  color: ${({ $active, theme }) => theme.colors[($active && 'blue01') || 'gray01']};

  ${mq.greaterXs} {
    display: flex;
  }
`;

import { CHECK_EMAIL, REGISTRATION } from 'constant/apiRoutes';
import { RegistrationFormT } from 'domain/entity/registration/RegistrationFormData';
import { http, setCredentials } from 'data/driver/Api';

export const fetchCheckEmail = async (email: string) => {
  await http.post(CHECK_EMAIL, { email });
};

export const fetchRegistration = async (formData: RegistrationFormT) => {
  const { data } = await http.post(REGISTRATION, {
    email: formData.email,
    password: formData.password,
    isAgreePolicy: formData.isAgreePolicy
  });
  setCredentials(data?.accessToken);

  return data;
};

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';

export const StyledWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0.5rem 0.5rem 0.5rem 1.2rem;

  ${mq.lowerMd} {
    margin: 0 0 0 1rem;
  }
`;

export const StyledText = styled.b`
  display: ${({ full }: { full: boolean }) => (full && 'flex') || 'none'};
  margin: 0.5rem;
  color: ${({ theme }) => theme.colors.blue01}
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2em;

  ${mq.greaterSm} {
    color: ${({ theme }) => theme.colors.blue01};
    display: flex;
  }
`;

export const StyledLogo = styled.img`
  width: 36px;
  height: 36px;
`;

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { CommonBilag } from '../../domain/entity/documnets/CommonBilag';

export interface ModalsState {
  registrationCloseModal: boolean;
  manualBilag: boolean;
  addHouse: boolean;
  rentContract: boolean;
  incomeBilag: boolean;
  changeRent: boolean;
  depreciation: boolean;
  manyHouses: boolean;
  updatePhoneNumber: boolean;
  checkContract: boolean;
  releaseDeposit: boolean;
  archiveBilag?: { show?: boolean; functionConfirm?: () => void };
  discardChange: { show?: boolean; functionConfirm?: () => void };
  moveToTrash?: { show?: boolean; functionConfirm?: () => void };
  appModal?: boolean;
  updateIsAgreePolicy?: boolean;
  bilagInfo: {
    type?: string;
    show: boolean;
    currentBilag?: CommonBilag;
  };
  pictureModal: boolean;
}

const initialState: ModalsState = {
  updateIsAgreePolicy: false,
  registrationCloseModal: false,
  manualBilag: false,
  addHouse: false,
  rentContract: false,
  incomeBilag: false,
  depreciation: false,
  changeRent: false,
  manyHouses: false,
  updatePhoneNumber: false,
  releaseDeposit: false,
  checkContract: false,
  archiveBilag: {
    show: false
  },
  discardChange: {
    show: false
  },
  moveToTrash: {
    show: false
  },
  appModal: false,
  bilagInfo: {
    type: '',
    show: false,
    currentBilag: undefined
  },
  pictureModal: false
};

export const modalsSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    openRegistrationModal(state: ModalsState) {
      state.registrationCloseModal = true;
    },
    closeRegistrationModal(state: ModalsState) {
      state.registrationCloseModal = false;
    },
    openManualBilag(state: ModalsState) {
      state.manualBilag = true;
    },
    closeManualBilag(state: ModalsState) {
      state.manualBilag = false;
    },
    openAddHouse(state: ModalsState) {
      state.addHouse = true;
    },
    closeAddHouse(state: ModalsState) {
      state.addHouse = false;
    },
    openRentContract(state: ModalsState) {
      state.rentContract = true;
    },
    closeRentContract(state: ModalsState) {
      state.rentContract = false;
    },
    openIncomeBilag(state: ModalsState) {
      state.incomeBilag = true;
    },
    closeIncomeBilag(state: ModalsState) {
      state.incomeBilag = false;
    },
    openDepreciation(state: ModalsState) {
      state.depreciation = true;
    },
    closeDepreciation(state: ModalsState) {
      state.depreciation = false;
    },
    openReleaseDeposit(state: ModalsState) {
      state.releaseDeposit = true;
    },
    closeReleaseDeposit(state: ModalsState) {
      state.releaseDeposit = false;
    },
    openManyHouses(state: ModalsState) {
      state.manyHouses = true;
    },
    closeManyHouses(state: ModalsState) {
      state.manyHouses = false;
    },
    openAppModal(state: ModalsState) {
      state.appModal = true;
    },
    closeAppModal(state: ModalsState) {
      state.appModal = false;
    },
    openArchiveBilag(state: ModalsState, { payload }: PayloadAction<() => void>) {
      state.archiveBilag = {
        show: true,
        functionConfirm: payload
      };
    },
    openDiscardChange(state: ModalsState, { payload }: PayloadAction<() => void>) {
      state.discardChange = {
        show: true,
        functionConfirm: payload
      };
    },
    openMoveToTrash(state: ModalsState, { payload }: PayloadAction<() => void>) {
      state.moveToTrash = {
        show: true,
        functionConfirm: payload
      };
    },
    closeArchiveBilag(state: ModalsState) {
      state.archiveBilag = {
        show: false,
        functionConfirm: undefined
      };
    },
    closeDiscardChange(state: ModalsState) {
      state.discardChange = {
        show: false,
        functionConfirm: undefined
      };
    },
    closeMoveToTrash(state: ModalsState) {
      state.moveToTrash = {
        show: false,
        functionConfirm: undefined
      };
    },
    openInfoBilag(
      state: ModalsState,
      { payload }: Partial<PayloadAction<{ type: string; currentBilag: CommonBilag }>>
    ) {
      state.bilagInfo = {
        show: true,
        type: payload?.type || state.bilagInfo.type,
        currentBilag: payload?.currentBilag || state.bilagInfo.currentBilag
      };
    },
    setInfoBilag(state: ModalsState, { payload }: PayloadAction<string>) {
      if (state.bilagInfo.currentBilag?.bilag && state.bilagInfo.currentBilag?.type === 'income-bilag')
        state.bilagInfo = {
          ...state.bilagInfo,
          currentBilag: {
            ...state.bilagInfo.currentBilag,
            bilag: {
              ...state.bilagInfo.currentBilag.bilag,
              sum: state.bilagInfo.currentBilag.bilag.sum ? Number(payload) : undefined
            }
          }
        };
    },
    closeInfoBilag(state: ModalsState) {
      state.bilagInfo = {
        show: false,
        type: '',
        currentBilag: undefined
      };
    },
    hiddenInfoBilag(state: ModalsState) {
      state.bilagInfo = {
        ...state.bilagInfo,
        show: false
      };
    },
    openChangeRent(state: ModalsState) {
      state.bilagInfo = {
        ...state.bilagInfo,
        show: false
      };
      state.changeRent = true;
    },
    closeChangeRent(state: ModalsState) {
      state.bilagInfo = {
        ...state.bilagInfo,
        show: true
      };
      state.changeRent = false;
    },
    openUpdatePhoneNumber(state: ModalsState) {
      state.updatePhoneNumber = true;
    },
    closeUpdatePhoneNumber(state: ModalsState) {
      state.updatePhoneNumber = false;
    },
    openCheckContract(state: ModalsState) {
      state.checkContract = true;
    },
    closeCheckContract(state: ModalsState) {
      state.checkContract = false;
    },
    openUpdateAgreePolicy(state: ModalsState) {
      state.updateIsAgreePolicy = true;
    },
    closeUpdateAgreePolicy(state: ModalsState) {
      state.updateIsAgreePolicy = false;
    },
    openPictureModal(state: ModalsState) {
      state.pictureModal = true;
    },
    closePictureModal(state: ModalsState) {
      state.pictureModal = false;
    }
  }
});

const selectModals = (state: RootState): ModalsState => state.modals;

export const modalsSelector = createSelector([selectModals], (modals) => modals);

export const {
  closeRegistrationModal,
  openRegistrationModal,
  closeManualBilag,
  closeAddHouse,
  openManualBilag,
  openAddHouse,
  closeRentContract,
  closeIncomeBilag,
  openRentContract,
  closeInfoBilag,
  openInfoBilag,
  openIncomeBilag,
  hiddenInfoBilag,
  closeChangeRent,
  openChangeRent,
  setInfoBilag,
  closeArchiveBilag,
  openArchiveBilag,
  closeDiscardChange,
  openDiscardChange,
  closeMoveToTrash,
  openMoveToTrash,
  openDepreciation,
  closeDepreciation,
  openManyHouses,
  closeManyHouses,
  openCheckContract,
  closeCheckContract
} = modalsSlice.actions;

export default modalsSlice.reducer;

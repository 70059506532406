import React, { FC, useEffect, useState } from 'react';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';
import { useFormContext } from 'react-hook-form';
import { endOfYear, startOfYear } from 'date-fns';
import HouseData from 'domain/entity/house/HouseData';
import { documentsSlice } from 'data/store/documentReducer';
import { useAppDispatch } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import Popup from 'components/common/Popup';
import DatePicker from 'components/controls/DatePicker';
import calendar from 'components/svg/calendar.svg?sprite';
import arrow from 'components/svg/arrow_down.svg?sprite';
import { arrowIconCss, calendarIconCss, StyledDatePicker, StyledItem } from './styled';

const dateToString = (date: Date) => format(date, 'dd.MM.yyyy');

type PropsT = {
  selectedHouse?: HouseData;
};

const DatePeriod: FC<PropsT> = (props) => {
  const { t: f } = useGlobalTranslation('fields');
  const { selectedHouse } = props;
  const { watch, setValue, register } = useFormContext();
  const [isChooseDate, setIsChooseDate] = useState(false);
  const [isDateTo, setIsDateTo] = useState(false);
  const watchDatePickerFrom = watch('date_from');
  const watchDatePickerTo = watch('date_to');
  const currentYear = new Date();
  const prevYear = subYears(new Date(), 1);
  const dispatch = useAppDispatch();
  const { fetchAllDocuments } = documentsSlice.actions;

  const handleDatePickerChange = (date: string, name: 'date_from' | 'date_to') => {
    const [day, month, year] = watchDatePickerFrom.split('.');
    const [day1, month1, year1] = format(new Date(date), 'dd.MM.yyyy').split('.');
    if (new Date(year, month, day).getTime() > new Date(Number(year1), Number(month1), Number(day1)).getTime()) {
      setValue('date_to', watchDatePickerFrom);
      setValue('date_from', dateToString(new Date(date)));
    } else {
      setValue(name, dateToString(new Date(date)));
    }
    if (!isDateTo) setIsDateTo(true);
  };

  useEffect(() => {
    if (watchDatePickerFrom && watchDatePickerTo && selectedHouse?.id) {
      dispatch(
        fetchAllDocuments({ houseId: selectedHouse.id, date_from: watchDatePickerFrom, date_to: watchDatePickerTo })
      );
    }
  }, [watchDatePickerFrom, watchDatePickerTo]);

  useEffect(() => {
    if (selectedHouse?.id) {
      dispatch(
        fetchAllDocuments({ houseId: selectedHouse.id, date_from: watchDatePickerFrom, date_to: watchDatePickerTo })
      );
    }
  }, [selectedHouse]);

  return (
    <div style={{ width: 'max-content' }}>
      <Popup
        onClose={() => {
          setIsChooseDate(false);
          setIsDateTo(false);
        }}
        button={(onClick) => (
          <StyledDatePicker onClick={onClick}>
            <Icon icon={calendar} css={calendarIconCss} />
            {!isChooseDate && (
              <div style={{ width: 180 }}>
                {watchDatePickerFrom ? watchDatePickerFrom : dateToString(startOfYear(new Date()))} -{' '}
                {watchDatePickerTo ? watchDatePickerTo : dateToString(new Date())}
              </div>
            )}
            {isChooseDate && (
              <div style={{ width: 180 }}>
                {watchDatePickerFrom ? watchDatePickerFrom : 'From'} - {watchDatePickerTo ? watchDatePickerTo : 'To'}
              </div>
            )}
            <Icon icon={arrow} css={arrowIconCss} />
          </StyledDatePicker>
        )}
        content={(onClose) => (
          <>
            {!isChooseDate && (
              <div style={{ width: '270px' }}>
                <StyledItem
                  onClick={() => {
                    setValue('date_from', dateToString(startOfYear(currentYear)));
                    setValue('date_to', dateToString(endOfYear(currentYear)));
                  }}
                >
                  {format(currentYear, 'yyyy')}
                </StyledItem>
                <StyledItem
                  onClick={() => {
                    setValue('date_from', dateToString(startOfYear(prevYear)));
                    setValue('date_to', dateToString(endOfYear(prevYear)));
                  }}
                >
                  {format(prevYear, 'yyyy')}
                </StyledItem>
                <StyledItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue('date_from', '');
                    setValue('date_to', '');
                    setIsChooseDate(true);
                  }}
                >
                  <Icon icon={calendar} css={calendarIconCss} />
                  {f('choosePeriod')}
                </StyledItem>
              </div>
            )}
            {isChooseDate && (
              <>
                {!isDateTo ? (
                  <DatePicker
                    value={watchDatePickerFrom}
                    name={register('date_from').name}
                    onClick={(value) => handleDatePickerChange(value, 'date_from')}
                  />
                ) : (
                  <DatePicker
                    onClose={onClose}
                    value={watchDatePickerFrom}
                    name={register('date_to').name}
                    onClick={(value) => handleDatePickerChange(value, 'date_to')}
                  />
                )}
              </>
            )}
          </>
        )}
      />
    </div>
  );
};

export default DatePeriod;

import React, { FC } from 'react';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import {
  ROUTE_ALTINN_INDEX,
  ROUTE_DASHBOARD,
  ROUTE_HOME_INDEX,
  ROUTE_PROFILE_INDEX,
  ROUTE_PROFILE_LANDLORD,
  ROUTE_REGISTER_CONTRACT
} from 'constant/routes';
import { Logo } from 'components/ui';
import Icon from 'components/common/Icon';
import arrowIcon from 'components/svg/arrow-left.svg?sprite';
import LangSwitcher from '../LangSwitcher';
import Tab from './Tab';
import Burger from './Burger';
import {
  StyledContainer,
  StyledTabsDesktop,
  StyledNav,
  StyledLogoWrapper,
  StyledChildrenContainer,
  StyledLangWrapper,
  StyledBurgerWrapper,
  StyledButtonClose,
  StyledTabsMobile,
  arrowIconCss,
  iconCss
} from './styled';
import checkIcon from '*.svg?sprite';
import profileIcon from 'components/svg/profile_icon.svg?sprite';
import homeIcon from 'components/svg/home.svg?sprite';
import documentIcon from 'components/svg/document.svg?sprite';
import { checkIconCss } from '../../../../containers/Subscription/components/Prices/styled';
import { ThemeT } from '../../../../context/Theme';

type Props = {
  onClick?: () => void;
  onSetMobileMenu?: () => void;
  isOpen?: boolean;
  withoutNavigation?: boolean;
  isHideLogo?: boolean;
};

const Topbar: FC<Props> = ({
  children,
  isOpen = false,
  onClick = () => {},
  onSetMobileMenu = () => {},
  withoutNavigation,
  isHideLogo = true
}) => {
  const { t } = useGlobalTranslation('topbar');

  return (
    <StyledContainer>
      {!withoutNavigation && (
        <StyledButtonClose isOpen={isOpen} onClick={onClick}>
          <Icon icon={arrowIcon} css={arrowIconCss} />
        </StyledButtonClose>
      )}
      <StyledNav>
        {!withoutNavigation && (
          <StyledBurgerWrapper>
            <Burger onClick={onSetMobileMenu} />
          </StyledBurgerWrapper>
        )}
        <StyledLogoWrapper isHideLogo={isHideLogo}>
          <Logo />
        </StyledLogoWrapper>
        {!withoutNavigation && (
          <>
            <StyledTabsDesktop>
              <Tab to={{ pathname: ROUTE_HOME_INDEX }}>{t('home')}</Tab>
              <Tab to={{ pathname: ROUTE_ALTINN_INDEX }}>{t('altinn')}</Tab>
              <Tab to={{ pathname: ROUTE_PROFILE_INDEX }}>{t('profile')}</Tab>
            </StyledTabsDesktop>
            <StyledTabsMobile>
              <Tab to={{ pathname: ROUTE_HOME_INDEX }}>
                <Icon icon={homeIcon} css={iconCss} />
              </Tab>
              <Tab to={{ pathname: ROUTE_ALTINN_INDEX }}>
                <Icon icon={documentIcon} css={iconCss} />
              </Tab>
              <Tab to={{ pathname: ROUTE_PROFILE_INDEX }}>
                <Icon icon={profileIcon} css={iconCss} />
              </Tab>
            </StyledTabsMobile>
          </>
        )}
        <StyledLangWrapper>
          <LangSwitcher />
        </StyledLangWrapper>
      </StyledNav>
      <StyledChildrenContainer>{children}</StyledChildrenContainer>
    </StyledContainer>
  );
};

export default Topbar;

import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
`;

export const StyledTitle = styled.h3`
  margin: 2rem 0 0.5rem 0;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-weight: 500;
`;

export const StyledSubtitle = styled.span`
  margin: 0.5rem 0 2.5rem 0;
`;

export const StyledNext = styled.span`
  color: ${({ theme }) => theme.colors.gray01};
  font-weight: 500;
  letter-spacing: 0.22px;
`;

export const checkIconCss = css`
  width: 48px;
`;

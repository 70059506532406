import React, { FC, useState } from 'react';
import { alertSlice, VariantAlertT } from 'data/store/alertReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { ThemeT } from 'context/Theme';
import { Wrapper, Block } from './styled';

const MAP_VARIANT_TO_COLOR: Record<VariantAlertT, keyof ThemeT['colors']> = {
  error: 'red01',
  success: 'green01'
};

const index: FC = () => {
  const { g } = useGlobalTranlation();
  const { variant, text, openLink } = useAppSelector(({ alert }) => alert);
  const [hide, setHide] = useState(false);
  const dispatch = useAppDispatch();
  const { resetAlertData } = alertSlice.actions;

  if (!variant || !text) {
    return null;
  }

  setTimeout(() => {
    setHide(true);
    setTimeout(() => {
      dispatch(resetAlertData());
      setHide(false);
    }, 300);
  }, 2000);

  return (
    <Wrapper hiddenAnimation={hide}>
      <Block color={MAP_VARIANT_TO_COLOR[variant]}>{g(text)}</Block>
    </Wrapper>
  );
};

export default index;

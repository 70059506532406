import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_PROFILE_SUBSCRIPTION } from 'constant/routes';
import { setCredentials } from 'data/driver/Api';
import { authSlice } from 'data/store/authReducer';
import { profileSlice } from 'data/store/profileReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { Spinner } from 'components/ui';
import { StyledContainer, StyledText } from './styled';

const LoginByUrl = () => {
  const { g } = useGlobalTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { profileFetching } = profileSlice.actions;
  const { logout, tokenFetchingSuccess } = authSlice.actions;
  const { error, pending } = useAppSelector((state) => state.profile);
  const { token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    setCredentials(urlToken || '');
    dispatch(tokenFetchingSuccess({ accessToken: urlToken || '' }));
    dispatch(profileFetching());
  }, [dispatch]);

  useEffect(() => {
    if (!pending) {
      if (token && error.length < 1) {
        setTimeout(() => {
          history.push({ pathname: ROUTE_PROFILE_SUBSCRIPTION });
        }, 50);
      } else {
        if (error.length > 0) {
          dispatch(logout());
          history.push({ pathname: ROUTE_LOGIN });
        }
      }
    }
  }, [token, pending]);

  return (
    <StyledContainer>
      <StyledText>{g('waiting')}</StyledText>
      <Spinner blue />
    </StyledContainer>
  );
};

export default LoginByUrl;

import { useHistory } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Modals from '../Modals';
import { StyledContainer, StyledContent, MainContent, HiddenLowerXs, HiddenGreaterXs } from './styled';

const MainLayout: FC = ({ children }) => {
  const {
    location: { pathname }
  } = useHistory();
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(true);

  useEffect(() => {
    setOpenMobile(true);
  }, [pathname]);

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  return (
    <StyledContainer>
      <Topbar
        isOpen={open}
        onClick={() => setOpen((prev) => !prev)}
        onSetMobileMenu={() => setOpenMobile(!openMobile)}
      />
      <StyledContent>
        <>
          <HiddenLowerXs>
            <Sidebar isOpen={open} {...{ openDeleteAlert, setOpenDeleteAlert }} />
          </HiddenLowerXs>
          <HiddenGreaterXs>
            <Sidebar isOpen={openMobile} {...{ openDeleteAlert, setOpenDeleteAlert }} />
          </HiddenGreaterXs>
        </>
        <MainContent isOpen={open}>{children}</MainContent>
      </StyledContent>
      <Modals />
    </StyledContainer>
  );
};

export default MainLayout;

import {
  CLOSE_DEPOSIT,
  CREATE_CHECK_LIST,
  CREATE_CONTRACT,
  DELETE_ALL_BILAGS_CONTRACT,
  FETCH_CONTRACT,
  FINISH_CONTRACT,
  GET_LIST_COUNTRY,
  REGENERATE_SIGN_BANK_ID_CHECK_LIST,
  RELEASE_DEPOSIT,
  SIGN_BANK_ID_CHECK_LIST,
  SIGN_BANK_ID_CONTRACT,
  SIGN_DEPOSIT_CONTRACT_CONTRACT,
  UPDATE_CONTRACT
} from 'constant/apiRoutes';
import {
  CheckContract,
  Contract,
  ContractFormData,
  ReleaseDepositFormData
} from 'domain/entity/rentContract/RentContract';
import { httpWithAuth } from 'data/driver/Api';
import { formatDateForApi } from 'helpers/date';

export const createContractApi = async (formData: ContractFormData) => {
  console.log(formData);
  const { data } = await httpWithAuth.post(CREATE_CONTRACT, {
    ...formData,
    documents: formData.documents,
    start_date: formatDateForApi(formData.start_date || ''),
    end_date: formatDateForApi(formData.end_date || ''),
    first_payment_date: formatDateForApi(formData.first_payment_date || ''),
    tenant_birthday: formatDateForApi(formData.tenant_birthday || ''),
    deposit_payment_deadline: formatDateForApi(formData.deposit_payment_deadline || ''),
    is_open_deposit_account: Number(formData.is_deposit_account_variant) === 1,
    deposit_count_payment_day:
      String(formData.is_deposit_account_variant) === '1' ? Number(formData.deposit_count_payment_day) : undefined,
    is_deposit_account_variant: undefined
  });

  return data;
};

export const updateContractApi = async ({
  formData,
  contractId
}: {
  formData: ContractFormData;
  contractId: number;
}) => {
  console.log(formData);
  const { data } = await httpWithAuth.put(UPDATE_CONTRACT(contractId), {
    ...formData,
    documents: formData.documents,
    start_date: formatDateForApi(formData.start_date || ''),
    end_date: formatDateForApi(formData.end_date || ''),
    first_payment_date: formatDateForApi(formData.first_payment_date || ''),
    tenant_birthday: formatDateForApi(formData.tenant_birthday || ''),
    deposit_payment_deadline: formatDateForApi(formData.deposit_payment_deadline || ''),
    deposit_count_payment_day: Number(formData.deposit_count_payment_day || 45),
    is_open_deposit_account: Number(formData.is_deposit_account_variant) === 1,
    is_deposit_account_variant: undefined
  });

  return data;
};

export const fetchContactsApi = async () => {
  const { data } = await httpWithAuth.get(FETCH_CONTRACT);
  return data;
};

export const signBankIdContactApi = async ({ id, checkList }: { id: Contract['id']; checkList?: boolean }) => {
  const { data } = await httpWithAuth.post(checkList ? SIGN_BANK_ID_CHECK_LIST(id) : SIGN_BANK_ID_CONTRACT(id));
  return data;
};

export const regenerateSignBankIdContactApi = async ({
  id,
  checkList
}: {
  id: Contract['id'];
  checkList?: boolean;
}) => {
  const { data } = await httpWithAuth.post(REGENERATE_SIGN_BANK_ID_CHECK_LIST(id), {
    checkList
  });
  return data;
};

export const signDepositAccountContactApi = async (contractId: number) => {
  const { data } = await httpWithAuth.post(SIGN_DEPOSIT_CONTRACT_CONTRACT(contractId));
  return data;
};

export const finishContactApi = async (contractId: number) => {
  const { data } = await httpWithAuth.post(FINISH_CONTRACT(contractId));
  return data;
};

export const deleteAllIncomeBilagsApi = async (contractId: number) => {
  const { data } = await httpWithAuth.post(DELETE_ALL_BILAGS_CONTRACT(contractId));
  return data;
};

export const closeDepositApi = async (contractId: number) => {
  const { data } = await httpWithAuth.post(CLOSE_DEPOSIT(contractId));
  return data;
};

export const releaseDepositApi = async ({
  formData,
  contractId
}: {
  formData: ReleaseDepositFormData;
  contractId: number;
}) => {
  const { data } = await httpWithAuth.post(RELEASE_DEPOSIT(contractId), {
    category: formData.category,
    tenant: formData.category === 'SPLIT' ? Number(formData.amount_tenant) : undefined,
    landlord: formData.category === 'SPLIT' ? Number(formData.amount_landlord) : undefined
  });
  return data;
};

export const getCountryListApi = async () => {
  const { data } = await httpWithAuth.get(GET_LIST_COUNTRY);

  return data;
};

export const fetchContractApi = async (contractId: number) => {
  const { data } = await httpWithAuth.get(UPDATE_CONTRACT(contractId));

  return data;
};

export const createCheckListApi = async ({ formData, contractId }: { formData: CheckContract; contractId: number }) => {
  const { data } = await httpWithAuth.post(CREATE_CHECK_LIST(contractId), {
    options: formData.options,
    number_keys: Number(formData.number_keys),
    garage: Number(formData.garage),
    fire_extinguisher_count: Number(formData.fire_extinguisher_count),
    parking_space: Number(formData.parking_space),
    stalls_count: Number(formData.stalls_count)
  });

  return data;
};

export type DepreciationFormDataT = {
  houseId: number;
  type: number;
  name?: string;
  date?: string;
  depreciation?: string;
  lifetime?: number;
  lifetimeVariant?: string;
  tax_deduction_sum?: string;
  current_total_revenue?: string;
  sum?: string;
  year?: string;
  documents?: (File | string)[];
};

export const INITIAL_DEPRECIATION_VALUE = {
  houseId: NaN,
  type: 1,
  name: '',
  date: '',
  depreciation: '15',
  lifetime: 3,
  lifetimeVariant: '1',
  tax_deduction_sum: '0',
  sum: '',
  year: undefined,
  documents: [],
  current_total_revenue: '0'
};

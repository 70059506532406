import styled, { css } from 'styled-components';

export const StyledDatePicker = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-self: end;
  font-size: 16px;
`;

export const calendarIconCss = css`
  width: 18px;
  fill: currentColor;
  margin-right: 15px;
`;

export const arrowIconCss = css`
  width: 48px;
  fill: currentColor;
`;

export const StyledItem = styled.label`
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  padding: 12px 25px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  transition: background-color 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray08};
  }
`;

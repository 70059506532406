import React, { FC } from 'react';
import { TextInfo } from 'components/common';
import { useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { StyledContainer } from './styled';

export const InfoFields: FC = () => {
  const { g } = useGlobalTranslation('landlord');
  const { selectedHouse } = useAppSelector(({ house }) => house);
  const { name, city, lease_number, postcode, share_number, property_number, address, holding_number, section_number } =
    selectedHouse || {};
  return (
    <StyledContainer>
      <TextInfo label={g('inputs.houseName')}>{name}</TextInfo>
      <TextInfo label={g('inputs.city')}>{city}</TextInfo>
      <TextInfo label={g('inputs.address')}>{address}</TextInfo>
      <TextInfo label={g('inputs.postcode')}>{postcode}</TextInfo>
      <TextInfo label={g('inputs.propertyNumber')}>{property_number}</TextInfo>
      <TextInfo label={g('inputs.leaseNumber')}>{lease_number}</TextInfo>
      <TextInfo label={g('inputs.shareNumber')}>{share_number}</TextInfo>
      <TextInfo label={g('inputs.subHoldingNumber')}>{holding_number}</TextInfo>
      <TextInfo label={g('inputs.sectionNumber')}>{section_number}</TextInfo>
    </StyledContainer>
  );
};

export default InfoFields;

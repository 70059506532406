import React, { FC, useState } from 'react';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Modal from 'components/common/Modal';
import View from './View';
import { StepEnum } from './View/data';
import { SubTitle } from './styled';
import { INITIAL_CONTRACT_FORM_DATA } from '../../../../domain/entity/rentContract/RentContract';

const MAX_STEP = 4;

type PropsT = {
  index?: number;
};

const NewRentContract: FC<PropsT> = () => {
  const [step, setStep] = useState<StepEnum>(StepEnum.RENT_CONTRACT);
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { currentContract } = useAppSelector((state) => state.contract);
  const { rentContract } = useAppSelector((state) => state.modals);
  const { closeRentContract, openDiscardChange } = modalsSlice.actions;

  const MAP_STEP_TO_TITLE: Record<StepEnum, string> = {
    [StepEnum.RENT_CONTRACT]: t('rentContract'),
    [StepEnum.SAVE_RENT_CONTRACT]: t('rentContract'),
    [StepEnum.INCLUDING]: f('including'),
    [StepEnum.DEPOSIT_ACCOUNT]: f('depositAccount'),
    [StepEnum.INFORM_ABOUT_TENANT]: f('informAboutTenant'),
    [StepEnum.TENANCY_TERMS]: f('tenancyTerms'),
    [StepEnum.HELP_SIGN_BY_ID]: '',
    [StepEnum.HELP_DEPOSIT]: ''
  };

  return (
    <Modal
      isOpen={rentContract}
      onClose={() =>
        isDirty ? dispatch(openDiscardChange(() => dispatch(closeRentContract()))) : dispatch(closeRentContract())
      }
      withoutAnimation={isDirty}
      title={MAP_STEP_TO_TITLE[step]}
    >
      {!(step === StepEnum.HELP_DEPOSIT || step === StepEnum.HELP_SIGN_BY_ID) && (
        <SubTitle>{step !== 0 && step !== 5 && `${step} / ${MAX_STEP}`}</SubTitle>
      )}
      <View
        initialValue={INITIAL_CONTRACT_FORM_DATA(currentContract)}
        setIsDirty={setIsDirty}
        setStep={setStep}
        step={step}
      />
    </Modal>
  );
};

export default NewRentContract;

import styled from 'styled-components';
import { mq } from 'constant/mediaqueries';
import Button from 'components/controls/Button';

export const StyledButton = styled(Button)`
  margin: 48px 0 30px;

  ${mq.lowerSm} {
    max-width: 100%;
    margin: 15px 0 0;
  }
`;

export const ActionsButton = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 278px);
  column-gap: 30px;

  ${mq.lowerSm} {
    margin: 0 15px 15px;
    grid-template-columns: 1fr;

    & button:last-of-type {
      grid-row: 1;
    }
  }
`;

import styled from 'styled-components';
import BaseButton from 'components/controls/Button';

export const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin: 30px 0 0;
  gap: 42px 15px;
  grid-template-columns: repeat(3, 80px);
`;

export const MonthButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.gray03};
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;

import styled, { css } from 'styled-components';
import BaseButton from 'components/controls/Button';

type ItemPropsT = {
  isSelected: boolean;
};

export const Wrapper = styled.div`
  display: grid;
  gap: 15px;
`;

export const Item = styled.div<ItemPropsT>`
  display: grid;
  width: 100%;
  padding: 12px 24px;
  border: 1px solid ${({ theme, isSelected }) => theme.colors[isSelected ? 'blue01' : 'gray04']};
  background: ${({ theme, isSelected }) => theme.colors[isSelected ? 'blue02' : 'transparent']};
  pointer-events: ${({ isSelected }) => isSelected && 'none'};
  cursor: pointer;
  transition: background-color 0.4s, border 0.4s, opacity 0.4s;

  &:hover {
    opacity: 0.6;
  }
`;

export const Name = styled.span`
  font-weight: 500;
`;

export const DateText = styled.span`
  color: ${({ theme }) => theme.colors.gray01};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const backIconCss = css`
  width: 48px;
  fill: currentColor;
`;

export const Top = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
`;

export const BackButton = styled(BaseButton)`
  position: relative;
  left: -24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  gap: 0;
  text-transform: initial;
`;

export const Title = styled.p`
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  flex: 1 0 auto;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red01};
  margin: 0;
  width: 100%;
  text-align: center;
`;

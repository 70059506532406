import styled from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const StyledContainer = styled.div`
  display: grid;
  column-gap: 2rem;
  ${mq.greaterMd} {
    grid-template-columns: 1fr 1fr;
  }
`;

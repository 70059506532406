import styled from 'styled-components';
import RadioButton from 'components/controls/RadioButton';

export const StyledRadioButton = styled(RadioButton)`
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: repeat(1, 300px);
  margin-top: 20px;
`;

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
`;

export const RadiosContent = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 5px;
`;

export const Span = styled.span`
  display: block;
  min-width: 756px;
  width: 100%;
`;

export const RadioButtonContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
`;

export const ContentPopup = styled.span`
  width: 200px;
  padding: 8px 12px;
  background-color: #ffffff;
`;

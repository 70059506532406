import React, { FC, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Context, themes } from 'context/Theme';
import ThemeType from 'context/Theme/ThemeType';

const [defaultTheme] = themes;

const Provider: FC = (props) => {
  const { children } = props;
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.Light);
  const theme = themes.find(({ type }) => type === themeType) || defaultTheme;

  return (
    <Context.Provider
      value={{
        themeType,
        setThemeType
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Context.Provider>
  );
};

export default Provider;

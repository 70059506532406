import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ROUTE_PROFILE_SUBSCRIPTION, ROUTE_RENT_CONTRACT } from 'constant/routes';
import { contractSlice } from 'data/store/rentContractReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import Icon from 'components/common/Icon';
import infoIcon from 'components/svg/warning.svg?sprite';
import { StepEnum } from 'components/layouts/Modals/NewRentContract/View/data';
import { useData } from 'components/layouts/Modals/NewRentContract/View';
import {
  Span,
  StyledButton,
  ActionsButton,
  ErrorMessage,
  Text,
  TextButton,
  infoIconCss
} from 'components/layouts/Modals/NewRentContract/styled';
import { modalsSlice } from '../../../../../../data/store/modalReducer';
import { StyledLink, StyledText, StyledTextContainer } from '../DepositAccount/styled';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const SaveRentContract: FC<PropsT> = (props) => {
  const { setStep } = props;
  const { data } = useData();
  const [signBankId, setSignBankId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSecond, setIsLoadingSecond] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { g } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { pending, pendingSignBankId } = useAppSelector((state) => state.contract);
  const { user, pending: userPending } = useAppSelector((state) => state.profile);
  const { error, newContractId } = useAppSelector((state) => state.contract);
  const { createContract, clearError, signContractByBankID, resetNewContractId } = contractSlice.actions;
  const { openUpdatePhoneNumber } = modalsSlice.actions;
  const { closeRentContract } = modalsSlice.actions;

  const onSubmit = async () => {
    setIsLoading(true);
    await dispatch(createContract(data));
  };

  const onSubmitSignBankId = async () => {
    setIsLoadingSecond(true);
    if (!user?.phone_code || !user.country) {
      dispatch(openUpdatePhoneNumber());
      setIsLoadingSecond(false);
      return;
    }

    await dispatch(createContract(data));
    setSignBankId(true);
  };

  useEffect(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (newContractId) {
      if (signBankId && user?.subscriptionActive) {
        dispatch(signContractByBankID({ id: newContractId }));
        setSignBankId(false);
      }
      history.push(`${ROUTE_RENT_CONTRACT}/${newContractId}`);
      dispatch(resetNewContractId());
    }
    setIsLoading(false);
    setIsLoadingSecond(false);
  }, [newContractId]);

  return (
    <>
      <Span />
      <ActionsButton>
        <Button isLoading={isLoading} disabled={pending || pendingSignBankId || userPending} onClick={onSubmit}>
          {f('saveContract')}
        </Button>
      </ActionsButton>
      <Text>{f('saveContractWithoutSending')}</Text>
      <ActionsButton>
        <StyledButton
          isLoading={isLoadingSecond}
          disabled={pending || pendingSignBankId || userPending}
          type={'button'}
          variant={'outline'}
          onClick={onSubmitSignBankId}
        >
          {f(String(data.is_deposit_account_variant) !== '1' ? 'signBankIdAndSend' : 'paySignBankIdAndSend')}
        </StyledButton>
      </ActionsButton>
      {user?.subscriptionActive && (
        <TextButton
          onClick={() => {
            setStep(StepEnum.HELP_SIGN_BY_ID);
          }}
          variant={'text'}
        >
          {f('signContractById')}
          <Icon icon={infoIcon} color={'blue01'} css={infoIconCss} />
        </TextButton>
      )}
      {!user?.subscriptionActive && (
        <>
          <StyledText>{f('buySubscribeOpenDeposit.title')}</StyledText>
          <StyledTextContainer>
            <StyledText>{f('buySubscribeOpenDeposit.message1')}</StyledText>
            <StyledLink href={ROUTE_PROFILE_SUBSCRIPTION} target={'_blank'}>
              {g('sidebar.subscription')}
            </StyledLink>
            <StyledText>{f('buySubscribeOpenDeposit.message3')}</StyledText>
          </StyledTextContainer>
        </>
      )}
      {error && <ErrorMessage>{g(error)}</ErrorMessage>}
    </>
  );
};

export default SaveRentContract;

enum Breakpoint {
  Xxs = 320,
  Xs = 544,
  Sm = 768,
  Md = 1024,
  Lg = 1200,
  Xl = 1300,
  Xxl = 1440,
  XxXl = 1600
}

export default Breakpoint;

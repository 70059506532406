import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';
import { ThemeT } from 'context/Theme';
import Input from 'components/controls/Input/Input';

type StatusPropsT = {
  isAuto?: boolean;
};

export const StyledContainer = styled.div`
  margin-top: 10px;

  ${mq.greaterXxl} {
    margin-top: 0;
  }
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
`;

export const StyledNameTable = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.22px;
  color: ${({ theme }) => theme.colors.black01};
`;

export const StyledSearchImg = styled.img`
  width: 21px;
  height: 21px;
`;

export const searchIconCss = css`
  width: 22px;
  fill: currentColor;
`;

type InputPropsT = {
  isFocused: boolean;
};

export const StyledInput = styled(Input)<InputPropsT>`
  width: ${({ isFocused }) => (isFocused ? '250px' : '105px')};
  transition: width 0.4s;
`;

export const styleInputCss = css`
  padding: 0 0 0 15px;
  height: max-content;
`;

export const fieldStyleCss = (isFocused: boolean, theme: ThemeT) => css`
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
  border: ${isFocused ? `1px solid ${theme.colors.blue01}` : '1px solid transparent'};
  border-radius: 3px;

  &:focus-within {
    box-shadow: none;
  }
`;

export const StyledTable = styled.table`
  margin-top: 5px;
  width: 100%;
  border-spacing: 0 6px;
`;

export const StyledHead = styled.thead``;

export const StyledHeadCell = styled.th`
  font-weight: 400;
  padding: 5px 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black01};
  line-height: 1.5;
  letter-spacing: 0.15px;

  &:last-of-type {
    text-align: right;
  }
`;

export const StyledRow = styled.tr<StatusPropsT>`
  color: ${({ theme, isAuto }) => (isAuto ? theme.colors.black01 : theme.colors.gray03)};

  &:hover td {
    border-top: 1px solid ${({ theme }) => theme.colors.blue01};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue01};

    &:first-of-type {
      border-left: 1px solid ${({ theme }) => theme.colors.blue01};
    }

    &:last-of-type {
      border-right: 1px solid ${({ theme }) => theme.colors.blue01};
    }
  }
`;

export const StyledCell = styled.td`
  cursor: pointer;
  font-weight: 400;
  padding: 18px 24px;
  text-align: left;
  line-height: 1.5;
  letter-spacing: 0.15px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray04};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};
  transition: border-color 0.4s;
  color: inherit;
  white-space: nowrap;

  &:first-of-type {
    font-weight: 500;
    border-left: 1px solid ${({ theme }) => theme.colors.gray04};
    border-radius: 3px 0 0 3px;
  }

  &:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.gray04};
    text-align: right;
    border-radius: 0 3px 3px 0;
  }
`;

type StyledSumPropsT = {
  color?: keyof ThemeT['colors'];
};

export const StyledCellSum = styled(StyledCell)<StyledSumPropsT>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : 'inherit')};
  font-weight: 500;
`;

export const StyledBody = styled.tbody``;

export const StatusAuto = styled.span<StatusPropsT>`
  visibility: ${({ isAuto }) => (isAuto ? 'visible' : 'hidden')};
  background: ${({ theme }) => theme.colors.gray07};
  margin-right: 20px;
  border-radius: 2px;
  padding: 1px 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const SortIcons = styled.span`
  display: inline-grid;
  gap: 3px;
  vertical-align: middle;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.gray01};
  cursor: pointer;
`;

export const iconCss = (active: boolean) => css`
  width: 10px;
  fill: ${active ? '#000' : 'currentColor'};
  transition: fill 0.4s;
`;

export const iconCssReverse = (active: boolean) => css`
  ${iconCss(active)};
  transform: rotate(180deg);
`;

import { FieldT } from './index';
import { ProfileFormT } from '../../../domain/entity/profile/ProfileFormData';
import { SelectOptionT } from '../../controls/Select';

export const HOUSE_INFO_FIELDS = (isHideName: boolean): FieldT[] => {
  const fields: FieldT[] = [
    { type: 'text', name: 'city', text: 'city', required: true },
    { type: 'text', name: 'address', text: 'address', required: true, style: { gridColumn: '1/3' } },
    { type: 'text', name: 'postcode', text: 'postcode', required: true, maxLength: 4 },
    { type: 'number', name: 'property_number', text: 'propertyNumber' },
    { type: 'number', name: 'holding_number', text: 'subHoldingNumber' },
    { type: 'number', name: 'lease_number', text: 'leaseNumber' },
    { type: 'number', name: 'section_number', text: 'sectionNumber' },
    { type: 'number', name: 'share_number', text: 'shareNumber' }
  ];

  return isHideName ? fields : [{ type: 'text', name: 'name', text: 'houseName', required: true }, ...fields];
};

export const PROFILE_FIELDS = (user?: ProfileFormT, countryOptions?: SelectOptionT[]): FieldT[] => [
  { type: 'text', name: 'firstName', text: 'firstName', required: true },
  { type: 'text', name: 'lastName', text: 'lastName', required: true },
  {
    type: 'phone',
    name: 'phone',
    text: 'phone',
    required: true,
    serverError: true,
    initialValue: `${user?.phone_code || ''}${user?.phone || ''}`
  },
  { type: 'selectCountry', name: 'country', text: 'country', required: true, options: countryOptions },
  { type: 'text', name: 'city', text: 'city', required: true },
  { type: 'text', name: 'address', text: 'address', required: true },
  { type: 'text', name: 'postcode', text: 'postcode', required: true },
  { type: 'text', name: 'ssn', text: 'ssn', required: true, maxLength: 11 },
  { type: 'date', name: 'birthday', text: 'dateOfBirth' },
  { type: 'text', name: 'iban', text: 'iban' }
];

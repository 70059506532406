import styled from 'styled-components';
import Button from '../../controls/Button';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 0;
`;

export const StyledText = styled.div`
  margin: 1.25rem 0 0 0;
  letter-spacing: 0.15px;
`;

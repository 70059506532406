import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTE_LOGIN } from 'constant/routes';
import { authSlice } from 'data/store/authReducer';
import useAppLogout from 'hooks/useAppLogout';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import checkbox from 'components/svg/big_square_check_icon.svg?sprite';
import { StyledTitle, StyledTextContainer, StyledSubtitle, checkboxIconCss, StyledContainer } from './styled';

const ChangeEmail: FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {
  const appLogout = useAppLogout();
  const { t, g } = useGlobalTranslation('changeEmail');
  const dispatch = useAppDispatch();
  const { changeEmailFetching } = authSlice.actions;
  const { codeConfirmed, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    appLogout();
    const { slug } = match?.params;
    dispatch(changeEmailFetching({ code: slug }));
  }, []);

  useEffect(() => {
    if (codeConfirmed !== undefined && codeConfirmed === false) {
      setTimeout(() => {
        window.location.href = ROUTE_LOGIN;
      }, 10000);
    }
  }, [codeConfirmed]);

  return (
    <StyledContainer>
      <Icon icon={checkbox} css={checkboxIconCss} />

      <StyledTextContainer>
        <StyledTitle>{t('title')}</StyledTitle>
        {error && <StyledSubtitle>{g(error)}</StyledSubtitle>}
      </StyledTextContainer>
    </StyledContainer>
  );
};

export default ChangeEmail;

import React, { FC } from 'react';
import Modal from 'components/common/Modal';
import { useAppSelector } from 'hooks/redux';
import View from './View';
import useGlobalTranslation from '../../../../hooks/useGlobalTranlation';

type PropsT = {
  index?: number;
};

const UpdateAgreePolicy: FC<PropsT> = () => {
  const { g } = useGlobalTranslation();
  const { updateIsAgreePolicy } = useAppSelector((state) => state.modals);

  if (!updateIsAgreePolicy) {
    return null;
  }

  return (
    <Modal withoutAnimation isHiddenCloseButton isOpen={updateIsAgreePolicy}>
      <View />
    </Modal>
  );
};

export default UpdateAgreePolicy;

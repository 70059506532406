import React, { FC } from 'react';
import BaseSelect, { SelectProps } from 'components/controls/Select';
import { Controller } from 'react-hook-form';

interface PropsT extends Omit<SelectProps, 'defaultValue'> {
  control: any;
  defaultValue?: string | number;
}

const Select: FC<PropsT> = (props) => {
  const { defaultValue, control, name, options, label, ...restProps } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref } }) => {
        const currentValue = options?.find((option) => value === option.value);

        return (
          <BaseSelect
            {...restProps}
            inputRef={ref}
            label={label}
            value={currentValue}
            onChange={(newValue) => onChange((newValue ?? value)?.value)}
            options={options}
          />
        );
      }}
    />
  );
};

export default Select;

import React, { FC } from 'react';
import { PinInput, PinInputProps } from '@chakra-ui/pin-input';
import Field, { PropsT as FieldPropsT } from './Field';
import { Wrapper } from './styled';

export type PropsT = Omit<FieldPropsT, 'value' | 'defaultValue' | 'onChange'> &
  Pick<PinInputProps, 'onChange' | 'onComplete'> & {
    length: number;
    value?: string;
    defaultValue?: string;
    onChange: (value: string) => void;
  };

const CodeField: FC<PropsT> = (props) => {
  const { length, type, size, onBlur, disabled, onChange, ...restProps } = props;
  const { name } = restProps;

  return (
    <Wrapper data-auto-scroll={restProps.name}>
      <PinInput autoFocus={true} {...restProps} type="alphanumeric" isDisabled={disabled} onChange={onChange}>
        {Object.keys(Array(length).fill(0)).map((key) => (
          <Field key={key} name={name} onBlur={onBlur} />
        ))}
      </PinInput>
    </Wrapper>
  );
};

export default CodeField;

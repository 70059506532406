import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PriceT } from 'domain/entity/subscription/PriceT';
import SubscriptionT from 'domain/entity/subscription/SubscriptionT';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';

type PaymentT = {
  url: string;
};

export interface ISubscriptionState {
  subscriptions: SubscriptionT[];
  prices: PriceT[] | null;
  portal: { success: boolean; url?: string; message?: string } | null;
  priceId: string | null;
  payment: PaymentT | null;
  pending: boolean;
  error: string;
}

const initialState: ISubscriptionState = {
  subscriptions: [],
  prices: null,
  portal: null,
  payment: null,
  priceId: null,
  pending: false,
  error: ''
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    subscriptionFetching(state: ISubscriptionState, { payload }: PayloadAction<{ priceId: string }>) {
      state.pending = true;
      state.priceId = payload.priceId;
    },
    subscriptionFetchingSuccess(state: ISubscriptionState, { payload }: PayloadAction<PaymentT>) {
      state.pending = false;
      state.payment = payload;
      state.error = '';
    },
    subscriptionInfoFetching(state: ISubscriptionState) {
      state.pending = true;
    },
    subscriptionInfoFetchingSuccess(
      state: ISubscriptionState,
      { payload }: PayloadAction<{ subscriptions: SubscriptionT[]; prices: PriceT[] }>
    ) {
      state.subscriptions = payload.subscriptions;
      state.prices = payload.prices;
      state.error = '';
      state.pending = false;
    },
    subscriptionInfoFetchingError(state: ISubscriptionState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    subscriptionPortalFetching(state: ISubscriptionState) {
      state.pending = true;
      state.error = '';
    },
    subscriptionPortalFetchingSuccess(
      state: ISubscriptionState,
      { payload }: PayloadAction<{ success: boolean; url?: string; message?: string }>
    ) {
      state.portal = payload;
      state.error = '';
      state.pending = false;
    },
    resetSubscription(state: ISubscriptionState) {
      state.subscriptions = [];
    }
  }
});

const selectSubscription = (state: RootState): ISubscriptionState => state.subscription;

export const subscriptionSelector = createSelector([selectSubscription], (subscription) => subscription);

export const {
  subscriptionFetching,
  subscriptionFetchingSuccess,
  subscriptionInfoFetching,
  subscriptionInfoFetchingSuccess,
  subscriptionInfoFetchingError,
  subscriptionPortalFetching,
  subscriptionPortalFetchingSuccess,
  resetSubscription
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

import styled, { css } from 'styled-components';
import { hidden, mq } from '../../../../../constant/mediaqueries';
import { Button } from '../../../../../components/ui';

export const ActionButtons = styled.div<{ isHiddenTop?: boolean; isHiddenBottom?: boolean }>`
  display: grid;
  justify-content: end;
  align-items: center;
  grid-auto-flow: column;
  gap: 15px;

  ${mq.lowerLg} {
    width: 100%;
    justify-content: end;
    margin: 20px 0 0;
  }

  ${mq.lowerXs} {
    justify-content: center;
    justify-items: center;
    grid-auto-flow: initial;
  }
  ${({ isHiddenTop, isHiddenBottom }) => {
    if (isHiddenTop) {
      return css`
        @media (max-width: 544px) {
          display: none;
        }
      `;
    }
    if (isHiddenBottom) {
      return css`
        @media (min-width: 544px) {
          display: none;
        }
      `;
    }
    return '';
  }}
`;

export const iconButtonCss = css`
  fill: currentColor;
  width: 24px;
`;

export const StyledButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.2px;
`;

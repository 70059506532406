import styled, { css } from 'styled-components';
import { mq } from '../../../../constant/mediaqueries';
import PictureList from '../../../controls/PictureList';

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
`;

export const StyledContainer = styled(PictureList)`
  margin: 2rem 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;

  ${mq.lowerSm} {
    grid-template-columns: 1fr 1fr;
  }

  ${mq.lowerXs} {
    grid-template-columns: 1fr;
  }
`;

export const imgCss = css`
  max-width: 204px;
  margin: 0;

  ${mq.lowerSm} {
    width: 100%;
    max-width: initial;
    height: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 204px;
  padding-top: 93%;

  ${mq.lowerXs} {
    width: 280px;
  }
`;

import React, { Dispatch, FC, SetStateAction } from 'react';
import { ViewVariantT } from '../Header';
import { YearButton, YearsContainer } from './styled';

type PropsT = {
  selectedMonth: Date;
  setSelectedMonth: Dispatch<SetStateAction<Date>>;
  selectedYear: Date;
  setViewVariant: Dispatch<SetStateAction<ViewVariantT>>;
};

const Years: FC<PropsT> = (props) => {
  const { selectedYear, setViewVariant, selectedMonth, setSelectedMonth } = props;
  const currentYear = new Date(selectedYear).getFullYear();
  const years = [
    ...Array(12)
      .fill(0)
      .map((year, index) => new Date(currentYear - index, 1, 1))
      .reverse(),
    ...Array(13)
      .fill(0)
      .map((year, index) => new Date(currentYear + index + 1, 1, 1))
  ];

  return (
    <YearsContainer>
      {years.map((date) => (
        <YearButton
          onClick={(event) => {
            event.stopPropagation();
            setSelectedMonth(new Date(date.getFullYear(), selectedMonth.getMonth(), selectedMonth.getDate()));
            setViewVariant('months');
          }}
          key={date.toString()}
          type="button"
          variant="text"
        >
          {date.getFullYear()}
        </YearButton>
      ))}
    </YearsContainer>
  );
};

export default Years;

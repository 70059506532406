import React, { FC } from 'react';
import { modalContentCss, StyledPicture } from './styled';
import { pictureViewerSelector, pictureViewerSlice } from '../../../../data/store/pictureViewerReducer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import Modal from '../../../common/Modal';

export const PictureViewer: FC = () => {
  const dispatch = useAppDispatch();
  const { show, src } = useAppSelector(pictureViewerSelector);
  const { resetPictureViewerData } = pictureViewerSlice.actions;
  const handleCloseClick = () => {
    dispatch(resetPictureViewerData());
  };

  return (
    <Modal
      isConfirm
      contentCss={modalContentCss}
      isOpen={show}
      onClose={handleCloseClick}
      content={() => <StyledPicture src={src} />}
    />
  );
};

export default PictureViewer;

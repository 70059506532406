import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 100% !important;
`;

export const Column = styled.div<ColumnT>`
  width: 100%;
  padding: 0 50px 20px;
  display: grid;
  gap: 20px;

  ${mq.greaterXxl} {
    gap: 40px;
    grid-auto-flow: column;
    grid-template-columns: 1092px;
    width: 100%;
  }
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0 0 0 0.5rem;
`;
export const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
  font-size: 0.875rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

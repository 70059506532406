import styled, { css } from 'styled-components';
import RadioButton from '../../../../../controls/RadioButton';
import Button from '../../../../../controls/Button';

type WrapperProps = {
  isDisplay?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  padding: 0;
  display: ${({ isDisplay = true }) => (isDisplay ? 'initial' : 'none')};
`;
export const Span = styled.span`
  display: block;
  min-width: 756px;
  width: 100%;
`;
export const Title = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
`;

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
`;

export const Label = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray01};
  grid-column: 1/3;
`;

export const StyledRadioButton = styled(RadioButton)`
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
  display: inline;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray01};
  max-width: 100%;
  text-transform: initial;
  text-align: start;
`;

export const CalculationsButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  text-transform: initial;
`;

export const infoIconCss = css`
  vertical-align: center;
`;

export const Actions = styled.div`
  margin-top: 20px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
`;

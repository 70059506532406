import React, { FC, ReactNode } from 'react';
import { EmptyList } from 'components/ui';
import { StyledContainer } from './styled';

interface PropsI {
  children: ReactNode;
  what: string;
  variant?: 'default' | 'trash';
}

const ListView: FC<PropsI> = ({ children, what, variant }) => {
  return <StyledContainer>{children || <EmptyList what={what} variant={variant} />} </StyledContainer>;
};

export default ListView;

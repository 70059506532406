import { CSSProperties } from 'react';

type FieldT = {
  type: string;
  name: string;
  text: string;
  required?: boolean;
  serverError?: boolean;
  style?: CSSProperties;
};

export const EMAIL_FIELDS: FieldT[] = [{ type: 'email', name: 'email', text: 'newEmail', serverError: true }];

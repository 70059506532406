import styled from 'styled-components';
import { containerMixin } from 'mixins';

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray03};
  letter-spacing: 0.15px;
  line-height: 1.5em;
`;

export const StyledTextButton = styled.div`
  margin: 1rem 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
`;

export const StyledHr = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray07};
  width: 100%;
  margin: 0 0 0.25rem 0;
`;

import * as Yup from 'yup';

const requiredWhenTypeItem = (type: string, field: any) => (type === '1' && field.required()) || field;
const schema = Yup.object().shape({
  houseId: Yup.string().required(),
  type: Yup.string().required().max(50),
  name: Yup.string().max(50).when('type', requiredWhenTypeItem),
  current_total_revenue: Yup.string().max(50),
  date: Yup.string().max(50).when('type', requiredWhenTypeItem),
  year: Yup.number().max(3000),
  depreciation: Yup.string(),
  lifetime: Yup.number().required().min(3),
  sum: Yup.string().when('type', requiredWhenTypeItem),
  tax_deduction_sum: Yup.string()
});

export default schema;

import { createContext, useContext } from 'react';
import themes from './themes';
import Provider from './Provider';
import ThemeType from './ThemeType';

export type ThemeT = {
  type: ThemeType;
  colors: {
    black01: string;
    black02: string;
    black03: string;
    black04: string;
    black05: string;
    black06: string;
    blue01: string;
    blue02: string;
    blue03: string;
    green01: string;
    green02: string;
    green03: string;
    green04: string;
    green05: string;
    white01: string;
    gray01: string;
    gray02: string;
    gray03: string;
    gray04: string;
    gray05: string;
    gray06: string;
    gray07: string;
    gray08: string;
    red01: string;
    orange01: string;
  };
  fontSizes: {
    xxs: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xlg: string;
    l: string;
    xl: string;
    '2xl': string;
  };
  radii: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    '2xl': string;
  };
  shadows: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  transition: string;
};

export type ContextT = {
  themeType: ThemeType;
  setThemeType: (themeType: ThemeType) => void;
};

export const Context = createContext<ContextT>({} as ContextT);

export default function useTheme(): ContextT {
  return useContext(Context);
}

export { themes, Provider };

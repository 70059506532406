import React, { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { TypeT, useHomeOverview } from './Table/data';
import Table from './Table';
import AddButton from './AddButton';
import DatePeriod from './DatePeriod';
import {
  StyledContainer,
  StyledHeader,
  StyledBlock,
  StyledBlockBlue,
  StyledDivider,
  StyledCost,
  StyledText,
  StyledTextHidden,
  SimpleBlock,
  StyledContent,
  SimpleBlockHidden,
  StyledSmallText,
  DatePickerContainer,
  HiddenModal
} from './styled';
import { ApplicationModal } from '../../components/common/ApplicationModal';
import { modalsSlice } from '../../data/store/modalReducer';

type FilterPropsT = {
  search: string;
  type: TypeT[];
  date_from: string;
  date_to: string;
};

const Dashboard = () => {
  const { t } = useGlobalTranlation('pages');
  const { g } = useGlobalTranlation();
  const formContext = useForm<FilterPropsT>({ defaultValues: { type: [], search: '', date_from: '', date_to: '' } });
  const dispatch = useAppDispatch();
  const { selectedHouse } = useAppSelector(({ house }) => house);
  const { appModal } = useAppSelector((state) => state.modals);
  const { closeAppModal, openAppModal } = modalsSlice.actions;
  const { documents } = useAppSelector(({ documents }) => documents);
  const { excludingTaxSumString, expenseSumString, taxSumString, incomeSumString } = useHomeOverview(documents || []);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      dispatch(openAppModal());
    }
  }, []);

  return (
    <FormProvider {...formContext}>
      <HiddenModal>
        <ApplicationModal
          isOpen={appModal || false}
          title={g('Utleieappen')}
          text={g('downloadApp')}
          cancelButtonProps={{ children: g('continueBrowser'), onClick: () => dispatch(closeAppModal()) }}
        />
      </HiddenModal>
      <StyledContainer>
        <StyledContent>
          <DatePickerContainer>
            <DatePeriod selectedHouse={selectedHouse} />
          </DatePickerContainer>
          <StyledHeader>
            <StyledBlockBlue>
              <SimpleBlock>
                <StyledCost color={'white01'}>
                  <StyledSmallText>kr</StyledSmallText> {incomeSumString}
                </StyledCost>
                <StyledText color={'white01'}>{g('income')}</StyledText>
                <StyledTextHidden color={'blue03'} fontWeight={600}>
                  {t('dashboard.taxFree')}: <StyledSmallText>kr</StyledSmallText> {excludingTaxSumString}
                </StyledTextHidden>
              </SimpleBlock>
              <StyledDivider />
              <SimpleBlockHidden>
                <StyledCost color={'blue03'}>
                  <StyledSmallText>kr</StyledSmallText> {excludingTaxSumString}
                </StyledCost>
                <StyledText color={'blue03'}>{t('dashboard.taxFree')}</StyledText>
              </SimpleBlockHidden>
            </StyledBlockBlue>
            <StyledBlock>
              <StyledCost>
                <StyledSmallText>kr</StyledSmallText> {expenseSumString}
              </StyledCost>
              <StyledText>{t('dashboard.costs')}</StyledText>
            </StyledBlock>
            <StyledBlock>
              <StyledCost>
                <StyledSmallText>kr</StyledSmallText> {taxSumString}
              </StyledCost>
              <StyledText>{t('dashboard.estimatedTax')}</StyledText>
            </StyledBlock>
          </StyledHeader>
        </StyledContent>
        <Table />
        <AddButton />
      </StyledContainer>
    </FormProvider>
  );
};

export default Dashboard;

import React, { FC } from 'react';
import Modal from 'components/common/Modal';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import View from './View';
import useGlobalTranslation from '../../../../hooks/useGlobalTranlation';

type PropsT = {
  index?: number;
};

const UpdateProfileNumber: FC<PropsT> = (props) => {
  const dispatch = useAppDispatch();
  const { g } = useGlobalTranslation();
  const { closeUpdatePhoneNumber } = modalsSlice.actions;
  const { updatePhoneNumber } = useAppSelector((state) => state.modals);

  if (!updatePhoneNumber) {
    return null;
  }

  return (
    <Modal
      isOpen={updatePhoneNumber}
      onClose={() => dispatch(closeUpdatePhoneNumber())}
      title={g('updatePhoneNumber.title')}
    >
      <View />
    </Modal>
  );
};

export default UpdateProfileNumber;

import styled from 'styled-components';
import { CSSProp } from 'styled-components';
import { ThemeT } from 'context/Theme';

type WrapperProps = {
  $css?: CSSProp;
  $color?: keyof ThemeT['colors'];
};

export const Wrapper = styled.span<WrapperProps>`
  display: inline-block;
  width: 1em;
  vertical-align: top;
  fill: ${(props) => props.$color && props.theme.colors[props.$color]};
  ${(props) => props.$css};
`;

export const Inner = styled.span`
  position: relative;
  display: block;
  width: 100%;
  height: 0;
`;

export const Svg = styled.svg`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

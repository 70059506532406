import React, { Dispatch, FC, MouseEventHandler, SetStateAction } from 'react';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import subYears from 'date-fns/subYears';
import en from 'date-fns/locale/en-US';
import Icon from 'components/common/Icon';
import arrowIcon from 'components/svg/next-arrow.svg?sprite';
import twoArrowIcon from 'components/svg/super-next-arrow.svg?sprite';
import { Wrapper, TitleButton, ActionsContainer, Button, arrowCss } from './styled';

export type ViewVariantT = 'months' | 'days' | 'year';

type PropsT = {
  selectedMonth: Date;
  selectedYears: Date;
  variant: ViewVariantT;
  setSelectedMonth: Dispatch<SetStateAction<Date>>;
  setSelectedYears: Dispatch<SetStateAction<Date>>;
  setViewVariant: Dispatch<SetStateAction<ViewVariantT>>;
};

const Header: FC<PropsT> = (props) => {
  const { selectedMonth, setSelectedMonth, setViewVariant, variant, selectedYears, setSelectedYears } = props;

  const setVariant: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    if (variant === 'days') {
      setViewVariant('months');
    }
    if (variant === 'months') {
      setViewVariant('year');
    }
    if (variant === 'year') {
      setViewVariant('days');
      setSelectedYears(selectedMonth);
    }
  };

  return (
    <Wrapper>
      {variant !== 'year' && (
        <>
          <ActionsContainer>
            <Button
              type="button"
              variant="text"
              color={'gray03'}
              onClick={() => setSelectedMonth(subYears(selectedMonth, 1))}
            >
              <Icon icon={twoArrowIcon} css={arrowCss(true)} />
            </Button>
            {variant === 'days' && (
              <Button type="button" variant="text" onClick={() => setSelectedMonth(subMonths(selectedMonth, 1))}>
                <Icon icon={arrowIcon} css={arrowCss(true)} />
              </Button>
            )}
          </ActionsContainer>
          <TitleButton type="button" variant="text" onClick={setVariant}>
            {format(selectedMonth, 'LLLL yyyy', {
              locale: en
            })}
          </TitleButton>
          <ActionsContainer>
            {variant === 'days' && (
              <Button type="button" variant="text" onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}>
                <Icon icon={arrowIcon} css={arrowCss()} />
              </Button>
            )}
            <Button type="button" variant="text" onClick={() => setSelectedMonth(addYears(selectedMonth, 1))}>
              <Icon icon={twoArrowIcon} css={arrowCss()} />
            </Button>
          </ActionsContainer>
        </>
      )}
      {variant === 'year' && (
        <>
          <ActionsContainer>
            <Button
              type="button"
              variant="text"
              color={'gray03'}
              onClick={() => setSelectedYears(subYears(selectedYears, 25))}
            >
              <Icon icon={twoArrowIcon} css={arrowCss(true)} />
            </Button>
          </ActionsContainer>
          <TitleButton type="button" variant="text" onClick={setVariant}>
            {`${selectedYears.getFullYear() - 11} - ${selectedYears.getFullYear() + 13}`}
          </TitleButton>
          <ActionsContainer>
            <Button type="button" variant="text" onClick={() => setSelectedYears(addYears(selectedYears, 25))}>
              <Icon icon={twoArrowIcon} css={arrowCss()} />
            </Button>
          </ActionsContainer>
        </>
      )}
    </Wrapper>
  );
};

export default Header;

// TODO: тут роуты для АПИ
import HouseData from 'domain/entity/house/HouseData';

export const LOGIN = 'auth/login';
export const RESET_PASSWORD_BY_LINK = 'auth/reset-password/send-link';
export const RESET_PASSWORD_BY_CODE = 'auth/reset-password/check';
export const RESET_PASSWORD = 'auth/reset-password';
export const CHANGE_EMAIL = (code: string) => `profile/email/${code}`;
export const FETCH_PROFILE = 'profile';
export const DELETE_PROFILE = 'profile';
export const FETCH_HOUSE = 'house';
export const DELETE_HOUSE = (houseId: number) => `house/${houseId}`;
export const CHANGE_EMAIL_BY_CODE = (code: string) => `profile/email/${code}`;
export const CHECK_EMAIL = 'auth/check-email';
export const REGISTRATION = 'auth/register';

export const GET_PROVIDERS = 'auth/providers';
export const LOGOUT_DIFI = 'auth/logoutDifi';
export const GET_DIFI_TOKEN = (code: string, state: string) => `difi/process?code=${code}&state=${state}`;
export const GET_ERROR_LIST = 'logger/get-errors';

export const FETCH_SUBSCRIPTION_INFO = 'subscription/info';
export const FETCH_SUBSCRIPTION_PORTAL = 'subscription/portal';
export const FETCH_SUBSCRIPTION = (priceId: string) => `subscription/subscribe/${priceId}`;

export const FETCH_ALL_DOCUMENTS = 'bilag/all-types';

export const ALTINN = 'altinn';
export const SEND_ALTINN_FORM = 'altinn/send-form';
export const FETCH_SCATTEETATEN_VIEW = 'altinn/getSkatteetatenView';
export const SEND_NEW_FORM_TO_ALTIN = 'altinn/send-new-form';
export const FETCH_ALTINN_DATA = `altinn/get-data-for-altinn`;

export const CREATE_BILAG = 'bilag';
export const EDIT_BILAG = (id?: number) => `bilag/${id}`;
export const RESTORE_BILAG = (bilagId: number, type = 'bilag') => `${type}/${bilagId}/restore`;
export const CREATE_INCOME_BILAG = 'incomebilag';
export const CHANGE_RENT_INCOME_BILAG = (bilagId: number) => `incomebilag/${bilagId}/update-sum`;
export const FETCH_CONTRACT = 'contract/all';
export const EDIT_INCOME_BILAG = (id?: number) => `incomebilag/${id}`;
export const CREATE_CONTRACT = 'contract';
export const UPDATE_CONTRACT = (contractId: number) => `contract/${contractId}`;
export const SIGN_BANK_ID_CONTRACT = (contractId: number) => `contract/${contractId}/sign-bankid`;
export const SIGN_BANK_ID_CHECK_LIST = (contractId: number) => `contract/${contractId}/sign-bankid-check-list`;
export const REGENERATE_SIGN_BANK_ID_CHECK_LIST = (contractId: number) =>
  `contract/${contractId}/regenerate-sign-bankid`;
export const SIGN_DEPOSIT_CONTRACT_CONTRACT = (contractId: number) => `contract/${contractId}/create-deposit`;
export const FINISH_CONTRACT = (contractId: number) => `contract/${contractId}/finish`;
export const DELETE_ALL_BILAGS_CONTRACT = (contractId: number) => `contract/${contractId}/trash-incomebilags`;
export const CLOSE_DEPOSIT = (contractId: number) => `contract/${contractId}/closeDeposit`;
export const RELEASE_DEPOSIT = (contractId: number) => `contract/${contractId}/releaseDeposit`;
export const GET_LIST_COUNTRY = 'contract/get-iso-country-list';
export const CREATE_CHECK_LIST = (contractId: number) => `contract/${contractId}/create-check-list`;
export const UPLOAD_DOCUMENTS = 'document';
export const FETCH_SUPPLIER = 'supplier';
export const CREATE_SUPPLIER = 'supplier';
export const DELETE_SUPPLIER = (supplierId: number) => `supplier/${supplierId}`;
export const GENERATE_CHECKLIST_PDF = (contractId: number) => `/contract/${contractId}/generate-checklist-pdf`;

export const PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL = 'profile/email';
export const PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD = 'profile/password';

export const CREATE_DEPRECIATION = 'depreciationbilag';
export const CREATE_DEPRECIATION_PERSONAL_USE = 'depreciationbilag/personal/create';
export const DELETE_DEPRECIATION_PERSONAL_USE = 'depreciationbilag/personal/delete';
export const FETCH_DEPRECIATION_PERSONAL_USE_CALCULATION = 'depreciationbilag/personal/calculate';

export const FETCH_REDIRECT_LINK_TO_SIGNICAT = (id: string) => `/contract/redirectLink/${id}`;

import format from 'date-fns/format';

export const formatDateForApi = (date: string): string | undefined => {
  const [day, month, year] = date.split('.');
  return date ? format(new Date(Number(year), Number(Number(month) - 1), Number(day)), 'yyyy-MM-dd') : undefined;
};

export const formatDateForField = (date?: string): string => {
  return date ? format(new Date(date), 'dd.MM.yyyy') : '';
};

import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ROUTE_TRASH_FOLDER } from 'constant/routes';
import { bilagSlice } from 'data/store/bilagReducer';
import { houseSelector } from 'data/store/houseReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import { documentsSelector, documentsSlice, fetchAllDocuments } from 'data/store/documentReducer';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { InfoFields } from 'components/common';
import { BackButton } from 'components/ui';
import PictureList from 'components/controls/PictureList';
import Icon from 'components/common/Icon';
import restoreIcon from 'components/svg/restore_icon.svg?sprite';
import SimplePage from 'components/common/SimplePage';
import { getBilagFields } from './fields';
import { StyledHr, StyledLink, StyledLinkText } from './styled';

export const TrashFolderItem: FC = () => {
  const { g } = useGlobalTranlation();
  const { currentDocument } = useAppSelector(documentsSelector);
  const { selectedHouse } = useAppSelector(houseSelector);

  const { setCurrentDocument } = documentsSlice.actions;
  const { restoreBilagFetch } = bilagSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { slug } = useParams<{ slug: string }>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!currentDocument && selectedHouse) {
      dispatch(fetchAllDocuments({ houseId: selectedHouse.id }));
    }
  }, []);

  useEffect(() => {
    if (!currentDocument && slug) {
      dispatch(setCurrentDocument(Number(slug)));
    }
  });
  const backButton =
    (currentDocument?.type === 'bilag' && currentDocument?.bilag.supplier.name) ||
    (currentDocument?.type === 'income-bilag' && currentDocument.bilag.title) ||
    (currentDocument?.type === 'depreciation-bilag' && currentDocument.bilag.name);

  const bilagType =
    (currentDocument?.type === 'bilag' && 'bilag') ||
    (currentDocument?.type === 'income-bilag' && 'incomebilag') ||
    (currentDocument?.type === 'depreciation-bilag' && 'depreciationbilag') ||
    'bilag';

  const fields = (currentDocument && getBilagFields(currentDocument)) || [];

  const onRestoreClick = (id: number) => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('cancel')
        },
        confirmButtonProps: {
          onClick: () => {
            dispatch(restoreBilagFetch({ id, type: bilagType, houseId: selectedHouse?.id || NaN }));
            history.goBack();
            dispatch(resetConfirmationData());
          },
          children: g('restore')
        },
        text: g('alerts.restoreBilag.message'),
        title: g('alerts.restoreBilag.title')
      })
    );
  };

  return (
    <SimplePage
      header={
        <>
          <BackButton route={ROUTE_TRASH_FOLDER}>{backButton}</BackButton>
          <StyledLink onClick={() => currentDocument?.bilag && onRestoreClick(currentDocument?.bilag.id)}>
            <Icon icon={restoreIcon} color={'blue01'} /> <StyledLinkText>{g('restore')}</StyledLinkText>
          </StyledLink>
        </>
      }
    >
      <InfoFields fields={fields} />
      {currentDocument?.bilag?.documents?.length > 0 && (
        <>
          <StyledHr />
          <PictureList documents={currentDocument?.bilag.documents} />
        </>
      )}
    </SimplePage>
  );
};

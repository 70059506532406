import { call, put, takeLatest } from 'redux-saga/effects';
import { uploadFiles } from 'data/store/fileReducer';
import { fetchAllDocuments } from 'data/store/documentReducer';
import { checkEmail, registration } from 'data/store/registrationReducer';
import { profileFetching, profileUpdate, profileDelete } from 'data/store/profileReducer';
import { createSupplier, deleteSupplier, fetchSupplier, SupplierUpdate } from 'data/store/supplierReducer';
import { changeRentIncomeBilag, createIncomeBilag, deleteIncomeBilag } from 'data/store/incomeBilagReducer';
import {
  fetchAltinnData,
  fetchAltinnFormData,
  fetchScattetatenView,
  loginAltinn,
  sendAltinnForm,
  sendNewFormToAltin
} from 'data/store/altinnReducer';
import { createManualBilag, deleteManualBilag, restoreBilagFetch, startStopBilag } from 'data/store/bilagReducer';
import {
  tokenFetching,
  resetPasswordFetching,
  resetPasswordCheckFetching,
  sendNewPasswordFetching,
  changeEmailFetching,
  logout,
  fetchAuthProviders,
  fetchDifiToken,
  logoutDifi
} from 'data/store/authReducer';
import {
  subscriptionInfoFetching,
  subscriptionPortalFetching,
  subscriptionFetching
} from 'data/store/subscriptionReducer';
import {
  createHouse,
  createHouseRegistration,
  fetchHouseDelete,
  fetchHouses,
  fetchHouseUpdate
} from 'data/store/houseReducer';
import {
  closeDeposit,
  createCheckList,
  createContract,
  createContractSignBankId,
  deleteAllIncomeBilags,
  fetchContract,
  fetchContracts,
  fetchFinishContract,
  getCountryList,
  regenerateSignContractByBankID,
  releaseDeposit,
  signContractByBankID,
  signContractDepositAccount,
  updateContract
} from 'data/store/rentContractReducer';
import {
  accountSettingsChangeEmailFetching,
  accountSettingsChangePasswordFetching
} from 'data/store/accountSettingsReducer';
import {
  createDepreciation,
  createDepreciationPersonalUse,
  deleteDepreciationBilag,
  deleteDepreciationPersonalUseBilag,
  fetchDepreciationPersonalCalculation
} from 'data/store/depreciationReducer';
import { uploadFilesSaga } from './file';
import { fetchAllDocumentsSaga } from './documents';
import {
  featchAltinnFormDataSaga,
  fetchAltinnDataSaga,
  fetchScatteetatenViewSaga,
  loginAltinnSaga,
  sendAltinnFormSaga,
  sendNewFormToAltinSaga
} from './altinn';
import { createManualBilagSaga, deleteManualBilagSaga, restoreBilagSaga, startStopBilagSaga } from './bilag';
import { createSupplierSaga, deleteSupplierSaga, fetchSupplierSaga, SupplierUpdateSaga } from './supplier';
import { changeRentIncomeBilagSaga, createIncomeBilagSaga, deleteIncomeBilagSaga } from './incomeBilag';
import {
  createHouseForRegistrationSaga,
  createHouseSaga,
  deleteHouseSaga,
  fetchHouseListSaga,
  updateHouseSaga
} from './House';
import {
  closeDepositSaga,
  contractSignBankIdSaga,
  contractSignDepositAccountSaga,
  createCheckListSaga,
  createContractSaga,
  createContractSignBankIdSaga,
  deleteAllIncomeBilagsSaga,
  fetchContractSaga,
  fetchContractListSaga,
  finishContractSaga,
  getCountryListSaga,
  regenerateSignBankIdSaga,
  releaseDepositSaga,
  updateContractSaga
} from './contract';
import {
  createDepreciationPersonalUseSaga,
  createDepreciationSaga,
  deleteDepreciationBilagSaga,
  deleteDepreciationPersonalUseBilagSaga,
  fetchDepreciationPersonalCalculationSaga
} from './depreciation';
import {
  fetchLoginSaga,
  fetchChangeEmailSaga,
  fetchNewPasswordSaga,
  fetchPasswordResetConfirmSaga,
  logoutSaga,
  fetchPasswordResetSaga,
  registrationSaga,
  checkEmailSaga,
  fetchAuthProvidersSaga,
  fetchDifiTokenSaga,
  logoutDifiSaga
} from './auth';
import {
  fetchSubscriptionInfoSaga,
  fetchSubscriptionSaga,
  fetchProfileSaga,
  deleteProfileSaga,
  fetchSubscriptionPortalSaga,
  updateProfileSaga,
  accountSettingsChangeEmailSaga,
  accountSettingsChangePasswordSaga
} from './profile';
import { fetchErrorListApi } from '../driver/auth/Auth';
import { fetchAllData, fetchAllDataError, fetchAllDataSuccess } from '../store/errorListReducer';

export function* fetchErrorList() {
  try {
    const response = yield call(fetchErrorListApi);
    yield put(fetchAllDataSuccess(response));
  } catch (e) {
    yield put(fetchAllDataError(e?.response?.data?.message || e?.message));
  }
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield takeLatest(tokenFetching.type, fetchLoginSaga);
  yield takeLatest(subscriptionFetching.type, fetchSubscriptionSaga);
  yield takeLatest(subscriptionInfoFetching.type, fetchSubscriptionInfoSaga);
  yield takeLatest(subscriptionPortalFetching.type, fetchSubscriptionPortalSaga);
  yield takeLatest(profileFetching.type, fetchProfileSaga);
  yield takeLatest(profileDelete.type, deleteProfileSaga);
  yield takeLatest(resetPasswordFetching.type, fetchPasswordResetSaga);
  yield takeLatest(resetPasswordCheckFetching.type, fetchPasswordResetConfirmSaga);
  yield takeLatest(sendNewPasswordFetching.type, fetchNewPasswordSaga);
  yield takeLatest(changeEmailFetching.type, fetchChangeEmailSaga);
  yield takeLatest(checkEmail.type, checkEmailSaga);
  yield takeLatest(registration.type, registrationSaga);
  yield takeLatest(profileUpdate.type, updateProfileSaga);
  yield takeLatest(createHouseRegistration.type, createHouseForRegistrationSaga);
  yield takeLatest(createHouse.type, createHouseSaga);
  yield takeLatest(logout.type, logoutSaga);
  yield takeLatest(fetchHouses.type, fetchHouseListSaga);
  yield takeLatest(fetchAllDocuments.type, fetchAllDocumentsSaga);
  yield takeLatest(fetchAltinnData.type, fetchAltinnDataSaga);
  yield takeLatest(loginAltinn.type, loginAltinnSaga);
  yield takeLatest(fetchAltinnFormData.type, featchAltinnFormDataSaga);
  yield takeLatest(sendAltinnForm.type, sendAltinnFormSaga);
  yield takeLatest(createManualBilag.type, createManualBilagSaga);
  yield takeLatest(uploadFiles.type, uploadFilesSaga);
  yield takeLatest(fetchSupplier.type, fetchSupplierSaga);
  yield takeLatest(createIncomeBilag.type, createIncomeBilagSaga);
  yield takeLatest(createContract.type, createContractSaga);
  yield takeLatest(createContractSignBankId.type, createContractSignBankIdSaga);
  yield takeLatest(accountSettingsChangeEmailFetching.type, accountSettingsChangeEmailSaga);
  yield takeLatest(accountSettingsChangePasswordFetching.type, accountSettingsChangePasswordSaga);
  yield takeLatest(deleteManualBilag.type, deleteManualBilagSaga);
  yield takeLatest(deleteIncomeBilag.type, deleteIncomeBilagSaga);
  yield takeLatest(startStopBilag.type, startStopBilagSaga);
  yield takeLatest(fetchHouseUpdate.type, updateHouseSaga);
  yield takeLatest(fetchHouseDelete.type, deleteHouseSaga);
  yield takeLatest(fetchContracts.type, fetchContractListSaga);
  yield takeLatest(fetchFinishContract.type, finishContractSaga);
  yield takeLatest(changeRentIncomeBilag.type, changeRentIncomeBilagSaga);
  yield takeLatest(createDepreciation.type, createDepreciationSaga);
  yield takeLatest(createDepreciationPersonalUse.type, createDepreciationPersonalUseSaga);
  yield takeLatest(fetchDepreciationPersonalCalculation.type, fetchDepreciationPersonalCalculationSaga);
  yield takeLatest(deleteDepreciationBilag.type, deleteDepreciationBilagSaga);
  yield takeLatest(SupplierUpdate.type, SupplierUpdateSaga);
  yield takeLatest(createSupplier.type, createSupplierSaga);
  yield takeLatest(deleteSupplier.type, deleteSupplierSaga);
  yield takeLatest(restoreBilagFetch.type, restoreBilagSaga);
  yield takeLatest(deleteDepreciationPersonalUseBilag.type, deleteDepreciationPersonalUseBilagSaga);
  yield takeLatest(deleteAllIncomeBilags.type, deleteAllIncomeBilagsSaga);
  yield takeLatest(signContractByBankID.type, contractSignBankIdSaga);
  yield takeLatest(signContractDepositAccount.type, contractSignDepositAccountSaga);
  yield takeLatest(closeDeposit.type, closeDepositSaga);
  yield takeLatest(releaseDeposit.type, releaseDepositSaga);
  yield takeLatest(getCountryList.type, getCountryListSaga);
  yield takeLatest(createCheckList.type, createCheckListSaga);
  yield takeLatest(regenerateSignContractByBankID.type, regenerateSignBankIdSaga);
  yield takeLatest(updateContract.type, updateContractSaga);
  yield takeLatest(fetchContract.type, fetchContractSaga);
  yield takeLatest(fetchAuthProviders.type, fetchAuthProvidersSaga);
  yield takeLatest(fetchDifiToken.type, fetchDifiTokenSaga);
  yield takeLatest(fetchScattetatenView.type, fetchScatteetatenViewSaga);
  yield takeLatest(sendNewFormToAltin.type, sendNewFormToAltinSaga);
  yield takeLatest(logoutDifi.type, logoutDifiSaga);
  yield takeLatest(fetchAllData.type, fetchErrorList);
}

import { combineReducers } from '@reduxjs/toolkit';
import authReducer, { AuthState } from './authReducer';
import subscriptionReducer, { ISubscriptionState } from './subscriptionReducer';
import profileReducer, { ProfileState } from './profileReducer';
import registrationReducer, { RegistrationState } from './registrationReducer';
import houseReducer, { HouseState } from './houseReducer';
import modalsReducer, { ModalsState } from './modalReducer';
import documentsReducer, { DocumentsState } from './documentReducer';
import altinnReducer, { AltinnState } from './altinnReducer';
import alertReducer, { AlertState } from './alertReducer';
import bilagReducer, { BilagState } from './bilagReducer';
import fileReducer, { FileState } from './fileReducer';
import supplierReducer, { SupplierState } from './supplierReducer';
import incomeBilagReducer, { IncomeBilagState } from './incomeBilagReducer';
import rentContractReducer, { ContractState } from './rentContractReducer';
import accountSettingsReducer, { AccountSettingsState } from './accountSettingsReducer';
import confirmationReducer, { ConfirmationState } from './confirmationReducer';
import depreciationReducer, { DepreciationState } from './depreciationReducer';
import pictureViewerReducer, { PictureViewerState } from './pictureViewerReducer';
import actionsReducer, { ActionsState } from './actionsReducer';
import ErrorListReducer, { ErrorListState } from './errorListReducer';

type CombineReducersT = {
  actions: ActionsState;
  auth: AuthState;
  subscription: ISubscriptionState;
  profile: ProfileState;
  registration: RegistrationState;
  house: HouseState;
  modals: ModalsState;
  documents: DocumentsState;
  altinn: AltinnState;
  alert: AlertState;
  bilag: BilagState;
  file: FileState;
  supplier: SupplierState;
  incomeBilag: IncomeBilagState;
  contract: ContractState;
  accountSettings: AccountSettingsState;
  confirmation: ConfirmationState;
  depreciation: DepreciationState;
  pictureViewer: PictureViewerState;
  errorList: ErrorListState;
};

export let rootReducer = combineReducers<CombineReducersT>({
  actions: actionsReducer,
  auth: authReducer,
  subscription: subscriptionReducer,
  profile: profileReducer,
  registration: registrationReducer,
  house: houseReducer,
  modals: modalsReducer,
  documents: documentsReducer,
  altinn: altinnReducer,
  alert: alertReducer,
  bilag: bilagReducer,
  file: fileReducer,
  supplier: supplierReducer,
  incomeBilag: incomeBilagReducer,
  contract: rentContractReducer,
  accountSettings: accountSettingsReducer,
  confirmation: confirmationReducer,
  depreciation: depreciationReducer,
  pictureViewer: pictureViewerReducer,
  errorList: ErrorListReducer
});

export default function createReducer(injectedReducers = {}) {
  rootReducer = combineReducers<CombineReducersT>({
    actions: actionsReducer,
    auth: authReducer,
    subscription: subscriptionReducer,
    profile: profileReducer,
    registration: registrationReducer,
    house: houseReducer,
    modals: modalsReducer,
    documents: documentsReducer,
    altinn: altinnReducer,
    alert: alertReducer,
    bilag: bilagReducer,
    file: fileReducer,
    supplier: supplierReducer,
    incomeBilag: incomeBilagReducer,
    contract: rentContractReducer,
    accountSettings: accountSettingsReducer,
    confirmation: confirmationReducer,
    depreciation: depreciationReducer,
    pictureViewer: pictureViewerReducer,
    errorList: ErrorListReducer,
    ...injectedReducers
  });

  return rootReducer;
}

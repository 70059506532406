import React, { FC } from 'react';
import format from 'date-fns/format';
import { useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { TextInfo } from 'components/common';
import { StyledContainer } from './styled';
import countryOptions from '../../../../constant/countryOptions';

export const InfoFields: FC = () => {
  const { g } = useGlobalTranslation('landlord');
  const { user } = useAppSelector(({ profile }) => profile);
  const birth = user?.birthday ? format(new Date(user?.birthday), 'dd.MM.yyyy') : '';
  const { countryOptions } = useAppSelector((state) => state.contract);

  return (
    <StyledContainer>
      <TextInfo label={g('inputs.firstName')}>{user?.firstName}</TextInfo>
      <TextInfo label={g('inputs.lastName')}>{user?.lastName}</TextInfo>
      <TextInfo label={g('inputs.phone')}>{(user?.phone && `${user?.phone_code || ''}${user?.phone}`) || ''}</TextInfo>
      <TextInfo label={g('inputs.country')}>
        {countryOptions?.find(({ value }) => value === user?.country)?.label || ''}
      </TextInfo>
      <TextInfo label={g('inputs.city')}>{user?.city}</TextInfo>
      <TextInfo label={g('inputs.address')}>{user?.address}</TextInfo>
      <TextInfo label={g('inputs.postcode')}>{user?.postcode}</TextInfo>
      <TextInfo label={g('inputs.ssn')}>{user?.ssn}</TextInfo>
      <TextInfo label={g('inputs.iban')}>{user?.iban}</TextInfo>
      <TextInfo label={g('inputs.birth')}>{birth}</TextInfo>
    </StyledContainer>
  );
};

export default InfoFields;

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from '../../domain/entity/house/HouseData';
import { AltinnDataT } from '../../domain/entity/altinn/AltinnBilag';
import AltinnLoginData from '../../domain/entity/altinn/AltinLoginData';
import { FetchScatteetatenViewT, SendAltinnDataT } from '../driver/altinn/Altinn';
import AltinFormT from '../../domain/entity/altinn/AltinForm';

export interface AltinnState {
  pending?: boolean;
  taxPendingView?: boolean;
  pendingLogin?: boolean;
  pendingForm?: boolean;
  loginDate?: number;
  showAltinnForm?: boolean;
  altinnData?: AltinnDataT[];
  credentials?: AltinnLoginData;
  altinnForms?: AltinFormT[];
  error?: string;
  altinnModal: boolean;
  loginAltinnStep: number;
  successMessage: string;
  scattetatenView?: string;
}

const initialState: AltinnState = {
  taxPendingView: false,
  pending: false,
  pendingLogin: false,
  pendingForm: false,
  loginDate: undefined,
  altinnData: undefined,
  credentials: undefined,
  altinnForms: undefined,
  altinnModal: false,
  showAltinnForm: false,
  loginAltinnStep: 0,
  successMessage: '',
  error: ''
};

export const altinnSlice = createSlice({
  name: 'altinn',
  initialState,
  reducers: {
    fetchAltinnData(state: AltinnState) {
      state.pending = true;
    },
    fetchAltinnDataSuccess(state: AltinnState, { payload }: PayloadAction<AltinnDataT[]>) {
      state.pending = false;
      state.altinnData = payload;
      state.error = '';
    },
    fetchAltinnDataError(state: AltinnState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    openAltinnModal(state: AltinnState) {
      state.altinnModal = true;
    },
    closeAltinnModal(state: AltinnState) {
      state.altinnModal = false;
    },
    updateAltinnCredentials(state: AltinnState, { payload }: PayloadAction<AltinnLoginData>) {
      state.credentials = payload;
    },
    loginAltinn(state: AltinnState, _: PayloadAction<AltinnLoginData>) {
      state.pendingLogin = true;
    },
    fetchAltinnFormData(state: AltinnState, _: PayloadAction<AltinnLoginData>) {},
    loginAltinnSuccess(state: AltinnState, { payload }: PayloadAction<AltinFormT[]>) {
      state.altinnForms = payload;
      state.pendingLogin = false;
      state.loginDate = new Date().getTime();
      state.error = '';
    },
    loginAltinnError(state: AltinnState, { payload }: PayloadAction<string>) {
      state.pendingLogin = false;
      state.loginDate = undefined;
      state.error = payload;
    },
    logoutAltinn(state: AltinnState) {
      state.loginDate = undefined;
    },
    sendAltinnForm(state: AltinnState, _: PayloadAction<SendAltinnDataT>) {
      state.pendingForm = true;
    },
    sendAltinnFormSuccess(state: AltinnState, { payload }: PayloadAction<string>) {
      state.pendingForm = false;
      state.successMessage = payload;
      state.error = '';
    },
    sendAltinnFormError(state: AltinnState, { payload }: PayloadAction<string>) {
      state.pendingForm = false;
      state.error = payload;
    },
    showAltinnForms(state: AltinnState) {
      state.showAltinnForm = true;
    },
    hiddenAltinnForms(state: AltinnState) {
      state.showAltinnForm = false;
    },
    nextLoginAltinnStep(state: AltinnState) {
      state.loginAltinnStep = state.loginAltinnStep + 1;
    },
    resetLoginAltinnStep(state: AltinnState) {
      state.loginAltinnStep = 0;
    },
    fetchScattetatenView(state: AltinnState, _: PayloadAction<FetchScatteetatenViewT>) {
      state.taxPendingView = true;
      state.error = '';
    },
    fetchScattetatenViewSuccess(state: AltinnState, { payload }: PayloadAction<{ url: string }>) {
      state.taxPendingView = false;
      state.scattetatenView = payload.url;
      state.error = '';
    },
    fetchScattetatenViewError(state: AltinnState, { payload }: PayloadAction<string>) {
      state.taxPendingView = false;
      state.scattetatenView = undefined;
      state.error = payload;
    },
    sendNewFormToAltin(state: AltinnState, _: PayloadAction<FetchScatteetatenViewT>) {
      state.pendingForm = true;
      state.error = '';
    },
    sendNewFormToAltinSuccess(state: AltinnState) {
      state.pendingForm = false;
      state.successMessage = 'success';
      state.error = '';
    },
    sendNewFormToAltinError(state: AltinnState, { payload }: PayloadAction<string>) {
      state.pendingForm = false;
      state.scattetatenView = undefined;
      state.error = payload;
    },
    resetError(state: AltinnState) {
      state.error = '';
    },
    resetSuccessMessage(state: AltinnState) {
      state.successMessage = '';
    },
    resetScatteetatenView(state: AltinnState) {
      state.scattetatenView = undefined;
    }
  }
});

export const selectAltinn = (state: RootState): AltinnState => state.altinn;

export const altinnSelector = createSelector([selectAltinn], (altinn) => altinn);

export const {
  fetchAltinnData,
  fetchAltinnDataError,
  fetchAltinnDataSuccess,
  openAltinnModal,
  closeAltinnModal,
  loginAltinnSuccess,
  updateAltinnCredentials,
  loginAltinnError,
  loginAltinn,
  fetchAltinnFormData,
  logoutAltinn,
  sendAltinnFormSuccess,
  sendAltinnFormError,
  sendAltinnForm,
  hiddenAltinnForms,
  showAltinnForms,
  nextLoginAltinnStep,
  resetLoginAltinnStep,
  fetchScattetatenView,
  fetchScattetatenViewError,
  fetchScattetatenViewSuccess,
  sendNewFormToAltin,
  sendNewFormToAltinError,
  sendNewFormToAltinSuccess
} = altinnSlice.actions;

export default altinnSlice.reducer;

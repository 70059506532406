import React, { FC } from 'react';
import {
  ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL,
  ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD
} from 'constant/routes';
import { profileSlice } from 'data/store/profileReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import { TextInfo } from 'components/common';
import editIcon from 'components/svg/edit_icon.svg?sprite';
import deleteIcon from 'components/svg/delete.svg?sprite';
import {
  StyledContainer,
  StyledField,
  StyledLink,
  StyledLinkText,
  Divider,
  DeleteButton,
  iconButtonCss,
  StyledText
} from './styled';

const InfoFields: FC = () => {
  const { t, g } = useGlobalTranslation('profile');
  const dispatch = useAppDispatch();
  const { profileDelete } = profileSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { user } = useAppSelector(({ profile }) => profile);

  const onHaveSubscription = () => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: t('haveSubscription.no')
        },
        confirmButtonProps: {
          onClick: () => {
            onClickDelete();
          },
          children: t('haveSubscription.yes')
        },
        text: t('haveSubscription.message'),
        title: t('haveSubscription.title')
      })
    );
  };

  const onClickDelete = () => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('cancel')
        },
        confirmButtonProps: {
          onClick: () => {
            dispatch(profileDelete());
            dispatch(resetConfirmationData());
          },
          children: g('continue')
        },
        text: t('delete.message'),
        title: t('delete.deleteAccount')
      })
    );
  };

  return (
    <StyledContainer>
      <StyledField>
        <TextInfo label={g('email')}>{user?.email}</TextInfo>
        <StyledLink to={ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL}>
          <Icon icon={editIcon} color={'blue01'} /> <StyledLinkText>{t('change.email')}</StyledLinkText>
        </StyledLink>
      </StyledField>
      <StyledField>{/*<TextInfo label={g('inputs.facebookAcc')}>{''}</TextInfo>*/}</StyledField>
      <StyledField>
        <TextInfo label={g('inputs.password')}>{'••••••••'}</TextInfo>
        <StyledLink to={ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD}>
          <Icon icon={editIcon} color={'blue01'} /> <StyledLinkText>{t('change.password')}</StyledLinkText>
        </StyledLink>
      </StyledField>
      <StyledField>{/*<TextInfo label={g('inputs.googleAcc')}>{''}</TextInfo>*/}</StyledField>
      <Divider />
      <StyledField>
        <TextInfo label={t('delete.deleteMyAccount')}>
          <DeleteButton variant={'text'} onClick={user?.subscriptionActive ? onHaveSubscription : onClickDelete}>
            <Icon icon={deleteIcon} css={iconButtonCss} />
            {t('delete.deleteAccount')}
          </DeleteButton>
        </TextInfo>
        <StyledText>{t('delete.title')}</StyledText>
      </StyledField>
    </StyledContainer>
  );
};

export default InfoFields;

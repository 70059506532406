import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled.a`
  text-transform: lowercase;
  margin: 10px 0.25rem;
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledText = styled.p`
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray01};
  max-width: 100%;
  text-align: center;
`;

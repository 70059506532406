import React, { Dispatch, FC, SetStateAction } from 'react';
import format from 'date-fns/format';
import { AltinnDataT } from 'domain/entity/altinn/AltinnBilag';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { EmptyList } from 'components/ui';
import Icon from 'components/common/Icon';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import { Wrapper, Button, backIconCss, Content, Item, LeftBlock, Title, Sum, DateText } from './styled';

export type ListTypeT = 'bilags' | 'incomeBilags' | 'formsAltinn' | 'depreciationBilag' | undefined;

type PropsT = {
  type?: ListTypeT;
  altinnData: AltinnDataT;
  setType: Dispatch<SetStateAction<ListTypeT>>;
};

const MAP_LIST_TYPE_TO_TITLE: Record<'bilags' | 'incomeBilags' | 'depreciationBilag', string> = {
  bilags: 'expensesBilag',
  depreciationBilag: 'depreciationBilag',
  incomeBilags: 'incomeBilag'
};

const MAP_LIST_TYPE_TO_KEY: Record<
  'bilags' | 'incomeBilags' | 'depreciationBilag',
  'bilagsList' | 'incomebilagsList' | 'depreciationList'
> = {
  bilags: 'bilagsList',
  depreciationBilag: 'depreciationList',
  incomeBilags: 'incomebilagsList'
};

const MAP_LIST_TYPE_TO_COLOR: Record<
  'bilags' | 'incomeBilags' | 'depreciationBilag',
  'red01' | 'green01' | 'orange01'
> = {
  bilags: 'red01',
  depreciationBilag: 'orange01',
  incomeBilags: 'green01'
};

const List: FC<PropsT> = (props) => {
  const { setType, altinnData, type } = props;
  const { g } = useGlobalTranslation();
  const bilagsList = (type && type !== 'formsAltinn' && altinnData[MAP_LIST_TYPE_TO_KEY[type]]) || [];
  const bilagsListCostType = (type && type === 'bilags' && altinnData['bilagsListCostType']) || {};
  const sortedBilagList = bilagsList
    .slice()
    .sort(({ date: a }, { date: b }) => new Date(b).valueOf() - new Date(a).valueOf());
  const maintenance = (bilagsListCostType && bilagsListCostType.maintenance) || '';
  const attachment_fee = (bilagsListCostType && bilagsListCostType.attachment_fee) || '';
  const municipal_taxes = (bilagsListCostType && bilagsListCostType.municipal_taxes) || '';
  const paid_salary = (bilagsListCostType && bilagsListCostType.paid_salary) || '';
  const other_costs = (bilagsListCostType && bilagsListCostType.other_costs) || '';
  // const maintenance = (bilagsListCostType && bilagsListCostType.maintenance && `${bilagsListCostType.maintenance}`) || '';
  // const attachment_fee = (bilagsListCostType && bilagsListCostType.attachment_fee && `${bilagsListCostType.attachment_fee}`) || '';
  // const municipal_taxes = (bilagsListCostType && bilagsListCostType.municipal_taxes && `${bilagsListCostType.municipal_taxes}`) || '';
  // const paid_salary = (bilagsListCostType && bilagsListCostType.paid_salary && `${bilagsListCostType.paid_salary}`) || '';
  // const other_costs = (bilagsListCostType && bilagsListCostType.other_costs && `${bilagsListCostType.other_costs}`) || '';
  const { t: f } = useGlobalTranslation('fields');

  return (
    <Wrapper>
      <Button variant={'text'} color={'black01'} onClick={() => setType(undefined)}>
        <Icon icon={backIcon} css={backIconCss} />
        {type && type !== 'formsAltinn' && g(MAP_LIST_TYPE_TO_TITLE[type])}
      </Button>
      <Content>{maintenance !== '' && `${f('maintenance')} ${maintenance}`}</Content>
      <Content>{attachment_fee !== '' && `${f('attachment_fee')} ${attachment_fee}`}</Content>
      <Content>{municipal_taxes !== '' && `${f('municipal_taxes')} ${municipal_taxes}`}</Content>
      <Content>{paid_salary !== '' && `${f('paid_salary')} ${paid_salary}`}</Content>
      <Content>{other_costs !== '' && `${f('other_costs')} ${other_costs}`}</Content>
      <Content>
        {(sortedBilagList.length > 0 &&
          sortedBilagList.map(({ id, sum, name, date }) => (
            <Item key={id}>
              <LeftBlock>
                <Title>{name === 'inPersonalUse' ? g(name) : name}</Title>
                <Sum color={(type && type !== 'formsAltinn' && MAP_LIST_TYPE_TO_COLOR[type]) || 'red01'}>{sum} NOK</Sum>
              </LeftBlock>
              <DateText>{format(new Date(date), 'dd.MM.yyyy')}</DateText>
            </Item>
          ))) || <EmptyList variant={'universal'} />}
      </Content>
    </Wrapper>
  );
};

export default List;

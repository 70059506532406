import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';
import BaseButton from 'components/controls/Button';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1620px;
`;

export const Column = styled.div<ColumnT>`
  width: 100%;
  padding: 0 50px 20px;
  display: grid;
  gap: 20px;

  ${mq.greaterXxl} {
    gap: 40px;
    grid-auto-flow: column;
    grid-template-columns: 1092px;
    width: 100%;
  }
`;

export const IconColumn = styled.div<ColumnT>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 48px;
`;

export const Button = styled(BaseButton)`
  text-transform: initial;
  font-size: 14px;
  line-height: 1.29;
`;

export const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.colors.red01};
`;

export const iconButtonCss = css`
  fill: currentColor;
  width: 24px;
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0 0 0 0.5rem;
`;
export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-transform: capitalize;
  font-size: 0.875rem;
`;

export const StyledActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 50px;
`;

import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 70px 100px 300px 120px 200px 200px 200px 200px;
`;

export const Container = styled.div`
  display: grid;
  width: max-content;
  margin: 20px;

  & ${Row}:nth-child(even) {
    background-color: rgba(173, 181, 189, 0.42);
  }
`;

export const MainRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 70px 100px 300px 120px 200px 200px 200px 200px;
  border-bottom: 1px solid #3d3d3d;
`;

export const MainCell = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 20px;
`;

export const Cell = styled.div`
  padding: 15px 20px;
  border-right: 1px solid #6c757d;
`;

export const DataCell = styled(Cell)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.4s background-color;

  &:hover {
    background-color: #eaeaea;
  }
`;

export const CopyButton = styled.button`
  background-color: #adb5bd;
  border: none;
  border-radius: 10px;
  transition: 0.4s opacity;
  cursor: pointer;
  padding: 10px 15px;

  &:hover {
    opacity: 0.5;
  }
`;

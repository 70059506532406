import { call, put } from 'redux-saga/effects';
import { fetchAllDocumentsError, fetchAllDocumentsSuccess } from 'data/store/documentReducer';
import { fetchAllDocumentsApi } from 'data/driver/documents/Documents';
import HouseData from 'domain/entity/house/HouseData';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export function* fetchAllDocumentsSaga({
  payload
}: PayloadAction<{ houseId: HouseData['id']; date_from?: string; date_to?: string }>) {
  try {
    const response = yield call(fetchAllDocumentsApi, payload);
    yield put(fetchAllDocumentsSuccess(response));
  } catch (e) {
    yield put(fetchAllDocumentsError(e?.response?.data?.message || e?.message));
  }
}

import styled, { css } from 'styled-components';
import Button from 'components/controls/Button';
import { hidden } from '../../../constant/mediaqueries';

type ButtonPropsT = {
  isOpen: boolean;
};

export const StyledButton = styled(Button)<ButtonPropsT>`
  color: ${({ theme, isOpen }) => (isOpen ? theme.colors.blue01 : theme.colors.black01)};
`;

export const calendarIconCss = css`
  width: 19px;
  fill: currentColor;
`;

export const DesktopInput = styled.div`
  ${hidden.lowerSm}
`;

export const MobileInput = styled.div`
  ${hidden.greaterSm}
`;

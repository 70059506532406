import * as Yup from 'yup';
import { checkDataRengeDate, checkPhone } from 'helpers/validation';
import dayjs from 'dayjs';
import { formatDateForApi } from '../../helpers/date';

export const rentContract = Yup.object().shape({
  houseId: Yup.string().required(),
  documents: Yup.array().required()
});

export const informAboutTenant = Yup.object().shape({
  tenant_first_name: Yup.string().required().max(50).min(2),
  tenant_last_name: Yup.string().required().max(50).min(2),
  tenant_phone_code: Yup.string(),
  tenant_phone: Yup.string()
    .when('tenant_phone_code', {
      is: (value: string) => Boolean(value),
      then: Yup.string().when('tenant_phone', {
        is: (endDate: string) => !!endDate,
        then: Yup.string().when(['tenant_phone', 'tenant_phone_code'], {
          is: checkPhone,
          then: (field) => field,
          otherwise: Yup.string().max(1, '123123')
        })
      })
    })
    .required(),
  tenant_email: Yup.string()
    .email()
    .required()
    .max(50)
    .matches(/\w{2}$/, 'email.invalid'),
  tenant_nationality: Yup.string().required(),
  tenant_country: Yup.string().required(),
  tenant_birthday: Yup.string().required(),
  tenant_address: Yup.string().required().max(50).min(2),
  tenant_city: Yup.string().required().max(60).min(2),
  tenant_postcode: Yup.string().required()
});

export const tenancyTerms = Yup.object().shape({
  type: Yup.string().required(),
  taxable: Yup.boolean().required(),
  start_date: Yup.string()
    .required()
    .when('type', {
      is: 'time-bound',
      then: Yup.string().when('start_date', {
        is: (endDate: string) => !!endDate,
        then: Yup.string().when(['start_date', 'end_date'], {
          is: checkDataRengeDate,
          then: (field) => field,
          otherwise: Yup.string().max(1, '123123')
        })
      })
    }),
  end_date: Yup.string().when('type', {
    is: 'time-bound',
    then: Yup.string()
      .required()
      .when(['start_date', 'end_date'], {
        is: checkDataRengeDate,
        then: (field) => field,
        otherwise: Yup.string().max(1, '123123')
      })
  }),
  first_payment_date: Yup.string().required(),
  month_resignation: Yup.number().required(),
  month_sum: Yup.number().required()
});

export const including = Yup.object().shape({
  keys_count: Yup.number().required(),
  comforts: Yup.array().required(),
  pets_allowed: Yup.boolean().required(),
  smoking_allowed: Yup.boolean().required(),
  other: Yup.string().max(1000)
});

export const depositAccount = Yup.object().shape({
  is_deposit_account_variant: Yup.number(),
  deposit_sum: Yup.string().when('is_deposit_account_variant', {
    is: (value: number) => value === 1 || value === 2,
    then: Yup.string().required()
  }),
  deposit_account: Yup.string().when('is_deposit_account_variant', {
    is: 2,
    then: Yup.string().required()
  }),
  deposit_payment_deadline: Yup.string().when('is_deposit_account_variant', {
    is: 2,
    then: Yup.string()
      .required()
      .when('deposit_payment_deadline', {
        is: (date: string) => {
          if (date) {
            return (
              dayjs(formatDateForApi(date)).set('hours', 23).isAfter(dayjs().set('hours', 0)) &&
              dayjs(formatDateForApi(date)).set('hours', 23).isBefore(dayjs().add(45, 'day'))
            );
          }
        },
        then: Yup.string().required(),
        otherwise: Yup.string().max(1, '123123')
      })
  }),
  deposit_count_payment_day: Yup.number()
    .nullable()
    .when('is_deposit_account_variant', {
      is: 1,
      then: Yup.number().required().min(1).max(45)
    })
});

export const checkContract = Yup.object({
  options: Yup.array(),
  number_keys: Yup.string().when('options', {
    is: (options: string[]) => options.includes('number_of_keys'),
    then: Yup.string().required()
  }),
  parking_space: Yup.string().when('options', {
    is: (options: string[]) => options.includes('parking'),
    then: Yup.string().required()
  }),
  garage: Yup.string().when('options', {
    is: (options: string[]) => options.includes('garage'),
    then: Yup.string().required()
  }),
  fire_extinguisher_count: Yup.string().when('options', {
    is: (options: string[]) => options.includes('fire_extinguisher'),
    then: Yup.string().required()
  }),
  stalls_count: Yup.string().when('options', {
    is: (options: string[]) => options.includes('stalls'),
    then: Yup.string().required()
  })
});

export const freeRentContract = rentContract.concat(tenancyTerms).concat(including);

const schema = Yup.object({
  rentContract,
  informAboutTenant,
  tenancyTerms,
  including,
  depositAccount
});

export default schema;

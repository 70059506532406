import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export type VariantAlertT = 'success' | 'error';

export interface AlertState {
  variant?: VariantAlertT;
  text?: string;
  openLink?: string;
}

const initialState: AlertState = {
  variant: undefined,
  text: undefined,
  openLink: undefined
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlertData(state: AlertState, { payload }: PayloadAction<AlertState>) {
      state.variant = payload.variant;
      state.text = payload.text;
    },
    resetAlertData(state: AlertState) {
      state.variant = undefined;
      state.text = undefined;
    }
  }
});

const selectAlert = (state: RootState): AlertState => state.alert;

export const alertSelector = createSelector([selectAlert], (alert) => alert);

export const { setAlertData, resetAlertData } = alertSlice.actions;

export default alertSlice.reducer;

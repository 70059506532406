import { call, put } from 'redux-saga/effects';
import {
  fetchAltinnDataSuccess,
  fetchAltinnDataError,
  loginAltinnSuccess,
  loginAltinnError,
  updateAltinnCredentials,
  closeAltinnModal,
  sendAltinnFormSuccess,
  sendAltinnFormError,
  showAltinnForms,
  nextLoginAltinnStep,
  resetLoginAltinnStep,
  fetchScattetatenViewSuccess,
  sendNewFormToAltinSuccess,
  sendNewFormToAltinError,
  fetchScattetatenViewError
} from 'data/store/altinnReducer';
import {
  fetchAltinnDataApi,
  fetchAltinnFormDataApi,
  fetchScatteetatenViewApi,
  FetchScatteetatenViewT,
  loginAltinnApi,
  SendAltinnDataT,
  sendAltinnFormApi,
  sendNewFormToAltinApi
} from '../driver/altinn/Altinn';
import HouseData from '../../domain/entity/house/HouseData';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { AltinnDataT } from '../../domain/entity/altinn/AltinnBilag';
import AltinnLoginData from '../../domain/entity/altinn/AltinLoginData';
import AltinFormT from '../../domain/entity/altinn/AltinForm';
import { setAlertData } from '../store/alertReducer';
import { setConfirmationData } from '../store/confirmationReducer';

export function* fetchAltinnDataSaga() {
  try {
    const response: AltinnDataT[] = yield call(fetchAltinnDataApi);
    yield put(fetchAltinnDataSuccess(response));
  } catch (e) {
    yield put(fetchAltinnDataError(e?.response?.data?.message || e?.message || 'errors.unknownError'));
  }
}

export function* loginAltinnSaga({ payload }: PayloadAction<AltinnLoginData>) {
  try {
    const response = yield call(loginAltinnApi, payload);
    if (response.success) {
      yield put(nextLoginAltinnStep());
    }
    if (!response.success) {
      yield put(loginAltinnError(response.message));
      yield put(resetLoginAltinnStep());
    }
  } catch (e) {
    yield put(loginAltinnError('errors.unknownError'));
  }
}

export function* featchAltinnFormDataSaga({ payload }: PayloadAction<AltinnLoginData>) {
  try {
    const response: AltinFormT & { success: boolean; message: string } = yield call(fetchAltinnFormDataApi, payload);
    if (Array.isArray(response)) {
      yield put(updateAltinnCredentials(payload));
      yield put(loginAltinnSuccess(response));
      yield put(closeAltinnModal());
      yield put(showAltinnForms());
      yield put(resetLoginAltinnStep());
    }
    if (!Array.isArray(response)) {
      yield put(loginAltinnError(response?.message || 'errors.unknownError'));
    }
  } catch (e) {
    yield put(loginAltinnError(e?.response?.data?.message || e?.message || 'errors.unknownError'));
  }
}

export function* sendAltinnFormSaga({ payload }: PayloadAction<SendAltinnDataT>) {
  try {
    const response = yield call(sendAltinnFormApi, payload);
    if (response.success) {
      yield put(setAlertData({ variant: 'success', text: response.message }));
      yield put(sendAltinnFormSuccess(''));
    }
    if (!response.success) {
      yield put(sendAltinnFormError(response.message));
    }
  } catch (e) {
    yield put(sendAltinnFormError(e?.response?.data?.message || e?.message || 'errors.unknownError'));
  }
}

export function* fetchScatteetatenViewSaga({ payload }: PayloadAction<FetchScatteetatenViewT>) {
  try {
    const response = yield call(fetchScatteetatenViewApi, payload);
    if (response.url) {
      yield put(setAlertData({ variant: 'success', text: response.message || 'Success!' }));
      yield put(fetchScattetatenViewSuccess(response));
    }
  } catch (e) {
    yield put(fetchScattetatenViewError(e?.response?.data?.message || e?.message || 'errors.unknownError'));
  }
}

export function* sendNewFormToAltinSaga({ payload }: PayloadAction<FetchScatteetatenViewT>) {
  try {
    const response = yield call(sendNewFormToAltinApi, payload);
    if (response.success) {
      yield put(
        setAlertData({
          variant: 'success',
          text: response.message || 'Success send form to altinn!',
          openLink: 'https://tt02.altinn.no/'
        })
      );
      yield put(sendNewFormToAltinSuccess());
    }
    if (!response.success) {
      yield put(sendNewFormToAltinError(response.message));
    }
  } catch (e) {
    yield put(sendNewFormToAltinError(e?.response?.data?.message || e?.message || 'errors.unknownError'));
  }
}

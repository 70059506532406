import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import HouseData from 'domain/entity/house/HouseData';
import { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import {
  fetchHousesSuccess,
  fetchHousesError,
  createHouseSuccess,
  createHouseError,
  fetchHouseUpdateSuccess,
  fetchHouseDeleteSuccess
} from 'data/store/houseReducer';
import { createHouseApi, deleteHouseApi, fetchHouseListApi, updateHouseApi } from 'data/driver/house/House';

export function* fetchHouseListSaga() {
  try {
    const response = yield call(fetchHouseListApi);
    yield put(fetchHousesSuccess(response));
  } catch (e) {
    yield put(fetchHousesError(e?.response?.data?.message || e?.message));
  }
}

export function* createHouseForRegistrationSaga({ payload }: PayloadAction<HouseCreateFormT>) {
  try {
    const response = yield call(createHouseApi, payload);
    yield put(createHouseSuccess(response));
  } catch (e) {
    yield put(createHouseError(e?.response?.data?.message || e?.message));
  }
}

export function* createHouseSaga({ payload }: PayloadAction<HouseCreateFormT>) {
  try {
    const response = yield call(createHouseApi, payload);
    yield put(createHouseSuccess(response));
  } catch (e) {
    yield put(createHouseError(e?.response?.data?.message || e?.message));
  }
}

export function* updateHouseSaga({ payload }: PayloadAction<HouseData>) {
  try {
    yield call(updateHouseApi, payload);
    yield put(fetchHouseUpdateSuccess(payload));
  } catch (e) {
    yield put(fetchHousesError(e?.response?.data?.message || e?.message));
  }
}

export function* deleteHouseSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(deleteHouseApi, payload);
    yield put(fetchHouseDeleteSuccess(payload));
  } catch (e) {
    yield put(fetchHousesError(e?.response?.data?.message || e?.message));
  }
}

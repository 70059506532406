import React, { FC } from 'react';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import bigFile from 'components/svg/bigFile_icon.svg?sprite';
import Icon from 'components/common/Icon';
import { StyledContainer, StyledText } from './styled';

interface PropsI {
  what?: string;
  variant?: 'default' | 'trash' | 'universal';
}

const EmptyList: FC<PropsI> = ({ variant = 'default', what = 'bilag' }) => {
  const { g } = useGlobalTranslation('depreciation');
  const text =
    (variant === 'trash' && g('errors.emptyTrash')) ||
    (variant === 'universal' && g('errors.emptyUniversal')) ||
    (what === 'bilag' && g('errors.emptyBilagList', { item: g(what).toLowerCase() })) ||
    (what === 'incomeBilag' && g('errors.emptyIncomeBilag', { item: g(what).toLowerCase() })) ||
    (what === 'rentContract' && g('errors.emptyRentContract', { item: g(what).toLowerCase() })) ||
    g('errors.emptyList', { item: g(what).toLowerCase() });
  return (
    <StyledContainer>
      <Icon icon={bigFile} />
      <StyledText>{text}</StyledText>
    </StyledContainer>
  );
};

export default EmptyList;

import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import INITIAL_PROFILE_DATA, { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import { ContractFormData, INITIAL_CONTRACT_FORM_DATA } from 'domain/entity/rentContract/RentContract';
import INITIAL_HOUSE_CREATE_DATA, { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import backgroundLogoSvg from 'components/svg/background_logo.svg?sprite';
import { StepEnum } from './data';
import LandlordInformation from './LandlordInformation';
import HouseInfo from './HouseInfo';
import RentContract from './RentContract';
import InformAboutTenant from './InformAboutTenant';
import CreatePassword from './CreatePassword';
import { StyledTitle, IconContainer, backgroundLogoCss } from './styled';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
  step: number;
};

const DataContext = createContext<{
  dataLandlord: ProfileFormT;
  dataHouseInfo: HouseCreateFormT;
  dataRentContract: ContractFormData;
  setLandlordValues: (values: Partial<ProfileFormT>) => void;
  setHouseInfoValues: (values: Partial<HouseCreateFormT>) => void;
  setContractValues: (values: Partial<ContractFormData>) => void;
}>({
  setLandlordValues: () => {},
  setHouseInfoValues: () => {},
  setContractValues: () => {},
  dataLandlord: INITIAL_PROFILE_DATA,
  dataHouseInfo: INITIAL_HOUSE_CREATE_DATA,
  dataRentContract: INITIAL_CONTRACT_FORM_DATA()
});

const View: FC<PropsT> = (props) => {
  const { g } = useGlobalTranslation();
  const { setStep, step } = props;
  const [dataLandlord, setDataLandlord] = useState<ProfileFormT>(INITIAL_PROFILE_DATA);
  const [dataHouseInfo, setDataHouseInfo] = useState<HouseCreateFormT>(INITIAL_HOUSE_CREATE_DATA);
  const [dataRentContract, setDataRentContract] = useState<ContractFormData>(INITIAL_CONTRACT_FORM_DATA());

  const setLandlordValues = (values: Partial<ProfileFormT>) => {
    setDataLandlord((prevState) => ({ ...prevState, ...values }));
  };

  const setHouseInfoValues = (values: Partial<HouseCreateFormT>) => {
    setDataHouseInfo((prevState) => ({ ...prevState, ...values }));
  };

  const setContractValues = (values: Partial<ContractFormData>) => {
    setDataRentContract((prevState) => ({ ...prevState, ...values }));
  };

  useEffect(() => {
    return () => {
      setStep(StepEnum.WELCOME);
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        dataLandlord,
        dataHouseInfo,
        dataRentContract,
        setLandlordValues,
        setHouseInfoValues,
        setContractValues
      }}
    >
      <StyledTitle>{g('freeContract')}</StyledTitle>
      <IconContainer>
        <Icon icon={backgroundLogoSvg} css={backgroundLogoCss} />
      </IconContainer>
      {step === StepEnum.LANDLORD_INFORMATION && <LandlordInformation setStep={setStep} />}
      {step === StepEnum.HOUSE_INFO && <HouseInfo setStep={setStep} />}
      {step === StepEnum.INFO_ABOUT_TENANT && <InformAboutTenant setStep={setStep} />}
      {step === StepEnum.RENT_CONTRACT && <RentContract setStep={setStep} />}
      {step === StepEnum.CREATE_PASSWORD && <CreatePassword setStep={setStep} />}
    </DataContext.Provider>
  );
};

export default View;

export const useData = () => useContext(DataContext);

import React, { ChangeEventHandler, FocusEventHandler, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormHandleSubmit, FormProvider } from 'react-hook-form';
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from 'constant/routes';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant/externalLinks';
import UserT from 'domain/entity/auth/UserT';
import StepEnum from 'domain/entity/registration/StepEnum';
import INITIAL_PROFILE_DATA, { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import INITIAL_HOUSE_CREATE_DATA, { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import INITIAL_REGISTRATION_FORM_DATA, { RegistrationFormT } from 'domain/entity/registration/RegistrationFormData';
import { houseSlice } from 'data/store/houseReducer';
import { modalsSlice } from 'data/store/modalReducer';
import { profileSlice } from 'data/store/profileReducer';
import { registrationSlice } from 'data/store/registrationReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { Input } from 'components/ui';
import Icon from 'components/common/Icon';
import checkIcon from 'components/svg/green_check_icon.svg?sprite';
import { houseSchema } from 'data/validations/houseValidation';
import { profileSchema } from 'data/validations/profileValidation';
import { registrationSchema } from 'data/validations/registrationValidation';
import {
  checkIconCss,
  Step,
  StyledButton,
  StyledButtonUnderline,
  StyledContainer,
  StyledForm,
  StyledFormContent,
  StyledSignUpContainer,
  StyledSignUpLink,
  StyledSuccessImg,
  StyledTextContainer,
  StyledTitle,
  StyledCheckbox,
  LinkCheckBox
} from './styled';
import format from 'date-fns/format';
import Fields from '../../../components/feature/Fields';
import { HOUSE_INFO_FIELDS, PROFILE_FIELDS } from '../../../components/feature/Fields/fields';
import i18n from 'i18next';

const View = () => {
  const { profileUpdate } = profileSlice.actions;
  const { g } = useGlobalTranslation();
  const { t } = useGlobalTranslation('forms');
  const { t: r } = useGlobalTranslation('registration');
  const { t: f } = useGlobalTranslation('fields');
  const { openRegistrationModal } = modalsSlice.actions;
  const { createHouseRegistration } = houseSlice.actions;
  const { checkEmail, registration, setInitialData } = registrationSlice.actions;
  const { user, pending: pendingProfile } = useAppSelector((state) => state.profile);
  const { countryOptions, noCountryOptions } = useAppSelector((state) => state.contract);
  const language = i18n.language;
  const { pending: pendingHouse } = useAppSelector((state) => state.house);
  const {
    step,
    checkEmailError,
    error: registrationError,
    pending: pendingRegistration,
    pendingCheckEmail,
    successEmail
  } = useAppSelector((state) => state.registration);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const registrationForm = useForm<RegistrationFormT>({
    resolver: yupResolver(registrationSchema),
    defaultValues: INITIAL_REGISTRATION_FORM_DATA
  });
  const {
    register: registrationFields,
    handleSubmit: handleRegistrationSubmit,
    formState: registrationFormState,
    getValues: registrationValues,
    setValue: setRegisterValue
  } = registrationForm;
  const profileForm = useForm<ProfileFormT>({
    resolver: yupResolver(profileSchema),
    defaultValues: INITIAL_PROFILE_DATA
  });
  const { handleSubmit: handleProfileSubmit, setValue: setProfileValue, getValues: getProfileValue } = profileForm;
  const houseForm = useForm<HouseCreateFormT>({
    resolver: yupResolver(houseSchema),
    defaultValues: INITIAL_HOUSE_CREATE_DATA
  });
  const { handleSubmit: handleHouseSubmit } = houseForm;

  function onSubmit<T>(formData: T) {
    if (step === StepEnum.general) {
      if (!checkEmailError) dispatch(registration(formData as unknown as RegistrationFormT));
      return;
    }

    if (step === StepEnum.profile) {
      dispatch(profileUpdate(formData as unknown as ProfileFormT));
      return;
    }

    if (step === StepEnum.finish) {
      dispatch(createHouseRegistration(formData as unknown as HouseCreateFormT));
      history.push(ROUTE_DASHBOARD);
    }
  }

  function ON_SUBMIT<T>(): Record<StepEnum, UseFormHandleSubmit<T>> {
    return {
      [StepEnum.general]: handleRegistrationSubmit,
      [StepEnum.profile]: handleProfileSubmit,
      [StepEnum.finish]: handleHouseSubmit
    };
  }

  useEffect(() => {
    if (user && step === StepEnum.profile) {
      Object.keys(user).map((key) => {
        if (key === 'country') setProfileValue(key, user[key as keyof UserT] || 'NO');
        if (key !== 'birthday' && key !== 'country') setProfileValue(key, user[key as keyof UserT]);
        if (key === 'birthday' && !!user[key as keyof UserT])
          setProfileValue(key, format(new Date(user[key as keyof UserT] as unknown as Date), 'dd.MM.yyyy'));
      });
    }
  }, [user]);

  const handleEmailBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if (!successEmail && registrationValues('email')) dispatch(checkEmail({ email: registrationValues('email') }));
    await registrationFields('email').onBlur(e);
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    dispatch(setInitialData());
    await registrationFields('email').onChange(e);
    setRegisterValue('email', e.target.value.replace(/\s+/g, ''));
  };

  return (
    <StyledContainer step={step}>
      <StyledTextContainer>
        <StyledTitle> {r('title')}</StyledTitle>
        <Step>{step + 1}/3</Step>
      </StyledTextContainer>
      <StyledForm id="loginform" onSubmit={ON_SUBMIT()[step as StepEnum](onSubmit)}>
        <StyledFormContent column={step === StepEnum.finish ? 2 : 1}>
          {step === StepEnum.general && (
            <FormProvider {...registrationForm}>
              <Input
                maxLength={50}
                label={f('email')}
                register={registrationFields('email')}
                onBlur={handleEmailBlur}
                onChange={handleEmailChange}
                error={
                  registrationFormState.errors['email'] ||
                  (checkEmailError === 'errors.emailExistsToLogin' && checkEmailError)
                }
                serverError={
                  checkEmailError === 'errors.emailExistsToLogin' ? (
                    <>
                      {g(checkEmailError)} <StyledSignUpLink to={ROUTE_LOGIN}>{r('logIn')}</StyledSignUpLink>{' '}
                      {g('errors.emailExistsToLoginNoAddition')}
                    </>
                  ) : undefined
                }
                errorMessage={registrationFormState.errors['email']?.message}
                isLoading={pendingCheckEmail}
                isSuccess={successEmail}
                endAdornment={
                  successEmail && (
                    <StyledSuccessImg>
                      <Icon icon={checkIcon} css={checkIconCss} />
                    </StyledSuccessImg>
                  )
                }
              />
              <Input
                maxLength={50}
                type="password"
                label={f(`password`)}
                register={registrationFields('password')}
                error={registrationFormState.errors['password']}
              />
              <Input
                maxLength={50}
                type="password"
                label={f(`repeatPassword`)}
                register={registrationFields('repeatPassword')}
                error={
                  registrationFormState.errors['password'] ||
                  registrationFormState.errors['repeatPassword'] ||
                  (checkEmailError === 'errors.isOldAppUser' && registrationError)
                }
                errorMessage={
                  registrationFormState.errors?.['password']?.message ||
                  registrationFormState.errors?.['repeatPassword']?.message
                }
                serverError={
                  (checkEmailError === 'errors.isOldAppUser' && (
                    <>
                      {g('errors.isOldAppUserStart')}{' '}
                      <StyledSignUpLink to={'#'}>{g('errors.isOldAppUserClick')}</StyledSignUpLink>{' '}
                      {g('errors.isOldAppUserEnd')}
                    </>
                  )) ||
                  registrationError
                }
              />
              <StyledCheckbox
                withPrompt
                register={registrationFields('isAgreePolicy')}
                value={'true'}
                errorMessage={
                  registrationFormState.errors?.['isAgreePolicy'] &&
                  g(`errors.${registrationFormState.errors?.['isAgreePolicy']?.message}`)
                }
              >
                {g('iAgree')}{' '}
                <LinkCheckBox href={TERMS_OF_USE_URL} target="_blank">
                  {g('terms')}
                </LinkCheckBox>{' '}
                {g('and')}{' '}
                <LinkCheckBox href={PRIVACY_POLICY_URL} target="_blank">
                  {g('policy')}
                </LinkCheckBox>
              </StyledCheckbox>
            </FormProvider>
          )}
          {step === StepEnum.profile && (
            <FormProvider {...profileForm}>
              <Fields
                inputProps={{ variant: 'default' }}
                fields={PROFILE_FIELDS(user, language === 'en' ? countryOptions : noCountryOptions)}
              />
            </FormProvider>
          )}
          {step === StepEnum.finish && (
            <FormProvider {...houseForm}>
              <Fields inputProps={{ withoutPrompt: true }} fields={HOUSE_INFO_FIELDS(false)} />
            </FormProvider>
          )}
        </StyledFormContent>
        <StyledButton
          type="submit"
          disabled={pendingCheckEmail}
          isLoading={pendingRegistration || pendingProfile || pendingHouse}
        >
          {step === StepEnum.finish ? r('buttons.finishRegistration') : r('buttons.continue')}
        </StyledButton>
        <StyledSignUpContainer>
          {r('alreadyHaveAnAccount')}
          {step === StepEnum.general && <StyledSignUpLink to={ROUTE_LOGIN}>{r('logIn')}</StyledSignUpLink>}
          {step !== StepEnum.general && (
            <StyledButtonUnderline type={'button'} variant={'text'} onClick={() => dispatch(openRegistrationModal())}>
              {r('logIn')}
            </StyledButtonUnderline>
          )}
        </StyledSignUpContainer>
      </StyledForm>
    </StyledContainer>
  );
};

export default View;

import React from 'react';
import { useTranslation } from 'react-i18next';
import worldIcon from 'styles/images/world_icon.svg?sprite';
import { StyledContainer, StyledText, StyledBorder, worldIconCss } from './styled';
import Icon from '../../../common/Icon';

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string) => i18n.changeLanguage(lng);

  return (
    <StyledContainer>
      <StyledText active={!['no', 'nb'].includes(i18n.language)} onClick={() => changeLanguage('en')}>
        EN
      </StyledText>
      <StyledBorder />
      <StyledText active={['no', 'nb'].includes(i18n.language)} onClick={() => changeLanguage('no')}>
        NO
      </StyledText>
      <Icon icon={worldIcon} css={worldIconCss} />
    </StyledContainer>
  );
};

export default LangSwitcher;

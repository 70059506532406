import React, {
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { CSSProp } from 'styled-components';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import errorIcon from 'components/svg/error.svg?sprite';
import eyeCloseIcon from 'components/svg/eye_closed.svg?sprite';
import eyeOpenIcon from 'components/svg/eye_opened.svg?sprite';
import { Spinner } from '../../ui';
import {
  Wrapper,
  StyledField,
  StyledLabel,
  StyledInput,
  StyledPrompt,
  StyledActiveCaps,
  StyledInputContainer,
  StyledButton,
  StyledHead,
  StyledServerError,
  errorIconCss,
  eyeIconCss,
  VariantT
} from './styled';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  variant?: VariantT;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  register?: UseFormRegisterReturn;
  serverError?: ReactNode;
  isLoading?: boolean;
  isSuccess?: boolean;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  additionalInformation?: ReactNode;
  withoutPrompt?: boolean;
  inputStyleCss?: CSSProp;
  fieldStyleCss?: CSSProp;
  wrapperStyle?: CSSProp;
  prompt?: ReactNode;
  additionalRef?: MutableRefObject<HTMLInputElement | null>;
  isSimply?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    className,
    error = false,
    isSuccess = false,
    errorMessage,
    register,
    type = 'text',
    serverError,
    required,
    isLoading,
    endAdornment,
    withoutPrompt,
    startAdornment,
    inputStyleCss,
    fieldStyleCss,
    wrapperStyle,
    value,
    variant = 'default',
    prompt,
    additionalInformation,
    additionalRef,
    isSimply = false,
    ...restProps
  } = props;
  const { g } = useGlobalTranslation();
  const [show, setShow] = useState(false);
  const [isActiveCapsLock, setIsActiveCapsLock] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const passwordParam = (type === 'password' && show && 'text') || type;

  const onShow = () => {
    setShow((prev) => !prev);
  };

  const checkCapsLock = (e: KeyboardEvent) => {
    if (e.getModifierState) {
      setIsActiveCapsLock(e?.getModifierState('CapsLock') || false);
    }
  };

  useEffect(() => {
    if (type === 'password') {
      inputRef.current?.addEventListener('keyup', checkCapsLock);
    }

    return () => inputRef.current?.removeEventListener('keyup', checkCapsLock);
  }, [inputRef]);

  return (
    <Wrapper className={className} styleCss={wrapperStyle}>
      <StyledField>
        <StyledHead htmlFor={register?.name}>
          <StyledLabel isError={error}>
            {label} {required && '*'}
          </StyledLabel>
          {type === 'password' && <StyledActiveCaps isActive={isActiveCapsLock}>{g('activeCaps')}</StyledActiveCaps>}
          {additionalInformation && additionalInformation}
        </StyledHead>
        <StyledInputContainer {...{ error, isSuccess, fieldStyleCss, variant }}>
          {startAdornment && startAdornment}
          {isSimply ? (
            <StyledInput value={value} type={type} {...restProps} ref={ref} />
          ) : (
            <StyledInput
              id={register?.name}
              {...register}
              {...restProps}
              type={(type === 'password' && passwordParam) || type}
              ref={(element: HTMLInputElement | null) => {
                register?.ref(element);
                inputRef.current = element;
                if (additionalRef) {
                  additionalRef.current = element;
                }
              }}
              inputStyleCss={inputStyleCss}
              value={value}
            />
          )}
          {type === 'password' && (
            <StyledButton type={'button'} onClick={onShow} variant={'text'}>
              <Icon icon={(show && eyeOpenIcon) || eyeCloseIcon} css={eyeIconCss} />
            </StyledButton>
          )}
          {isLoading && <Spinner blue />}
          {endAdornment && endAdornment}
        </StyledInputContainer>
      </StyledField>
      {!withoutPrompt && (
        <StyledPrompt error={error && !!(errorMessage || serverError)}>
          <Icon icon={errorIcon} css={errorIconCss} />
          {(errorMessage && g(`validation.${errorMessage}`)) ||
            (serverError && <StyledServerError>{serverError}</StyledServerError>)}
        </StyledPrompt>
      )}
      {prompt && prompt}
    </Wrapper>
  );
});

export default Input;

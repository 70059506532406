import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required(''),
  address: Yup.string().required(''),
  postcode: Yup.string().required(''),
  city: Yup.string().required('')
});

export default schema;

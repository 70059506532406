import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Wrapper, Item, Title, Text, Actions } from './styled';
import Button from '../../../../../controls/Button';
import { StepT } from 'components/layouts/Modals/Depreciation';
import useGlobalTranslation from '../../../../../../hooks/useGlobalTranlation';

type PropsT = {
  setStep: Dispatch<SetStateAction<StepT>>;
};

const View: FC<PropsT> = (props) => {
  const { t } = useGlobalTranslation('depreciation');
  const { g } = useGlobalTranslation();
  const { setStep } = props;

  return (
    <Wrapper>
      <Item>
        <Title>{t('info.first.title')}</Title>
        <Text dangerouslySetInnerHTML={{ __html: t('info.first.text.firstText') }} />
        <Text>{t('info.first.text.secondText')}</Text>
      </Item>
      <Item>
        <Title>{t('info.second.title')}</Title>
        <Text>{t('info.second.text')}</Text>
      </Item>
      <Actions>
        <Button variant={'text'} onClick={() => setStep('form')}>
          {g('continue')}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default View;

import React, { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import format from 'date-fns/format';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import UserT from 'domain/entity/auth/UserT';
import { profileSchema } from 'data/validations/profileValidation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { ROUTE_PROFILE_LANDLORD } from 'constant/routes';
import { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import { profileSlice } from 'data/store/profileReducer';
import Icon from 'components/common/Icon';
import { Button } from 'components/ui';
import Fields from 'components/feature/Fields';
import SimplePage from 'components/common/SimplePage';
import { PROFILE_FIELDS } from 'components/feature/Fields/fields';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import {
  backIconCss,
  StyledForm,
  StyledFormContainer,
  StyledLink,
  StyledLinkText,
  StyledSubmitWrapper
} from './styled';
import i18n from 'i18next';

export const LandlordEdit: FC = () => {
  const dispatch = useAppDispatch();
  const { profileUpdate } = profileSlice.actions;
  const { t, g } = useGlobalTranslation('landlord');
  const history = useHistory();
  const { user, pending } = useAppSelector(({ profile }) => profile);
  const { countryOptions, noCountryOptions } = useAppSelector((state) => state.contract);
  const language = i18n.language;
  const form = useForm<ProfileFormT>({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      firstName: (user && user.firstName) || '',
      lastName: (user && user.lastName) || '',
      birthday: user?.birthday ? dayjs(new Date(user.birthday)).format('DD.MM.YYYY') : '',
      iban: (user && user.iban) || '',
      phone: (user && user.phone) || ''
    }
  });
  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (user) {
      Object.keys(user).map((key) => {
        if (key !== 'birthday') setValue(key, user && user[key as keyof UserT]);
        if (key === 'birthday' && user && !!user[key as keyof UserT])
          setValue(key, format(new Date(user[key as keyof UserT] as unknown as Date), 'dd.MM.yyyy'));
      });
    }
  }, [user]);

  const onSubmit = (data: ProfileFormT) => {
    dispatch(profileUpdate(data));
    history.goBack();
  };
  return (
    <SimplePage
      header={
        <StyledLink to={ROUTE_PROFILE_LANDLORD}>
          <Icon icon={backIcon} css={backIconCss} color={'black01'} />
          <StyledLinkText>{t('title')}</StyledLinkText>
        </StyledLink>
      }
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContainer>
          <FormProvider {...form}>
            <Fields
              inputProps={{ variant: 'outline', withoutPrompt: true }}
              fields={PROFILE_FIELDS(user, language === 'en' ? countryOptions : noCountryOptions)}
            />
          </FormProvider>
        </StyledFormContainer>
        <StyledSubmitWrapper>
          <Button isLoading={pending}>{g('save')}</Button>
        </StyledSubmitWrapper>
      </StyledForm>
    </SimplePage>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getErrMsg } from 'helpers/locale';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ROUTE_DASHBOARD, ROUTE_REGISTRATION, ROUTE_RESET_PASSWORD } from 'constant/routes';
import StepEnum from 'domain/entity/registration/StepEnum';
import { authSlice } from 'data/store/authReducer';
import { registrationSlice } from 'data/store/registrationReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input } from 'components/ui';
import schema from './validation';
import gglIcon from 'styles/images/google_icon.svg';
import fbIcon from 'styles/images/fb_icon.svg';
import {
  StyledContainer,
  StyledTextContainer,
  StyledTitle,
  StyledRow,
  StyledLink,
  StyledLinksContainer,
  StyledIconLink,
  StyledButton,
  StyledForm,
  StyledFormContent,
  StyledSignUpContainer,
  StyledSignUpLink
} from './styled';

type UserSubmitForm = {
  email: string;
  password: string;
};

const Login = () => {
  const refPasswordInput = useRef<HTMLInputElement | null>(null);
  const { t, g } = useGlobalTranslation('login');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(schema)
  });
  const history = useHistory();
  const { tokenFetching, tokenFetchingError } = authSlice.actions;
  const { registrationSuccess } = registrationSlice.actions;
  const { pending, token, error } = useAppSelector((state) => state.auth);
  const { user } = useAppSelector((state) => state.profile);

  const dispatch = useAppDispatch();

  const onSubmit = ({ email, password }: UserSubmitForm) => {
    refPasswordInput.current?.blur();

    dispatch(tokenFetching({ email, password }));
  };

  useEffect(() => {
    if (user?.is_register_finished === false) {
      dispatch(registrationSuccess(StepEnum.profile));
      history.push({ pathname: ROUTE_REGISTRATION });
    }
    if (token && !pending && user?.is_register_finished) {
      history.push({ pathname: ROUTE_DASHBOARD });
    }
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(tokenFetchingError(''));
    };
  }, []);

  return (
    <StyledContainer>
      <StyledTextContainer>
        <StyledTitle> {t('title')}</StyledTitle>
        <StyledLinksContainer>
          <StyledIconLink href={'#'}>
            <img src={fbIcon} alt={'facebook'} />
          </StyledIconLink>
          <StyledIconLink href={'#'}>
            <img src={gglIcon} alt={'google'} />
          </StyledIconLink>
        </StyledLinksContainer>
      </StyledTextContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContent>
          <Input
            type={'email'}
            label={g('email')}
            register={register('email')}
            error={errors?.email}
            errorMessage={errors?.email?.message}
          />
          <Input
            additionalRef={refPasswordInput}
            type={'password'}
            label={g('password')}
            register={register('password')}
            error={errors?.password || error}
            errorMessage={getErrMsg(errors, 'password')}
            serverError={g(error || '')}
          />
        </StyledFormContent>
        <StyledRow>
          <StyledLink to={ROUTE_RESET_PASSWORD}>{t('buttons.reset')}</StyledLink>
        </StyledRow>
        <StyledButton isLoading={pending}>{g('login')}</StyledButton>
        <StyledSignUpContainer>
          {t('dontHaveAcc')}
          <StyledSignUpLink to={ROUTE_REGISTRATION}>{g('signUp')}</StyledSignUpLink>
        </StyledSignUpContainer>
      </StyledForm>
    </StyledContainer>
  );
};

export default Login;

import styled, { css } from 'styled-components';
import { ThemeT } from 'context/Theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  width: 100%;
`;

export const StyledTitle = styled.h3`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 15px 0;
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
`;

export const StyledDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  margin: 1.5rem 0;
`;

export const StyledCheckRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0 0 1rem 0;

  img {
    margin-right: 1rem;
  }
`;

export const checkIconCss = (theme: ThemeT) => css`
  width: 19px;
  margin-right: 10px;
  fill: ${theme.colors.blue01};
`;

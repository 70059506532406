import dayjs from 'dayjs';
import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant/externalLinks';
import { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import { modalsSlice } from 'data/store/modalReducer';
import { profileSlice } from 'data/store/profileReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import { agreePolicySchema } from 'data/validations/agreePolicyValidation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import { Actions, LinkCheckBox, StyledCheckbox } from './styled';

const View: FC = () => {
  const { profileUpdate, profileDelete } = profileSlice.actions;
  const { closeUpdateAgreePolicy } = modalsSlice.actions;
  const { user, pending, isDeletedSuccess, isUpdatedProfile } = useAppSelector((state) => state.profile);
  const formData = useForm<ProfileFormT>({
    resolver: yupResolver(agreePolicySchema),
    defaultValues: { ...user, birthday: user?.birthday ? dayjs(new Date(user.birthday)).format('DD.MM.YYYY') : '' }
  });
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { handleSubmit } = formData;
  const { g } = useGlobalTranslation();
  const { t } = useGlobalTranslation('profile');
  const dispatch = useAppDispatch();

  const onSubmit = (data: ProfileFormT) => {
    dispatch(profileUpdate({ ...data, isAgreePolicy: true }));
    dispatch(closeUpdateAgreePolicy());
  };

  const deleteAccountClick = () => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('cancel')
        },
        confirmButtonProps: {
          onClick: () => {
            dispatch(profileDelete());
            dispatch(closeUpdateAgreePolicy());
            dispatch(resetConfirmationData());
          },
          children: g('continue')
        },
        text: t('delete.message'),
        title: t('delete.deleteAccount')
      })
    );
  };

  return (
    <FormProvider {...formData}>
      <StyledCheckbox
        register={formData.register('isAgreePolicy')}
        value={'true'}
        errorMessage={
          formData.formState.errors?.['isAgreePolicy'] &&
          g(`errors.${formData.formState.errors?.['isAgreePolicy']?.message}`)
        }
      >
        {g('iAgree')}{' '}
        <LinkCheckBox href={TERMS_OF_USE_URL} target="_blank">
          {g('terms')}
        </LinkCheckBox>{' '}
        {g('and')}{' '}
        <LinkCheckBox href={PRIVACY_POLICY_URL} target="_blank">
          {g('policy')}
        </LinkCheckBox>
      </StyledCheckbox>
      <Actions>
        <Button
          variant={'text'}
          color={'red01'}
          disabled={pending}
          isLoading={isDeletedSuccess}
          onClick={deleteAccountClick}
        >
          {t('delete.deleteAccount')}
        </Button>
        <Button variant={'text'} disabled={pending} isLoading={isUpdatedProfile} onClick={handleSubmit(onSubmit)}>
          {g('continue')}
        </Button>
      </Actions>
    </FormProvider>
  );
};

export default View;

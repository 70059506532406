import styled, { css, CSSProp } from 'styled-components';
import { ThemeT } from 'context/Theme';
import Button from 'components/controls/Button';

export type VariantT = 'outline' | 'default';

type ErrorPropsT = {
  error: boolean;
};

type SuccessPropsT = {
  isSuccess: boolean;
  fieldStyleCss?: CSSProp;
};

const colorData = (theme: ThemeT) => ({
  defaultColor: theme.colors.black01,
  mutedColor: theme.colors.gray01,
  outlineColor: theme.colors.gray04,
  highlightColor: theme.colors.blue01,
  errorColor: theme.colors.red01,
  successColor: theme.colors.green01
});

export const StyledLabel = styled.span<{ isError: boolean }>`
  color: ${({ theme, isError }) => (isError ? theme.colors.red01 : colorData(theme).mutedColor)};
  font-size: 14px;
  font-weight: normal;
  pointer-events: none;
  top: -0.625rem;
  transition: 300ms ease all;
`;

const defaultInputContainerCss = (error: boolean, theme: ThemeT, isSuccess: boolean) => css`
  border-bottom: 1px solid
    ${(error && colorData(theme).errorColor) ||
    (isSuccess && colorData(theme).successColor) ||
    colorData(theme).mutedColor};
`;

const outlineInputContainerCss = (error: boolean, theme: ThemeT, isSuccess: boolean) => css`
  margin-top: 4px;
  border-radius: 3px;
  padding: 0 7px 0 24px;
  border: 1px solid
    ${(error && colorData(theme).errorColor) ||
    (isSuccess && colorData(theme).successColor) ||
    colorData(theme).outlineColor};

  & ${StyledInput} {
    height: 48px;
  }
`;

type InputPropsT = {
  inputStyleCss?: CSSProp;
};

export const StyledInput = styled.textarea<InputPropsT>`
  background: none;
  height: 42px;
  color: ${({ theme }) => colorData(theme).defaultColor};
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  transition: 300ms ease all;
  -moz-appearance: textfield;
  resize: none;

  ::-webkit-inner-spin-button {
    display: none;
  }
  ${css``}

  &:focus {
    outline: none;
    border-color: ${({ theme }) => colorData(theme).highlightColor};
    transition: 300ms ease all;

    ${StyledLabel} {
      transition: 300ms ease all;
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray03};
  }

  ${({ inputStyleCss }) => inputStyleCss && inputStyleCss}
`;

type InputContainerPropsT = {
  variant: VariantT;
};

export const StyledInputContainer = styled.div<InputContainerPropsT & ErrorPropsT & SuccessPropsT>`
  display: flex;
  align-items: center;
  transition: all 0.4s;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.blue01};
  }

  ${({ fieldStyleCss }) => fieldStyleCss && fieldStyleCss}
  ${({ error, variant, theme, isSuccess }) =>
    variant === 'default' && defaultInputContainerCss(error, theme, isSuccess)}
  ${({ error, variant, theme, isSuccess }) =>
    variant === 'outline' && outlineInputContainerCss(error, theme, isSuccess)}
`;

export const StyledButton = styled(Button)`
  height: 42px;
  width: 42px;
  padding: 0;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const eyeIconCss = css`
  fill: currentColor;
  width: 25px;
`;

export const StyledHead = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledServerError = styled.span`
  color: inherit;
`;

export const StyledField = styled.label`
  position: relative;
  width: 100%;

  input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 900;
  }
`;

export const StyledPrompt = styled.div<ErrorPropsT>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.colors.red01};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 1em;
  visibility: ${({ error }) => (error && 'visible') || 'hidden'};

  img {
    margin-right: 0.25rem;
  }
`;

type StyledActiveCapsPropsT = {
  isActive: boolean;
};

export const StyledActiveCaps = styled.div<StyledActiveCapsPropsT>`
  pointer-events: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.gray03};
  visibility: ${({ isActive }) => (isActive && 'visible') || 'hidden'};
  opacity: ${({ isActive }) => (isActive && 1) || 0};
  transition: all 300ms;
`;

export const errorIconCss = css`
  margin-right: 5px;
  fill: currentColor;
`;

export enum StepEnum {
  RENT_CONTRACT,
  INFORM_ABOUT_TENANT,
  TENANCY_TERMS,
  INCLUDING,
  DEPOSIT_ACCOUNT,
  SAVE_RENT_CONTRACT,
  HELP_DEPOSIT,
  HELP_SIGN_BY_ID
}

import {
  CHANGE_EMAIL,
  CHANGE_EMAIL_BY_CODE,
  CHECK_EMAIL,
  FETCH_PROFILE,
  GET_DIFI_TOKEN,
  GET_ERROR_LIST,
  GET_PROVIDERS,
  LOGIN,
  LOGOUT_DIFI,
  RESET_PASSWORD,
  RESET_PASSWORD_BY_CODE,
  RESET_PASSWORD_BY_LINK
} from 'constant/apiRoutes';
import { http, httpWithAuth, setCredentials } from 'data/driver/Api';
import CredentialsResponseT from 'data/responseType/CredentialsResponseType';
import CredentialT from 'domain/entity/auth/CredentialT';
import NewPasswordT from 'domain/entity/auth/NewPassword';

export const login = async ({ email, password }: CredentialT) => {
  const { data } = await http.post<CredentialsResponseT>(LOGIN, { email, password });
  setCredentials(data?.accessToken);

  return data;
};

export const resetPasswordByLink = async (body: { email: CredentialT['email'] }) => {
  const { data } = await http.post(RESET_PASSWORD_BY_LINK, body);

  return data;
};

export const resetPasswordByCode = async (body: { code: string }) => {
  const { data } = await http.post(RESET_PASSWORD_BY_CODE, body);

  return data;
};

export const setNewPassword = async (body: NewPasswordT) => {
  const { data } = await http.post(RESET_PASSWORD, body);

  return data;
};

export const requestChangeEmail = async ({ code }: { code: string }) => {
  const { data } = await http.get(CHANGE_EMAIL(code));

  return data;
};

export const fetchProfile = async () => {
  const { data } = await httpWithAuth.get(FETCH_PROFILE);

  return data;
};

export const changeEmailByCode = async ({ code }: { code: string }) => {
  const { data } = await http.get(CHANGE_EMAIL_BY_CODE(code));

  return data;
};

export const fetchCheckEmail = async (email: string) => {
  await http.post(CHECK_EMAIL, { email });
};

export const fetchAuthProvidersApi = async () => {
  const { data } = await http.get(GET_PROVIDERS);

  return data;
};

export const fetchDifiTokenApi = async ({ code, state }: { code: string; state: string }) => {
  const { data } = await httpWithAuth.get(GET_DIFI_TOKEN(code, state));

  return data;
};

export const logoutDifiApi = async () => {
  const { data } = await httpWithAuth.get(LOGOUT_DIFI);

  return data;
};

export const fetchErrorListApi = async () => {
  const { data } = await httpWithAuth.get(GET_ERROR_LIST);

  return data;
};

import React, { Dispatch, FC, SetStateAction } from 'react';
import ContractIsFinished from './ContractIsFinished';
import ConfirmedRelease from './ConfirmedRelease';

type PropsT = {
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
};

const View: FC<PropsT> = (props) => {
  const { step, setStep } = props;

  return (
    <>
      {step === 0 && <ContractIsFinished setStep={setStep} />}
      {step === 1 && <ConfirmedRelease />}
    </>
  );
};

export default View;

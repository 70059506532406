import React, { FC } from 'react';
import { StyledContainer, StyledImageLowerXs, PdfLink, iconCss, ImageLink, StyledImg } from './styled';
import { useAppDispatch } from '../../../hooks/redux';
import { pictureViewerSlice } from '../../../data/store/pictureViewerReducer';
import Icon from '../../common/Icon';
import pdf from 'components/svg/pdf.svg?sprite';
import { CSSProp } from 'styled-components';

interface PropsI {
  documents: string[];
  className?: string;
  imgCss?: CSSProp;
}

const PictureList: FC<PropsI> = ({ documents, className, imgCss }) => {
  console.log(documents);
  const dispatch = useAppDispatch();
  const { setPictureViewerData } = pictureViewerSlice.actions;

  return (
    <StyledContainer className={className}>
      {documents &&
        documents.map((item) => {
          const isPdf = item?.includes('.pdf');

          return !isPdf ? (
            <React.Fragment key={item}>
              <StyledImageLowerXs
                src={item}
                onClick={() => dispatch(setPictureViewerData({ src: item, show: true }))}
                imgCss={imgCss}
              />
              <ImageLink href={item} target={'_blank'}>
                <StyledImg src={item} imgCss={imgCss} />
              </ImageLink>
            </React.Fragment>
          ) : (
            <PdfLink href={item} target={'_blank'} imgCss={imgCss}>
              <Icon icon={pdf} css={iconCss} />
            </PdfLink>
          );
        })}
    </StyledContainer>
  );
};

export default PictureList;

import styled from 'styled-components';
import Checkbox from '../../../../controls/Checkbox/Checkbox';

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
`;

export const StyledDescriptionContainer = styled.div`
  grid-column: 1/3;
`;

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: repeat(1, 278px);
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
  justify-content: space-between;
  padding: 14px 17px 10px 24px;
  font-size: 16px;
  line-height: 1.5;

  letter-spacing: 0.15px;
`;

export const StyledLabel = styled.div`
  margin-top: 26px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const Span = styled.span`
  display: block;
  min-width: 756px;
  width: 100%;
`;

import { css } from 'styled-components';
import { mq } from '../constant/mediaqueries';

export const containerMixin = css`
  box-sizing: content-box;
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  padding: 0 50px 20px;

  ${mq.greaterXxl} {
    gap: 40px;
    grid-auto-flow: column;
    width: 100%;
    grid-template-columns: 1092px 420px;
  }
`;

export const pageHeaderMixin = css`
  margin: 1.5rem 0 0 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5em;
`;

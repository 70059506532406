import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import arrow from 'components/svg/arrow-left-light.svg?sprite';
import { DepreciationFormDataT } from '../../../domain/entity/depreciation/DepreciationFormData';
import { calculateYears } from '../../../helpers/depreciation';
import { Wrapper, Title, BackButton, Head, Table, HeadCell, Cell, Tr, Body } from './styled';
import Icon from '../Icon';
import { StepT } from 'components/layouts/Modals/Depreciation';
import useGlobalTranslation from '../../../hooks/useGlobalTranlation';
import { numberWithSpaces } from '../../../helpers/number';

type PropsT = {
  setStep: () => void;
  lifetime: number;
  sum: number;
};

const Calculations: FC<PropsT> = (props) => {
  const { setStep, lifetime, sum } = props;
  const { t } = useGlobalTranslation('depreciation');

  const calculations = useMemo(() => {
    if (sum && lifetime) {
      return calculateYears({
        lifetime,
        sum
      });
    }
  }, [lifetime, sum]);

  useEffect(() => {
    return () => {
      setStep();
    };
  }, []);

  return (
    <Wrapper>
      <BackButton variant={'text'} onClick={setStep}>
        <Icon icon={arrow} />
      </BackButton>
      <Title>{t('calculations.title')}</Title>
      <div>
        <Table>
          <Head>
            <Tr>
              <HeadCell>{t('calculations.lifetimeYears')}</HeadCell>
              <HeadCell>{t('calculations.residualValue')}</HeadCell>
              <HeadCell>{t('calculations.expensedAmount')}</HeadCell>
            </Tr>
          </Head>
          <Body>
            {calculations?.map(({ lifetime, sum, expensed }, index) => (
              <Tr key={lifetime}>
                <Cell>{index + 1}</Cell>
                <Cell>{numberWithSpaces(sum)}</Cell>
                <Cell>{expensed}</Cell>
              </Tr>
            ))}
          </Body>
        </Table>
      </div>
    </Wrapper>
  );
};

export default Calculations;

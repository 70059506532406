import { ThemeT } from 'context/Theme';
import ThemeType from 'context/Theme/ThemeType';

const light: ThemeT = {
  type: ThemeType.Light,
  colors: {
    black01: '#3D3D3D',
    black02: '#000000',
    black03: '#1F2924',
    black04: '#182F1F',
    black05: '#304156',
    black06: '#4E5A6B',
    blue01: '#4A90E2',
    blue02: '#E6EFFA',
    blue03: '#1A4C87',
    green01: '#00B976',
    green02: '#63CC82',
    green03: '#f0fbf7',
    green04: '#42916D',
    green05: '#D9F5EB',
    white01: '#ffffff',
    gray01: '#B7B7B7',
    gray02: '#F3F5F6',
    gray03: '#7E7E7E',
    gray04: '#E0E0E0',
    gray05: '#E8EBED',
    gray06: '#FAFBFC',
    gray07: '#f1f2f3',
    gray08: '#f2f4f5',
    red01: '#F64F4F',
    orange01: '#f6954f'
  },
  fontSizes: {
    xxs: '0.625rem', // 10px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xlg: '1.25rem', // 20px
    l: '1.5rem', // 24px
    xl: '1.75rem', // 36px
    '2xl': '2.25rem' // 36px
  },
  radii: {
    sm: '0.25rem',
    md: '0.375rem',
    lg: '0.875rem',
    xl: '1.25rem',
    xxl: '2.3rem',
    '2xl': '4.875rem'
  },
  shadows: {
    sm: ' 0 0 5px 5px rgba(0, 0, 0, 0.1)',
    md: '0 0 7px 7px rgba(0, 0, 0, 0.1)',
    lg: '0 0 10px 10px rgba(0, 0, 0, 0.1)',
    xl: '0 0 20px 10px rgba(0, 0, 0, 0.1)'
  },
  transition: '0.4s'
};

export default light;

import React from 'react';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import bigSquareCheckIcon from 'components/svg/big_square_check_icon.svg?sprite';
import { StyledContainer, StyledSubtitle, StyledTitle, StyledNext, checkIconCss } from './styled';

const Thanks = ({ date }: { date?: string }) => {
  const { t } = useGlobalTranslation('subscription');
  return (
    <StyledContainer>
      <Icon icon={bigSquareCheckIcon} css={checkIconCss} />
      <StyledTitle>{t('thanks.title')}</StyledTitle>
      <StyledSubtitle>{t('thanks.subtitle')}</StyledSubtitle>
      <StyledNext>{t('thanks.nextDate', { date })}</StyledNext>
    </StyledContainer>
  );
};

export default Thanks;

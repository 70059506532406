import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  CheckContract,
  Contract,
  ContractFormData,
  ReleaseDepositFormData
} from 'domain/entity/rentContract/RentContract';
import { uploadFileApi } from 'data/driver/file/File';
import { closeRentContract } from 'data/store/modalReducer';
import { fetchAllDocuments } from 'data/store/documentReducer';
import {
  closeDepositApi,
  createCheckListApi,
  createContractApi,
  deleteAllIncomeBilagsApi,
  fetchContactsApi,
  fetchContractApi,
  finishContactApi,
  getCountryListApi,
  regenerateSignBankIdContactApi,
  releaseDepositApi,
  signBankIdContactApi,
  signDepositAccountContactApi,
  updateContractApi
} from 'data/driver/contract/Contract';
import {
  closeDepositError,
  closeDepositSuccess,
  createCheckListSuccess,
  createContractError,
  createContractSignBankIdError,
  createContractSignBankIdSuccess,
  createContractSuccess,
  deleteAllIncomeBilagsSuccess,
  fetchContractError,
  fetchContractsSuccess,
  fetchContractSuccess,
  fetchFinishContractSuccess,
  getCountryListError,
  getCountryListSuccess,
  releaseDepositError,
  releaseDepositSuccess,
  signContractByBankIDError,
  signContractByBankIDSuccess,
  updateContractError,
  updateContracts,
  updateContractSuccess
} from 'data/store/rentContractReducer';

export function* createContractSaga({ payload }: PayloadAction<ContractFormData>) {
  try {
    const responseDocuments = yield call(uploadFileApi, payload.documents || []);
    const response: Contract = yield call(createContractApi, { ...payload, documents: responseDocuments });
    yield put(fetchAllDocuments({ houseId: payload.houseId }));
    yield put(createContractSuccess(response));
    yield put(closeRentContract());
  } catch (e) {
    yield put(createContractError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* updateContractSaga({
  payload: { formData, contractId }
}: PayloadAction<{ formData: ContractFormData; contractId: number }>) {
  try {
    const responseDocuments = yield call(uploadFileApi, formData.documents || []);
    const response: Contract = yield call(updateContractApi, {
      formData: { ...formData, documents: responseDocuments },
      contractId
    });
    yield put(updateContractSuccess(response));
    yield put(closeRentContract());
  } catch (e) {
    yield put(updateContractError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* createContractSignBankIdSaga({ payload }: PayloadAction<ContractFormData>) {
  try {
    const response = yield call(uploadFileApi, payload.documents || []);
    const contractResponse = yield call(createContractApi, { ...payload, documents: response });
    yield call(signBankIdContactApi, contractResponse.id);
    yield put(fetchAllDocuments({ houseId: payload.houseId }));
    yield put(createContractSignBankIdSuccess());
    yield put(closeRentContract());
  } catch (e) {
    yield put(createContractSignBankIdError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* contractSignBankIdSaga({ payload }: PayloadAction<{ id: Contract['id']; checkList?: boolean }>) {
  try {
    const response = yield call(signBankIdContactApi, payload);
    yield put(signContractByBankIDSuccess(response));
  } catch (e) {
    yield put(signContractByBankIDError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* regenerateSignBankIdSaga({ payload }: PayloadAction<{ id: Contract['id']; checkList?: boolean }>) {
  try {
    const response = yield call(regenerateSignBankIdContactApi, payload);
    yield put(signContractByBankIDSuccess(response));
  } catch (e) {
    yield put(signContractByBankIDError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* closeDepositSaga({ payload }: PayloadAction<Contract['id']>) {
  try {
    const response = yield call(closeDepositApi, payload);
    yield put(closeDepositSuccess(response));
  } catch (e) {
    yield put(closeDepositError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* releaseDepositSaga({
  payload
}: PayloadAction<{ formData: ReleaseDepositFormData; contractId: number }>) {
  try {
    const response = yield call(releaseDepositApi, payload);
    yield put(releaseDepositSuccess(response));
  } catch (e) {
    yield put(releaseDepositError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* contractSignDepositAccountSaga({ payload }: PayloadAction<Contract['id']>) {
  try {
    const response = yield call(signDepositAccountContactApi, payload);
    yield put(signContractByBankIDSuccess(response));
  } catch (e) {
    yield put(signContractByBankIDError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* fetchContractListSaga() {
  try {
    const response = yield call(fetchContactsApi);
    yield put(fetchContractsSuccess(response));
  } catch (e) {
    yield put(createContractError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* finishContractSaga({ payload }: PayloadAction<number>) {
  try {
    const response = yield call(finishContactApi, payload);
    yield put(updateContracts(response));
    yield put(fetchFinishContractSuccess(response));
  } catch (e) {
    yield put(createContractError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* deleteAllIncomeBilagsSaga({ payload }: PayloadAction<number>) {
  try {
    const response = yield call(deleteAllIncomeBilagsApi, payload);
    yield put(updateContracts(response));
    yield put(deleteAllIncomeBilagsSuccess());
  } catch (e) {
    yield put(createContractError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* getCountryListSaga() {
  try {
    const response = yield call(getCountryListApi);
    yield put(getCountryListSuccess(response));
  } catch (e) {
    yield put(getCountryListError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* createCheckListSaga({ payload }: PayloadAction<{ formData: CheckContract; contractId: number }>) {
  try {
    const response = yield call(createCheckListApi, payload);
    yield put(createCheckListSuccess(response));
  } catch (e) {
    yield put(getCountryListError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* fetchContractSaga({ payload }: PayloadAction<number>) {
  try {
    const response = yield call(fetchContractApi, payload);
    yield put(fetchContractSuccess(response));
  } catch (e) {
    yield put(fetchContractError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

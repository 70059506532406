import styled from 'styled-components';
import Button from 'components/controls/Button';
import Checkbox from '../../../../components/controls/Checkbox/Checkbox';
import { mq } from '../../../../constant/mediaqueries';

export const StyledContent = styled.div`
  margin-top: 26px;
  display: grid;
  gap: 10px 24px;
  justify-content: center;
`;

export const Title = styled.p`
  text-align: start;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  margin: 0;

  ${mq.lowerSm} {
    margin-top: 15px;
  }
`;

export const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
`;

export const StyledButton = styled(Button)`
  margin: 20px 0 0;
  width: 280px;
`;

export const ActionsButton = styled.div`
  display: grid;
  justify-items: center;
  margin-top: 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  letter-spacing: 0.15px;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue01};
  cursor: pointer;
`;

export const HiddenLabel = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

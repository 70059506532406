import React, { FC } from 'react';
import { TextInfo } from '../index';
import { notOkIconCss, StyledContainer } from './styled';
import useGlobalTranslation from '../../../hooks/useGlobalTranlation';
import { InfoFieldT, InfoFieldValueT } from '../../../domain/entity/common';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '../../../constant/formats';
import okIcon from 'components/svg/ok_icon.svg?sprite';
import notOkIcon from 'components/svg/not_ok_icon.svg?sprite';
import Icon from '../Icon';
import { numberWithSpaces } from '../../../helpers/number';

interface PropsI {
  fields: InfoFieldT[];
  className?: string;
}

const InfoFields: FC<PropsI> = ({ fields, className }) => {
  const { g } = useGlobalTranslation();

  const renderBoolean = (value: InfoFieldValueT) =>
    (value && <Icon icon={okIcon} />) || <Icon icon={notOkIcon} css={notOkIconCss} />;

  return (
    <StyledContainer {...{ className }}>
      {fields.map(({ label, value, type, hidden }) => (
        <React.Fragment key={label}>
          {!hidden && (
            <TextInfo label={g(label)}>
              {(type === 'currency' && `${numberWithSpaces(Number(value))} NOK`) ||
                (type === 'date' && `${dayjs(`${value}`).format(DEFAULT_DATE_FORMAT)}`) ||
                (type === 'boolean' && renderBoolean(value)) ||
                g(`${value}`)}
            </TextInfo>
          )}
        </React.Fragment>
      ))}
    </StyledContainer>
  );
};

export default InfoFields;

import React, { FC, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from 'constant/formats';
import { ROUTE_SEPARATE_INCOME_BILAGS_ITEM } from 'constant/routes';
import { IncomeBilag } from 'domain/entity/documnets/CommonBilag';
import { modalsSlice } from 'data/store/modalReducer';
import { documentsSelector, documentsSlice } from 'data/store/documentReducer';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import { ListView } from 'components/common';
import SimpleRow from 'components/controls/SimpleRow';
import SimplePage from 'components/common/SimplePage';
import plusIcon from 'components/svg/plus.svg?sprite';
import { StyledButton, plusIconCss } from './styled';
import useSubscribeCheck from '../../hooks/useSubscribeCheck';

const SeparateIncomeBilags: FC = () => {
  const { g } = useGlobalTranlation();
  const { selectedHouse } = useAppSelector((state) => state.house);
  const { documents } = useAppSelector(documentsSelector);
  const { fetchAllDocuments, setCurrentDocument } = documentsSlice.actions;
  const { openIncomeBilag } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const subscribeCheck = useSubscribeCheck();

  useEffect(() => {
    if (!documents?.length && selectedHouse) {
      dispatch(fetchAllDocuments({ houseId: selectedHouse.id }));
    }
  }, []);

  const incomeBilags =
    (documents?.filter(
      (item) => item.type === 'income-bilag' && item.bilag?.contractId === null && !item.bilag?.isTrashed
    ) as { type: 'income-bilag'; bilag: IncomeBilag }[]) || [];

  const onBilagClick = (id: number) => {
    dispatch(setCurrentDocument(id));
    history.push(generatePath(ROUTE_SEPARATE_INCOME_BILAGS_ITEM, { slug: id }), { slug: id });
  };

  const list =
    incomeBilags?.length > 0 &&
    incomeBilags?.map(
      ({ bilag }) =>
        bilag?.title && (
          <SimpleRow
            key={bilag.id}
            name={bilag.title}
            info={`${dayjs(bilag.start_date).format(DEFAULT_DATE_FORMAT)}`}
            onClick={() => onBilagClick(bilag.id)}
          />
        )
    );
  return (
    <SimplePage
      header={
        <>
          {g('sidebar.separateIncomeBilags')}
          <StyledButton onClick={() => subscribeCheck() && dispatch(openIncomeBilag())} variant={'text'}>
            <Icon icon={plusIcon} css={plusIconCss} />
            {g('addNewIncomeBilag')}
          </StyledButton>
        </>
      }
    >
      <ListView what={'incomeBilag'}>{list}</ListView>
    </SimplePage>
  );
};

export default SeparateIncomeBilags;

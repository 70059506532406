import styled from 'styled-components';
import { hidden, mq } from '../../../constant/mediaqueries';

type MainContentPropsT = {
  isOpen: boolean;
};

export const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: minmax(100vh, auto);
`;

export const StyledContent = styled.div`
  margin-top: 60px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  align-items: start;

  ${mq.lowerXs} {
    margin-top: 50px;
  }
`;

export const MainContent = styled.div<MainContentPropsT>`
  display: flex;
  justify-content: flex-start;
  transition: margin-left 0.5s;
  position: relative;

  ${mq.greaterSm} {
    margin-left: ${({ isOpen }) => (isOpen ? '24px' : '260px')};
  }
`;

export const HiddenGreaterXs = styled.div`
  ${hidden.greaterSm}
`;

export const HiddenLowerXs = styled.div`
  ${hidden.lowerSm}
`;

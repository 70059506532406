import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { Checkbox } from 'components/ui';
import Icon from 'components/common/Icon';
import Popup from 'components/common/Popup';
import { TypeT } from 'containers/Dashboard/Table/data';
import filterIcon from 'components/svg/filter-menu.svg?sprite';
import { StyledButton, StyledItem, filterIconCss } from './styled';

type ItemT = { value: TypeT; label: string };

const DATA: ItemT[] = [
  { value: 'depreciation', label: 'depreciation' },
  { value: 'income-bilag', label: 'incomeBilag' },
  { value: 'manual-bilag', label: 'manualBilag' },
  { value: 'archived-bilag', label: 'archivedBilag' },
  { value: 'rent-contract', label: 'rentContract' }
];

const CellWithFilter: FC = () => {
  const { register } = useFormContext();
  const { g } = useGlobalTranslation();
  const { t } = useGlobalTranslation('pages');

  return (
    <Popup
      button={(onClick, isOpen) => (
        <StyledButton type="button" onClick={onClick} isOpen={isOpen}>
          {t('dashboard.table.heads.type')}
          <Icon icon={filterIcon} css={filterIconCss} />
        </StyledButton>
      )}
    >
      {DATA.map(({ value, label }) => (
        <StyledItem key={value}>
          <Checkbox register={register('type')} value={value} />
          {g(label)}
        </StyledItem>
      ))}
    </Popup>
  );
};

export default CellWithFilter;

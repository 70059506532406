import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
`;

import { CSSProperties } from 'react';

type FieldT = {
  type: string;
  name: string;
  text: string;
  required?: boolean;
  serverError?: boolean;
  style?: CSSProperties;
};

export const PASSWORD_FIELDS: FieldT[] = [
  { type: 'password', name: 'oldPassword', text: 'oldPassword', serverError: true },
  { type: 'password', name: 'newPassword', text: 'createNewPassword' },
  { type: 'password', name: 'repeatNewPassword', text: 'repeatNewPassword' }
];

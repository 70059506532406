import React, { FC } from 'react';
import ManualBilag from './ManualBilag';
import AddHouse from './AddHouse';
import NewRentContract from './NewRentContract';
import IncomeBilag from './IncomeBilag';
import Modal from '../../../containers/Altinn/Main/Modal';
import Alert from '../../common/Alert';
import InfoBilag from './InfoBilag';
import { Confirmation } from '../../common';
import ChangeRent from './ChangeRent';
import ConfirmationModals from './ConfirmationModals';
import Depreciation from './Depreciation';
import PictureViewer from './PictureViewer';
import ActionsModal from './ActionsModal';
import UpdateProfileNumber from './UpdatePhoneNumber';
import ReleaseDeposit from './ReleaseDeposit';
import CheckListContract from './CheckListContract';
import UpdateAgreePolicy from './UpdateAgreePolicy';
import PicturesModal from './PicturesModal';

const Modals: FC = () => {
  return (
    <>
      <AddHouse />
      <NewRentContract />
      <IncomeBilag />
      <Modal />
      <InfoBilag />
      <ManualBilag />
      <Depreciation />
      <ChangeRent />
      <ActionsModal />
      <UpdateProfileNumber />
      <ReleaseDeposit />
      <CheckListContract />
      <UpdateAgreePolicy />
      <ConfirmationModals />
      <PicturesModal />
      <PictureViewer />
      <Confirmation />
      <Alert />
    </>
  );
};

export default Modals;

import React, { FC } from 'react';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import ConfirmationModal from 'components/common/ConfirmationModal';

const ManyHouses: FC = () => {
  const { manyHouses } = useAppSelector((state) => state.modals);
  const { closeManyHouses } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const { t } = useGlobalTranslation('manyHouses');

  return (
    <ConfirmationModal
      isOpen={manyHouses}
      onClose={() => dispatch(closeManyHouses())}
      title={t('title')}
      text={t('text')}
      confirmButtonProps={{
        onClick: (onClose) => {
          onClose();
        },
        children: t('cancel')
      }}
    />
  );
};

export default ManyHouses;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getErrMsg } from 'helpers/locale';
import useAppLogout from 'hooks/useAppLogout';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authSlice } from 'data/store/authReducer';
import { Form } from 'components/ui';
import { Button, Input } from 'components/ui';
import SuccessMessage from './SuccessMessage';
import schema from './validation';
import { StyledTextContainer, StyledTitle, StyledContainer } from './styled';

type ResetPasswordNewSubmit = {
  password: string;
  repeat: string;
};

const ResetPasswordNew = ({ match }: RouteComponentProps) => {
  const appLogout = useAppLogout();
  const { t, g } = useGlobalTranslation('resetPasswordNew');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ResetPasswordNewSubmit>({
    resolver: yupResolver(schema)
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useAppDispatch();

  const { resetPasswordCheckFetching, sendNewPasswordFetching } = authSlice.actions;
  // @ts-ignore
  const { pendingReset, code, codeConfirmed, error } = useAppSelector((state) => state.auth);

  const [sent, setSent] = useState(false);

  useEffect(() => {
    appLogout();
    // @ts-ignore
    const slug = match?.params.slug;
    dispatch(resetPasswordCheckFetching({ code: slug }));
  }, []);

  useEffect(() => {
    setErrorMessage(error);
    if (error) setSent(false);
  }, [error]);

  const onSubmit = (data: ResetPasswordNewSubmit) => {
    const patternCheck = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=\S+$).{6,50}$/.test(
      data.password
    );
    if (patternCheck) {
      dispatch(sendNewPasswordFetching({ ...data, code }));
      setSent(true);
      setErrorMessage(undefined);
    } else {
      setErrorMessage(g('errors.password.pattern'));
    }
  };

  return (
    <StyledContainer>
      {(sent && <SuccessMessage />) || (
        <>
          <StyledTextContainer>
            <StyledTitle>{t('title')}</StyledTitle>
          </StyledTextContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type={'password'}
              register={register('password')}
              error={errors?.password}
              errorMessage={getErrMsg(errors, 'password')}
              label={t('password.new')}
            />
            <Input
              type={'password'}
              register={register('repeat')}
              error={errors?.repeat || errorMessage}
              errorMessage={getErrMsg(errors, 'repeat')}
              label={t('password.repeat')}
              serverError={g(errorMessage || '')}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button isLoading={pendingReset}>{t('button')}</Button>
            </div>
          </Form>
        </>
      )}
    </StyledContainer>
  );
};

export default ResetPasswordNew;

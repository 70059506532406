import React, { FC, ReactNode } from 'react';
import Icon from '../../common/Icon';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import { backIconCss, StyledLink, StyledLinkText } from '../../../containers/Landlord/components/LandlordEdit/styled';

interface PropsI {
  route: string;
  children: ReactNode;
}

const BackButton: FC<PropsI> = ({ children, route }) => {
  return (
    <StyledLink to={route}>
      <Icon icon={backIcon} css={backIconCss} color={'black01'} />
      <StyledLinkText>{children}</StyledLinkText>
    </StyledLink>
  );
};

export default BackButton;

import React, { FC } from 'react';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import ConfirmationModal from 'components/common/ConfirmationModal';

const DiscardChange: FC = () => {
  const { discardChange } = useAppSelector((state) => state.modals);
  const { closeDiscardChange } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const { t } = useGlobalTranslation('discardChanges');

  return (
    <ConfirmationModal
      isConfirm
      isOpen={!!discardChange?.show}
      title={t('title')}
      text={t('text')}
      cancelButtonProps={{
        onClick: () => dispatch(closeDiscardChange()),
        children: t('cancel')
      }}
      confirmButtonProps={{
        onClick: (onClose) => {
          if (discardChange?.functionConfirm) {
            discardChange.functionConfirm();
          }
          onClose();
        },
        children: t('submit')
      }}
    />
  );
};

export default DiscardChange;

import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { uploadFiles, uploadFilesError, uploadFilesSuccess } from '../store/fileReducer';
import { uploadFileApi } from '../driver/file/File';

export function* uploadFilesSaga({ payload }: PayloadAction<File[]>) {
  try {
    const response = yield call(uploadFileApi, payload);
    yield put(uploadFilesSuccess(response));
  } catch (e) {
    yield put(uploadFilesError(e?.response?.data?.error || e?.error));
  }
}

import styled from 'styled-components';
import { Button } from 'components/ui';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b7b7b7;
  max-width: 30rem;
  position: relative;
`;

export const StyledTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled.div`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.black01};
  text-align: center;
  font-weight: 500;
`;

export const StyledSubtitle = styled.div`
  margin: 1.25rem 0;
`;

export const StyledButton = styled(Button)`
  margin-top: 48px;
  justify-self: center;
`;

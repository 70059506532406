import React, { FC } from 'react';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Modal from 'components/common/Modal';
import View from './View';

const CheckListContract: FC = () => {
  const { t } = useGlobalTranslation('titles');
  const { checkContract } = useAppSelector((state) => state.modals);
  const { closeCheckContract } = modalsSlice.actions;
  const dispatch = useAppDispatch();

  return (
    <Modal isOpen={checkContract} title={t('chekContract')} onClose={() => dispatch(closeCheckContract())}>
      <View />
    </Modal>
  );
};

export default CheckListContract;

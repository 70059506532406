import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from 'domain/entity/house/HouseData';
import { HouseCreateFormT } from '../../domain/entity/house/HouseCreateFormData';

export interface HouseState {
  pending?: boolean;
  houseList: HouseData[];
  selectedHouse?: HouseData;
  error: string;
}

const initialState: HouseState = {
  pending: undefined,
  houseList: localStorage.getItem('house') ? JSON.parse(localStorage.getItem('house') || '') : [],
  error: ''
};

export const houseSlice = createSlice({
  name: 'house',
  initialState,
  reducers: {
    createHouse(state: HouseState, _: PayloadAction<HouseCreateFormT>) {
      state.pending = true;
    },
    createHouseRegistration(state: HouseState, _: PayloadAction<HouseCreateFormT>) {
      state.pending = true;
    },
    fetchHouses(state: HouseState) {
      state.pending = true;
    },
    fetchHouseUpdate(state: HouseState, _: PayloadAction<HouseData>) {
      state.pending = true;
    },
    fetchHouseUpdateSuccess(state: HouseState, { payload }: PayloadAction<HouseData>) {
      state.pending = false;
      state.selectedHouse = payload;
      state.houseList = state.houseList.map((house) => {
        return house.id === payload.id ? payload : house;
      });
      // todo: remove it naxyi.
      localStorage.setItem('selectedHouse', String(payload.id));
      state.error = '';
    },
    fetchHousesSuccess(state: HouseState, { payload }: PayloadAction<HouseData[]>) {
      const oldHouseId = localStorage.getItem('selectedHouse');
      console.log(oldHouseId);
      state.pending = false;
      state.selectedHouse =
        oldHouseId && !isNaN(Number(oldHouseId)) ? payload.find(({ id }) => id === Number(oldHouseId)) : payload[0];
      state.error = '';
      state.houseList = payload;
    },
    setSelectedHouse(state: HouseState, { payload }: PayloadAction<HouseData>) {
      localStorage.setItem('selectedHouse', String(payload.id));
      state.selectedHouse = payload;
    },
    fetchHousesError(state: HouseState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    createHouseSuccess(state: HouseState, { payload }: PayloadAction<HouseData>) {
      state.pending = false;
      state.selectedHouse = payload;
      state.houseList = [...state.houseList, payload];
      state.error = '';
    },
    fetchHouseDelete(state: HouseState, _: PayloadAction<number>) {
      state.pending = true;
      state.error = '';
    },
    fetchHouseDeleteSuccess(state: HouseState, { payload }: PayloadAction<number>) {
      state.pending = false;
      state.houseList = state.houseList?.filter((house) => house.id !== payload);
      state.selectedHouse = state?.houseList && state.houseList[0];
      state.error = '';
    },
    createHouseError(state: HouseState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    }
  }
});

export const selectHouse = (state: RootState): HouseState => state.house;

export const houseSelector = createSelector([selectHouse], (house) => house);

export const {
  createHouseError,
  createHouseSuccess,
  createHouse,
  fetchHousesSuccess,
  fetchHousesError,
  fetchHouses,
  fetchHouseUpdate,
  fetchHouseUpdateSuccess,
  createHouseRegistration,
  setSelectedHouse,
  fetchHouseDelete,
  fetchHouseDeleteSuccess
} = houseSlice.actions;

export default houseSlice.reducer;

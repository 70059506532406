// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { APP_STORE, GOOGLE_PLAY } from 'constant/externalLinks';
import { ROUTE_ALTINN_INDEX, ROUTE_TEST_SKATTEETATEN } from 'constant/routes';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import Logo from 'components/ui/Logo/Logo';
import Button from 'components/controls/Button';
import Spinner from 'components/ui/Spinner/Spinner';
import googleIcon from 'components/svg/googlePlay.svg?sprite';
import appStoreIcon from 'components/svg/appStore.svg?sprite';
import { ActionContainer, LinkApp, storeIconCss } from '../ContactUs/styled';
import { Wrapper, Text } from './styled';
const env = process.env;
//const env = runtimeEnv();

const LogoutDifiRedirect: FC = () => {
  const { t } = useGlobalTranslation('scatteetaten');
  const history = useHistory();
  const SCATTETATEN_ENABLED = env.REACT_APP_SCATTETATEN_ENABLED;

  if (!/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    history.push(SCATTETATEN_ENABLED === 'true' ? ROUTE_ALTINN_INDEX : ROUTE_TEST_SKATTEETATEN);

    return null;
  }

  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return (
      <Wrapper>
        <Logo />
        <Text>{t('success')}</Text>
        <Button
          onClick={() => {
            history.push(SCATTETATEN_ENABLED === 'true' ? ROUTE_ALTINN_INDEX : ROUTE_TEST_SKATTEETATEN);
          }}
        >
          {t('button')}
        </Button>
        <ActionContainer>
          <LinkApp href={GOOGLE_PLAY} target={'_blank'}>
            <Icon icon={googleIcon} css={storeIconCss} />
          </LinkApp>
          <LinkApp href={APP_STORE} target={'_blank'}>
            <Icon icon={appStoreIcon} css={storeIconCss} />
          </LinkApp>
        </ActionContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Spinner blue />
    </Wrapper>
  );
};

export default LogoutDifiRedirect;

import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { modalsSlice } from 'data/store/modalReducer';
import useSubscribeCheck from 'hooks/useSubscribeCheck';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import plusIcon from 'components/svg/plus.svg?sprite';
import serverIcon from 'components/svg/server.svg?sprite';
import documentIcon from 'components/svg/document.svg?sprite';
import duplicateIcon from 'components/svg/duplicate.svg?sprite';
import documentsIcon from 'components/svg/documents.svg?sprite';
import { StyledPopup, StyledText, StyledSelect, StyledItem, iconCss } from './styled';

const AddButton = () => {
  const { t } = useGlobalTranslation('pages');
  const { g } = useGlobalTranslation();
  const { openManualBilag, openRentContract, openIncomeBilag, openDepreciation } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const subscribeCheck = useSubscribeCheck();

  return (
    <StyledPopup
      variant={'top'}
      button={(onClick) => (
        <StyledSelect variant={'fill'} onClick={onClick}>
          <StyledText>
            <Icon icon={plusIcon} css={iconCss} />
            {t('dashboard.addNewDocument')}
          </StyledText>
        </StyledSelect>
      )}
    >
      <StyledItem
        variant={'text'}
        color={'black01'}
        onClick={() => {
          subscribeCheck() && dispatch(openDepreciation());
        }}
      >
        <Icon icon={serverIcon} css={iconCss} />
        {g('deprication')}
      </StyledItem>
      <StyledItem variant={'text'} color={'black01'} onClick={() => dispatch(openRentContract())}>
        <Icon icon={documentIcon} css={iconCss} />
        {g('rentContract')}
      </StyledItem>
      <StyledItem variant={'text'} color={'black01'} onClick={() => subscribeCheck() && dispatch(openIncomeBilag())}>
        <Icon icon={duplicateIcon} css={iconCss} />
        {g('incomeBilag')}
      </StyledItem>
      <StyledItem variant={'text'} color={'black01'} onClick={() => subscribeCheck() && dispatch(openManualBilag())}>
        <Icon icon={documentsIcon} css={iconCss} />
        {g('manualBilag')}
      </StyledItem>
    </StyledPopup>
  );
};

export default AddButton;

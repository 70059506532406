import styled, { css } from 'styled-components';
import { hexColorOpacity } from '../../../../helpers/color';
import BaseButton from '../../../controls/Button';

type ItemPropsT = {
  grid?: number;
};

export const Title = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
`;

export const Item = styled.div<ItemPropsT>`
  display: grid;
  gap: 10px;
  grid-column: ${({ grid }) => (grid ? '1/3' : 'initial')};
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray03};
  margin: 0;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const modalContentCss = css`
  max-width: 756px;
  width: 100%;
`;

export const Content = styled.div`
  margin-top: 35px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px 61px;
`;

export const Divider = styled.div`
  margin: 30px 0;
  border-bottom: 1px solid ${({ theme }) => hexColorOpacity(theme.colors.gray03, 0.5)};
`;

export const ImageContainer = styled.a`
  display: block;
  position: relative;
  width: 115px;
  height: 72px;
`;

export const iconCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const FilesList = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 10px;
`;

export const Actions = styled.div`
  margin-top: 50px;
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
`;

export const Block = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 50px;
`;

export const Button = styled(BaseButton)`
  text-transform: initial;
  font-size: 14px;
  line-height: 1.29;
`;

export const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.colors.red01};
`;

export const iconButtonCss = css`
  fill: currentColor;
  width: 24px;
`;

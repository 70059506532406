import { DELETE_PROFILE, FETCH_PROFILE } from 'constant/apiRoutes';
import { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import { httpWithAuth } from 'data/driver/Api';
import { formatDateForApi } from 'helpers/date';

export const updateProfile = async (formData: ProfileFormT) => {
  const { data } = await httpWithAuth.put(FETCH_PROFILE, {
    firstName: formData.firstName,
    lastName: formData.lastName,
    birthday: formData.birthday ? formatDateForApi(formData.birthday) : undefined,
    phone: formData.phone || undefined,
    phone_code: formData.phone_code || undefined,
    iban: formData.iban || undefined,
    country: formData.country || undefined,
    address: formData.address || undefined,
    city: formData.city || undefined,
    postcode: formData.postcode || undefined,
    ssn: formData.ssn || undefined,
    isAgreePolicy: formData.isAgreePolicy || undefined
  });

  return data;
};

export const deleteProfileApi = async () => {
  await httpWithAuth.delete(DELETE_PROFILE);
};

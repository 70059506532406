import styled from 'styled-components';

export const Text = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.gray01};
  text-align: center;
`;

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: repeat(1, 300px);
  margin-top: 20px;
`;

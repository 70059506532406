import React, { Dispatch, FC, SetStateAction } from 'react';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Input from 'components/controls/Input/Input';
import Select from 'components/FormControls/Select';
import DateField from 'components/controls/DateField';
import { useForm, FormProvider } from 'react-hook-form';
import { ContractFormData, INITIAL_CONTRACT_FORM_DATA } from 'domain/entity/rentContract/RentContract';
import { yupResolver } from '@hookform/resolvers/yup';
import { informAboutTenant } from 'data/validations/contractValidation';
import { StepEnum } from '../data';
import { Title, Content, StyledContent } from '../LandlordInformation/styled';
import { ActionsButton, StyledButton } from '../HouseInfo/styled';
import { useData } from '../index';
import PhoneField from '../../../../components/controls/PhoneField';
import { useAppSelector } from '../../../../hooks/redux';
import i18n from 'i18next';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const InformAboutTenant: FC<PropsT> = (props) => {
  const { setStep } = props;
  const { dataRentContract, setContractValues } = useData();
  const { countryOptions, nationalityOptions, noNationalityOptions, noCountryOptions } = useAppSelector(
    (state) => state.contract
  );
  const formData = useForm<ContractFormData>({
    resolver: yupResolver(informAboutTenant),
    defaultValues: { ...INITIAL_CONTRACT_FORM_DATA(), ...dataRentContract }
  });
  const {
    register,
    getValues,
    control,
    formState: { errors },
    handleSubmit
  } = formData;
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const language = i18n.language;

  const onSubmit = (data: ContractFormData) => {
    setContractValues(data);
    setStep(StepEnum.RENT_CONTRACT);
  };

  const handleBackClick = () => {
    setContractValues(getValues());
    setStep(StepEnum.HOUSE_INFO);
  };

  return (
    <>
      <FormProvider {...formData}>
        <Content>
          <Title>{f('informAboutTenant')}</Title>
          <StyledContent>
            <Input
              maxLength={50}
              register={register('tenant_first_name')}
              withoutPrompt
              variant={'outline'}
              label={f('tenantFirstName')}
              required
              error={errors.tenant_first_name}
            />
            <Input
              maxLength={50}
              register={register('tenant_last_name')}
              withoutPrompt
              variant={'outline'}
              label={f('tenantLastName')}
              required
              error={errors.tenant_last_name}
            />
            <PhoneField
              initialValue={`${dataRentContract.tenant_phone_code || ''}${dataRentContract.tenant_phone || ''}`}
              error={errors.tenant_phone}
              required
              type={'tel'}
              withoutPrompt
              variant={'outline'}
              label={f('phone')}
              namePhone={'tenant_phone'}
              namePhoneCode={'tenant_phone_code'}
            />
            <Input
              register={register('tenant_email')}
              withoutPrompt
              variant={'outline'}
              label={f('email')}
              required
              error={errors.tenant_email}
            />
            <DateField
              register={register('tenant_birthday')}
              value={getValues('tenant_birthday')}
              variant={'outline'}
              label={f('dateOfBirth')}
              withoutPrompt
              error={errors.tenant_birthday}
              required
            />
            <Select
              control={control}
              name={'tenant_nationality'}
              label={f('nationality')}
              options={language === 'en' ? nationalityOptions : noNationalityOptions}
              isRequired
              error={errors.tenant_nationality}
            />
            <Select
              control={control}
              name={'tenant_country'}
              label={f('country')}
              options={language === 'en' ? countryOptions : noCountryOptions}
              isRequired
              error={errors.tenant_country}
            />
            <Input
              maxLength={60}
              register={register('tenant_city')}
              withoutPrompt
              variant={'outline'}
              label={f('city')}
              required
              error={errors.tenant_city}
            />
            <Input
              maxLength={99}
              register={register('tenant_address')}
              withoutPrompt
              variant={'outline'}
              label={f('address')}
              required
              error={errors.tenant_address}
            />
            <Input
              maxLength={50}
              register={register('tenant_postcode')}
              withoutPrompt
              variant={'outline'}
              label={f('postcode')}
              required
              error={errors.tenant_postcode}
            />
            <div />
          </StyledContent>
          <ActionsButton>
            <StyledButton type={'button'} variant={'outline'} onClick={handleBackClick}>
              {a('back')}
            </StyledButton>
            <StyledButton type={'button'} variant={'fill'} onClick={handleSubmit(onSubmit)}>
              {t('next')}
            </StyledButton>
          </ActionsButton>
        </Content>
      </FormProvider>
    </>
  );
};

export default InformAboutTenant;

import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InformAboutTenantFormData } from 'domain/entity/rentContract/RentContract';
import { informAboutTenant } from 'data/validations/contractValidation';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppSelector } from 'hooks/redux';
import Select from 'components/FormControls/Select';
import Input from 'components/controls/Input/Input';
import DateField from 'components/controls/DateField';
import { StepEnum } from 'components/layouts/Modals/NewRentContract/View/data';
import { useData } from 'components/layouts/Modals/NewRentContract/View';
import PhoneField from 'components/controls/PhoneField';
import { Span, Content, StyledButton, TwoActionsButton } from 'components/layouts/Modals/NewRentContract/styled';
import i18n from 'i18next';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const InformAboutTenant: FC<PropsT> = (props) => {
  const { data, setIsDirty, setValues } = useData();
  const { t: f } = useGlobalTranslation('fields');
  const { t } = useGlobalTranslation();
  const { setStep } = props;
  const language = i18n.language;
  const { countryOptions, nationalityOptions, noCountryOptions, noNationalityOptions } = useAppSelector(
    (state) => state.contract
  );
  const formData = useForm<InformAboutTenantFormData>({
    resolver: yupResolver(informAboutTenant),
    defaultValues: data
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
    control
  } = formData;

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [isDirty]);

  return (
    <FormProvider {...formData}>
      <Span />
      <Content>
        <Input
          register={register('tenant_first_name')}
          withoutPrompt
          variant={'outline'}
          label={f('tenantFirstName')}
          required
          error={errors.tenant_first_name}
        />
        <Input
          register={register('tenant_last_name')}
          withoutPrompt
          variant={'outline'}
          label={f('tenantLastName')}
          required
          error={errors.tenant_last_name}
        />
        <PhoneField
          error={errors.tenant_phone}
          required
          type={'tel'}
          withoutPrompt
          variant={'outline'}
          label={f('phone')}
          namePhone={'tenant_phone'}
          namePhoneCode={'tenant_phone_code'}
          initialValue={`${data.tenant_phone_code || ''}${data.tenant_phone || ''}`}
        />
        <Input
          register={register('tenant_email')}
          withoutPrompt
          variant={'outline'}
          label={f('email')}
          required
          error={errors.tenant_email}
        />
        <DateField
          register={register('tenant_birthday')}
          value={getValues('tenant_birthday')}
          variant={'outline'}
          label={f('dateOfBirth')}
          withoutPrompt
          error={errors.tenant_birthday}
          required
        />
        <Select
          control={control}
          name={'tenant_nationality'}
          label={f('nationality')}
          options={language === 'en' ? nationalityOptions : noNationalityOptions}
          isRequired
          isSearchable
          error={errors.tenant_nationality}
        />
      </Content>
      <Content>
        <Select
          control={control}
          name={'tenant_country'}
          label={f('country')}
          options={language === 'en' ? countryOptions : noCountryOptions}
          isRequired
          isSearchable
          error={errors.tenant_country}
        />
        <Input
          register={register('tenant_city')}
          withoutPrompt
          variant={'outline'}
          label={f('city')}
          required
          error={errors.tenant_city}
        />
        <Input
          register={register('tenant_address')}
          withoutPrompt
          variant={'outline'}
          label={f('address')}
          required
          error={errors.tenant_address}
        />
        <Input
          register={register('tenant_postcode')}
          withoutPrompt
          variant={'outline'}
          label={f('postcode')}
          required
          error={errors.tenant_postcode}
        />
      </Content>
      <TwoActionsButton>
        <StyledButton
          type={'button'}
          variant={'outline'}
          onClick={() => {
            setValues(getValues());
            setStep(StepEnum.RENT_CONTRACT);
          }}
        >
          {f('back')}
        </StyledButton>
        <StyledButton
          type={'button'}
          variant={'fill'}
          onClick={handleSubmit((formData) => {
            setValues(formData);
            setStep(StepEnum.TENANCY_TERMS);
          })}
        >
          {t('next')}
        </StyledButton>
      </TwoActionsButton>
    </FormProvider>
  );
};

export default InformAboutTenant;

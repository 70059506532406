import styled from 'styled-components';

export const StyledDot = styled.div<{ active: boolean; blue: boolean }>`
  background-color: ${({ blue, theme }) => theme.colors[(blue && 'blue01') || 'white01']};
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: ${({ active }) => (active && 1) || 0.5};
`;

export const StyledDots = styled.div`
  display: flex;
  flex-direction: row;
`;

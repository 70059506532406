import styled, { css } from 'styled-components';
import { ThemeT } from 'context/Theme';
import { hexColorOpacity } from 'helpers/color';
import BaseButton from 'components/controls/Button';

export const DaysWeekContainer = styled.div`
  display: grid;
  justify-content: space-between;
  padding: 0 12px;
  grid-template-columns: repeat(7, 36px);
`;

export const DayWeek = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: ${({ theme }) => hexColorOpacity(theme.colors.black01, 0.6)};
  font-size: 14px;
`;

export const DaysContainer = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 3px;
  grid-template-columns: repeat(7, 24px);
  gap: 6px 16px;
`;

type DayPropsT = {
  $isThisMonth?: boolean;
  $isToday?: boolean;
  $isSelectedDay?: boolean;
};

const todayDayCss = (theme: ThemeT) => css`
  border-radius: 4px;
  background-color: ${theme.colors.gray03};
  color: ${theme.colors.white01};
`;

const selectedDayCss = (theme: ThemeT) => css`
  border: 1px solid ${theme.colors.gray03};
  border-radius: 8px;
`;

export const Day = styled(BaseButton)<DayPropsT>`
  width: 24px;
  height: 24px;
  color: ${({ theme, $isThisMonth }) => ($isThisMonth ? theme.colors.black01 : theme.colors.gray01)};

  font-size: 14px;
  font-weight: 400;
  outline: none;

  ${({ $isToday, theme }) => $isToday && todayDayCss(theme)}
  ${({ $isSelectedDay, theme }) => $isSelectedDay && selectedDayCss(theme)}
`;

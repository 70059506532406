import styled, { css } from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';
import BaseButton from 'components/controls/Button';
import { containerMixin } from '../../../mixins';
import { ThemeT } from '../../../context/Theme';
import { mq } from '../../../constant/mediaqueries';

export const Container = styled.div`
  ${containerMixin}
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  gap: 15px;

  ${mq.lowerXs} {
    grid-template-columns: 250px;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
`;

export const HeaderContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
`;

export const UserContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  width: max-content;
  justify-self: right;
`;

export const LogoutButton = styled(BaseLink)`
  height: 30px;
  width: 60px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white01};
  color: ${({ theme }) => theme.colors.blue01};
  border: 1px solid ${({ theme }) => theme.colors.blue01};
  cursor: pointer;
  font-weight: 600;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.5;
  }
`;

export const SupportLink = styled.a`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 600;
`;

export const UserName = styled.div`
  justify-self: end;

  ${mq.lowerSm} {
    font-size: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LeftBlock = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const houseSelectActive = (theme: ThemeT) => css`
  border: 1px solid ${theme.colors.blue01};
  color: ${theme.colors.blue01};
`;

export const HouseSelect = styled.div<{ isActive: boolean }>`
  display: grid;
  gap: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  color: ${({ theme }) => theme.colors.gray04};
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }

  ${({ isActive, theme }) => isActive && houseSelectActive(theme)}
`;

export const HouseData = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin: 0.5rem 0 0 0;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};

  ${mq.lowerSm} {
    font-size: 14px;
    align-items: center;
  }
`;

export const HouseName = styled.div`
  display: flex;
  align-items: center;
`;

export const houseIconCss = css`
  width: 24px;
  margin-right: 10px;
`;

export const checkIconCss = css`
  width: 24px;
  margin-right: 10px;
  fill: currentColor;
`;

export const Footer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 48px;

  ${mq.lowerSm} {
    margin-top: 20px;
  }
`;

export const Button = styled(BaseButton)`
  max-width: 280px;
`;

export const buttonCss = css`
  ${mq.lowerXs} {
    font-size: 14px;
    padding: 0 10px;
    width: 310px;
    max-width: max-content;
  }
`;

export const Link = styled.a`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
`;

export const Content = styled.div`
  display: grid;
  margin-top: 36px;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  border-radius: 3px;
  transition: border 0.4s;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.blue01};
  }
`;

export const ItemTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
`;

export const ItemSum = styled.span<{ color: 'red01' | 'green01' | 'orange01' }>`
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const LinkButton = styled.a`
  width: 320px;

  ${mq.lowerXs} {
    width: min-content;
    display: flex;
    justify-content: center;
  }
`;

import styled from 'styled-components';
import Button from '../../controls/Button';

export const Wrapper = styled.div`
  position: relative;
  min-width: 700px;
`;

export const Title = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
`;

export const BackButton = styled(Button)`
  height: 25px;
  position: absolute;
  top: 0;
`;

export const Table = styled.table`
  margin-top: 24px;
  padding-right: 48px;
  border-collapse: collapse;
  width: 100%;
`;

export const Head = styled.thead``;

export const Tr = styled.tr``;

export const HeadCell = styled.th`
  text-align: center;
  padding-bottom: 29px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const Body = styled.tbody`
  & tr:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors.gray06};
  }
`;

export const Cell = styled.td`
  width: 33%;
  padding: 12px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
`;

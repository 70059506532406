import React, { FC } from 'react';
import { APP_STORE, GOOGLE_PLAY } from 'constant/externalLinks';
import Modal from 'components/common/Modal';
import Button, { PropsT as ButtonPropsT } from 'components/controls/Button';
import Icon from 'components/common/Icon';
import googleIcon from 'components/svg/googlePlay.svg?sprite';
import appStoreIcon from 'components/svg/appStore.svg?sprite';

import {
  Title,
  Text,
  Action,
  contentCss,
  LinkApp,
  storeIconCss,
  ActionContainer,
  Container,
  StyledLogo
} from './styled';
import useGlobalTranslation from '../../../hooks/useGlobalTranlation';
import logoImg from '../../../styles/images/logo_big.png';

interface PropsT {
  isOpen?: boolean;
  title?: string;
  text?: string;
  onClose?: () => void;
  cancelButtonProps?: Omit<ButtonPropsT, 'color' | 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: () => void;
  };
}

export const ApplicationModal: FC<PropsT> = ({ isOpen, cancelButtonProps, onClose, title, text }) => {
  const handleCloseClick = () => {
    (cancelButtonProps && cancelButtonProps.onClick()) || (onClose && onClose());
  };

  return (
    <Modal
      isOpen={isOpen || false}
      onClose={handleCloseClick}
      isHiddenCloseButton
      contentCss={contentCss}
      content={(onClose) => (
        <Container>
          <StyledLogo src={logoImg} alt={'logo'} />
          {!!title && <Title>{title}</Title>}
          <Text>{text}</Text>
          <ActionContainer>
            <LinkApp href={GOOGLE_PLAY} target={'_blank'} aria-label={'Google play'}>
              <Icon icon={googleIcon} css={storeIconCss} />
            </LinkApp>
            <LinkApp href={APP_STORE} target={'_blank'} aria-label={'App store'}>
              <Icon icon={appStoreIcon} css={storeIconCss} />
            </LinkApp>
          </ActionContainer>
          <Action>
            <Button {...cancelButtonProps} onClick={onClose} variant={'text'} />
          </Action>
        </Container>
      )}
    />
  );
};

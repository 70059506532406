import React, { FC, useEffect } from 'react';
import { ROUTE_PROFILE_LANDLORD_EDIT } from 'constant/routes';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { SignedUserInfo } from 'components';
import Icon from 'components/common/Icon';
import SimplePage from 'components/common/SimplePage';
import editIcon from 'components/svg/edit_icon.svg?sprite';
import { InfoFields } from './components';
import { StyledLink, StyledLinkText, StyledLinkWrapper } from './styled';

const Landlord: FC = () => {
  const { g } = useGlobalTranlation('landlord');

  return (
    <SimplePage
      variant={'profile'}
      header={
        <>
          <SignedUserInfo sign={g('sidebar.landlord')} />
          <StyledLinkWrapper>
            <StyledLink to={ROUTE_PROFILE_LANDLORD_EDIT}>
              <Icon icon={editIcon} color={'blue01'} /> <StyledLinkText>{g('edit')}</StyledLinkText>
            </StyledLink>
          </StyledLinkWrapper>
        </>
      }
    >
      <InfoFields />
    </SimplePage>
  );
};

export default Landlord;

import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ROUTE_HOUSE_INFO } from 'constant/routes';
import HouseData from 'domain/entity/house/HouseData';
import { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import { fetchHouseUpdate } from 'data/store/houseReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getErrMsg } from 'helpers/locale';
import Icon from 'components/common/Icon';
import { Button, Input } from 'components/ui';
import DateField from 'components/controls/DateField';
import SimplePage from 'components/common/SimplePage';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import {
  StyledForm,
  StyledFormContainer,
  StyledSubmitWrapper,
  StyledLinkText,
  StyledLink,
  backIconCss
} from './styled';
import { houseSchema } from 'data/validations/houseValidation';
import Fields from '../../../../components/feature/Fields';
import { HOUSE_INFO_FIELDS } from '../../../../components/feature/Fields/fields';

const HouseInfoEdit: FC = () => {
  const { g } = useGlobalTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { selectedHouse, pending } = useAppSelector(({ house }) => house);

  const form = useForm<HouseCreateFormT>({
    resolver: yupResolver(houseSchema),
    defaultValues: {
      name: (selectedHouse && selectedHouse.name) || '',
      address: (selectedHouse && selectedHouse.address) || '',
      postcode: (selectedHouse && selectedHouse.postcode) || '',
      city: (selectedHouse && selectedHouse.city) || '',
      property_number: (selectedHouse && selectedHouse.property_number) || '',
      holding_number: (selectedHouse && selectedHouse.holding_number) || '',
      lease_number: (selectedHouse && selectedHouse.lease_number) || '',
      section_number: (selectedHouse && selectedHouse.section_number) || '',
      share_number: (selectedHouse && selectedHouse.share_number) || ''
    }
  });
  const { register, handleSubmit, formState, getValues } = form;

  const onSubmit = (data: HouseData) => {
    dispatch(fetchHouseUpdate({ ...data, id: (selectedHouse && selectedHouse.id) || 0 }));
    history.push(ROUTE_HOUSE_INFO);
  };

  return (
    <SimplePage
      header={
        <StyledLink to={ROUTE_HOUSE_INFO}>
          <Icon icon={backIcon} css={backIconCss} color={'black01'} />
          <StyledLinkText>{g('editTitles.houseInfo')}</StyledLinkText>
        </StyledLink>
      }
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContainer>
          <FormProvider {...form}>
            {selectedHouse && (
              <Fields inputProps={{ variant: 'outline', withoutPrompt: true }} fields={HOUSE_INFO_FIELDS(false)} />
            )}
          </FormProvider>
        </StyledFormContainer>
        <StyledSubmitWrapper>
          <Button isLoading={pending}>{g('save')}</Button>
        </StyledSubmitWrapper>
      </StyledForm>
    </SimplePage>
  );
};

export default HouseInfoEdit;

import styled from 'styled-components';
import BaseButton from 'components/controls/Button';

export const HelpContent = styled.div``;

export const Text = styled.div`
  padding: 20px;
  max-width: 550px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const ActionContainer = styled.div`
  width: 100%;
  display: grid;
  justify-content: end;
`;

export const Button = styled(BaseButton)``;

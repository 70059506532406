import React, { forwardRef, InputHTMLAttributes } from 'react';
import { usePinInputField } from '@chakra-ui/pin-input';
import { TextField } from './styles';

export type PropsT = InputHTMLAttributes<HTMLInputElement>;

const Field = forwardRef<HTMLInputElement, PropsT>((props, ref) => {
  const { ...restProps } = props;
  const pinInputFieldProps = usePinInputField({ ref, ...restProps });

  return <TextField {...pinInputFieldProps} />;
});

export default Field;

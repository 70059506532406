import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { HELP_INSTRUCTIONS_URL } from 'constant/externalLinks';
import AltinnLoginData from 'domain/entity/altinn/AltinLoginData';
import { altinnSlice } from 'data/store/altinnReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import BaseModal from 'components/common/Modal';
import Button from 'components/controls/Button';
import Input from 'components/controls/Input/Input';
import CodeField from 'components/controls/CodeField';
import { firstStepSchema } from './validation';
import {
  Wrapper,
  CheckBox,
  Link,
  TextInformation,
  ButtonContainer,
  modalContent,
  WrapperNextStep,
  ErrorMessage,
  RadioContainer,
  StyledRadioButton
} from './styled';

const LOGIN_ALTINN_STORAGE_NAME = 'ALTINN_LOGIN';

const MAP_STEP_TO_TITLE = ['title.stepFirst', 'title.stepSecond', 'title.stepThree'];

const Modal: FC = () => {
  const { register, control, handleSubmit, setValue } = useForm<AltinnLoginData>({
    resolver: yupResolver(firstStepSchema),
    defaultValues: { authMethod: 'SMSPin' }
  });
  const { t: actionsTranslate } = useGlobalTranslation('actions');
  const { g } = useGlobalTranslation();
  const { t: fieldsTranslate } = useGlobalTranslation('fields');
  const { t: altinnTranslate } = useGlobalTranslation('altinn');
  const [pin, setPin] = useState<string | null>(null);
  const { altinnModal, error, loginAltinnStep } = useAppSelector(({ altinn }) => altinn);
  const {
    closeAltinnModal,
    updateAltinnCredentials,
    loginAltinn,
    fetchAltinnFormData,
    nextLoginAltinnStep,
    resetLoginAltinnStep
  } = altinnSlice.actions;
  const dispatch = useAppDispatch();

  const onSubmit = (data: AltinnLoginData) => {
    if (loginAltinnStep === 0) {
      if (data.saveData) {
        localStorage.setItem(LOGIN_ALTINN_STORAGE_NAME, JSON.stringify(data));
      }
      dispatch(updateAltinnCredentials(data));
      dispatch(nextLoginAltinnStep());
      return;
    }

    if (loginAltinnStep === 1) {
      dispatch(loginAltinn(data));
      return;
    }

    if (loginAltinnStep === 2) {
      dispatch(fetchAltinnFormData({ ...data, userPinCode: pin || '' }));
    }
  };

  useEffect(() => {
    const altinnDataJSON = localStorage.getItem(LOGIN_ALTINN_STORAGE_NAME);
    const altinnData = altinnDataJSON ? JSON.parse(altinnDataJSON) : undefined;

    altinnData &&
      Object.keys(altinnData).map((key) => {
        setValue(key, altinnData[key]);
      });
  }, []);

  return (
    <BaseModal
      title={altinnTranslate(MAP_STEP_TO_TITLE[loginAltinnStep])}
      isOpen={altinnModal}
      onClose={() => {
        dispatch(closeAltinnModal());
        dispatch(resetLoginAltinnStep());
      }}
      contentCss={modalContent}
    >
      {loginAltinnStep === 0 && (
        <>
          {error && <ErrorMessage>{g(error)}</ErrorMessage>}
          <Wrapper>
            <Input
              register={register('userSSN')}
              label={fieldsTranslate('personalBirthNumber')}
              variant={'outline'}
              withoutPrompt
              required
            />
            <Input
              register={register('systemUserName')}
              label={fieldsTranslate('userName')}
              variant={'outline'}
              withoutPrompt
              additionalInformation={
                <Link href={HELP_INSTRUCTIONS_URL} target={'_blank'}>
                  {altinnTranslate('links.whereCanIFindThis')}
                </Link>
              }
              required
            />
            <Input
              register={register('systemPassword')}
              type={'password'}
              label={fieldsTranslate('altinnPassword')}
              variant={'outline'}
              withoutPrompt
              required
            />
            <CheckBox register={register('saveData')}>{fieldsTranslate('saveLoginAltinnCheckbox')}</CheckBox>
          </Wrapper>
          <TextInformation>{altinnTranslate('modalText')}</TextInformation>
          <ButtonContainer>
            <Button type={'button'} onClick={handleSubmit(onSubmit)}>
              {actionsTranslate('continue')}
            </Button>
          </ButtonContainer>
        </>
      )}
      {loginAltinnStep === 1 && (
        <WrapperNextStep>
          <RadioContainer>
            <StyledRadioButton
              control={control}
              label={altinnTranslate('radioButton.minIdSms')}
              {...register('authMethod')}
              value={'SMSPin'}
            />
          </RadioContainer>
          <RadioContainer>
            <StyledRadioButton
              control={control}
              label={altinnTranslate('radioButton.minIdCode')}
              {...register('authMethod')}
              value={'AltinnPin'}
            />
          </RadioContainer>
          <ButtonContainer>
            <Button type={'button'} onClick={handleSubmit(onSubmit)}>
              {actionsTranslate('continue')}
            </Button>
          </ButtonContainer>
        </WrapperNextStep>
      )}
      {loginAltinnStep === 2 && (
        <WrapperNextStep>
          {error && <ErrorMessage>{g(error)}</ErrorMessage>}
          <CodeField length={5} onChange={(value) => setPin(value)} />
          <ButtonContainer>
            <Button disabled={pin?.length !== 5} type={'button'} onClick={handleSubmit(onSubmit)}>
              {actionsTranslate('receiveForm')}
            </Button>
          </ButtonContainer>
        </WrapperNextStep>
      )}
    </BaseModal>
  );
};

export default Modal;

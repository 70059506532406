import React, { FC, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import { ROUTE_SUPPLIER_INFO, ROUTE_SUPPLIERS_ADD_NEW } from 'constant/routes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { supplierSlice } from 'data/store/supplierReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import { ListView } from 'components/common';
import SimpleRow from 'components/controls/SimpleRow';
import SimplePage from 'components/common/SimplePage';
import plusIcon from 'components/svg/plus.svg?sprite';
import { StyledLink, StyledLinkText, plusIconCss } from './styled';

const Suppliers: FC = () => {
  const { t } = useGlobalTranslation();
  const { suppliers } = useAppSelector(({ supplier }) => supplier);
  const { fetchSupplier, setCurrentSupplier } = supplierSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSupplier());
  }, [dispatch]);

  const history = useHistory();

  const onClickSupplier = (id: number) => {
    dispatch(setCurrentSupplier(id));
    history.push(generatePath(ROUTE_SUPPLIER_INFO, { slug: id }), { slug: id });
  };
  const list =
    suppliers &&
    suppliers.map(({ archived, name, id }) => (
      <SimpleRow
        name={name || `${t('options.supplier.unnamed')}`}
        onClick={() => onClickSupplier(id)}
        key={id}
        status={(archived && 'archived') || undefined}
      />
    ));
  return (
    <SimplePage
      header={
        <>
          <>{t('sidebar.suppliers')}</>
          <StyledLink to={ROUTE_SUPPLIERS_ADD_NEW}>
            <Icon icon={plusIcon} css={plusIconCss} />
            <StyledLinkText>{t('options.supplier.addNewSupplier')}</StyledLinkText>
          </StyledLink>
        </>
      }
    >
      <ListView what={'supplier'}>{(list?.length || 0) > 0 && list}</ListView>
    </SimplePage>
  );
};
export default Suppliers;

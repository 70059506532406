export const HOME_PAGE = '/';
export const ROUTE_DASHBOARD = '/home/dashboard';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_RESET_PASSWORD_NEW = `${ROUTE_RESET_PASSWORD}/new-password/:slug`;
export const ROUTE_LOGIN = '/login';
export const ROUTE_REGISTRATION = '/registration';
export const ROUTE_REGISTER_CONTRACT = '/register_contract';
export const ROUTE_TENANT_NOTIFICATION = '/tenant_notification';
export const ROUTE_LOGIN_TOKEN = '/login/by-url';
export const ROUTE_HOME_INDEX = '/home';
export const ROUTE_ALTINN_INDEX = '/altinn';
export const ROUTE_ALTINN_SUBSCRIPTION = '/altinn/subscription';
export const ROUTE_PROFILE_INDEX = '/profile';
export const ROUTE_PROFILE_SUBSCRIPTION = '/profile/subscription';
export const ROUTE_PROFILE_LANDLORD = '/profile/landlord';
export const ROUTE_PROFILE_LANDLORD_EDIT = '/profile/landlord/edit';
export const ROUTE_PROFILE_ACCOUNT_SETTINGS = '/profile/account-settings';
export const ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL = '/profile/account-settings/change-email';
export const ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD = '/profile/account-settings/change-password';
export const ROUTE_CONTACT_US = '/profile/contact-us';
export const ROUTE_CHANGE_EMAIL = '/change/email/:slug';
export const ROUTE_REDIRECT_TO_SIGNICAT = '/r/:type/:slug';
export const ROUTE_NOT_FOUND_PAGE = '/not-found';
export const ROUTE_LOGOUT_DIFI = '/logout/difi';
export const ROUTE_LOGOUT_DIFI_REDIRECT = '/logout-difi';
export const ROUTE_PROCESS_DIFI_TOKEN = '/signin-difi';
export const ROUTE_LOGIN_DIFI = '/login-signin-difi';
export const ROUTE_TEST_SKATTEETATEN = '/skatteetaten';
export const ROUTE_ERROR_LIST = '/error-list';

export const ROUTE_HOUSE_INFO = '/home/house-info';
export const ROUTE_HOUSE_INFO_EDIT = '/home/house-info/edit';
export const ROUTE_RENT_CONTRACT = '/home/contract';
export const ROUTE_RENT_CONTRACT_INFO = '/home/contract/:slug';
export const ROUTE_DETAILED_COST = '/home/detailed-costs';
export const ROUTE_SUPPLIERS = '/home/suppliers';
export const ROUTE_SUPPLIERS_ADD_NEW = '/home/suppliers/add-new';
export const ROUTE_SUPPLIER_INFO = '/home/suppliers/:slug';
export const ROUTE_SUPPLIER_INFO_EDIT = '/home/suppliers/edit/:slug';
export const ROUTE_FURNITURE = '/home/furniture-and-home-contents';
export const ROUTE_FURNITURE_ITEM = '/home/furniture-and-home-contents/:slug';
export const ROUTE_SEPARATE_INCOME_BILAGS = '/home/separate-income-bilags';
export const ROUTE_SEPARATE_INCOME_BILAGS_ITEM = '/home/separate-income-bilags/:slug';
export const ROUTE_TRASH_FOLDER = '/home/trash-folder';
export const ROUTE_TRASH_FOLDER_ITEM = '/home/trash-folder/:slug';

export const PRIVATE_ROUTES = [
  ROUTE_PROFILE_INDEX,
  ROUTE_PROFILE_SUBSCRIPTION,
  ROUTE_ALTINN_INDEX,
  ROUTE_HOME_INDEX,
  ROUTE_HOUSE_INFO,
  ROUTE_REGISTER_CONTRACT
];

export const PUBLIC_ROUTES = [
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD,
  ROUTE_RESET_PASSWORD_NEW,
  ROUTE_LOGIN_TOKEN,
  ROUTE_CHANGE_EMAIL,
  ROUTE_REGISTRATION
];

export const EXTERNAL_PRIVACY_POLICY = 'https://www.termsfeed.com/live/fbe6b5bb-c000-45d8-a9b5-0699393eec9f';
export const EXTERNAL_TERMS_OF_USE = 'https://www.termsfeed.com/live/0d38c011-9567-4f0b-b74c-1f5208f425b1';
export const EXTERNAL_GENERATE_PDF = (contractId: number) => `/contract/${contractId}/generate-pdf`;

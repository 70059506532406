import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
`;

export const StyledDescriptionContainer = styled.div`
  grid-column: 1/3;
`;

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: 278px;
  margin-top: 48px;
`;

import axios from 'axios';

export default class ImageReader {
  public static readAsDataURL(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        const { target } = e;

        if (target && target.result) {
          return resolve(String(target.result));
        }

        reject();
      };

      reader.readAsDataURL(file);
    });
  }

  public static readAsBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { responseType: 'blob' })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}

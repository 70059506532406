import * as Yup from 'yup';

export const releaseDepositSchema = Yup.object().shape({
  category: Yup.string(),
  amount_tenant: Yup.string().when('category', {
    is: 'SPLIT',
    then: Yup.string().required()
  }),
  amount_landlord: Yup.string().when('category', {
    is: 'SPLIT',
    then: Yup.string().required()
  })
});

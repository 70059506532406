import * as Yup from 'yup';
import { checkDataRengeDate, checkPhone } from 'helpers/validation';

export const tenantSchema = Yup.object({
  tenant_first_name: Yup.string().required().max(50),
  tenant_last_name: Yup.string().required().max(50),
  tenant_birthday: Yup.string().required(),
  tenant_phone_code: Yup.string(),
  tenant_phone: Yup.string()
    .when('tenant_phone_code', {
      is: (value: string) => Boolean(value),
      then: Yup.string().when('tenant_phone', {
        is: (endDate: string) => !!endDate,
        then: Yup.string().when(['tenant_phone', 'tenant_phone_code'], {
          is: checkPhone,
          then: (field) => field,
          otherwise: Yup.string().max(1, '123123')
        })
      })
    })
    .required(),
  // nationality: Yup.string(),
  address: Yup.string().required(),
  tenant_email: Yup.string().email().required().max(50)
});

export const rentContractSchema = Yup.object({
  houseId: Yup.string().required(),
  type: Yup.string().required(), // running, time-bound
  start_date: Yup.string()
    .required()
    .when('type', {
      is: 'time-bound',
      then: Yup.string().when('start_date', {
        is: (endDate: string) => !!endDate,
        then: Yup.string().when(['start_date', 'end_date'], {
          is: checkDataRengeDate,
          then: (field) => field,
          otherwise: Yup.string().max(1, '123123')
        })
      })
    }),
  end_date: Yup.string().when('type', {
    is: 'time-bound',
    then: Yup.string()
      .required()
      .when(['start_date', 'end_date'], {
        is: checkDataRengeDate,
        then: (field) => field,
        otherwise: Yup.string().max(1, '123123')
      })
  }), // required if time-bound
  month_resignation: Yup.number().required(),
  month_sum: Yup.number().required(),
  first_payment_date: Yup.string().required(),
  taxable: Yup.boolean().required(),
  keys_count: Yup.number().required(),
  comforts: Yup.array().required(),
  pets_allowed: Yup.boolean().required(),
  smoking_allowed: Yup.boolean().required(),
  other: Yup.string().max(1000)
  //deposit_account: Yup.string().required(),
  //payment_deadline: Yup.string().required()
  //open_deposit_account: Yup.boolean().required()
});

const schema = Yup.object({
  rentContractSchema,
  tenantSchema
});

export default schema;

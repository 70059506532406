import React, { FC } from 'react';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Modal from 'components/common/Modal';
import View from './View';

const AddHouse: FC = () => {
  const { t } = useGlobalTranslation('titles');
  const { addHouse } = useAppSelector((state) => state.modals);
  const { closeAddHouse } = modalsSlice.actions;
  const dispatch = useAppDispatch();

  return (
    <Modal isOpen={addHouse} title={t('newHouse')} onClose={() => dispatch(closeAddHouse())}>
      <View />
    </Modal>
  );
};

export default AddHouse;

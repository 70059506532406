import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
//Constants
import {
  ROUTE_CHANGE_EMAIL,
  ROUTE_LOGIN,
  ROUTE_LOGIN_TOKEN,
  ROUTE_REGISTRATION,
  ROUTE_RESET_PASSWORD,
  ROUTE_RESET_PASSWORD_NEW
} from 'constant/routes';
// Containers
import { ChangeEmail, Login, LoginByUrl, ResetPassword, ResetPasswordNew } from 'containers';
// Components
import { CloseButton } from 'components/layouts/PublicLayout/components';
import RegistrationClose from 'components/layouts/Modals/RegistrationClose';
// Images
import welcomeImg from 'components/svg/welcome.svg?sprite';
// Styled
import Registration from 'containers/Registration';
import {
  StyledContainer,
  StyledRight,
  StyledLeft,
  StyledBgImage,
  StyledLogoWrapper,
  StyledLogo,
  StyledLangSwitcherContainer,
  welcomeIconCss
} from './styled';
import LangSwitcher from 'components/layouts/MainLayout/LangSwitcher';
import Icon from '../../common/Icon';
import { contractSlice } from '../../../data/store/rentContractReducer';
import { useAppDispatch } from '../../../hooks/redux';

const PublicLayout = () => {
  const { getCountryList } = contractSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  return (
    <StyledContainer>
      <StyledLeft>
        <StyledLogoWrapper>
          <StyledLogo />
        </StyledLogoWrapper>
        <StyledBgImage>
          <Icon icon={welcomeImg} css={welcomeIconCss} />
        </StyledBgImage>
      </StyledLeft>
      <StyledRight>
        <CloseButton />
        <StyledLangSwitcherContainer>
          <LangSwitcher />
        </StyledLangSwitcherContainer>
        <Switch>
          <Route path={ROUTE_LOGIN_TOKEN} exact component={LoginByUrl} />
          <Route path={ROUTE_LOGIN} exact component={Login} />
          <Route path={ROUTE_RESET_PASSWORD} exact component={ResetPassword} />
          <Route path={ROUTE_RESET_PASSWORD_NEW} exact component={ResetPasswordNew} />
          <Route path={ROUTE_CHANGE_EMAIL} exact component={ChangeEmail} />
          <Route path={ROUTE_REGISTRATION} exact component={Registration} />
          {/*<Route path={ROUTE_RESET_PASSWORD_NEW} exact component={Dashboard}/>*/}
        </Switch>
      </StyledRight>
      <RegistrationClose />
    </StyledContainer>
  );
};

export default PublicLayout;

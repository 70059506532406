import React, { FC } from 'react';
import Icon from 'components/common/Icon';
import searchIcon from 'components/svg/search.svg?sprite';
import { Wrapper, bigSearchIconCss, Title, Text } from './styled';

const NoSearched: FC = () => {
  return (
    <Wrapper>
      <Icon icon={searchIcon} css={bigSearchIconCss} />
      <Title>There is nothing found that corresponds to your request</Title>
      <Text>Verify your filters and try again</Text>
    </Wrapper>
  );
};

export default NoSearched;

import styled from 'styled-components';

type WrapperPropsT = {
  isRevert?: boolean;
};

export const Wrapper = styled.label<WrapperPropsT>`
  position: relative;
  display: flex;
  justify-content: ${({ isRevert }) => (isRevert ? 'revert' : 'space-between')};
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  cursor: pointer;
  padding: 12px 12px 12px 0;
`;

export const Label = styled.span``;

type CustomRadioPropsT = {
  isSelected?: boolean;
};

export const CustomRadio = styled.span<CustomRadioPropsT>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${({ theme, isSelected }) => theme.colors[isSelected ? 'blue01' : 'gray01']};
  transition: border 0.4s;
  margin: 0 15px;
`;

export const Checked = styled.span<CustomRadioPropsT>`
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${({ theme, isSelected }) => theme.colors[isSelected ? 'blue01' : 'transparent']};
  border-radius: 50%;
  transition: background-color 0.4s;
`;

export const Input = styled.input`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
`;

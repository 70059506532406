import dayjs from 'dayjs';
import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import { profileSlice } from '../../../../../data/store/profileReducer';
import { ProfileFormT } from '../../../../../domain/entity/profile/ProfileFormData';
import { Content, ActionsButton, ErrorMessage, Text } from './styled';
import { modalsSlice } from '../../../../../data/store/modalReducer';
import { profileSchema } from '../../../../../data/validations/profileValidation';
import Fields from '../../../../feature/Fields';
import { PROFILE_FIELDS } from '../../../../feature/Fields/fields';

const View: FC = () => {
  const { profileUpdate } = profileSlice.actions;
  const { closeUpdatePhoneNumber } = modalsSlice.actions;
  const { error, user } = useAppSelector((state) => state.profile);
  const { countryOptions } = useAppSelector((state) => state.contract);
  const formData = useForm<ProfileFormT>({
    resolver: yupResolver(profileSchema),
    defaultValues: { ...user, birthday: user?.birthday ? dayjs(new Date(user.birthday)).format('DD.MM.YYYY') : '' }
  });
  const { handleSubmit } = formData;
  const { g } = useGlobalTranslation();
  const dispatch = useAppDispatch();

  const onSubmit = (data: ProfileFormT) => {
    dispatch(profileUpdate(data));
    dispatch(closeUpdatePhoneNumber());
  };

  return (
    <FormProvider {...formData}>
      <Content>
        <Fields fields={PROFILE_FIELDS(user, countryOptions)} inputProps={{ variant: 'default' }} />
      </Content>
      <Text>{g('updatePhoneNumber2.text')}</Text>
      <ErrorMessage>{error}</ErrorMessage>
      <ActionsButton>
        <Button onClick={handleSubmit(onSubmit)}>{g('updatePhoneNumber.save')}</Button>
      </ActionsButton>
    </FormProvider>
  );
};

export default View;

import React, { FC, useState } from 'react';
import Modal from 'components/common/Modal';
import { IncomeBilagFormData, InitialIncomeBilagData } from 'domain/entity/IncomeBilag/IncomeBilagFormData';
import { modalsSlice } from 'data/store/modalReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import View from './View';
import { getIncomeBilagToIncomeBilagData } from './View/data';

type PropsT = {
  index?: number;
};

const IncomeBilag: FC<PropsT> = () => {
  const { selectedHouse } = useAppSelector((state) => state.house);
  const initialValues: IncomeBilagFormData = {
    ...InitialIncomeBilagData,
    houseId: Number(selectedHouse?.id)
  };
  const { t } = useGlobalTranslation('titles');
  const dispatch = useAppDispatch();
  const {
    incomeBilag,
    bilagInfo: { currentBilag }
  } = useAppSelector((state) => state.modals);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { closeIncomeBilag, openInfoBilag, openDiscardChange } = modalsSlice.actions;

  const closeModal = () => {
    dispatch(closeIncomeBilag());

    if (currentBilag?.type) {
      dispatch(openInfoBilag());
    }
  };

  return (
    <Modal
      isOpen={incomeBilag}
      onClose={() => {
        isDirty ? dispatch(openDiscardChange(closeModal)) : closeModal();
      }}
      withoutAnimation={isDirty}
      title={t('incomeBilag')}
    >
      <View
        setIsDirty={setIsDirty}
        defaultValues={
          currentBilag?.type === 'income-bilag' ? getIncomeBilagToIncomeBilagData(currentBilag.bilag) : initialValues
        }
      />
    </Modal>
  );
};

export default IncomeBilag;

import React, { FC, ReactNode, ReactNodeArray } from 'react';
import { StyledContainer, StyledContent, StyledHeader } from './styled';
import { Spinner } from '../../ui';

type Props = {
  header?: ReactNode | ReactNodeArray;
  variant?: 'profile';
  pending?: false;
};
const SimplePage: FC<Props> = ({ header, children, variant, pending }) => {
  return (
    <StyledContainer>
      {(pending && <Spinner blue />) || (
        <>
          {header && <StyledHeader variant={variant}>{header}</StyledHeader>}
          <StyledContent>{children}</StyledContent>
        </>
      )}
    </StyledContainer>
  );
};

export default SimplePage;

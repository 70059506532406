export type ProfileFormT = {
  firstName: string;
  lastName: string;
  birthday: string;
  iban: string;
  phone: string;
  phone_code: string;
  email?: string;
  ssn?: string;
  country?: string;
  city?: string;
  address?: string;
  postcode?: string;
  isAgreePolicy?: boolean;
};

const INITIAL_PROFILE_DATA: ProfileFormT = {
  firstName: '',
  lastName: '',
  birthday: '',
  iban: '',
  phone: '',
  phone_code: '',
  email: '',
  ssn: '',
  country: 'NO',
  city: '',
  address: '',
  postcode: '',
  isAgreePolicy: false
};

export default INITIAL_PROFILE_DATA;

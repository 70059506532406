import React, { FC, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import { ROUTE_RENT_CONTRACT_INFO } from 'constant/routes';
import { modalsSlice } from 'data/store/modalReducer';
import { houseSelector } from 'data/store/houseReducer';
import { contractSlice } from 'data/store/rentContractReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import { ListView } from 'components/common';
import SimpleRow from 'components/controls/SimpleRow';
import SimplePage from 'components/common/SimplePage';
import plusIcon from 'components/svg/plus.svg?sprite';
import { StyledButton, plusIconCss } from './styled';

const RentContracts: FC = () => {
  const { g } = useGlobalTranlation();
  const { selectedHouse } = useAppSelector(houseSelector);
  const { contractList } = useAppSelector(({ contract }) => contract);
  const { documents } = useAppSelector(({ documents }) => documents);
  const { openRentContract } = modalsSlice.actions;
  const { fetchContracts, setCurrentContract } = contractSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchContracts());
  }, [dispatch, documents?.length]);

  const history = useHistory();

  const onClickContract = (id: number) => {
    dispatch(setCurrentContract(id));
    history.push(generatePath(ROUTE_RENT_CONTRACT_INFO, { slug: id }), { slug: id });
  };

  const list =
    (contractList &&
      contractList?.length > 0 &&
      selectedHouse &&
      contractList
        .filter(({ houseId }) => houseId === selectedHouse.id)
        .map(({ status, tenant_first_name, tenant_last_name, id, isFinished }) => (
          <SimpleRow
            key={id}
            name={`${tenant_first_name} ${tenant_last_name}`}
            status={isFinished ? 'finished' : status}
            onClick={() => onClickContract(id)}
          />
        ))) ||
    [];

  return (
    <SimplePage
      header={
        <>
          {g('sidebar.rentContracts')}
          <StyledButton onClick={() => dispatch(openRentContract())} variant={'text'}>
            <Icon icon={plusIcon} css={plusIconCss} />
            {g('addNewRentContract')}
          </StyledButton>
        </>
      }
    >
      <ListView what={'rentContract'}>{list?.length > 0 && list}</ListView>
    </SimplePage>
  );
};

export default RentContracts;

import styled, { css, CSSProp } from 'styled-components';
import Button from '../../controls/Button';
import { mq } from '../../../constant/mediaqueries';

const hiddenWrapperAnimation = css`
  animation: hiddenBlock 0.3s ease;
`;

type WrapperPropsT = {
  isHidden: boolean;
  top: number;
};

export const Wrapper = styled.div<WrapperPropsT>`
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100vh;
  z-index: 999;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;

  ${({ isHidden }) => isHidden && hiddenWrapperAnimation}

  @keyframes hiddenBlock {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const Fade = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(78, 78, 78, 0.22);
  animation: fadeBlock 0.25s ease;

  ${mq.lowerXs} {
    background-color: #ffffff;
  }

  @keyframes fadeBlock {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

type ContentPropsT = {
  css?: CSSProp;
};

export const Content = styled.div<ContentPropsT>`
  position: relative;
  z-index: 1;
  max-width: 800px;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.white01};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 41px 48px 48px;
  animation: topShow 0.3s ease;

  ${({ css }) => css && css}

  ${mq.lowerXs} {
    padding: 20px 20px 20px;
    box-shadow: none;
  }

  ${mq.greaterXs} {
    margin-top: 90px;
    margin-bottom: 20px;
  }

  @keyframes topShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Title = styled.p`
  text-align: center;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  margin: 0;
`;

export const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.gray03};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const ActionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: 48px;
  margin-top: 20px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const ButtonClose = styled(Button)`
  position: absolute;
  z-index: 3;
  top: 30px;
  right: 30px;
  height: 24px;

  ${mq.lowerXs} {
    top: 10px;
    right: 10px;
  }
`;

export const closeIconCss = css`
  width: 24px;
`;

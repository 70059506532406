import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { EXTERNAL_GENERATE_PDF, ROUTE_RENT_CONTRACT } from 'constant/routes';
import { API_URL, httpWithAuth } from 'data/driver/Api';
import { confirmationSlice } from 'data/store/confirmationReducer';
import { contractSlice, fetchFinishContract, closeDeposit } from 'data/store/rentContractReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { BackButton } from 'components/ui';
import SimplePage from 'components/common/SimplePage';
import { TabSwitch } from 'components/controls/TabSwitch';
import ContractInfo from './ContractInfo';
import { makeDownloadFromUrl } from './helpers';
import SignatureModal from './SignatureModal';
import {
  Row,
  StyledActionContainer,
  StyledButton,
  StyledSwitchWrapper,
  StyledTabContainer,
  LeftBlock,
  Status,
  TitleText
} from './styled';
import { GENERATE_CHECKLIST_PDF } from '../../../../constant/apiRoutes';
import { modalsSlice } from '../../../../data/store/modalReducer';
import { Buttons } from './Buttons';

export const RentContractInfo: FC = () => {
  const { currentContract, contractList, pending } = useAppSelector(({ contract }) => contract);
  const { fetchContracts, setCurrentContract, signContractByBankID, deletePayContract, fetchContract } =
    contractSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { openRentContract, openReleaseDeposit, openPictureModal } = modalsSlice.actions;
  const { slug } = useParams<{ slug: string }>();
  const { g, t } = useGlobalTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const tenantFullName =
    (currentContract && `${currentContract.tenant_first_name} ${currentContract.tenant_last_name}`) || '';
  useEffect(() => {
    if (!contractList?.length) {
      dispatch(fetchContracts());
    }
  }, []);

  useEffect(() => {
    if (contractList?.length && contractList?.some(({ id }) => id === Number(slug))) {
      dispatch(fetchContract(Number(slug)));
    }
  }, [contractList]);

  const [selected, setSelected] = useState<string>();
  const [isOpenSignatureModal, setIsOpenSignatureModal] = useState(false);

  const onClickSignature = () => {
    setIsOpenSignatureModal(true);
  };
  const onClickPictures = () => {
    dispatch(openPictureModal());
  };

  const onClickSignatureBankId = (checkList?: boolean) => {
    dispatch(signContractByBankID({ id: Number(slug), checkList }));
  };

  const onClickPdf = () => {
    if (currentContract)
      httpWithAuth
        .get(API_URL + EXTERNAL_GENERATE_PDF(currentContract.id))
        .then((res) => res.status === 200 && makeDownloadFromUrl(tenantFullName, res.data.url));
  };

  const onClickChecklistPdf = () => {
    if (currentContract)
      httpWithAuth
        .get(API_URL + GENERATE_CHECKLIST_PDF(currentContract.id))
        .then((res) => res.status === 200 && makeDownloadFromUrl(tenantFullName, res.data.url, 'checklist'));
  };

  useEffect(() => {
    if (!currentContract && slug) {
      dispatch(fetchContracts());
    }
  }, []);

  useEffect(() => {
    if (!currentContract && slug) {
      dispatch(fetchContract(Number(slug)));
    }
  }, [currentContract]);

  const tabs = [
    { value: 'contractInfo' },
    { value: 'paymentData', isComing: true },
    { value: 'increaseRent', isComing: true }
  ];

  const status = (() => {
    if (currentContract?.signicatInfo?.tenantLink && !currentContract?.signicatInfo?.isSignedTenant) {
      return g('contract.status.signingTenant');
    }
    if (
      currentContract?.signicatInfo?.isSignedTenant &&
      currentContract?.is_open_deposit_account &&
      !currentContract?.signicatInfo.depositId
    ) {
      return g('contract.status.signedTenantAndDepositCreated');
    }
    if (currentContract?.signicatInfo?.isSignedTenant) {
      return g('contract.status.signedTenant');
    }
  })();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const payStatus = urlParams.get('pay');
    if (location.search) {
      history.replace(location.pathname);
    }
    if (payStatus === 'success') {
      dispatch(
        setConfirmationData({
          show: true,
          onClose: () => {
            dispatch(resetConfirmationData());
          },
          confirmButtonProps: {
            onClick: () => {
              dispatch(resetConfirmationData());
            },
            children: g('alerts.successPay.ok')
          },
          text: g('alerts.successPay.message'),
          title: g('alerts.successPay.title')
        })
      );
    }
    if (payStatus === 'fail') {
      dispatch(
        setConfirmationData({
          show: true,
          onClose: () => {
            dispatch(resetConfirmationData());
          },
          confirmButtonProps: {
            onClick: () => {
              dispatch(resetConfirmationData());
            },
            children: g('alerts.failedPay.ok')
          },
          text: g('alerts.failedPay.message'),
          title: g('alerts.failedPay.title')
        })
      );
    }
  }, []);

  useEffect(() => {
    if (currentContract?.payUrl) {
      dispatch(deletePayContract());
      window.location.replace(currentContract.payUrl);
    }
  }, [currentContract]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentContract());
    };
  }, []);

  const updateContract = () => {
    dispatch(fetchContract(Number(currentContract?.id)));
  };

  return (
    <SimplePage
      header={
        <>
          <LeftBlock>
            <BackButton route={ROUTE_RENT_CONTRACT}>
              <TitleText>{tenantFullName}</TitleText>
            </BackButton>
            <Status>
              {status}
              {currentContract?.status !== 'finished' && (
                <StyledButton
                  isLoading={pending}
                  disabled={pending}
                  variant={'text'}
                  color={'blue01'}
                  onClick={updateContract}
                >
                  {g('updateStatus')}
                </StyledButton>
              )}
            </Status>
          </LeftBlock>
          <Buttons isHiddenTop={true} />
        </>
      }
    >
      <StyledActionContainer>
        <Row>
          <StyledSwitchWrapper>
            <TabSwitch tabs={tabs} onChange={(value) => setSelected(value)} />
          </StyledSwitchWrapper>
          {/*{selected && (*/}
          {/*  <NotifyHint*/}
          {/*    text={g(*/}
          {/*      `notifies.${*/}
          {/*        (selected === 'contractInfo' && 'notifyAboutTermination') ||*/}
          {/*        (selected === 'paymentData' && 'paymentNotRecived') ||*/}
          {/*        (selected === 'increaseRent' && 'sendMessageToTheTenant')*/}
          {/*      }`*/}
          {/*    )}*/}
          {/*  />*/}
          {/*)}*/}
        </Row>
      </StyledActionContainer>
      <StyledTabContainer>
        {selected === 'contractInfo' && (
          <ContractInfo
            {...{
              currentContract,
              onClickSignature,
              onClickPictures,
              onClickPdf,
              onClickSignatureBankId,
              onClickChecklistPdf
            }}
          />
        )}
      </StyledTabContainer>
      <SignatureModal
        tenantSignature={currentContract?.signature_tenant}
        landlordSignature={currentContract?.signature_landlord}
        isOpen={isOpenSignatureModal}
        onClose={() => setIsOpenSignatureModal(false)}
      />
      <Buttons isHiddenBottom={true} />
    </SimplePage>
  );
};

import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { supplierSlice } from 'data/store/supplierReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { TextInfo } from 'components/common';
import { StyledContainer } from './styled';

export const SupplierInfoFields: FC = () => {
  const { g } = useGlobalTranslation();
  const { slug } = useParams<{ slug: string }>();
  const dispatch = useAppDispatch();
  const { fetchSupplier, setCurrentSupplier } = supplierSlice.actions;
  const { currentSupplier, suppliers } = useAppSelector(({ supplier }) => supplier);
  const { name, city, postcode, address } = currentSupplier || {};

  useEffect(() => {
    if (!suppliers?.length) {
      dispatch(fetchSupplier());
    }
  }, []);

  useEffect(() => {
    if (suppliers?.length && suppliers?.some(({ id }) => id === Number(slug))) {
      dispatch(setCurrentSupplier(Number(slug)));
    }
  }, [suppliers]);

  return (
    <StyledContainer>
      <TextInfo label={g('inputs.name')}>{name}</TextInfo>
      <TextInfo label={g('inputs.address')}>{address}</TextInfo>
      <TextInfo label={g('inputs.postcode')}>{postcode}</TextInfo>
      <TextInfo label={g('inputs.city')}>{city}</TextInfo>
    </StyledContainer>
  );
};

export default SupplierInfoFields;

import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { Form } from 'components/ui';
import { containerMixin } from 'mixins';
import { mq } from '../../../../constant/mediaqueries';

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 1em;
  margin: 0 0 0 1.25rem;

  ${mq.lowerLg} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-template-columns: 10px 1fr;
  margin: 0;
  font-size: 1.25rem;
  overflow: hidden;
`;

export const StyledForm = styled(Form)`
  margin: 2.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const StyledFormContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1.5rem;
`;

export const StyledSubmitWrapper = styled.div`
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const backIconCss = css`
  width: 10px;
  fill: currentColor;
`;

import styled from 'styled-components';
import BaseButton from 'components/controls/Button';

export const YearsContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(5, 1fr);
`;

export const YearButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.gray03};
  padding: 15px 0;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;

import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Info from './Info';
import Form from './Form';
import { useForm, FormProvider } from 'react-hook-form';
import { DepreciationFormDataT, INITIAL_DEPRECIATION_VALUE } from 'domain/entity/depreciation/DepreciationFormData';
import { useAppSelector } from '../../../../../hooks/redux';
import Calculations from '../../../../common/Calculations';
import { StepT } from '../index';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './validation';

type PropsT = {
  step: StepT;
  setStep: Dispatch<SetStateAction<StepT>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
};

const View: FC<PropsT> = (props) => {
  const { step, setStep, setIsDirty } = props;
  const { selectedHouse } = useAppSelector((state) => state.house);

  const formData = useForm<DepreciationFormDataT>({
    defaultValues: {
      ...INITIAL_DEPRECIATION_VALUE,
      houseId: selectedHouse?.id,
      documents: []
    },
    resolver: yupResolver(schema)
  });
  const lifetime = formData.watch('lifetime');
  const sum = formData.watch('sum');

  useEffect(() => {
    setIsDirty(formData.formState.isDirty);
  }, [formData.formState.isDirty]);

  useEffect(() => {
    localStorage.setItem('DEPRECIATION_INFO', '1');

    return () => {
      setStep('form');
    };
  }, []);

  return (
    <FormProvider {...formData}>
      {step === 'info' && <Info setStep={setStep} />}
      <Form isDisplay={step === 'form'} setStep={setStep} />
      {step === 'calculations' && <Calculations setStep={() => setStep('form')} sum={sum} lifetime={lifetime} />}
    </FormProvider>
  );
};

export default View;

import React, { useEffect, useRef, useState } from 'react';
import { StyledDot, StyledDots } from './styled';

type Props = {
  blue?: boolean;
};

const Spinner = ({ blue = false }: Props) => {
  const dotsInterval = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [activeDot, setActiveDot] = useState(1);

  useEffect(() => {
    return () => {
      dotsInterval.current !== null && clearInterval(dotsInterval.current);
    };
  }, []);

  if (dotsInterval.current === null) {
    dotsInterval.current = setInterval(() => {
      setActiveDot((prevActiveDot) => (prevActiveDot === 2 ? 0 : prevActiveDot + 1));
    }, 200);
  }

  return (
    <StyledDots>
      <StyledDot blue={blue} active={activeDot === 0} />
      <StyledDot blue={blue} active={activeDot === 1} />
      <StyledDot blue={blue} active={activeDot === 2} />
    </StyledDots>
  );
};

export default Spinner;

const findString: string[][] = [
  [
    'A valid mobile is reqired when signer is setup with sms notifications. Make sure you include a valid number and country code with prefix.',
    'mobile'
  ],
  ['The supplied mobile number is invalid', 'mobile']
];

export const getTextSignicatError = (errors: { message: string }[]): string[] => {
  const foundMessages: string[] = [];

  for (const item of findString) {
    errors?.map(({ message }) => {
      if (message.includes(item[0])) foundMessages.push(item[1]);
    });
  }

  if (foundMessages.length === 0) {
    return ['unknownError'];
  }

  return Array.from(new Set(foundMessages));
};

import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { containerMixin } from 'mixins';
import { Form } from 'components/ui';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const StyledForm = styled(Form)`
  margin: 2.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const StyledFormContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

export const Column = styled.div<ColumnT>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems && alignItems) || 'initial'};
`;

export const backIconCss = css`
  width: 10px;
  fill: currentColor;
`;

export const StyledSubmitWrapper = styled.div`
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 1em;
  margin: 0 0 0 1.25rem;
`;

export const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: 1.25rem;
`;

import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTE_FURNITURE } from 'constant/routes';
import { InfoFieldT } from 'domain/entity/common';
import { documentsSelector } from 'data/store/documentReducer';
import { useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { BackButton } from 'components/ui';
import { InfoFields } from 'components/common';
import SimplePage from 'components/common/SimplePage';
import PictureList from 'components/controls/PictureList';
import { StyledHr } from './styled';

const FurnitureAndHomeItem: FC = () => {
  const { currentDocument } = useAppSelector(documentsSelector);
  const { g } = useGlobalTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!currentDocument) {
      history.goBack();
    }
  }, [currentDocument]);

  if (currentDocument?.type !== 'depreciation-bilag') {
    return null;
  }

  const fields: InfoFieldT[] = [
    {
      value: (currentDocument && currentDocument.bilag.name) || '',
      label: 'inputs.item',
      type: 'text'
    },
    {
      value: (currentDocument && currentDocument.bilag.date) || '',
      label: 'inputs.date',
      type: 'date'
    },
    {
      value: Number(currentDocument && currentDocument.bilag.sum) || '',
      label: 'inputs.sum',
      type: 'currency'
    },
    {
      value: ((currentDocument.bilag.lifetime < 3 && g('options.depreciationRadio.1')) ||
        g('options.depreciationRadio.2')) as string,
      label: 'inputs.lifetime',
      type: 'text'
    }
  ];

  return (
    <SimplePage header={<BackButton route={ROUTE_FURNITURE}>{currentDocument?.bilag.name}</BackButton>}>
      <InfoFields fields={fields} />
      <StyledHr />
      {currentDocument?.bilag?.documents && <PictureList documents={currentDocument?.bilag.documents} />}
    </SimplePage>
  );
};

export default FurnitureAndHomeItem;

import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import { ROUTE_FURNITURE_ITEM } from 'constant/routes';
import { DEFAULT_DATE_FORMAT } from 'constant/formats';
import { DepreciationBilag } from 'domain/entity/documnets/CommonBilag';
import { modalsSlice } from 'data/store/modalReducer';
import { documentsSelector, documentsSlice } from 'data/store/documentReducer';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import { ListView } from 'components/common';
import SimpleRow from 'components/controls/SimpleRow';
import SimplePage from 'components/common/SimplePage';
import plusIcon from 'components/svg/plus.svg?sprite';
import { StyledButton, plusIconCss } from './styled';
import useSubscribeCheck from '../../hooks/useSubscribeCheck';

const FurnitureAndHome: FC = () => {
  const { g } = useGlobalTranlation();
  const { selectedHouse } = useAppSelector((state) => state.house);
  const { documents } = useAppSelector(documentsSelector);
  const { openDepreciation } = modalsSlice.actions;
  const { fetchAllDocuments, setCurrentDocument } = documentsSlice.actions;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const subscribeCheck = useSubscribeCheck();

  useEffect(() => {
    if (selectedHouse) {
      dispatch(fetchAllDocuments({ houseId: selectedHouse?.id || NaN }));
    }
  }, [selectedHouse]);

  const depreciationBilags =
    (documents?.filter((bilag) => bilag.type === 'depreciation-bilag' && !bilag.bilag.isTrashed) as {
      type: 'depreciation-bilag';
      bilag: DepreciationBilag;
    }[]) || [];

  const onBilagClick = (id: number) => {
    dispatch(setCurrentDocument(id));
    history.push(generatePath(ROUTE_FURNITURE_ITEM, { slug: id }), { slug: id });
  };

  const list =
    depreciationBilags?.length > 0 &&
    depreciationBilags?.map(
      ({ bilag }) =>
        bilag?.name && (
          <SimpleRow
            key={`${bilag.id}_${bilag.name}`}
            name={bilag.name}
            info={`${dayjs(bilag.date).format(DEFAULT_DATE_FORMAT)}`}
            onClick={() => onBilagClick(bilag.id)}
          />
        )
    );
  return (
    <SimplePage
      header={
        <>
          {g('sidebar.furnitureAndHomeContents')}
          <StyledButton onClick={() => subscribeCheck() && dispatch(openDepreciation())} variant={'text'}>
            <Icon icon={plusIcon} css={plusIconCss} />
            {g('addNewDepreciationBilag')}
          </StyledButton>
        </>
      }
    >
      <ListView what={'depreciation'}>{list}</ListView>
    </SimplePage>
  );
};

export default FurnitureAndHome;

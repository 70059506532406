import React, {
  ChangeEvent,
  FocusEvent,
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  useState,
  useEffect
} from 'react';
import { useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import Input, { Props as InputPropsT } from 'components/controls/Input/Input';
import Popup from 'components/common/Popup';
import DatePicker from 'components/controls/DatePicker';
import calendarIcon from 'components/svg/calendar.svg?sprite';
import Icon from '../../common/Icon';
import { StyledButton, calendarIconCss, MobileInput, DesktopInput } from './styled';
import addYears from 'date-fns/addYears';

type PropsT = InputPropsT;

const fixDate = (date: string) => {
  const dateArray = date.split('.');
  const [day, month, year] = dateArray;

  if (Number(month) !== 2 && Number(day) > 31) {
    dateArray[0] = '30';
  }

  if (Number(month) === 2 && Number(day) > 29) {
    dateArray[0] = '28';
  }

  if (Number(month) > 12) {
    dateArray[1] = '12';
  }

  if (Number(year) > getYear(addYears(new Date(), 10))) {
    dateArray[2] = getYear(addYears(new Date(), 10)).toString();
  }

  if (Number(year) < getYear(new Date()) - 120) {
    dateArray[2] = (getYear(new Date()) - 120).toString();
  }

  return dateArray;
};

const DateField: FC<PropsT> = (props) => {
  const context = useFormContext();
  const { type, register, ...restProps } = props;
  const watch: string = context?.watch(register?.name);
  const [mobileValue, setMobileValue] = useState('');

  const change = (date: string) => {
    setMobileValue(date);
    const value = format(new Date(date), 'dd.MM.yyyy');
    const fixedDate = fixDate(value);
    context?.setValue(register?.name, fixedDate.join('.'));
    context.clearErrors(register?.name);
  };

  const setValue = (event: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>, value: string) => {
    register?.onChange({ ...event });
    context?.setValue(register?.name, value);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const date = fixDate(event.target.value);

    setValue(event, date.join('.'));
    context.clearErrors(register?.name);
  };

  useEffect(() => {
    if (watch) {
      const [dd, mm, yyyy] = watch?.split('.');
      dd && setMobileValue([yyyy, mm, dd].join('-'));
    }
  }, [watch]);

  return (
    <React.Fragment>
      <DesktopInput>
        <ReactInputMask
          mask="DQ.MQ.9999"
          placeholder="DD.MM.YYYY"
          // @ts-ignore
          formatChars={{
            D: '[0-3]',
            Q: '[0-9]',
            M: '[0-1]',
            '9': '[0-9]'
          }}
          register={register}
          onChange={handleInputChange}
          {...restProps}
        >
          {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
            <Input
              {...inputProps}
              endAdornment={
                <Popup
                  variant={'bottomLeft'}
                  button={(onClick, isOpen) => (
                    <StyledButton isOpen={isOpen} type={'button'} onClick={onClick} variant={'text'}>
                      <Icon icon={calendarIcon} css={calendarIconCss} />
                    </StyledButton>
                  )}
                  content={(onClose) => (
                    <DatePicker onClose={onClose} onChange={change} value={watch} name={register?.name} />
                  )}
                />
              }
            />
          )}
        </ReactInputMask>
      </DesktopInput>
      <MobileInput>
        <Input {...restProps} value={mobileValue} onChange={(event) => change(event.target.value)} type={'date'} />
      </MobileInput>
    </React.Fragment>
  );
};

export default DateField;

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from '../../domain/entity/house/HouseData';
import { AltinnDataT } from '../../domain/entity/altinn/AltinnBilag';
import AltinnLoginData from '../../domain/entity/altinn/AltinLoginData';
import { SendAltinnDataT } from '../driver/altinn/Altinn';
import AltinFormT from '../../domain/entity/altinn/AltinForm';
import { BilagFormDataT } from '../../domain/entity/bilag/BilagFormData';
import { Supplier } from '../../domain/entity/supplier/SuplierData';
import { DepreciationFormDataT } from '../../domain/entity/depreciation/DepreciationFormData';
import { BilagState } from './bilagReducer';

export interface CreateDepreciationPersonalBilagPayload {
  year?: number;
  houseId?: number;
}

export interface DepreciationPersonalCalculateResponse {
  date: string;
  id: number;
  sum: number;
  totalSum: number;
}

export interface DepreciationState {
  pending?: boolean;
  pendingCalculation?: boolean;
  deletePending?: boolean;
  calculation?: DepreciationPersonalCalculateResponse;
  error?: string;
}

const initialState: DepreciationState = {
  pending: false,
  deletePending: false,
  pendingCalculation: false,
  calculation: undefined,
  error: ''
};

export const depreciationSlice = createSlice({
  name: 'depreciation',
  initialState,
  reducers: {
    createDepreciation(
      state: DepreciationState,
      _: PayloadAction<{ data: DepreciationFormDataT; currentHouse: HouseData }>
    ) {
      state.pending = true;
      state.error = '';
    },
    createDepreciationPersonalUse(
      state: DepreciationState,
      _: PayloadAction<{ data: DepreciationFormDataT; currentHouse: HouseData }>
    ) {
      state.pending = true;
    },
    createDepreciationSuccess(state: DepreciationState) {
      state.pending = false;
      state.error = '';
    },
    createDepreciationError(state: DepreciationState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    fetchDepreciationPersonalCalculation(
      state: DepreciationState,
      _: PayloadAction<CreateDepreciationPersonalBilagPayload>
    ) {
      state.pendingCalculation = true;
      state.error = '';
    },
    fetchDepreciationPersonalCalculationSuccess(
      state: DepreciationState,
      { payload }: PayloadAction<DepreciationPersonalCalculateResponse>
    ) {
      state.pendingCalculation = false;
      state.calculation = payload;
      state.error = '';
    },
    fetchDepreciationPersonalCalculationError(state: DepreciationState, { payload }: PayloadAction<string>) {
      state.pendingCalculation = false;
      state.error = payload;
    },
    deleteDepreciationBilag(state: DepreciationState, _: PayloadAction<number>) {
      state.deletePending = true;
      state.error = '';
    },
    deleteDepreciationBilagSucces(state: DepreciationState) {
      state.deletePending = false;
      state.error = '';
    },
    deleteDepreciationPersonalUseBilag(state: DepreciationState, _: PayloadAction<{ year: number; house: HouseData }>) {
      state.deletePending = true;
      state.error = '';
    },
    deleteDepreciationBilagError(state: DepreciationState, { payload }: PayloadAction<string>) {
      state.deletePending = false;
      state.error = payload;
    }
  }
});

const selectDepreciation = (state: RootState): DepreciationState => state.depreciation;

export const supplierSelector = createSelector([selectDepreciation], (depreciation) => depreciation);

export const {
  createDepreciation,
  createDepreciationError,
  createDepreciationSuccess,
  createDepreciationPersonalUse,
  fetchDepreciationPersonalCalculation,
  fetchDepreciationPersonalCalculationError,
  fetchDepreciationPersonalCalculationSuccess,
  deleteDepreciationBilag,
  deleteDepreciationBilagError,
  deleteDepreciationBilagSucces,
  deleteDepreciationPersonalUseBilag
} = depreciationSlice.actions;

export default depreciationSlice.reducer;

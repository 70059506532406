import * as Yup from 'yup';
import { NorwegianId } from 'norwegian-national-id-validator';
import { checkPhone } from 'helpers/validation';
import { emailSchema } from './emailValidation';

export const profileSchema = Yup.object().shape({
  firstName: Yup.string().required('').nullable().max(50, '').min(2, ''),
  lastName: Yup.string().required('').nullable().max(50, '').min(2, ''),
  birthday: Yup.string(),
  iban: Yup.string().nullable(),
  phone_code: Yup.string(),
  phone: Yup.string()
    .when('phone_code', {
      is: (value: string) => Boolean(value),
      then: Yup.string().when('phone', {
        is: (endDate: string) => !!endDate,
        then: Yup.string().when(['phone', 'phone_code'], {
          is: checkPhone,
          then: (field) => field,
          otherwise: Yup.string().max(1, '')
        })
      })
    })
    .required(),
  country: Yup.string().required('').nullable(),
  city: Yup.string().required('').nullable().max(50, '').min(2, ''),
  address: Yup.string().required('').nullable().max(50, '').min(2, ''),
  postcode: Yup.string().required('').nullable(),
  ssn: Yup.string()
    .min(11, '')
    .required('')
    .when('city', {
      is: true,
      otherwise: Yup.string().when('ssn', {
        is: (value: string) => {
          if (value) {
            return NorwegianId(value).isValid();
          }
        },
        otherwise: Yup.string().max(1, '').nullable()
      })
    })
    .nullable()
});

export const profileSchemaWithEmail = profileSchema.concat(emailSchema);

import styled from 'styled-components';

export const Content = styled.div`
  height: 100vh;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  flex-flow: column;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.blue01};
`;

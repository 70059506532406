export type IncomeBilagFormData = {
  id?: number;
  start_date?: string;
  end_date?: string;
  sum?: string;
  houseId?: number;
  tenant_first_name?: string;
  tenant_last_name?: string;
  tenant_birthday?: string;
  tenant_email?: string;
  tenant_phone?: string;
  deposit_sum?: string;
  separatedTaxable?: boolean;
  other?: string;
  documents?: (File | string)[];
};

export const InitialIncomeBilagData = {
  start_date: '',
  end_date: '',
  sum: '',
  houseId: '',
  tenant_first_name: '',
  tenant_last_name: '',
  tenant_birthday: '',
  tenant_email: '',
  tenant_phone: '',
  deposit_sum: '',
  separatedTaxable: false,
  other: '',
  documents: []
};

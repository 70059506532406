import React, { FC, useEffect, useState } from 'react';
import { StyledComingSoon, StyledContainer, StyledTab } from './styled';
import useGlobalTranslation from '../../../hooks/useGlobalTranlation';
import { ComingSoon } from 'components/ui';

export type TabT = {
  value: string;
  isComing?: boolean;
};

interface PropsI {
  tabs: TabT[];
  onChange: (value: string) => void;
}

export const TabSwitch: FC<PropsI> = ({ tabs, onChange }) => {
  const [selected, setSelected] = useState<string>();
  const { g } = useGlobalTranslation();

  useEffect(() => {
    if (!selected && tabs.length > 0) {
      setSelected(tabs[0].value);
    }
  }, []);
  useEffect(() => {
    if (selected) onChange(selected);
  }, [selected]);

  return (
    <StyledContainer>
      {tabs.map(({ value, isComing }: TabT) => (
        <StyledTab
          key={value}
          isComing={!!isComing}
          $active={selected === value}
          onClick={() => !isComing && setSelected(value)}
        >
          {(isComing && (
            <>
              {g(value)} <ComingSoon>{g('comingSoon')}</ComingSoon>
            </>
          )) ||
            g(value)}
        </StyledTab>
      ))}
    </StyledContainer>
  );
};

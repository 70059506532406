import styled, { css } from 'styled-components';
import { mq } from '../../../constant/mediaqueries';

const hiddenWrapperAnimation = css`
  animation: hiddenBlock 0.3s ease;
`;

type WrapperPropsT = {
  isHidden: boolean;
};

export const Wrapper = styled.div<WrapperPropsT>`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ isHidden }) => isHidden && hiddenWrapperAnimation}

  @keyframes hiddenBlock {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const Fade = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(78, 78, 78, 0.22);
  animation: fadeBlock 0.25s ease;

  @keyframes fadeBlock {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 96px;
  width: 480px;
  height: 192px;
  background-color: ${({ theme }) => theme.colors.white01};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 39px 48px 27px;
  animation: topShow 0.3s ease;

  @keyframes topShow {
    from {
      top: -100%;
    }
    to {
      top: 0;
    }
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  line-height: 28px;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  margin: 0;
`;

export const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.gray03};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const ActionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: 48px;
  margin-top: 20px;
  font-size: ${({ theme }) => theme.fontSizes.sm};

  ${mq.lowerSm} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const contentCss = css`
  max-width: 480px;

  ${mq.lowerSm} {
    width: 100%;
  }
`;

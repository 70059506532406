import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { actionsSelector } from '../../../../data/store/actionsReducer';
import ActionsListModal from '../../../common/ActionsListModal';

const ActionsModal: FC = () => {
  const { show, cancelButtonProps, title, actions, onClose } = useAppSelector(actionsSelector);

  return (actions && <ActionsListModal isOpen={show} {...{ cancelButtonProps, title, actions, onClose }} />) || <></>;
};

export default ActionsModal;

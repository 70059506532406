import React from 'react';
import { ROUTE_LOGIN } from 'constant/routes';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import checkbox from 'components/svg/big_square_check_icon.svg?sprite';
import Icon from 'components/common/Icon';
import {
  StyledTitle,
  StyledTextContainer,
  StyledSubtitle,
  StyledLink,
  StyledSuccessContainer,
  checkboxIconCss
} from './styled';

const SuccessMessage = () => {
  const { t, g } = useGlobalTranslation('resetPasswordNew');

  return (
    <StyledSuccessContainer>
      <Icon icon={checkbox} css={checkboxIconCss} />
      <StyledTextContainer>
        <StyledTitle>{t('success.title')}</StyledTitle>
        <StyledSubtitle>
          {t('success.subtitle')}
          <StyledLink to={ROUTE_LOGIN}>{g('login')}</StyledLink>
        </StyledSubtitle>
      </StyledTextContainer>
    </StyledSuccessContainer>
  );
};

export default SuccessMessage;

import React, { FC, InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { StyledContainer, StyledCheckbox, StyledLabel, errorIconCss, StyledPrompt } from './styled';
import Icon from '../../common/Icon';
import errorIcon from 'components/svg/error.svg?sprite';

type PropsT = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  register?: UseFormRegisterReturn;
  withPrompt?: boolean;
  errorMessage?: string;
};

const Checkbox: FC<PropsT> = (props) => {
  const { children, className, register, errorMessage, withPrompt, ...restProps } = props;

  return (
    <div>
      <StyledContainer className={className}>
        <StyledCheckbox error={!!errorMessage} {...restProps} type="checkbox" {...register} />
        <StyledLabel>{children}</StyledLabel>
      </StyledContainer>
      {withPrompt && (
        <StyledPrompt error={Boolean(errorMessage)}>
          <Icon icon={errorIcon} css={errorIconCss} />
          {errorMessage && errorMessage}
        </StyledPrompt>
      )}
    </div>
  );
};

export default Checkbox;

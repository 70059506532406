import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
  align-items: center;
  grid-auto-flow: column;
  justify-content: start;
  width: 100%;
  min-height: 72px;
  margin: 1rem 0;
`;

export const Label = styled.label`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  cursor: pointer;
  width: 125px;
  height: 50px;
  color: ${({ theme }) => theme.colors.blue01};
  text-transform: uppercase;
`;

export const addIconCss = css`
  margin-right: 12px;
  width: 18px;
  fill: currentColor;
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
`;

export const FilesList = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
`;

type FadePropsT = {
  isBackground: boolean;
};

export const Fade = styled.div<FadePropsT>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ isBackground }) => isBackground && 'rgba(196, 196, 196, 0.4)'};
  transition: background 0.4s;
`;

export const DeleteButton = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  opacity: 0;
  color: ${({ theme }) => theme.colors.white01};
  transition: opacity 0.4s;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100px;
  padding-top: 62.61%;

  &:hover ${Fade} {
    background: rgba(0, 0, 0, 0.34);
  }

  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;

export const iconCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const deleteIconCss = css`
  width: 24px;
  fill: currentColor;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

import React, { FC, FormHTMLAttributes } from 'react';
import { StyledForm } from './styled';

type Props = FormHTMLAttributes<HTMLFormElement>;

const Form: FC<FormHTMLAttributes<HTMLFormElement>> = ({ children, ...restProps }: Props) => {
  return <StyledForm {...restProps}>{children}</StyledForm>;
};

export default Form;

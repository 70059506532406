import styled, { css } from 'styled-components';
import Button from 'components/controls/Button';
import { mq } from '../../../../constant/mediaqueries';
import { ThemeT } from '../../../../context/Theme';

type ContainerPropsT = {
  isOpen: boolean;
};

const containerClosedCss = (theme: ThemeT) => css`
  width: 24px;
  background-color: transparent;

  ${mq.lowerSm} {
    width: 0;
  }

  ${mq.lowerXs} {
    width: 100%;
    left: -100%;
    background-color: ${theme.colors.gray06};
  }
`;

export const StyledContainer = styled.div<ContainerPropsT>`
  position: fixed;
  margin-top: 60px;
  height: calc(100% - 60px);
  top: 0;
  width: 260px;
  background-color: ${({ theme }) => theme.colors.gray06};
  box-shadow: inset -1px 0px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: all 0.5s;
  z-index: 10;

  ${mq.lowerXs} {
    height: calc(100% - 50px);
    z-index: 1;
    left: 0;
    width: 100%;
    margin-top: 50px;
  }

  ${({ isOpen, theme }) => isOpen && containerClosedCss(theme)}
`;

export const StyledHeader = styled.div``;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};
`;

export const StyledContent = styled.div`
  margin-top: 19px;
`;

export const StyledFooter = styled.div`
  margin-top: 19px;
`;

export const StyledAddButton = styled(Button)`
  max-width: 100%;
  margin: 47px 0 36px;
`;

const hideContentCss = css`
  opacity: 0;
`;

export const StyledWidthFixed = styled.div<ContainerPropsT>`
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr max-content;
  padding: 0 10px;
  transition: opacity 0.5s;

  ${mq.greaterXs} {
    width: 260px;
    ${({ isOpen }) => isOpen && hideContentCss}
  }
`;

export const plusIconCss = css`
  fill: currentColor;
  width: 24px;
`;

export const LinkContainer = styled.div`
  margin: 20px 0 10px;
  display: flex;
  justify-content: space-around;
`;

export const Link = styled.a`
  font-size: 12px;
  line-height: 1.67;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.gray03};
  cursor: pointer;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`;

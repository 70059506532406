import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ROUTE_CONTACT_US,
  ROUTE_DASHBOARD,
  ROUTE_DETAILED_COST,
  ROUTE_FURNITURE,
  ROUTE_HOME_INDEX,
  ROUTE_HOUSE_INFO,
  ROUTE_PROFILE_ACCOUNT_SETTINGS,
  ROUTE_PROFILE_INDEX,
  ROUTE_PROFILE_LANDLORD,
  ROUTE_PROFILE_SUBSCRIPTION,
  ROUTE_RENT_CONTRACT,
  ROUTE_SEPARATE_INCOME_BILAGS,
  ROUTE_SUPPLIERS,
  ROUTE_TRASH_FOLDER
} from 'constant/routes';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import deleteIcon from 'components/svg/delete.svg?sprite';
import deleteClockIcon from 'components/svg/delete-clock.svg?sprite';
import {
  StyledWrapper,
  StyledScroll,
  StyledLink,
  iconCss,
  IconContainer,
  Divider,
  StyledDeleteLink,
  StyledTrashLink,
  trashIconCss
} from './styled';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { confirmationSlice } from '../../../../../data/store/confirmationReducer';
import { fetchHouseDelete } from '../../../../../data/store/houseReducer';
import { alertSlice } from '../../../../../data/store/alertReducer';
import { ComingSoon } from '../../../../ui';

type Props = {
  openDeleteAlert: boolean;
  setOpenDeleteAlert: Dispatch<SetStateAction<boolean>>;
};

type LinkPropsT = {
  text: string;
  route: string;
  isComing?: boolean;
};

const DATA_LINKS_DASHBOARD: LinkPropsT[] = [
  {
    text: 'sidebar.allBilags',
    route: ROUTE_DASHBOARD
  },
  {
    text: 'sidebar.houseInfo',
    route: ROUTE_HOUSE_INFO
  },
  {
    text: 'sidebar.rentContracts',
    route: ROUTE_RENT_CONTRACT
  },
  {
    text: 'sidebar.detailedCost',
    route: ROUTE_DETAILED_COST,
    isComing: true
  },
  {
    text: 'sidebar.suppliers',
    route: ROUTE_SUPPLIERS
  },
  {
    text: 'sidebar.furnitureAndHomeContents',
    route: ROUTE_FURNITURE
  },
  {
    text: 'sidebar.separateIncomeBilags',
    route: ROUTE_SEPARATE_INCOME_BILAGS
  }
];

const DATA_LINKS_PROFILE: LinkPropsT[] = [
  { text: 'sidebar.landlord', route: ROUTE_PROFILE_LANDLORD },
  { text: 'sidebar.accountSettings', route: ROUTE_PROFILE_ACCOUNT_SETTINGS },
  { text: 'sidebar.subscription', route: ROUTE_PROFILE_SUBSCRIPTION }
];

const MAP_PATHNAME_TO_LINKS = (pathname: string): LinkPropsT[] => {
  if (pathname.includes(ROUTE_HOME_INDEX)) {
    return DATA_LINKS_DASHBOARD;
  }
  if (pathname.includes(ROUTE_PROFILE_INDEX)) {
    return DATA_LINKS_PROFILE;
  }

  return [];
};

const Nav: FC<Props> = (props) => {
  const { pathname } = useLocation();
  const { g } = useGlobalTranslation();
  const links = MAP_PATHNAME_TO_LINKS(pathname) || [];
  const dispatch = useAppDispatch();

  const { selectedHouse, houseList } = useAppSelector((state) => state.house);

  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { setAlertData } = alertSlice.actions;

  const onDeletePress = () => {
    if (selectedHouse?.id) {
      dispatch(fetchHouseDelete(selectedHouse?.id));
    }
  };

  return (
    <StyledWrapper>
      <StyledScroll>
        {links.map(({ route, text, isComing }) => {
          const isActive = pathname.includes(route);

          return (
            <StyledLink key={route} $active={isActive} to={route} $isComing={isComing}>
              {(isComing && (
                <>
                  {g(text)} <ComingSoon>{g('comingSoon')}</ComingSoon>
                </>
              )) ||
                g(text)}
            </StyledLink>
          );
        })}
      </StyledScroll>
      {pathname.includes(ROUTE_HOME_INDEX) && (
        <>
          <StyledTrashLink
            key={ROUTE_TRASH_FOLDER}
            to={ROUTE_TRASH_FOLDER}
            $active={pathname.includes(ROUTE_TRASH_FOLDER)}
          >
            <IconContainer color={'black01'}>
              <Icon color={'gray03'} icon={deleteClockIcon} css={trashIconCss} />
            </IconContainer>
            {g('trashFolder')}
          </StyledTrashLink>
          <StyledDeleteLink
            $active={false}
            onClick={() => {
              if (houseList?.length > 1) {
                dispatch(
                  setConfirmationData({
                    show: true,
                    cancelButtonProps: {
                      onClick: () => {
                        dispatch(resetConfirmationData());
                      },
                      children: g('alerts.deleteHouse.buttons.no')
                    },
                    confirmButtonProps: {
                      onClick: () => {
                        onDeletePress();
                        dispatch(resetConfirmationData());
                      },
                      children: g('alerts.deleteHouse.buttons.yes')
                    },
                    text: g('alerts.deleteHouse.message'),
                    title: g('alerts.deleteHouse.title')
                  })
                );
              }
              if (houseList?.length === 1) {
                dispatch(
                  setConfirmationData({
                    show: true,
                    confirmButtonProps: {
                      onClick: () => {
                        dispatch(resetConfirmationData());
                      },
                      children: 'Ok'
                    },
                    text: g('alerts.deleteLastHouse.message'),
                    title: g('alerts.deleteLastHouse.title'),
                    onClose: () => {
                      dispatch(resetConfirmationData());
                    }
                  })
                );
              }
            }}
          >
            <IconContainer color={'red01'}>
              <Icon icon={deleteIcon} css={iconCss} />
            </IconContainer>
            {g('deleteHouse')}
          </StyledDeleteLink>
        </>
      )}
      {pathname.includes(ROUTE_PROFILE_INDEX) && (
        <>
          <Divider />
          <StyledLink $active={pathname === ROUTE_CONTACT_US} to={ROUTE_CONTACT_US}>
            {g('sidebar.contactUs')}
          </StyledLink>
        </>
      )}
    </StyledWrapper>
  );
};

export default Nav;

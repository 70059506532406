import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import CredentialT from '../../domain/entity/auth/CredentialT';
import { call, put } from 'redux-saga/effects';
import {
  changeEmailByCode,
  fetchAuthProvidersApi,
  fetchCheckEmail,
  fetchDifiTokenApi,
  login,
  logoutDifiApi,
  resetPasswordByCode,
  resetPasswordByLink,
  setNewPassword
} from '../driver/auth/Auth';
import {
  changeEmailFetchingError,
  changeEmailFetchingSuccess,
  fetchAuthProvidersError,
  fetchAuthProvidersSuccess,
  fetchDifiTokenError,
  fetchDifiTokenSuccess,
  logoutDifiError,
  logoutDifiSuccess,
  resetPasswordCheckFetchingError,
  resetPasswordCheckFetchingSuccess,
  resetPasswordFetchingError,
  resetPasswordFetchingSuccess,
  sendNewPasswordFetchingError,
  sendNewPasswordFetchingSuccess,
  tokenFetchingError,
  tokenFetchingSuccess
} from '../store/authReducer';
import { difiDataReset, profileDataReset, profileFetchingSuccess } from '../store/profileReducer';
import NewPasswordT from '../../domain/entity/auth/NewPassword';
import { RegistrationFormT } from '../../domain/entity/registration/RegistrationFormData';
import { fetchRegistration } from '../driver/registration/Registration';
import {
  checkEmailError,
  checkEmailSuccess,
  registrationError,
  registrationSuccess
} from '../store/registrationReducer';
import StepEnum from '../../domain/entity/registration/StepEnum';
import { resetToken } from '../driver/Api';
import { resetSubscription } from '../store/subscriptionReducer';

export function* fetchLoginSaga({ payload }: PayloadAction<CredentialT>) {
  try {
    const response = yield call(login, payload);
    yield put(tokenFetchingSuccess(response));
    yield put(profileFetchingSuccess(response.user));
  } catch (e) {
    yield put(tokenFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchPasswordResetSaga({ payload }: PayloadAction<{ email: CredentialT['email'] }>) {
  try {
    yield call(resetPasswordByLink, payload);
    yield put(resetPasswordFetchingSuccess());
  } catch (e) {
    yield put(resetPasswordFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchPasswordResetConfirmSaga({ payload }: PayloadAction<{ code: string }>) {
  try {
    const response = yield call(resetPasswordByCode, payload);
    yield put(resetPasswordCheckFetchingSuccess(response));
  } catch (e) {
    yield put(resetPasswordCheckFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchNewPasswordSaga({ payload }: PayloadAction<NewPasswordT>) {
  try {
    yield call(setNewPassword, payload);
    yield put(sendNewPasswordFetchingSuccess());
  } catch (e) {
    yield put(sendNewPasswordFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* registrationSaga({ payload }: PayloadAction<RegistrationFormT>) {
  try {
    const response = yield call(fetchRegistration, payload);
    yield put(registrationSuccess(StepEnum.profile));
    yield put(tokenFetchingSuccess(response));
    yield put(profileFetchingSuccess(response.user));
  } catch (e) {
    yield put(registrationError(e?.response?.data?.message || e?.message));
  }
}

export function* logoutSaga() {
  try {
    yield call(resetToken);
    yield put(profileDataReset());
    yield put(registrationSuccess(StepEnum.general));
    yield put(resetSubscription());
    localStorage.clear();
  } catch (e) {}
}

export function* checkEmailSaga({ payload }: PayloadAction<{ email: string }>) {
  try {
    yield call(fetchCheckEmail, payload.email);
    yield put(checkEmailSuccess());
  } catch (e) {
    yield put(checkEmailError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchChangeEmailSaga({ payload }: PayloadAction<{ code: string }>) {
  try {
    yield call(changeEmailByCode, payload);
    yield put(changeEmailFetchingSuccess());
  } catch (e) {
    yield put(changeEmailFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchAuthProvidersSaga() {
  try {
    const response = yield call(fetchAuthProvidersApi);
    yield put(fetchAuthProvidersSuccess(response));
  } catch (e) {
    yield put(fetchAuthProvidersError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchDifiTokenSaga({ payload }: PayloadAction<{ code: string; state: string }>) {
  try {
    const response = yield call(fetchDifiTokenApi, payload);
    yield put(fetchDifiTokenSuccess(response));
  } catch (e) {
    yield put(fetchDifiTokenError(e?.response?.data?.message || e?.message));
  }
}

export function* logoutDifiSaga() {
  try {
    const response = yield call(logoutDifiApi);
    yield put(logoutDifiSuccess(response));
    yield put(difiDataReset());
  } catch (e) {
    yield put(logoutDifiError(e?.response?.data?.message || e?.message));
  }
}

import React, { Dispatch, FC, SetStateAction } from 'react';
import { StepEnum } from '../data';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { ActionContainer, Button, HelpContent, Text } from './styled';

type PropsT = {
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const HelpDepositContent: FC<PropsT> = (props) => {
  const { setStep } = props;
  const { g } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');

  return (
    <HelpContent>
      <Text>{g('helpsContent.openDepAccountWebsite')}</Text>
      <ActionContainer>
        <Button onClick={() => setStep(StepEnum.DEPOSIT_ACCOUNT)} variant={'text'}>
          {f('continue')}
        </Button>
      </ActionContainer>
    </HelpContent>
  );
};

export default HelpDepositContent;

import styled from 'styled-components';

interface ContainerPropsI {
  status?: 'active' | 'expired' | 'finished' | 'archived';
}

export const LeftWrapper = styled.div`
  line-height: 1.2em;
  letter-spacing: 0.15px;
  font-weight: 500;
`;

export const RightWrapper = styled.div`
  line-height: 1.2em;
  letter-spacing: 0.15px;
  color: #b7b7b7;
`;

export const StyledContainer = styled.div<ContainerPropsI>`
  cursor: pointer;
  width: 100%;
  height: 4.5rem;
  display: flex;
  padding: 1.5rem;
  margin: 0 0 0.75rem 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  transition: all 0.2s linear;
  box-shadow: ${({ status }) => (status === 'active' && '0px 4px 4px rgba(0, 0, 0, 0.25)') || 'none'};
  :hover {
    transition: all 0.15s linear;
    border: 1px solid ${({ theme }) => theme.colors.blue01};
  }
  ${LeftWrapper} {
    color: ${({ theme, status }) => (status === 'archived' && theme.colors.gray03) || theme.colors.black01};
  }
`;

import * as Yup from 'yup';
import { checkDataRengeDate } from 'helpers/validation';

const schema = Yup.object().shape({
  start_date: Yup.string()
    .required('')
    .when('end_date', {
      is: (endDate: string) => endDate,
      then: Yup.string().when(['start_date', 'end_date'], {
        is: checkDataRengeDate,
        then: (field) => field,
        otherwise: Yup.string().max(1, '123123')
      })
    }),
  end_date: Yup.lazy((endDate) =>
    Yup.string()
      .required('')
      .when('start_date', {
        is: (endDate: string) => endDate,
        then: Yup.string().when(['start_date', 'end_date'], {
          is: checkDataRengeDate,
          then: (field) => field,
          otherwise: Yup.string().max(1, '123123')
        })
      })
  ),
  sum: Yup.string().required(''),
  houseId: Yup.string().required(''),
  tenant_first_name: Yup.string().required(''),
  tenant_last_name: Yup.string().required(''),
  tenant_birthday: Yup.string().required(''),
  tenant_email: Yup.string().email().required(''),
  tenant_phone: Yup.string(),
  deposit_sum: Yup.string().required(''),
  separatedTaxable: Yup.boolean(),
  other: Yup.string()
});

export default schema;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  justify-items: center;
`;

export const StyledLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
`;

export const Text = styled.div`
  margin-top: 12px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  max-width: 380px;
  text-align: center;
`;

export const Action = styled.div`
  width: 100%;
  margin-top: 20px;
  display: grid;
  justify-content: end;
`;

export const contentCss = css`
  padding-bottom: 20px;
`;

export const ActionContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  margin-top: 15px;
`;

export const LinkApp = styled.a`
  cursor: pointer;
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`;

export const storeIconCss = css`
  width: 155px;
`;

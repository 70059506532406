import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1092px;
  line-height: 1.2em;
`;
export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.gray01};
  padding: 16px 0 11px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};
`;

import React, { FC, useState } from 'react';
import { getTrackBackground, Range as BaseRange } from 'react-range';
import { RangeThumb, RangeTrack, RangeTrackFill, Label, ValueContainer, Content, Price } from './styled';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

type PropsT = {
  register?: UseFormRegisterReturn;
  label?: string;
  required?: boolean;
  sum?: string;
};

const Range: FC<PropsT> = (props) => {
  const { label, required, sum = 0, register } = props;
  const context = useFormContext();
  const rangeWatch = context.watch(register?.name);
  const [value, setValue] = useState<number[]>([rangeWatch || 0]);

  return (
    <div>
      <Label>
        {label} {required ? '*' : ''}
      </Label>
      <Content>
        <ValueContainer>
          {value[0]}% NOK <Price>{Math.floor(Number(sum) * (value[0] / 100) * 100) / 100}</Price>
        </ValueContainer>
        <BaseRange
          values={value}
          onChange={(values) => {
            setValue(values);
            context.setValue(register?.name, values[0]);
          }}
          min={0}
          max={100}
          step={1}
          renderTrack={({ props, children }) => (
            <RangeTrack {...props}>
              <RangeTrackFill
                background={getTrackBackground({
                  values: value,
                  colors: ['#548BF4', '#ccc'],
                  min: 0,
                  max: 100
                })}
                ref={props.ref}
              />
              {children}
            </RangeTrack>
          )}
          renderThumb={({ props }) => <RangeThumb {...props} />}
        />
      </Content>
    </div>
  );
};

export default Range;

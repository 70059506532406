import React, { Dispatch, FC, SetStateAction } from 'react';
import { StepEnum } from '../data';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { ActionContainer, Button, HelpContent, Text } from './styled';

type PropsT = {
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const HelpSignByID: FC<PropsT> = (props) => {
  const { setStep } = props;
  const { g } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');

  return (
    <HelpContent>
      <Text>
        {g('helpsContent.signContractById.text')}
        <ul style={{ paddingLeft: 15 }}>
          <li>{g('helpsContent.signContractById.text1')}</li>
          <li>{g('helpsContent.signContractById.text2')}</li>
          <li>{g('helpsContent.signContractById.text3')}</li>
          <li>{g('helpsContent.signContractById.text4')}</li>
          <li>{g('helpsContent.signContractById.text5')}</li>
        </ul>
      </Text>
      <ActionContainer>
        <Button onClick={() => setStep(StepEnum.SAVE_RENT_CONTRACT)} variant={'text'}>
          {f('continue')}
        </Button>
      </ActionContainer>
    </HelpContent>
  );
};

export default HelpSignByID;

import React, { FC, useEffect, useState } from 'react';
import { Button, Input } from '../../../../components/ui';
import { getErrMsg } from '../../../../helpers/locale';
import useGlobalTranslation from '../../../../hooks/useGlobalTranlation';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './validation';
import { StyledForm, StyledFormContainer, StyledSubmitWrapper } from './styled';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ROUTE_PROFILE_ACCOUNT_SETTINGS } from '../../../../constant/routes';
import Icon from '../../../../components/common/Icon';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import { backIconCss, StyledLink, StyledLinkText } from '../../../Landlord/components/LandlordEdit/styled';
import { EMAIL_FIELDS } from './fields';
import { accountSettingsSlice } from '../../../../data/store/accountSettingsReducer';
import ChangeEmailT from '../../../../domain/entity/profile/ChangeEmailT';
import { useHistory } from 'react-router';
import { confirmationSlice } from '../../../../data/store/confirmationReducer';
import SimplePage from '../../../../components/common/SimplePage';

const ChangeEmail: FC = () => {
  const dispatch = useAppDispatch();
  const { accountSettingsChangeEmailFetching } = accountSettingsSlice.actions;
  const { pending, error } = useAppSelector((state) => state.accountSettings);

  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;

  const { t, g } = useGlobalTranslation('profile');
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });

  const [openAlert, setOpenAlert] = useState(false);
  const history = useHistory();

  const onSubmit = (data: ChangeEmailT) => {
    dispatch(accountSettingsChangeEmailFetching(data));
    if (!error) {
      dispatch(
        setConfirmationData({
          show: true,
          confirmButtonProps: {
            onClick: () => {
              dispatch(resetConfirmationData());
              history.push(ROUTE_PROFILE_ACCOUNT_SETTINGS);
            },
            children: 'Ok'
          },
          text: g('successes.emailChanged', { email: getValues('email') }),
          title: g('success'),
          onClose: () => {
            dispatch(resetConfirmationData());
            history.push(ROUTE_PROFILE_ACCOUNT_SETTINGS);
          }
        })
      );
    }
  };

  const { register, handleSubmit, formState, getValues } = form;

  useEffect(() => {
    if (pending !== undefined && !pending && !error) {
      setOpenAlert(!!getValues('email'));
    }
  }, [pending]);
  useEffect(() => {
    if (error === 'errors.emailExists' || error?.includes('errors.emailExists')) {
      dispatch(
        setConfirmationData({
          title: g('error'),
          text: g(error),
          show: true,
          confirmButtonProps: { children: 'Ok', onClick: () => dispatch(resetConfirmationData()) }
        })
      );
    }
  }, [error]);
  return (
    <>
      <SimplePage
        header={
          <StyledLink to={ROUTE_PROFILE_ACCOUNT_SETTINGS}>
            <Icon icon={backIcon} css={backIconCss} color={'black01'} />
            <StyledLinkText>{t('change.email')}</StyledLinkText>
          </StyledLink>
        }
      >
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledFormContainer>
            {EMAIL_FIELDS.map(({ name, text, serverError, type, ...restProps }) => (
              <FormProvider {...form}>
                <Input
                  {...restProps}
                  key={name}
                  variant={'outline'}
                  maxLength={50}
                  label={t(text)}
                  register={register(name)}
                  error={!!formState.errors[name] || (serverError && !!error)}
                  errorMessage={getErrMsg(formState.errors, name)}
                  serverError={(serverError && g(error)) || g(error || '')}
                />
              </FormProvider>
            ))}
          </StyledFormContainer>
          <StyledSubmitWrapper>
            <Button isLoading={pending}>{g('save')}</Button>
          </StyledSubmitWrapper>
        </StyledForm>
      </SimplePage>
    </>
  );
};

export default ChangeEmail;

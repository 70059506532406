import React, { FC, ReactNode } from 'react';
import Button, { PropsT as ButtonPropsT } from 'components/controls/Button';
import { Title, CancelWrapper, ActionsContainer, contentCss } from './styled';
import Modal from '../Modal';

type PropsT = {
  isOpen: boolean;
  title?: string;
  actions: ReactNode[];
  cancelButtonProps?: Omit<ButtonPropsT, 'color' | 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: () => void;
  };
  onClose?: () => void;
  isConfirm?: boolean;
};

const ActionsListModal: FC<PropsT> = (props) => {
  const { isOpen, title, actions, cancelButtonProps, isConfirm, onClose } = props;

  const handleCloseClick = () => {
    (cancelButtonProps && cancelButtonProps.onClick()) || (onClose && onClose());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseClick}
      contentCss={contentCss}
      isConfirm={isConfirm}
      content={(onClose) => (
        <>
          {!!title && <Title>{title}</Title>}
          <ActionsContainer>
            {actions.map((action) => action)}
            <CancelWrapper>{<Button variant="text" {...cancelButtonProps} onClick={onClose} />}</CancelWrapper>
          </ActionsContainer>
        </>
      )}
    />
  );
};

export default ActionsListModal;

import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { ContractFormData, INITIAL_CONTRACT_FORM_DATA } from 'domain/entity/rentContract/RentContract';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Select from 'components/FormControls/Select';
import Input from 'components/controls/Input/Input';
import DateField from 'components/controls/DateField';
import { rentContractSchema } from 'components/layouts/Modals/RentContract/validation';
import FileField from 'components/controls/FileField';
import Textarea from 'components/controls/Textarea/Input';
import { StyledDescriptionContainer, StyledCheckbox, StyledLabel } from 'components/layouts/Modals/RentContract/styled';
import { StepEnum } from '../data';
import { Title, Content, StyledContent } from '../LandlordInformation/styled';
import { ActionsButton, StyledButton } from '../HouseInfo/styled';
import { useData } from '../index';
import { infoIconCss, RadiosContent, TextButton } from '../../../../components/layouts/Modals/NewRentContract/styled';
import { StyledRadioButton } from '../../../../components/layouts/Modals/Depreciation/View/Form/styled';
import Icon from '../../../../components/common/Icon';
import infoIcon from 'components/svg/warning.svg?sprite';
import { radios } from '../../../../components/layouts/Modals/NewRentContract/View/DepositAccount';
import Popup from '../../../../components/common/Popup';
import { PopupContent } from 'containers/RegisterContract/View/styled';

type PropsT = {
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const RentContract: FC<PropsT> = (props) => {
  const { setContractValues, dataRentContract } = useData();
  const formData = useForm<ContractFormData>({
    resolver: yupResolver(rentContractSchema),
    defaultValues: { ...INITIAL_CONTRACT_FORM_DATA(), ...dataRentContract }
  });
  const {
    register,
    getValues,
    control,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors }
  } = formData;
  const { t } = useGlobalTranslation();
  const { g } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const watchType = watch('type');
  const depositAmount = watch('deposit_sum');
  const monthSum = watch('month_sum');
  const isDepositAccount = watch('is_deposit_account_variant');
  const { setStep } = props;

  const onSubmit = (data: ContractFormData) => {
    setContractValues(data);
    setStep(StepEnum.CREATE_PASSWORD);
  };

  const handleBackClick = () => {
    setContractValues(getValues());
    setStep(StepEnum.INFO_ABOUT_TENANT);
  };

  useEffect(() => {
    if (Number(monthSum) * 6 < depositAmount) {
      setError('deposit_sum', { message: 'Max' });
    } else {
      clearErrors('deposit_sum');
    }
  }, [depositAmount]);

  useEffect(() => {
    setValue('deposit_account', isDepositAccount === '2' ? dataRentContract.deposit_account : '');
    setValue(
      'deposit_count_payment_day',
      isDepositAccount === '1' ? dataRentContract.deposit_count_payment_day : undefined
    );
  }, [isDepositAccount]);

  return (
    <>
      <FormProvider {...formData}>
        <Content>
          <Title>{t('rentContract')}</Title>
          <StyledContent>
            <Select
              control={control}
              name={'type'}
              label={f('type')}
              options={[
                { value: 'running', label: f('running') },
                { value: 'time-bound', label: f('timeBound') }
              ]}
              isRequired
              error={errors.type}
            />
            <StyledCheckbox register={register('taxable')}>{f('taxable')}</StyledCheckbox>
            <DateField
              register={register('start_date')}
              value={getValues('start_date')}
              variant={'outline'}
              label={f('startDate')}
              withoutPrompt
              required
              error={errors.start_date}
            />
            {watchType === 'time-bound' && (
              <DateField
                register={register('end_date')}
                value={getValues('end_date')}
                variant={'outline'}
                label={f('endDate')}
                withoutPrompt
                required
                error={errors.end_date}
              />
            )}
            <DateField
              register={register('first_payment_date')}
              value={getValues('first_payment_date')}
              variant={'outline'}
              label={f('dateOfFirstPayment')}
              withoutPrompt
              required
              error={errors.first_payment_date}
            />
            <Input
              register={register('month_resignation')}
              type={'number'}
              withoutPrompt
              variant={'outline'}
              label={f('numberOfMonthResignation')}
              required
              error={errors.month_resignation}
            />
            <Input
              register={register('month_sum')}
              type={'number'}
              withoutPrompt
              variant={'outline'}
              label={f('monthlyRentNOK')}
              required
              error={errors.month_sum}
            />
          </StyledContent>
          <StyledLabel>{f('deposit')}</StyledLabel>
          <RadiosContent>
            {radios.map(({ value, label, id }) => (
              <StyledRadioButton
                key={id}
                control={control}
                label={f(label)}
                {...register('is_deposit_account_variant')}
                value={value}
              />
            ))}
          </RadiosContent>
          {isDepositAccount === '1' && (
            <div style={{ width: 'max-content', paddingLeft: 10, marginBottom: 20 }}>
              <Popup
                mode={'click'}
                button={(onClick) => (
                  <TextButton onClick={onClick} color={'blue01'} variant={'text'}>
                    {f('openDepAccountWebsite')}
                    <Icon color={'blue01'} icon={infoIcon} css={infoIconCss} />
                  </TextButton>
                )}
                content={() => <PopupContent>{g('helpsContent.openDepAccountWebsite')}</PopupContent>}
              />
            </div>
          )}
          {(isDepositAccount === '1' || isDepositAccount === '2') && (
            <StyledContent>
              {isDepositAccount === '2' && (
                <Input
                  required
                  register={register('deposit_account')}
                  withoutPrompt
                  variant={'outline'}
                  label={f('depositAccountIBAN')}
                  error={errors.deposit_account}
                />
              )}
              <Input
                register={register('deposit_sum')}
                withoutPrompt
                variant={'outline'}
                label={f('deposit_amount')}
                error={errors.deposit_sum}
                required
              />
              {isDepositAccount === '2' && (
                <DateField
                  register={register('deposit_payment_deadline')}
                  value={getValues('deposit_payment_deadline')}
                  variant={'outline'}
                  label={f('payment_deadline')}
                  withoutPrompt
                  error={errors.deposit_payment_deadline}
                  required
                />
              )}
              {isDepositAccount === '1' && (
                <Input
                  type={'number'}
                  register={register('deposit_count_payment_day')}
                  withoutPrompt
                  variant={'outline'}
                  label={f('deposit_count_payment_day')}
                  error={errors.deposit_count_payment_day}
                  required
                />
              )}
            </StyledContent>
          )}
          <StyledLabel>{f('including')}</StyledLabel>
          <StyledContent style={{ marginTop: 15 }}>
            <Input
              register={register('keys_count')}
              withoutPrompt
              variant={'outline'}
              label={f('numberOfKeysHandedOut')}
              type={'number'}
              required
              error={errors.keys_count}
            />
            <div />
            <div>
              <StyledCheckbox register={register('comforts')} value={'electricity'}>
                {f('electricity')}
              </StyledCheckbox>
              <StyledCheckbox register={register('comforts')} value={'heating'}>
                {f('heating')}
              </StyledCheckbox>
              <StyledCheckbox register={register('comforts')} value={'water-drain'}>
                {f('waterDrain')}
              </StyledCheckbox>
              <StyledCheckbox register={register('comforts')} value={'tv-subscription'}>
                {f('tVSubscription')}
              </StyledCheckbox>
              <StyledCheckbox register={register('comforts')} value={'internet'}>
                {f('internet')}
              </StyledCheckbox>
            </div>
            <div>
              <StyledCheckbox register={register('pets_allowed')}>{f('petsAllowed')}</StyledCheckbox>
              <StyledCheckbox register={register('smoking_allowed')}>{f('smokingAllowed')}</StyledCheckbox>
            </div>
          </StyledContent>
          <StyledContent>
            <StyledDescriptionContainer>
              <Textarea rows={5} register={register('other')} withoutPrompt variant={'outline'} label={f('other')} />
            </StyledDescriptionContainer>
          </StyledContent>
          <div style={{ gridColumn: '1/3', margin: '20px 15px 0 15px' }}>
            <FileField initialFilesAsFile={dataRentContract.documents} register={register('documents')} isMulti />
          </div>
          <ActionsButton>
            <StyledButton type={'button'} variant={'outline'} onClick={handleBackClick}>
              {a('back')}
            </StyledButton>
            <StyledButton type={'button'} variant={'fill'} onClick={handleSubmit(onSubmit)}>
              {t('next')}
            </StyledButton>
          </ActionsButton>
        </Content>
      </FormProvider>
    </>
  );
};

export default RentContract;

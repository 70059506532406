import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_LOGIN } from 'constant/routes';
import { authSlice } from 'data/store/authReducer';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import ConfirmationModal from 'components/common/ConfirmationModal';

const RegistrationClose: FC = () => {
  const { registrationCloseModal } = useAppSelector((state) => state.modals);
  const { closeRegistrationModal } = modalsSlice.actions;
  const { logout } = authSlice.actions;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useGlobalTranslation('registration');

  return (
    <ConfirmationModal
      isOpen={registrationCloseModal}
      title={t('registrationCloseModal.title')}
      text={t('registrationCloseModal.text')}
      cancelButtonProps={{
        onClick: () => dispatch(closeRegistrationModal()),
        children: t('registrationCloseModal.cancel')
      }}
      confirmButtonProps={{
        onClick: (onClose) => {
          dispatch(logout());
          history.push(ROUTE_LOGIN);
          onClose();
        },
        children: t('registrationCloseModal.logout')
      }}
    />
  );
};

export default RegistrationClose;

import styled, { css } from 'styled-components';
import Button from 'components/controls/Button';
import { hexColorOpacity } from 'helpers/color';

export type VariantT = 'top' | 'bottom' | 'bottomLeft';

type ListPropsT = {
  variant: VariantT;
  isOpen: boolean;
  isWithoutFullWidth?: boolean;
};

export const StyledContainer = styled.div`
  display: grid;
  position: relative;
`;

const topListPosition = css`
  margin-bottom: 5px;
  bottom: 100%;
  right: 0;
`;

const bottomListPosition = css`
  margin-top: 5px;
  top: 100%;
`;

const bottomLeftListPosition = css`
  margin-top: 5px;
  top: 100%;
  right: 0;
`;

export const StyledList = styled.div<ListPropsT>`
  position: absolute;
  display: grid;
  background-color: ${({ theme }) => theme.colors.gray06};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-width: ${({ isWithoutFullWidth }) => (isWithoutFullWidth ? '100%' : 'initial')};
  min-width: 100%;
  width: max-content;
  border-radius: 3px;
  z-index: 100;
  animation: ${({ isOpen }) => (isOpen ? 'hiddenBlock 0.25s ease' : 'fadeBlock 0.25s ease')};

  ${({ variant }) => variant === 'top' && topListPosition}
  ${({ variant }) => variant === 'bottom' && bottomListPosition}
  ${({ variant }) => variant === 'bottomLeft' && bottomLeftListPosition}

  @keyframes fadeBlock {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hiddenBlock {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

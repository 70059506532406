import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import StepEnum from 'domain/entity/registration/StepEnum';
import { RootState } from './store';
import { RegistrationFormT } from '../../domain/entity/registration/RegistrationFormData';

export interface RegistrationState {
  step: StepEnum;
  pending?: boolean;
  pendingCheckEmail?: boolean;
  error?: string;
  checkEmailError?: string;
  successEmail?: boolean;
}

const initialState: RegistrationState = {
  step: StepEnum.general,
  pendingCheckEmail: undefined,
  pending: undefined,
  error: undefined,
  checkEmailError: undefined,
  successEmail: undefined
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    checkEmail(state: RegistrationState, _: PayloadAction<{ email: string }>) {
      state.pendingCheckEmail = true;
    },
    checkEmailSuccess(state: RegistrationState) {
      state.successEmail = true;
      state.pendingCheckEmail = false;
      state.checkEmailError = undefined;
    },
    checkEmailError(state: RegistrationState, { payload }: PayloadAction<string>) {
      state.pendingCheckEmail = false;
      state.checkEmailError = payload;
    },
    registration(state: RegistrationState, _: PayloadAction<RegistrationFormT>) {
      state.pending = true;
    },
    registrationSuccess(state: RegistrationState, { payload }: PayloadAction<number>) {
      state.pending = false;
      state.step = payload;
      state.error = undefined;
      state.checkEmailError = undefined;
      state.successEmail = undefined;
    },
    registrationError(state: RegistrationState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    setInitialData(state: RegistrationState) {
      state.error = undefined;
      state.checkEmailError = undefined;
      state.successEmail = undefined;
    }
  }
});

const selectRegistration = (state: RootState): RegistrationState => state.registration;

export const registrationSelector = createSelector([selectRegistration], (registration) => registration);

export const { checkEmail, checkEmailSuccess, checkEmailError, registration, registrationSuccess, registrationError } =
  registrationSlice.actions;

export default registrationSlice.reducer;

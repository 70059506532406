import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { BilagFormDataT } from '../../domain/entity/bilag/BilagFormData';
import {
  createManualBilagApi,
  deleteManualBilagApi,
  editManualBilagApi,
  restoreBilagApi,
  startStopBilagApi
} from '../driver/bilag/Bilag';
import {
  createManualBilagError,
  createManualBilagSuccess,
  deleteManualBilagError,
  deleteManualBilagSucces,
  restoreBilagSuccess,
  startStopBilagError,
  startStopBilagSuccess
} from '../store/bilagReducer';
import { fetchAllDocuments, fetchAllDocumentsSuccess, setIsTrashTrue } from '../store/documentReducer';
import { uploadFileApi } from '../driver/file/File';
import { closeInfoBilag, closeManualBilag, openInfoBilag } from '../store/modalReducer';
import { setSelectedHouse } from '../store/houseReducer';
import HouseData from '../../domain/entity/house/HouseData';
import { fetchAllDocumentsApi } from '../driver/documents/Documents';

export function* createManualBilagSaga({
  payload: { data, currentHouse }
}: PayloadAction<{ data: BilagFormDataT; currentHouse: HouseData }>) {
  try {
    const response = yield call(uploadFileApi, data.documents);
    const newBilag = yield call(data.id ? editManualBilagApi : createManualBilagApi, {
      ...data,
      documents: response
    });
    yield put(createManualBilagSuccess());
    yield put(closeManualBilag());
    if (data.id) {
      // @ts-ignore
      yield put(openInfoBilag({ currentBilag: { type: 'bilag', bilag: newBilag } }));
    }
    yield put(setSelectedHouse({ ...currentHouse }));
  } catch (e) {
    yield put(createManualBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* deleteManualBilagSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(deleteManualBilagApi, payload);
    yield put(deleteManualBilagSucces());
    yield put(setIsTrashTrue(payload));
    yield put(closeInfoBilag());
  } catch (e) {
    yield put(deleteManualBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* startStopBilagSaga({
  payload
}: PayloadAction<{ id: number; isStopped: boolean; currentHouse: HouseData }>) {
  try {
    yield call(startStopBilagApi, payload);
    yield put(setSelectedHouse({ ...payload.currentHouse }));
    yield put(startStopBilagSuccess());
    yield put(closeInfoBilag());
  } catch (e) {
    yield put(startStopBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* restoreBilagSaga({ payload }: PayloadAction<{ id: number; type: string; houseId: number }>) {
  try {
    yield call(restoreBilagApi, payload);
    const response = yield call(fetchAllDocumentsApi, payload);
    yield put(fetchAllDocumentsSuccess(response));
    yield put(restoreBilagSuccess());
  } catch (e) {}
}

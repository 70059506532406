import Breakpoint from 'enum/Breakpoint';
import { DefaultTheme, ThemedCssFunction } from 'styled-components';

const lower = (bp: Breakpoint): string => `(max-width: ${bp - 1}px)`;
const greater = (bp: Breakpoint): string => `(min-width: ${bp}px)`;
const between = (bpFrom: Breakpoint, bpTo: Breakpoint) => `${greater(bpFrom)} and ${lower(bpTo)}`;

export const rule = {
  lowerXxs: lower(Breakpoint.Xxs),
  lowerXs: lower(Breakpoint.Xs),
  lowerSm: lower(Breakpoint.Sm),
  lowerMd: lower(Breakpoint.Md),
  lowerLg: lower(Breakpoint.Lg),
  lowerXl: lower(Breakpoint.Xl),
  lowerXxl: lower(Breakpoint.Xxl),
  greaterXxs: greater(Breakpoint.Xxs),
  greaterXs: greater(Breakpoint.Xs),
  greaterSm: greater(Breakpoint.Sm),
  greaterMd: greater(Breakpoint.Md),
  greaterLg: greater(Breakpoint.Lg),
  greaterXl: greater(Breakpoint.Xl),
  greaterXxl: greater(Breakpoint.Xxl),
  greaterXxXl: greater(Breakpoint.XxXl),
  betweenSmMd: between(Breakpoint.Sm, Breakpoint.Md),
  betweenSmLg: between(Breakpoint.Sm, Breakpoint.Md),
  betweenSmXs: between(Breakpoint.Sm, Breakpoint.Xs),
  betweenMdLg: between(Breakpoint.Md, Breakpoint.Lg),
  betweenXsSm: between(Breakpoint.Xs, Breakpoint.Sm)
};

type RuleT = keyof typeof rule;

/**
 * @example
 *
 * styled.div`
 *     ${mq.lowerSm} {
 *         color: red;
 *     }
 * `
 *
 * // same as above
 * styled.div`
 *     @media (max-width: ${Breakpoint.Sm}) {
 *         color: red;
 *     }
 * `
 * */
export const mq = (Object.keys(rule) as RuleT[]).reduce(
  (acc, bp) => ({
    ...acc,
    [bp]: `@media ${rule[bp]}`
  }),
  {} as Record<RuleT, string>
);

/**
 * @example
 *
 * styled.div`${hidden.lowerSm}`;
 *
 * // same as above
 * styled.div`
 *     @media (max-width: ${Breakpoint.Sm}) {
 *         display: none;
 *     }
 * `;
 * */
export const hidden = (Object.keys(mq) as RuleT[]).reduce(
  (acc, bp) => ({
    ...acc,
    [bp]: `
      ${mq[bp]} {
        display: none;
      }
    `
  }),
  {} as Record<RuleT, ThemedCssFunction<DefaultTheme>>
);

import styled, { css } from 'styled-components';
import BaseButton from 'components/controls/Button';

export const Wrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray01};
  grid-auto-flow: column;
`;

export const ActionsContainer = styled.div`
  display: flex;
`;

export const TitleButton = styled(BaseButton)`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.black01};
`;

export const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.black01};

  &:focus {
    box-shadow: none;
  }
`;

export const arrowCss = (isRotate?: boolean) => css`
  width: 22px;
  transform: rotate(${isRotate ? '180deg' : 0});
  fill: currentColor;
`;

import styled from 'styled-components';
import { ThemeT } from 'context/Theme';

type WrapperPropsT = {
  color?: keyof ThemeT['colors'];
  hiddenAnimation?: boolean;
};

export const Wrapper = styled.div<WrapperPropsT>`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 70px;
  animation: ${({ hiddenAnimation }) => (hiddenAnimation ? 'hiddenBlock 0.4s' : 'showBlock 0.4s')};
  z-index: 9999999;

  @keyframes showBlock {
    from {
      bottom: -100px;
    }
    to {
      bottom: 70px;
    }
  }

  @keyframes hiddenBlock {
    from {
      bottom: 70px;
    }
    to {
      bottom: -100px;
    }
  }
`;

export const Block = styled.div<WrapperPropsT>`
  padding: 10px;
  background-color: ${({ theme, color = 'green01' }) => theme.colors[color]};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white01};
  width: max-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

import React, { Dispatch, FC, SetStateAction } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import INITIAL_HOUSE_CREATE_DATA, { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import { Content, Title, StyledContent } from '../LandlordInformation/styled';
import { StepEnum } from '../data';
import { ActionsButton, StyledButton } from './styled';
import { useData } from '../index';
import Fields from '../../../../components/feature/Fields';
import { HOUSE_INFO_FIELDS } from '../../../../components/feature/Fields/fields';
import { houseSchema } from '../../../../data/validations/houseValidation';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const HouseInfo: FC<PropsT> = (props) => {
  const { dataHouseInfo, setHouseInfoValues } = useData();
  const formData = useForm<HouseCreateFormT>({
    resolver: yupResolver(houseSchema),
    defaultValues: { ...INITIAL_HOUSE_CREATE_DATA, ...dataHouseInfo }
  });
  const { handleSubmit, getValues } = formData;
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const { setStep } = props;

  const onSubmit = (data: HouseCreateFormT) => {
    setHouseInfoValues(data);
    setStep(StepEnum.INFO_ABOUT_TENANT);
  };

  const handleBackStepClick = () => {
    setHouseInfoValues(getValues());
    setStep(StepEnum.LANDLORD_INFORMATION);
  };

  return (
    <FormProvider {...formData}>
      <Content>
        <Title>{t('sidebar.houseInfo')}</Title>
        <StyledContent>
          <Fields inputProps={{ variant: 'outline', withoutPrompt: true }} fields={HOUSE_INFO_FIELDS(false)} />
        </StyledContent>
        <ActionsButton>
          <StyledButton type={'button'} variant={'outline'} onClick={handleBackStepClick}>
            {a('back')}
          </StyledButton>
          <StyledButton type={'button'} variant={'fill'} onClick={handleSubmit(onSubmit)}>
            {t('next')}
          </StyledButton>
        </ActionsButton>
      </Content>
    </FormProvider>
  );
};

export default HouseInfo;

import styled, { css } from 'styled-components';

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background: radial-gradient(
    50% 227.56% at 50% 50%,
    rgba(255, 255, 255, 0.144) 4.69%,
    rgba(103, 103, 103, 0.142) 100%
  );
  position: fixed;
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  z-index: -4;
`;

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FETCH_REDIRECT_LINK_TO_SIGNICAT } from 'constant/apiRoutes';
import { ROUTE_NOT_FOUND_PAGE } from 'constant/routes';
import { http } from 'data/driver/Api';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Spinner from 'components/ui/Spinner/Spinner';
import Topbar from 'components/layouts/MainLayout/Topbar';
import { Content, Text } from './styled';

type ParamTypeT = 'lc' | 'tc' | 'lcl' | 'tcl';

type ParamsT = {
  type: ParamTypeT;
  slug: string;
};

const MapParamTypeToRequest: Record<ParamTypeT, { documentType: string; signer: string }> = {
  lc: {
    signer: 'landlord',
    documentType: 'contract'
  },
  tc: {
    signer: 'tenant',
    documentType: 'contract'
  },
  lcl: {
    signer: 'landlord',
    documentType: 'checklist'
  },
  tcl: {
    signer: 'tenant',
    documentType: 'checklist'
  }
};

const RedirectToSignicat: FC = () => {
  const { g } = useGlobalTranslation();
  const history = useHistory();
  const [redirectLink, setRedirectLink] = useState<string | null>();
  const [error, setError] = useState<string | null>(null);
  const { type, slug } = useParams<ParamsT>();

  const getRedirectLink = async () => {
    try {
      const { data } = await http.post(FETCH_REDIRECT_LINK_TO_SIGNICAT(slug), { ...MapParamTypeToRequest[type] });

      setRedirectLink(data);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    getRedirectLink().then();
  }, []);

  if (redirectLink) {
    window.location.replace(redirectLink);
  }

  if (error || redirectLink === null) {
    history.push(ROUTE_NOT_FOUND_PAGE);
  }

  return (
    <>
      <Topbar withoutNavigation isHideLogo={false} />
      <Content>
        <Text>{g('redirectToSignicat')}</Text>
        <Spinner blue={true} />
      </Content>
    </>
  );
};

export default RedirectToSignicat;

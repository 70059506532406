import React, { useState, useRef, useEffect, ReactNode, FC, MouseEventHandler, HTMLAttributes } from 'react';
import { StyledContainer, StyledList, VariantT } from './styled';

type PropsT = HTMLAttributes<HTMLDivElement> & {
  mode?: 'click' | 'hover';
  content?: (onClose: () => void, isOpen: boolean) => ReactNode;
  button: ((onClick: MouseEventHandler<HTMLButtonElement>, isOpen: boolean) => ReactNode) | ReactNode;
  onClose?: () => void;
  isWithoutFullWidth?: boolean;
  variant?: VariantT;
};

const Popup: FC<PropsT> = (props) => {
  const {
    mode = 'click',
    button,
    children,
    content,
    variant = 'bottom',
    onClose,
    isWithoutFullWidth,
    ...restProps
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReal, setIsOpenReal] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const rootRef = useRef<HTMLDivElement | null>(null);

  const handleClosePopup = () => {
    setStartAnimation(true);
    setIsOpenReal(false);
    if (onClose) {
      onClose();
    }
    setTimeout(() => {
      setStartAnimation(false);
      setIsOpen(false);
    }, 200);
  };

  useEffect(() => {
    let isCanceled = false;
    const onClick = (event: DocumentEventMap['click']) => {
      if (!rootRef.current?.contains(event?.target as Node)) {
        if (!isCanceled) handleClosePopup();
      }
    };
    if (isOpen) {
      document.addEventListener('click', onClick);
    } else {
      document.removeEventListener('click', onClick);
    }

    return () => {
      isCanceled = true;
      document.removeEventListener('click', onClick);
    };
  }, [isOpen]);

  const openPopup = () => {
    setIsOpenReal(true);
    setIsOpen(true);
  };

  const hoverContainerProps = {
    onMouseEnter: openPopup,
    onMouseLeave: handleClosePopup
  };

  return (
    <StyledContainer ref={rootRef} {...restProps} {...(mode === 'hover' ? hoverContainerProps : {})}>
      {typeof button === 'function' ? button(() => (isOpen ? handleClosePopup() : openPopup()), isOpenReal) : button}
      {isOpen && (
        <StyledList isWithoutFullWidth={isWithoutFullWidth} isOpen={startAnimation} variant={variant}>
          {(content && content(handleClosePopup, isOpen)) || children}
        </StyledList>
      )}
    </StyledContainer>
  );
};

export default Popup;

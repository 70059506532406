import React, { useState } from 'react';
import format from 'date-fns/format';
import { useTheme } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { CommonBilag } from 'domain/entity/documnets/CommonBilag';
import { modalsSlice } from 'data/store/modalReducer';
import { ThemeT } from 'context/Theme';
import { numberWithSpaces } from 'helpers/number';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { EmptyList } from 'components/ui';
import Icon from 'components/common/Icon';
import searchIcon from 'components/svg/search.svg?sprite';
import sortIcon from 'components/svg/topSort.svg?sprite';
import CellWithFilter from './Filter';
import NoSearched from './NoSearched';
import { getRenderItemValues, sortedData, SortT, TypeT } from './data';
import {
  StyledContainer,
  StyledHeader,
  StyledNameTable,
  searchIconCss,
  StyledInput,
  styleInputCss,
  fieldStyleCss,
  StyledTable,
  StyledHead,
  StyledCell,
  StyledRow,
  StyledBody,
  StyledHeadCell,
  StatusAuto,
  StyledCellSum,
  SortIcons,
  iconCss,
  iconCssReverse
} from './styled';

const Table = () => {
  const { watch, register } = useFormContext();
  const watchType: TypeT[] = watch('type');
  const watchSearch: string = watch('search');
  const [isFocused, setIsFocused] = useState(false);
  const [sort, setSort] = useState<SortT | null>(null);
  const { t } = useGlobalTranslation('pages');
  const { g } = useGlobalTranslation();
  const theme = useTheme();
  const { documents } = useAppSelector(({ documents }) => documents);
  const dispatch = useAppDispatch();
  const { openInfoBilag } = modalsSlice.actions;
  const tableData = documents
    ?.map((bilag) => getRenderItemValues(bilag))
    .filter(({ isTrashed }) => !isTrashed)
    .filter(({ type }) => (watchType.length === 0 ? true : watchType.includes(type as TypeT)))
    .filter(({ name }) => name.includes(watchSearch));

  const chooseSort = (key: 'string' | 'number' | 'date') => {
    if (!sort || sort.key !== key) {
      setSort({ key, type: 'ASC' });
      return;
    }
    if (sort.key === key && sort.type === 'ASC') {
      setSort({ key, type: 'DESC' });
    }
    if (sort.key === key && sort.type === 'DESC') {
      setSort(null);
    }
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledNameTable>{t('dashboard.allBilags')}</StyledNameTable>
        <StyledInput
          register={register('search')}
          isFocused={isFocused}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (!watchSearch) {
              setIsFocused(false);
            }
          }}
          inputStyleCss={styleInputCss}
          wrapperStyle={{}}
          fieldStyleCss={fieldStyleCss(isFocused, theme as ThemeT)}
          placeholder={g('search')}
          withoutPrompt
          startAdornment={<Icon icon={searchIcon} css={searchIconCss} />}
        />
      </StyledHeader>
      <StyledTable>
        <StyledHead>
          <StyledRow>
            <StyledHeadCell>
              {t('dashboard.table.heads.name')}
              <SortIcons onClick={() => chooseSort('string')}>
                <Icon icon={sortIcon} css={iconCss(sort?.key === 'string' && sort?.type === 'ASC')} />
                <Icon icon={sortIcon} css={iconCssReverse(sort?.key === 'string' && sort?.type === 'DESC')} />
              </SortIcons>
            </StyledHeadCell>
            <StyledHeadCell>
              <CellWithFilter />
            </StyledHeadCell>
            <StyledHeadCell>
              {t('dashboard.table.heads.date')}
              <SortIcons onClick={() => chooseSort('date')}>
                <Icon icon={sortIcon} css={iconCss(sort?.key === 'date' && sort?.type === 'ASC')} />
                <Icon icon={sortIcon} css={iconCssReverse(sort?.key === 'date' && sort?.type === 'DESC')} />
              </SortIcons>
            </StyledHeadCell>
            <StyledHeadCell>
              {t('dashboard.table.heads.amount')}
              <SortIcons onClick={() => chooseSort('number')}>
                <Icon icon={sortIcon} css={iconCss(sort?.key === 'number' && sort?.type === 'ASC')} />
                <Icon icon={sortIcon} css={iconCssReverse(sort?.key === 'number' && sort?.type === 'DESC')} />
              </SortIcons>
            </StyledHeadCell>
          </StyledRow>
        </StyledHead>
        <StyledBody>
          {sortedData(tableData || [], sort)?.map((bilag) => {
            const {
              id,
              type,
              isAutomaticallyDepreciation,
              isAutomaticallyBilag,
              isArchivedBilag,
              name,
              date,
              sum,
              colorSum
            } = bilag;
            return (
              <StyledRow
                onClick={() => {
                  dispatch(
                    openInfoBilag({
                      currentBilag: { ...documents?.find(({ bilag }) => bilag?.id === id) } as CommonBilag,
                      type: type || ''
                    })
                  );
                }}
                key={id}
                isAuto={!!(isAutomaticallyDepreciation || isAutomaticallyBilag || !isArchivedBilag)}
              >
                <StyledCell>
                  {g(name)} {isArchivedBilag && <StatusAuto isAuto={isArchivedBilag}>{g('archived')}</StatusAuto>}
                </StyledCell>
                <StyledCell>
                  <StatusAuto isAuto={!!(isAutomaticallyDepreciation || isAutomaticallyBilag)}>{g('auto')}</StatusAuto>
                  {type === 'manual-bilag' && g('manualBilag')}
                  {type === 'archived-bilag' && g('archivedBilag')}
                  {type === 'income-bilag' && g('incomeBilag')}
                  {type === 'depreciation' && g('depreciation')}
                  {type === 'rent-contract' && g('rentContract')}
                </StyledCell>
                <StyledCell>{date && format(new Date(date), 'dd.MM.yyyy')}</StyledCell>
                <StyledCellSum color={colorSum as unknown as keyof ThemeT['colors']}>
                  kr {numberWithSpaces(sum.toFixed(0))}
                </StyledCellSum>
              </StyledRow>
            );
          })}
        </StyledBody>
      </StyledTable>
      {((!tableData || tableData?.length === 0) && watchSearch.length === 0 && <EmptyList what={'bilag'} />) ||
        (watchSearch.length > 0 && <NoSearched />)}
    </StyledContainer>
  );
};

export default Table;

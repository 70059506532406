import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Bilag } from 'domain/entity/documnets/CommonBilag';
import { BilagFormDataT } from 'domain/entity/bilag/BilagFormData';
import { bilagSlice } from 'data/store/bilagReducer';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Range from 'components/controls/Range';
import Button from 'components/controls/Button';
import Input from 'components/controls/Input/Input';
import Select from 'components/FormControls/Select';
import FileField from 'components/controls/FileField';
import DateField from 'components/controls/DateField';
import { SelectOptionT } from 'components/controls/Select';
import schema from './validation';
import { COST_TYPE, REPEAT } from './data';
import { Content, StyledDescriptionContainer, ActionsButton, ErrorMessage } from './styled';
import { Span } from '../../IncomeBilag/View/styled';
import { APP_SUPPLIER_COMPANY_ID } from '../../../../../constant/global';

type PropsT = {
  defaultValues: BilagFormDataT;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
};

const View: FC<PropsT> = (props) => {
  const { defaultValues, setIsDirty } = props;
  const {
    bilagInfo: { currentBilag }
  } = useAppSelector((state) => state.modals);
  const isEdit = Boolean(currentBilag?.bilag.id);
  const formData = useForm<BilagFormDataT>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isDirty },
    getValues,
    setValue,
    watch
  } = formData;
  const { t: f } = useGlobalTranslation('fields');
  const { g } = useGlobalTranslation();
  const { t: a } = useGlobalTranslation('actions');
  const { t: o } = useGlobalTranslation('options');
  const { pending, error } = useAppSelector((state) => state.bilag);
  const { suppliers } = useAppSelector((state) => state.supplier);
  const { houseList, selectedHouse } = useAppSelector((state) => state.house);
  const houseOptions: SelectOptionT[] = houseList?.map(({ name, id }) => ({ value: id, label: name })) || [];
  const watchAmount = watch('sum');
  const watchSupplier = watch('supplierId');
  const { createManualBilag } = bilagSlice.actions;
  const { openArchiveBilag } = modalsSlice.actions;
  const dispatch = useAppDispatch();

  const isArchivedBilag = (currentBilag?.type === 'bilag' && currentBilag.bilag.archived && true) || false;
  const originalSupplierId = currentBilag?.type === 'bilag' && currentBilag.bilag.supplier.id;

  const suppliersOptions =
    suppliers
      ?.filter(
        ({ id, archived }) =>
          !archived || (archived && originalSupplierId === id && (isArchivedBilag || originalSupplierId === id))
      )
      .map(({ id, name, archived }) => ({
        value: id,
        label: (name || '') + ((archived && g('options.supplier.archived')) || '')
      })) || [];

  const onSubmit = (data: BilagFormDataT) => {
    dispatch(createManualBilag({ data: { ...data, archived: false }, currentHouse: selectedHouse }));
  };

  const onSubmitArchived = () => {
    dispatch(
      openArchiveBilag(() =>
        dispatch(createManualBilag({ data: { ...getValues(), archived: true }, currentHouse: selectedHouse }))
      )
    );
  };

  useEffect(() => {
    const supplier = suppliers?.find(({ id }) => id === watchSupplier);
    setValue('supplier_name', supplier?.name);
    setValue('supplier_address', supplier?.address);
    setValue('supplier_city', supplier?.city);
    setValue('supplier_postcode', supplier?.postcode);
    clearErrors('supplier_name');
    clearErrors('supplier_address');
    clearErrors('supplier_city');
    clearErrors('supplier_postcode');
  }, [watchSupplier, suppliers]);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <FormProvider {...formData}>
      <Span />
      <Content>
        <Select
          control={control}
          name={'houseId'}
          defaultValue={selectedHouse?.id}
          options={houseOptions}
          label={f('houseName')}
          isRequired
        />
        <Select
          disabled={currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate}
          control={control}
          name={'cost_type'}
          defaultValue={COST_TYPE[0].value}
          options={COST_TYPE.map(({ label, value }) => ({ value, label: o(label) }))}
          label={f('costType')}
          error={errors.cost_type}
          isRequired
        />
        <StyledDescriptionContainer>
          <Input
            register={register('description')}
            withoutPrompt
            variant={'outline'}
            label={f('description')}
            required
            error={errors.description}
          />
        </StyledDescriptionContainer>
      </Content>
      <Content>
        <Select
          disabled={currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate}
          control={control}
          name={'supplierId'}
          defaultValue={0}
          options={[{ value: 0, label: 'New supplier' }, ...suppliersOptions]}
          label={f('supplier')}
          error={errors.supplierId}
          isRequired
        />
        <Input
          register={register('supplier_name')}
          disabled={
            (currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate) ||
            watchSupplier === APP_SUPPLIER_COMPANY_ID
          }
          withoutPrompt
          variant={'outline'}
          label={f('name')}
          required
          error={errors.supplier_name}
        />
        <Input
          register={register('supplier_address')}
          disabled={
            (currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate) ||
            watchSupplier === APP_SUPPLIER_COMPANY_ID
          }
          withoutPrompt
          variant={'outline'}
          label={f('address')}
          required
          error={errors.supplier_address}
        />
        <Input
          register={register('supplier_postcode')}
          disabled={
            (currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate) ||
            watchSupplier === APP_SUPPLIER_COMPANY_ID
          }
          withoutPrompt
          variant={'outline'}
          label={f('postcode')}
          required
          error={errors.supplier_postcode}
        />
        <Input
          register={register('supplier_city')}
          disabled={
            (currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate) ||
            watchSupplier === APP_SUPPLIER_COMPANY_ID
          }
          withoutPrompt
          variant={'outline'}
          label={f('city')}
          required
          error={errors.supplier_city}
        />
      </Content>
      <Content>
        <Select
          disabled={currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate}
          control={control}
          name={'repeat'}
          defaultValue={REPEAT[0].value}
          options={REPEAT.map(({ value, label }) => ({ value, label: o(label) }))}
          label={f('repeat')}
          error={errors.repeat}
          isRequired
        />
        <Input
          disabled={currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate}
          register={register('sum')}
          withoutPrompt
          type={'number'}
          variant={'outline'}
          label={f('amountNOK')}
          required
          error={errors.sum}
        />
        <DateField
          disabled={currentBilag?.type === 'bilag' && currentBilag.bilag.isAutoGenerate}
          required
          value={getValues('date')}
          register={register('date')}
          variant={'outline'}
          label={f('date')}
          withoutPrompt
          error={errors.date}
        />
        <Range sum={watchAmount || 0} register={register('franchise')} label={f('ofWhichDeductible')} required />
        <div style={{ gridColumn: '1/3' }}>
          <FileField
            initialFiles={(currentBilag?.bilag as Bilag)?.documents}
            register={register('documents')}
            isMulti
          />
        </div>
      </Content>
      {error && <ErrorMessage>{g(error)}</ErrorMessage>}
      <ActionsButton isEdit={isEdit}>
        {!isEdit && (
          <Button disabled={pending} variant={'outline'} onClick={onSubmitArchived}>
            {a('archive')}
          </Button>
        )}
        <Button isLoading={pending} disabled={pending} onClick={handleSubmit(onSubmit)}>
          {a('save')}
        </Button>
      </ActionsButton>
    </FormProvider>
  );
};

export default View;

import styled from 'styled-components';

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.125rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9;
  &:focus {
    outline: none;
  }

  div {
    width: 1.5rem;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.black01};
    transition: all 300ms linear;
    position: relative;
    transform-origin: 1px;
  }
`;

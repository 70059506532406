import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { ContractFormData, INITIAL_CONTRACT_FORM_DATA } from 'domain/entity/rentContract/RentContract';
import RentContract from './RentContract';
import InformAboutTenant from './InformAboutTenant';
import TenancyTerms from './TenancyTerms';
import Including from './Including';
import DepositAccount from './DepositAccount';
import { StepEnum } from './data';
import SaveRentContract from './SaveRentContract';
import HelpDepositContent from './HelpDepositContent';
import HelpSignByID from './HelpSignByID';
import { useAppSelector } from '../../../../../hooks/redux';

type PropsT = {
  index?: number;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<StepEnum>>;
  step: number;
  initialValue: ContractFormData;
};

const DataContext = createContext<{
  data: ContractFormData;
  setValues: (values: Partial<ContractFormData>) => void;
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
}>({ setValues: () => {}, data: INITIAL_CONTRACT_FORM_DATA() });

const View: FC<PropsT> = (props) => {
  const { setIsDirty, setStep, step, initialValue } = props;
  const [data, setData] = useState<ContractFormData>(initialValue);
  const setValues = (values: Partial<ContractFormData>) => {
    setData((prevState) => ({ ...prevState, ...values }));
  };

  useEffect(() => {
    return () => {
      setStep(StepEnum.RENT_CONTRACT);
    };
  }, []);

  return (
    <DataContext.Provider value={{ data, setValues, setIsDirty }}>
      {step === StepEnum.RENT_CONTRACT && <RentContract setStep={setStep} />}
      {step === StepEnum.INFORM_ABOUT_TENANT && <InformAboutTenant setStep={setStep} />}
      {step === StepEnum.TENANCY_TERMS && <TenancyTerms setStep={setStep} />}
      {step === StepEnum.INCLUDING && <Including setStep={setStep} />}
      {step === StepEnum.HELP_DEPOSIT && <HelpDepositContent setStep={setStep} />}
      {step === StepEnum.HELP_SIGN_BY_ID && <HelpSignByID setStep={setStep} />}
      {step === StepEnum.DEPOSIT_ACCOUNT && <DepositAccount setStep={setStep} />}
      {step === StepEnum.SAVE_RENT_CONTRACT && <SaveRentContract setStep={setStep} />}
    </DataContext.Provider>
  );
};

export default View;

export const useData = () => useContext(DataContext);

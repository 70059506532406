import styled from 'styled-components';
import { mq } from 'constant/mediaqueries';

type ContainerProps = {
  checked: boolean;
};

export const StyledContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ checked, theme }) => theme.colors[(checked && 'blue02') || 'blue01']};
  background-color: ${({ checked, theme }) => (checked && theme.colors.blue02) || 'transparent'};
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  margin: 0 0.25rem;
  position: relative;
  transition: all 0.3s ease-out;
  height: 10rem;
  width: 9.75rem;

  ${mq.greaterXs} {
    margin: 1rem;
    height: 12.75rem;
    width: 12rem;
  }
`;

export const StyledTitle = styled.div`
  margin: 0 0 0.75rem 0;
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: 500;
`;

export const StyledPrice = styled.div`
  color: ${({ theme }) => theme.colors.black01};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-weight: 500;
`;

export const StyledSubtitle = styled.div`
  margin: 0.75rem 0 0 0;
  color: ${({ theme }) => theme.colors.gray03};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 500;
`;

export const StyledSale = styled.span`
  position: absolute;
  top: -1rem;
  padding: 0.25rem 1.125rem;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.blue01};
  color: ${({ theme }) => theme.colors.white01};
  line-height: 22px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

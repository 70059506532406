import { httpWithAuth } from 'data/driver/Api';
import { FETCH_SUBSCRIPTION, FETCH_SUBSCRIPTION_INFO, FETCH_SUBSCRIPTION_PORTAL } from 'constant/apiRoutes';

export const fetchSubscriptionInfo = async () => {
  const { data } = await httpWithAuth.get(FETCH_SUBSCRIPTION_INFO);

  return data;
};

export const fetchSubscriptionPortalUrl = async () => {
  const { data } = await httpWithAuth.get(FETCH_SUBSCRIPTION_PORTAL);

  return data;
};

export const requestSubscription = async ({ priceId }: { priceId: string }) => {
  const { data } = await httpWithAuth.post(FETCH_SUBSCRIPTION(priceId));

  return data;
};

import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_ALTINN_INDEX, ROUTE_PROFILE_INDEX } from 'constant/routes';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant/externalLinks';
import { authSlice } from 'data/store/authReducer';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import plusIcon from 'components/svg/plus.svg?sprite';
import Nav from './Nav';
import SelectHome from './SelectHome';
import ProfileInfo from './ProfileInfo';
import {
  StyledContainer,
  StyledHeader,
  Divider,
  StyledContent,
  StyledAddButton,
  StyledWidthFixed,
  plusIconCss,
  Link,
  StyledFooter,
  LinkContainer
} from './styled';

type PropsT = {
  isOpen: boolean;
  openDeleteAlert: boolean;
  setOpenDeleteAlert: Dispatch<SetStateAction<boolean>>;
};

const Sidebar: FC<PropsT> = (props) => {
  const { isOpen, openDeleteAlert, setOpenDeleteAlert } = props;
  const { location } = useHistory();
  const { g } = useGlobalTranslation();
  const { t: actionsTranslate } = useGlobalTranslation('actions');
  const { openAddHouse, openManyHouses } = modalsSlice.actions;
  const { logout } = authSlice.actions;
  const { houseList } = useAppSelector((state) => state.house);
  const dispatch = useAppDispatch();

  return (
    <StyledContainer isOpen={isOpen}>
      <StyledWidthFixed isOpen={isOpen}>
        <StyledHeader>
          {!location.pathname.includes(ROUTE_PROFILE_INDEX) && <SelectHome />}
          {location.pathname.includes(ROUTE_PROFILE_INDEX) && <ProfileInfo />}
          <Divider />
        </StyledHeader>

        <StyledContent>
          {!location.pathname.includes(ROUTE_ALTINN_INDEX) && <Nav {...{ openDeleteAlert, setOpenDeleteAlert }} />}
        </StyledContent>
        <StyledFooter>
          <Divider />
          {location.pathname.includes('home') && (
            <StyledAddButton
              type={'button'}
              variant={'outline'}
              onClick={() => dispatch((houseList?.length || 0) < 4 ? openAddHouse() : openManyHouses())}
            >
              <Icon icon={plusIcon} css={plusIconCss} />
              {g('sidebar.addNewHouse')}
            </StyledAddButton>
          )}
          {!location.pathname.includes('home') && !location.pathname.includes('altinn') && (
            <>
              <StyledAddButton
                style={{ marginBottom: 0 }}
                type={'button'}
                variant={'outline'}
                onClick={() => dispatch(logout())}
              >
                {actionsTranslate('logout')}
              </StyledAddButton>
              <LinkContainer>
                <Link href={TERMS_OF_USE_URL} target="_blank">
                  {g('terms')}
                </Link>
                <Link href={PRIVACY_POLICY_URL} target="_blank">
                  {g('policy')}
                </Link>
              </LinkContainer>
            </>
          )}
        </StyledFooter>
      </StyledWidthFixed>
    </StyledContainer>
  );
};

export default Sidebar;

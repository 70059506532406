import styled from 'styled-components';
import { LinkProps } from 'react-router-dom';

export const StyledContainer = styled.div`
  max-width: 1620px;
  box-sizing: content-box;
  width: 100%;
  padding: 0 50px 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
`;

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const Column = styled.div<ColumnT>`
  display: flex;
  flex-direction: column;
  max-width: 1092px;
  align-items: ${({ alignItems }) => (alignItems && alignItems) || 'initial'};
`;

export const StyledHr = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray07};
  width: 100%;
  margin: 0 0 0.25rem 0;
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0 0 0 0.5rem;
`;

export const StyledLink = styled.div<LinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.875rem;
  cursor: pointer;
`;

import { formatDateForApi } from './date';
import { isValidPhoneNumber } from 'react-phone-number-input/input';

export const checkDataRengeDate = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return true;
  }
  if (startDate && endDate) {
    return (
      new Date(String(formatDateForApi(startDate))).getTime() - new Date(String(formatDateForApi(endDate))).getTime() <=
      0
    );
  }
  return false;
};

export const checkPhone = (phone: string, countryCode: string) => {
  if (!phone && !countryCode) {
    return false;
  }
  const phoneWithCountry = `${countryCode}${phone}`;
  if (/^\+0/.test(phoneWithCountry)) {
    return false;
  }
  return isValidPhoneNumber(phoneWithCountry);
};

import React, { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import INITIAL_HOUSE_CREATE_DATA, { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import { modalsSlice } from 'data/store/modalReducer';
import { houseSlice } from 'data/store/houseReducer';
import { useAppDispatch } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import { Content, ActionsButton } from './styled';
import Fields from '../../../../feature/Fields';
import { HOUSE_INFO_FIELDS } from '../../../../feature/Fields/fields';
import { houseSchema } from 'data/validations/houseValidation';

const View: FC = () => {
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const { closeAddHouse } = modalsSlice.actions;
  const { createHouse } = houseSlice.actions;
  const dispatch = useAppDispatch();
  const houseForm = useForm<HouseCreateFormT>({
    resolver: yupResolver(houseSchema),
    defaultValues: INITIAL_HOUSE_CREATE_DATA
  });

  const onSubmit = (formData: HouseCreateFormT) => {
    dispatch(createHouse(formData));
    dispatch(closeAddHouse());
  };

  return (
    <form onSubmit={houseForm.handleSubmit(onSubmit)}>
      <Content>
        <FormProvider {...houseForm}>
          <Fields inputProps={{ withoutPrompt: true }} fields={HOUSE_INFO_FIELDS(false)} />
        </FormProvider>
      </Content>
      <ActionsButton>
        <Button type={'submit'}>{a('save')}</Button>
      </ActionsButton>
    </form>
  );
};

export default View;

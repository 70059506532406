import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { fetchAllDocuments, setIsTrashTrue, setNewSumForContract } from '../store/documentReducer';
import { uploadFileApi } from '../driver/file/File';
import { closeIncomeBilag, closeInfoBilag, closeChangeRent, openInfoBilag, setInfoBilag } from '../store/modalReducer';
import { IncomeBilagFormData } from '../../domain/entity/IncomeBilag/IncomeBilagFormData';
import {
  changeRentIncomeBilagApi,
  createIncomeBilagApi,
  deleteIncomeBilagApi,
  editIncomeBilagApi
} from '../driver/IncomeBilag/IncomeBilag';
import {
  changeRentIncomeBilagError,
  changeRentIncomeBilagSuccess,
  createIncomeBilagError,
  createIncomeBilagSuccess,
  deleteIncomeBilagError,
  deleteIncomeBilagSucces
} from '../store/incomeBilagReducer';
import HouseData from '../../domain/entity/house/HouseData';
import { setSelectedHouse } from '../store/houseReducer';

export function* createIncomeBilagSaga({
  payload: { data, currentHouse }
}: PayloadAction<{ data: IncomeBilagFormData; currentHouse: HouseData }>) {
  try {
    const response = yield call(uploadFileApi, data.documents || []);
    yield call(data.id ? editIncomeBilagApi : createIncomeBilagApi, { ...data, documents: response });
    yield put(fetchAllDocuments({ houseId: Number(data.houseId) }));
    yield put(createIncomeBilagSuccess());
    yield put(setSelectedHouse({ ...currentHouse }));
    yield put(closeIncomeBilag());
  } catch (e) {
    yield put(createIncomeBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* deleteIncomeBilagSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(deleteIncomeBilagApi, payload);
    yield put(deleteIncomeBilagSucces());
    yield put(setIsTrashTrue(payload));
    yield put(closeInfoBilag());
  } catch (e) {
    yield put(deleteIncomeBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* changeRentIncomeBilagSaga({ payload }: PayloadAction<{ id: number; sum: string }>) {
  try {
    yield call(changeRentIncomeBilagApi, payload);
    yield put(setNewSumForContract({ id: payload.id, newSum: Number(payload.sum) }));
    yield put(setInfoBilag(payload.sum));
    yield put(changeRentIncomeBilagSuccess());
    yield put(closeChangeRent());
  } catch (e) {
    yield put(changeRentIncomeBilagError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

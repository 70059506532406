import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import { Supplier } from '../../domain/entity/supplier/SuplierData';
import { SupplierCreateFormT } from '../../domain/entity/supplier/SupplierCreateFormData';

export interface SupplierState {
  pending?: boolean;
  suppliers?: Supplier[];
  currentSupplier?: Supplier;
  error?: string;
}

const initialState: SupplierState = {
  pending: false,
  suppliers: [],
  currentSupplier: undefined,
  error: ''
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    fetchSupplier(state: SupplierState) {
      state.pending = true;
    },
    fetchSupplierSuccess(state: SupplierState, { payload }: PayloadAction<Supplier[]>) {
      state.suppliers = payload;
      state.pending = false;
      state.error = '';
    },
    fetchSupplierError(state: SupplierState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    createSupplier(state: SupplierState, _: PayloadAction<SupplierCreateFormT>) {
      state.pending = true;
    },
    createSupplierSuccess(state: SupplierState) {
      state.pending = false;
      state.error = '';
    },
    createSupplierError(state: SupplierState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    setCurrentSupplier(state: SupplierState, { payload }: PayloadAction<number>) {
      state.currentSupplier = state.suppliers?.filter(({ id }) => id === payload)[0];
      state.pending = false;
      state.error = '';
    },
    deleteSupplier(state: SupplierState, _: PayloadAction<number>) {
      state.pending = true;
    },
    deleteSupplierSuccess(state: SupplierState, { payload }: PayloadAction<number>) {
      state.suppliers = state.suppliers?.map((supplier) => {
        if (supplier.id !== payload) {
          return {
            ...supplier,
            archived: true
          };
        }
        return supplier;
      });
      state.currentSupplier = undefined;
      state.pending = false;
    },
    SupplierUpdate(state: SupplierState, _: PayloadAction<Supplier>) {
      state.pending = true;
    },
    SupplierUpdateSuccess(state: SupplierState, { payload }: PayloadAction<Supplier>) {
      state.pending = false;
      state.currentSupplier = payload;
      state.error = '';
    }
  }
});

const selectSupplier = (state: RootState): SupplierState => state.supplier;

export const supplierSelector = createSelector([selectSupplier], (supplier) => supplier);

export const {
  deleteSupplier,
  deleteSupplierSuccess,
  fetchSupplier,
  fetchSupplierSuccess,
  fetchSupplierError,
  SupplierUpdate,
  SupplierUpdateSuccess,
  setCurrentSupplier,
  createSupplierSuccess,
  createSupplier,
  createSupplierError
} = supplierSlice.actions;

export default supplierSlice.reducer;

import styled, { css } from 'styled-components';
import { mq } from '../../../../../constant/mediaqueries';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr;
  gap: 12px;
  padding: 16px 0 11px;
`;

export const profileIconCss = css`
  width: 24px;
  fill: currentColor;
`;

export const UserName = styled.div`
  text-align: left;
  font-weight: 500;
`;

export const Info = styled.div`
  position: relative;
  top: 5px;
`;

export const Email = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  ${mq.greaterXs} {
    width: 184px;
  }
`;

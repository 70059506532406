import React, { CSSProperties, FC, ReactNode, TextareaHTMLAttributes } from 'react';
import { CSSProp } from 'styled-components';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import errorIcon from 'components/svg/error.svg?sprite';
import { Spinner } from '../../ui';
import {
  StyledField,
  StyledLabel,
  StyledInput,
  StyledPrompt,
  StyledInputContainer,
  StyledHead,
  StyledServerError,
  errorIconCss,
  VariantT
} from './styled';

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  variant?: VariantT;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  register?: UseFormRegisterReturn;
  serverError?: ReactNode;
  isLoading?: boolean;
  isSuccess?: boolean;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  withoutPrompt?: boolean;
  inputStyleCss?: CSSProp;
  fieldStyleCss?: CSSProp;
  wrapperStyle?: CSSProperties;
  prompt?: ReactNode;
};

const Textarea: FC<Props> = (props) => {
  const {
    label,
    className,
    error = false,
    isSuccess = false,
    errorMessage,
    register,
    serverError,
    required,
    isLoading,
    endAdornment,
    withoutPrompt,
    startAdornment,
    inputStyleCss,
    fieldStyleCss,
    wrapperStyle,
    value,
    variant = 'default',
    prompt,
    ...restProps
  } = props;
  const { g } = useGlobalTranslation();

  return (
    <div className={className} style={wrapperStyle}>
      <StyledField>
        <StyledHead>
          <StyledLabel isError={error}>
            {label} {required && '*'}
          </StyledLabel>
        </StyledHead>
        <StyledInputContainer {...{ error, isSuccess, fieldStyleCss, variant }}>
          {startAdornment && startAdornment}
          <StyledInput {...register} {...restProps} inputStyleCss={inputStyleCss} value={value} maxLength={1000} />
          {isLoading && <Spinner blue />}
          {endAdornment && endAdornment}
        </StyledInputContainer>
      </StyledField>
      {!withoutPrompt && (
        <StyledPrompt error={error && !!(errorMessage || serverError)}>
          <Icon icon={errorIcon} css={errorIconCss} />
          {(errorMessage && g(`validation.${errorMessage}`)) ||
            (serverError && <StyledServerError>{serverError}</StyledServerError>)}
        </StyledPrompt>
      )}
      {prompt && prompt}
    </div>
  );
};

export default Textarea;

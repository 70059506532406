import {
  CREATE_BILAG,
  CREATE_DEPRECIATION,
  CREATE_DEPRECIATION_PERSONAL_USE,
  DELETE_DEPRECIATION_PERSONAL_USE,
  FETCH_DEPRECIATION_PERSONAL_USE_CALCULATION
} from 'constant/apiRoutes';
import { httpWithAuth } from 'data/driver/Api';
import { DepreciationFormDataT } from '../../../domain/entity/depreciation/DepreciationFormData';
import { TAX_DEPRECIATION_AMOUNT } from '../../../helpers/helpers';
import { radios } from 'components/layouts/Modals/Depreciation/View/Form';
import { formatDateForApi } from '../../../helpers/date';
import { CreateDepreciationPersonalBilagPayload } from '../../store/depreciationReducer';

export const createDepreciationApi = async (formData: DepreciationFormDataT) => {
  const lifetime =
    (formData?.type &&
      formData?.type === 1 &&
      formData?.lifetimeVariant === radios[1].value &&
      formData?.sum &&
      Number(formData?.sum) < TAX_DEPRECIATION_AMOUNT &&
      3) ||
    formData.lifetime;

  await httpWithAuth.post(CREATE_DEPRECIATION, {
    houseId: formData.houseId,
    name: formData.name,
    date: formatDateForApi(formData?.date || ''),
    sum: formData.sum,
    documents: formData.documents,
    lifetime
  });
};

export const createDepreciationPersonalUseApi = async (formData: DepreciationFormDataT): Promise<void> => {
  await httpWithAuth.post(CREATE_DEPRECIATION_PERSONAL_USE, {
    houseId: formData.houseId,
    year: formData.year
  });
};

export const fetchDepreciationPersonalCalculationApi = async ({
  houseId,
  year
}: CreateDepreciationPersonalBilagPayload): Promise<void> => {
  const { data } = await httpWithAuth.post(FETCH_DEPRECIATION_PERSONAL_USE_CALCULATION, {
    houseId,
    year
  });

  return data;
};

export const deleteDepreciationBilagApi = async (id: number) => {
  await httpWithAuth.delete(`${CREATE_DEPRECIATION}/${id}`);
};

export const deleteDepreciationPersonalUseBilagApi = async (data: { year: number; houseId: number }) => {
  await httpWithAuth.delete(`${DELETE_DEPRECIATION_PERSONAL_USE}`, { data: data });
};

import React, { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { InitialReleaseDepositData, ReleaseDepositFormData } from 'domain/entity/rentContract/RentContract';
import { releaseDepositSchema } from 'data/validations/releaseDeposit';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Modal from 'components/common/Modal';
import View from './View';

const ReleaseDeposit: FC = () => {
  const { releaseDeposit } = useAppSelector((state) => state.modals);
  const { t, g } = useGlobalTranslation();
  const { closeReleaseDeposit } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const formData = useForm<ReleaseDepositFormData>({
    resolver: yupResolver(releaseDepositSchema),
    defaultValues: {
      ...InitialReleaseDepositData
    }
  });
  const isCategory = formData.watch('category');

  const onCloseModal = () => {
    dispatch(closeReleaseDeposit());
    setStep(0);
  };

  const infoTitle: Record<string, string> = {
    TENANT: 'releaseDepositTitle.tenant',
    SPLIT: 'releaseDepositTitle.split',
    DISPUTE: 'releaseDepositTitle.dispute'
  };

  return (
    <Modal
      isOpen={releaseDeposit}
      title={step === 0 ? t('contractIsFinished') : g(infoTitle[isCategory])}
      onClose={onCloseModal}
    >
      <FormProvider {...formData}>
        <View step={step} setStep={setStep} />
      </FormProvider>
    </Modal>
  );
};

export default ReleaseDeposit;

import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useDispatch } from 'react-redux';
import { errorListSlice } from '../../data/store/errorListReducer';
import { useAppSelector } from '../../hooks/redux';
import { Container, MainCell, MainRow, Row, DataCell, Cell, CopyButton } from './styled';
import dayjs from 'dayjs';
import Modal from '../../components/common/Modal';
import { alertSlice } from '../../data/store/alertReducer';
import Icon from '../../components/common/Icon';
import checkIcon from 'components/svg/check_icon.svg?sprite';
import crossIcon from 'components/svg/close_icon.svg?sprite';
import { Input } from '../../components/ui';
import { useHistory } from 'react-router';
import { ROUTE_NOT_FOUND_PAGE } from '../../constant/routes';

export const options = {
  title: '',
  chartArea: { width: '50%' },
  hAxis: {
    title: 'Success&Error',
    minValue: 0
  },
  vAxis: {
    title: 'Date'
  }
};

const LoggerList: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fetchAllData } = errorListSlice.actions;
  const { list, error } = useAppSelector((state) => state.errorList);
  const [data, setData] = useState(null);
  const { setAlertData } = alertSlice.actions;
  const [filteredList, setFilteredList] = useState(list);
  const [userId, setUserId] = useState<string | undefined>();
  const [dataChart, setDataChart] = useState<any[][]>();

  useLayoutEffect(() => {
    dispatch(fetchAllData());
  }, []);

  useEffect(() => {
    const newDataForArray = new Array(8).fill(0).map((_, index) => {
      const date = dayjs().subtract(index, 'days');
      const total = list?.reduce(
        (acc: [number, number], item: { created_at: string; success: boolean }) => {
          if (dayjs(item.created_at).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
            return item.success ? [acc[0] + 1, acc[1]] : [acc[0], acc[1] + 1];
          }
          return acc;
        },
        [0, 0]
      );
      return [date.format('YYYY-MM-DD'), ...total];
    });

    setDataChart([['Date', 'Success', 'Error'], ...newDataForArray.reverse()]);
  }, [list]);

  useEffect(() => {
    if (userId)
      setFilteredList(
        list?.filter((item) => {
          return String(item.userId).indexOf(String(userId)) !== -1;
        })
      );

    if (!userId) {
      setFilteredList(list);
    }
  }, [userId, list]);

  if (error) {
    history.push(ROUTE_NOT_FOUND_PAGE);
  }

  if (list && list?.length === 0) {
    return null;
  }

  return (
    <Container>
      <Chart chartType="Bar" width="100%" height="400px" data={dataChart} options={options} />
      <div style={{ width: 150 }}>
        <Input placeholder={'user id'} onChange={(e) => setUserId(e.target.value)} />
      </div>
      <MainRow>
        <MainCell>ID</MainCell>
        <MainCell>SUCCESS</MainCell>
        <MainCell>TYPE</MainCell>
        <MainCell>USER ID</MainCell>
        <MainCell>INTERNAL DATA</MainCell>
        <MainCell>SEND DATA</MainCell>
        <MainCell>REQUEST ERROR</MainCell>
        <MainCell>CREATE</MainCell>
      </MainRow>
      {filteredList?.map((data) => (
        <Row>
          <Cell style={{ textAlign: 'center' }}>{data.id}</Cell>
          <Cell style={{ textAlign: 'center' }}>
            {!data.success ? <Icon icon={crossIcon} color={'red01'} /> : <Icon icon={checkIcon} color={'green01'} />}
          </Cell>
          <Cell>{data.type}</Cell>
          <Cell style={{ textAlign: 'center' }}>{data.userId}</Cell>
          <DataCell onClick={() => setData(data.internalData)}>{data.internalData}</DataCell>
          <DataCell onClick={() => setData(data.sendData)}>{data.sendData}</DataCell>
          <DataCell onClick={() => setData(data.requestError)}>
            {typeof data.requestError === 'object' ? JSON.stringify(data.requestError) : `${data.requestError}`}
          </DataCell>
          <Cell style={{ textAlign: 'center' }}>{dayjs(data.created_at).format('DD-MM-YYYY HH:mm')}</Cell>
        </Row>
      ))}
      <Modal onClose={() => setData(null)} isOpen={Boolean(data)}>
        <CopyButton
          onClick={() => {
            const textField = document.createElement('input');
            textField.setAttribute('value', data);
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            dispatch(setAlertData({ text: 'copied', variant: 'success' }));
          }}
        >
          COPY
        </CopyButton>
        <div style={{ width: '100%', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
          {data && typeof data === 'object' ? <pre>{JSON.stringify(data, null, 4)}</pre> : `${data}`}
        </div>
      </Modal>
    </Container>
  );
};

export default LoggerList;

import { PropsT as ButtonPropsT } from '../../components/controls/Button';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import { ReactNode } from 'react';

export interface ActionsState {
  show: boolean;
  title?: string;
  actions?: ReactNode[];
  onClose?: () => void;
  cancelButtonProps?: Omit<ButtonPropsT, 'color' | 'variant' | 'social' | 'onClick'> & {
    children: string;
    onClick: () => void;
  };
}

const initialState: ActionsState = {
  show: false,
  cancelButtonProps: undefined,
  actions: undefined,
  title: undefined
};

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setActionsData(state: ActionsState, { payload }: PayloadAction<ActionsState>) {
      state.show = payload.show;
      state.title = payload?.title;
      state.actions = payload.actions;
      state.cancelButtonProps = payload.cancelButtonProps;
      state.onClose = payload.onClose;
    },
    resetActionsData(state: ActionsState) {
      state.show = false;
      state.cancelButtonProps = undefined;
      state.title = undefined;
      state.actions = undefined;
      state.onClose = undefined;
    }
  }
});

const selectActions = (state: RootState): ActionsState => state.actions;

export const actionsSelector = createSelector([selectActions], (actions) => actions);

export const { setActionsData, resetActionsData } = actionsSlice.actions;

export default actionsSlice.reducer;

import styled, { css } from 'styled-components';
import { ThemeT } from '../../../context/Theme';

export type VariantT = 'default' | 'outline';

type ContainerT = {
  variant: VariantT;
};

export const StyledMenu = styled.div`
  position: absolute;
  z-index: 999;
  margin-top: 5px;
  background-color: ${({ theme }) => theme.colors.gray06};
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const StyledOption = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 12px 27px;
  transition: background-color 0.4s;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray04};
  }
`;

export const StyleValueContainer = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
`;

const defaultInputContainerCss = (theme: ThemeT) => css`
  border-bottom: 1px solid ${theme.colors.gray01};
  margin-bottom: 20px;
  height: 41px;

  & ${StyleValueContainer} {
    padding-left: 5px;
  }

  &:focus-within {
    border-bottom: 1px solid ${theme.colors.blue01};
  }
`;

const outlineInputContainerCss = (theme: ThemeT) => css`
  border: 1px solid ${theme.colors.gray04};
  border-radius: 3px;

  &:focus-within {
    border: 1px solid ${theme.colors.blue01};
  }
`;

export const Container = styled.div<ContainerT>`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  height: 48px;
  transition: border 0.4s;
  ${({ theme, variant }) => (variant === 'default' ? defaultInputContainerCss(theme) : outlineInputContainerCss(theme))}
`;

type IconContainerPropsT = {
  isOpen: boolean;
};

export const IconContainer = styled.span<IconContainerPropsT>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : 0)});
  transition: transform 0.4s;
`;

export const arrowIconCss = css`
  width: 48px;
`;

type ContainerPropsT = {
  isError: boolean;
};

export const StyledLabel = styled.span`
  color: ${({ theme }) => theme.colors.gray01};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
`;

export const StyledContainer = styled.div<ContainerPropsT>`
  top: -5px;
  position: relative;
  width: 100%;

  ${({ isError }) =>
    isError &&
    css`
      & ${StyledLabel} {
        color: ${({ theme }) => theme.colors.red01};
      }
      & ${Container} {
        border-color: ${({ theme }) => theme.colors.red01};
      }
    `}
`;

import {
  ALTINN,
  FETCH_ALTINN_DATA,
  FETCH_SCATTEETATEN_VIEW,
  SEND_ALTINN_FORM,
  SEND_NEW_FORM_TO_ALTIN
} from 'constant/apiRoutes';
import { httpWithAuth } from 'data/driver/Api';
import HouseData from '../../../domain/entity/house/HouseData';
import { AltinnDataT } from '../../../domain/entity/altinn/AltinnBilag';
import AltinLoginData from '../../../domain/entity/altinn/AltinLoginData';
import AltinFormT from '../../../domain/entity/altinn/AltinForm';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';

export const fetchAltinnDataApi = async () => {
  const { data } = await httpWithAuth.get<AltinnDataT[]>(FETCH_ALTINN_DATA);

  return data;
};

export const loginAltinnApi = async (formData: AltinLoginData) => {
  const { data } = await httpWithAuth.post(ALTINN, {
    action: 'login',
    data: { ...formData, userPassword: formData.systemPassword }
  });

  return data;
};

export const fetchAltinnFormDataApi = async (formData: AltinLoginData) => {
  const { data } = await httpWithAuth.post(ALTINN, {
    action: 'getForms',
    data: {
      ...formData,
      userPassword: formData.systemPassword,
      dateFrom: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
      dateTo: format(addDays(new Date(), 1), 'yyyy-MM-dd')
    }
  });

  return data;
};

export type SendAltinnDataT = {
  credential: AltinLoginData;
  formId: AltinFormT['id'];
  houseId: HouseData['id'];
};

export const sendAltinnFormApi = async ({ formId, houseId, credential }: SendAltinnDataT) => {
  const { data } = await httpWithAuth.post(SEND_ALTINN_FORM, {
    data: { ...credential, userPassword: credential.systemPassword },
    formId,
    houseId
  });

  return data;
};

export type FetchScatteetatenViewT = {
  houseIds: HouseData['id'][];
};

export const fetchScatteetatenViewApi = async ({ houseIds }: FetchScatteetatenViewT) => {
  const { data } = await httpWithAuth.post(FETCH_SCATTEETATEN_VIEW, {
    houseIds
  });

  return data;
};

export const sendNewFormToAltinApi = async ({ houseIds }: FetchScatteetatenViewT) => {
  const { data } = await httpWithAuth.post(SEND_NEW_FORM_TO_ALTIN, {
    houseIds
  });

  return data;
};

import styled, { css } from 'styled-components';
import Button from 'components/controls/Button';

export const StyledButton = styled(Button)`
  text-transform: initial;
  font-size: 14px;
  font-weight: 500;
`;

export const plusIconCss = css`
  fill: ${({ theme }) => theme.colors.blue01};
  width: 24px;
`;

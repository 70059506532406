import React from 'react';
import RootRouter from 'routes';
import { BrowserRouter } from 'react-router-dom';
import 'utils/locales/i18n';
import { Provider as ThemeProvider } from 'context/Theme';
import { getToken } from 'data/driver/Api';
import { useAppDispatch } from 'hooks/redux';
import { authSlice } from 'data/store/authReducer';

function App() {
  const token = getToken();
  const dispatch = useAppDispatch();
  const { tokenFetchingSuccess } = authSlice.actions;

  if (token) {
    dispatch(tokenFetchingSuccess({ accessToken: token }));
  }

  return (
    <BrowserRouter>
      <ThemeProvider>
        <RootRouter />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const StyledTitle = styled.h3`
  text-align: center;
  margin: 8rem 0 1.5rem 0;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;

  ${mq.lowerSm} {
    margin: 90px 0 0 0;
  }
`;

export const Title = styled.p`
  text-align: start;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  margin: 0;
`;

export const PopupContent = styled.div`
  padding: 8px 12px;
  background-color: #ffffff;
  max-width: 300px;
  color: ${({ theme }) => theme.colors.gray03};
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: -3;
  padding: 0 20px;
`;

export const backgroundLogoCss = css`
  width: 30em;
`;

import styled, { css } from 'styled-components';
import { mq } from '../../../../constant/mediaqueries';
import { Link, LinkProps } from 'react-router-dom';
import { Form } from '../../../../components/ui';
import { containerMixin } from '../../../../mixins';

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 1em;
  margin: 0 0 0 1.25rem;
  text-transform: capitalize;
`;

export const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 2.25rem 0;
  font-size: 1.25rem;
`;

export const StyledForm = styled(Form)`
  margin: 2.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 33rem;
`;

export const StyledFormContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 1.5rem;
`;

export const StyledSubmitWrapper = styled.div`
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 18rem;
  place-self: center;
`;

export const backIconCss = css`
  width: 10px;
  fill: currentColor;
`;

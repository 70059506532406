import * as Yup from 'yup';

export const INITIAL_VALUES = {
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: ''
};

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('password.required')
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=\S+$).{6,50}$/, 'password.check')
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,50}$/,
      'password.checkLowercase'
    ),
  newPassword: Yup.string()
    .required('password.required')
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=\S+$).{6,50}$/, 'password.check')
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,50}$/,
      'password.checkLowercase'
    )
    .notOneOf([Yup.ref('oldPassword'), null], 'password.notEquals'),
  repeatNewPassword: Yup.string()
    .required('password.required')
    .oneOf([Yup.ref('newPassword'), null], 'password.match')
});
export default schema;

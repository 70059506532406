import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0;
`;

export const Item = styled.div`
  max-width: 545px;
`;

export const Title = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
`;

export const Text = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

import styled from 'styled-components';
import { mq } from '../../../constant/mediaqueries';

interface TabPropsI {
  $active: boolean;
  isComing: boolean;
}

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mq.greaterMd} {
    flex-direction: row;
  }
`;

export const StyledTab = styled.div<TabPropsI>`
  display: flex;
  flex-wrap: nowrap;
  padding: 0.75rem 3.5rem;
  background-color: ${({ $active, theme }) => ($active && theme.colors.gray06) || 'transparent'};
  color: ${({ isComing, theme }) => isComing && theme.colors.gray03};
  font-weight: ${({ $active }) => ($active && 500) || 400};
  cursor: pointer;
  user-select: none;

  ${mq.lowerLg} {
    padding: 0.75rem 2rem;
  }
`;

export const StyledComingSoon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  font-size: 0.5rem;
  width: max-content;
  color: ${({ theme }) => theme.colors.white01};
  background-color: #b7b7b7;
  border-radius: 10px;
  padding: 0.3rem;
  margin-left: 0.25rem;
  text-align: center;
`;

import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import { RentContractFormData } from 'domain/entity/rentContract/RentContract';
import { rentContract } from 'data/validations/contractValidation';
import { houseSchema } from 'data/validations/houseValidation';
import { houseSlice } from 'data/store/houseReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Fields from 'components/feature/Fields';
import Button from 'components/controls/Button';
import Select from 'components/FormControls/Select';
import FileField from 'components/controls/FileField';
import { SelectOptionT } from 'components/controls/Select';
import { HOUSE_INFO_FIELDS } from 'components/feature/Fields/fields';
import { useData } from 'components/layouts/Modals/NewRentContract/View';
import { StepEnum } from 'components/layouts/Modals/NewRentContract/View/data';
import { Span, Content, ActionsButton } from 'components/layouts/Modals/NewRentContract/styled';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const RentContract: FC<PropsT> = (props) => {
  const { data, setIsDirty, setValues } = useData();
  const dispatch = useAppDispatch();
  const { fetchHouseUpdate } = houseSlice.actions;
  const { houseList, selectedHouse } = useAppSelector((state) => state.house);
  const formData = useForm<RentContractFormData>({
    resolver: yupResolver(rentContract),
    defaultValues: { ...data, houseId: data?.houseId || Number(selectedHouse?.id) }
  });
  const formDataHouse = useForm<HouseCreateFormT>({
    resolver: yupResolver(houseSchema),
    defaultValues: { ...selectedHouse }
  });
  const {
    register,
    control,
    setValue,
    formState: { errors, isDirty }
  } = formData;
  const watchHouseId = formData.watch('houseId');

  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const houseOptions: SelectOptionT[] = houseList?.map(({ name, id }) => ({ value: id, label: name })) || [];
  const { pending } = useAppSelector((state) => state.contract);
  const { setStep } = props;

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (selectedHouse?.id || data?.houseId) {
      setValue('houseId', Number(data?.houseId) || selectedHouse?.id);
    }
  }, []);

  useEffect(() => {
    const foundHouse = houseList.find(({ id }) => watchHouseId === id);
    if (foundHouse) {
      Object.keys(foundHouse).map((key) => {
        if (key !== 'houseId') {
          formDataHouse.setValue(key, foundHouse[key as keyof HouseCreateFormT]);
        }
      });
    }
  }, [watchHouseId]);

  const getFiles = useMemo(() => data.documents.filter((file) => typeof file !== 'string') as File[], [data.documents]);

  return (
    <>
      <Span />
      <Content>
        <Select
          control={control}
          name={'houseId'}
          defaultValue={selectedHouse?.id}
          label={f('houseName')}
          isRequired
          options={houseOptions}
          error={errors.houseId}
        />
        <FormProvider {...formDataHouse}>
          <Fields inputProps={{ variant: 'outline', withoutPrompt: true }} fields={HOUSE_INFO_FIELDS(true)} />
        </FormProvider>
        <div />
      </Content>
      <FormProvider {...formData}>
        <div style={{ gridColumn: '1/3', marginTop: 20 }}>
          <FileField
            initialFiles={data.documents.filter((file) => typeof file === 'string') as string[]}
            initialFilesAsFile={getFiles}
            register={register('documents')}
            isMulti
          />
        </div>
      </FormProvider>
      <ActionsButton>
        <Button
          isLoading={pending}
          disabled={pending}
          onClick={formDataHouse.handleSubmit((houseData) => {
            dispatch(fetchHouseUpdate({ ...houseData, id: Number(watchHouseId) }));
            setValues(formData.getValues());
            setStep(StepEnum.INFORM_ABOUT_TENANT);
          })}
        >
          {t('next')}
        </Button>
      </ActionsButton>
    </>
  );
};

export default RentContract;

import React, { FC, useEffect, useState } from 'react';
import Modal from '../../../common/Modal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { modalsSlice } from '../../../../data/store/modalReducer';
import View from './View';

export type StepT = 'info' | 'form' | 'calculations';

const DEPRECIATION_INFO = 'DEPRECIATION_INFO';

const Depreciation: FC = () => {
  const { depreciation } = useAppSelector((state) => state.modals);
  const isNotFirst = localStorage.getItem(DEPRECIATION_INFO);
  const [step, setStep] = useState<StepT>(isNotFirst ? 'form' : 'info');
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { closeDepreciation, openDiscardChange } = modalsSlice.actions;

  const onCloseModal = () => {
    dispatch(closeDepreciation());
  };

  return (
    <Modal
      isHiddenCloseButton={step === 'calculations'}
      isOpen={depreciation}
      onClose={() => (isDirty ? dispatch(openDiscardChange(onCloseModal)) : onCloseModal())}
      withoutAnimation={isDirty}
    >
      <View setIsDirty={setIsDirty} step={step} setStep={setStep} />
    </Modal>
  );
};

export default Depreciation;

import React from 'react';
import { FC } from 'react';
import { UserInfo } from '../UserInfo';
import { StyledContainer, StyledText, Divider, StyledInfoContainer } from './styled';
import Select from '../FormControls/Select';

interface PropsT {
  sign?: string;
}

const SignedUserInfo: FC<PropsT> = ({ sign }) => {
  return (
    <StyledContainer>
      <StyledInfoContainer>
        <UserInfo />
        <StyledText>{sign}</StyledText>
      </StyledInfoContainer>
      <Divider />
    </StyledContainer>
  );
};

export default SignedUserInfo;

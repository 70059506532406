import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Bilag } from 'domain/entity/documnets/CommonBilag';
import { IncomeBilagFormData } from 'domain/entity/IncomeBilag/IncomeBilagFormData';
import { incomeBilagSlice } from 'data/store/incomeBilagReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Button from 'components/controls/Button';
import Select from 'components/FormControls/Select';
import Input from 'components/controls/Input/Input';
import DateField from 'components/controls/DateField';
import { SelectOptionT } from 'components/controls/Select';
import FileField from 'components/controls/FileField';
import schema from './validation';
import { Content, StyledDescriptionContainer, ActionsButton, StyledCheckbox, Span } from './styled';
import Textarea from '../../../../controls/Textarea/Input';

type PropsT = {
  defaultValues: IncomeBilagFormData;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
};

const View: FC<PropsT> = (props) => {
  const { defaultValues, setIsDirty } = props;
  const {
    bilagInfo: { currentBilag }
  } = useAppSelector((state) => state.modals);
  const formData = useForm<IncomeBilagFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty }
  } = formData;
  const { houseList, selectedHouse } = useAppSelector((state) => state.house);
  const { pending } = useAppSelector((state) => state.incomeBilag);
  const houseOptions: SelectOptionT[] = houseList?.map(({ name, id }) => ({ value: id, label: name })) || [];
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const dispatch = useAppDispatch();
  const { createIncomeBilag } = incomeBilagSlice.actions;

  const onSubmit = (data: IncomeBilagFormData) => {
    dispatch(createIncomeBilag({ data, currentHouse: selectedHouse }));
  };

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <FormProvider {...formData}>
      <Span />
      <Content>
        <Select
          control={control}
          name={'houseId'}
          defaultValue={selectedHouse?.id}
          label={f('houseName')}
          options={houseOptions}
          isRequired
          error={errors.houseId}
        />
        <div />
        <Input
          register={register('tenant_first_name')}
          withoutPrompt
          variant={'outline'}
          label={f('tenantFirstName')}
          required
          error={errors.tenant_first_name}
        />
        <Input
          register={register('tenant_phone')}
          type={'tel'}
          error={errors.tenant_phone}
          withoutPrompt
          variant={'outline'}
          label={f('phone')}
        />
        <Input
          register={register('tenant_last_name')}
          withoutPrompt
          variant={'outline'}
          label={f('tenantLastName')}
          error={errors.tenant_last_name}
          required
        />
        <Input
          register={register('tenant_email')}
          error={errors.tenant_email}
          withoutPrompt
          variant={'outline'}
          label={f('email')}
          required
        />
        <DateField
          value={getValues('tenant_birthday')}
          register={register('tenant_birthday')}
          variant={'outline'}
          label={f('dateOfBirth')}
          withoutPrompt
          error={errors.tenant_birthday}
          required
        />
        <div />
      </Content>
      <Content>
        <DateField
          value={getValues('start_date')}
          register={register('start_date')}
          variant={'outline'}
          label={f('startDate')}
          withoutPrompt
          error={errors.start_date}
          required
        />
        <Input
          register={register('sum')}
          type={'number'}
          error={errors.sum}
          withoutPrompt
          variant={'outline'}
          label={f('rentalPaymentNOK')}
          required
        />
        <DateField
          register={register('end_date')}
          value={getValues('end_date')}
          variant={'outline'}
          label={f('endDate')}
          withoutPrompt
          error={errors.end_date}
          required
        />
        <Input
          register={register('deposit_sum')}
          type={'number'}
          error={errors.deposit_sum}
          withoutPrompt
          variant={'outline'}
          label={f('depositNOK')}
          required
        />
        <div />
        <StyledCheckbox register={register('separatedTaxable')}>{f('taxable')}</StyledCheckbox>
      </Content>
      <Content>
        <StyledDescriptionContainer>
          <Textarea
            rows={5}
            error={errors.other}
            register={register('other')}
            withoutPrompt
            variant={'outline'}
            label={f('other')}
          />
        </StyledDescriptionContainer>
      </Content>
      <div style={{ gridColumn: '1/3', marginTop: 10, marginBottom: 10 }}>
        <FileField
          initialFiles={(currentBilag?.bilag as Bilag)?.documents || []}
          register={register('documents')}
          isMulti
        />
      </div>
      <ActionsButton>
        <Button isLoading={pending} disabled={pending} onClick={handleSubmit(onSubmit)}>
          {a('save')}
        </Button>
      </ActionsButton>
    </FormProvider>
  );
};

export default View;

import styled, { css, CSSProp } from 'styled-components';
import { hexColorOpacity } from 'helpers/color';
import { ThemeT } from 'context/Theme';

export type VariantT = 'fill' | 'outline' | 'text';
export type ColorT = keyof ThemeT['colors'];

type ButtonContainerPropsT = {
  variant?: VariantT;
  color?: ColorT;
};

const buttonStylesCss = (theme: ThemeT, color: ColorT): Record<VariantT, CSSProp> => ({
  fill: css`
    background-color: ${theme.colors[color]};
    color: ${theme.colors.white01};
  `,
  text: css`
    display: inline-grid;
    width: max-content;
    height: max-content;
    color: ${theme.colors[color]};

    :hover {
      opacity: 0.8;
      background-color: transparent;
    }
  `,
  outline: css`
    background-color: transparent;
    color: ${theme.colors[color]};
    border: 1px solid ${theme.colors[color]};
    :hover {
      color: ${theme.colors.white01};
    }
  `
});

export const StyledContainer = styled.button<ButtonContainerPropsT>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 20rem;
  width: 100%;
  height: 48px;
  border-radius: 3px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 300ms ease all;
  font-size: 1em;
  font-weight: bold;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;

  &:hover {
    transition: 300ms ease all;
    background-color: ${({ theme }) => hexColorOpacity(theme.colors.blue01, 0.95)};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray01};
  }

  &:focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  ${({ theme, color = 'blue01', variant = 'fill' }) => buttonStylesCss(theme, color)[variant]}

  ${({ css }) => css}
`;

export const StyledText = styled.span`
  font-size: inherit;
  text-align: center;
  color: inherit;
`;

export const StyledSocialIconWrapper = styled.div``;

import styled, { css } from 'styled-components';
import Button from 'components/controls/Button';
import { mq } from '../../../../../constant/mediaqueries';

export const StyledHomeItem = styled(Button)`
  padding: 15px;
  width: 100%;
  max-width: 100%;
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  transition: background-color 0.25s;
  overflow: hidden;
  text-transform: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray08};
  }
`;

export const StyledSelectHome = styled(Button)`
  width: 100%;
  max-width: initial;
  padding: 0 0 0 10px;
  margin: 10px 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  text-transform: none;

  &:focus {
    box-shadow: none;
  }
`;

export const HouseName = styled.span`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2em;
`;

export const StyledHomeText = styled.p`
  display: grid;
  grid-auto-flow: column;
  align-items: end;
  gap: 12px;
  margin: 0;
  letter-spacing: 0.15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 0.8;
  text-overflow: ellipsis;
`;

export const homeIconCss = css`
  width: 20px;
  fill: currentColor;
`;

export const arrowIconCss = (isOpen: boolean) => css`
  width: 48px;
  fill: currentColor;
  transform: rotate(${isOpen ? '180deg' : '0'});
  transition: all 0.4s;
`;

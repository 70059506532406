import styled from 'styled-components';

export const RangeTrack = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.gray01};
  border-radius: 1px;
`;

type RangeTrackFillPropsT = {
  background: string;
};

export const RangeTrackFill = styled.div<RangeTrackFillPropsT>`
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background: ${({ background }) => background};
`;

export const RangeThumb = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue01};
  top: 0;
`;

export const Label = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.gray01};
`;

export const ValueContainer = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.gray01};
`;

export const Content = styled.div`
  display: grid;
  gap: 10px;
`;

export const Price = styled.span`
  color: ${({ theme }) => theme.colors.black01};
`;

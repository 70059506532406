import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CheckContract,
  ContractFormData,
  INITIAL_CHECK_LIST,
  INITIAL_CONTRACT_FORM_DATA
} from 'domain/entity/rentContract/RentContract';
import { checkContract } from 'data/validations/contractValidation';
import { contractSlice } from 'data/store/rentContractReducer';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import {
  Content,
  ActionsButton,
  StyledCheckbox,
  StyledLabel,
  StyledInput,
  inputFieldCss,
  inputStyleCss,
  CheckBox,
  Text
} from './styled';

const MAX_INPUT_VALUE = 99;

const View: FC = () => {
  const { t: a } = useGlobalTranslation('actions');
  const { t: checklist } = useGlobalTranslation('checklist');
  const { closeCheckContract } = modalsSlice.actions;
  const { createCheckList } = contractSlice.actions;
  const { currentContract } = useAppSelector((state) => state.contract);
  const dispatch = useAppDispatch();
  const formData = useForm<CheckContract>({
    resolver: yupResolver(checkContract),
    defaultValues: INITIAL_CHECK_LIST
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = formData;
  const parking_space = watch('parking_space');
  const options = watch('options');
  const fire_extinguisher_count = watch('fire_extinguisher_count');
  const garage = watch('garage');
  const stalls_count = watch('stalls_count');
  const number_keys = watch('number_keys');

  const onSubmit = (formData: CheckContract) => {
    dispatch(createCheckList({ formData, contractId: Number(currentContract?.id) }));
    dispatch(closeCheckContract());
  };

  useEffect(() => {
    console.log(currentContract?.checkList);
    if (currentContract?.checkList) {
      setValue('options', currentContract.checkList.options);
      setValue('stalls_count', currentContract.checkList.stalls_count);
      setValue('garage', currentContract.checkList.garage);
      setValue('parking_space', currentContract.checkList.parking_space);
      setValue('fire_extinguisher_count', currentContract.checkList.fire_extinguisher_count);
    }
    if (currentContract?.checkList?.number_keys || currentContract?.keys_count) {
      setValue('number_keys', currentContract?.checkList?.number_keys || currentContract?.keys_count);
    }
  }, [currentContract]);

  //TODO: чтобы решить эту проблему, сделать нормальный инпут
  useEffect(() => {
    if (parking_space && parking_space !== 0 && !options.includes('parking')) {
      setValue('options', [...options, 'parking']);
    }
    if (fire_extinguisher_count && fire_extinguisher_count !== 0 && !options.includes('fire_extinguisher')) {
      setValue('options', [...options, 'fire_extinguisher']);
    }
    if (garage && garage !== 0 && !options.includes('garage')) {
      setValue('options', [...options, 'garage']);
    }
    if (stalls_count && stalls_count !== 0 && !options.includes('stalls')) {
      setValue('options', [...options, 'stalls']);
    }
    if (number_keys && number_keys !== 0 && !options.includes('number_keys')) {
      setValue('options', [...options, 'number_keys']);
    }
    if (parking_space) {
      setValue('parking_space', Number(parking_space) > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : parking_space);
    }
    if (fire_extinguisher_count) {
      setValue(
        'fire_extinguisher_count',
        Number(fire_extinguisher_count) > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : fire_extinguisher_count
      );
    }
    if (garage) {
      setValue('garage', Number(garage) > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : garage);
    }
    if (stalls_count) {
      setValue('stalls_count', Number(stalls_count) > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : stalls_count);
    }
    if (number_keys) {
      setValue('number_keys', Number(number_keys) > MAX_INPUT_VALUE ? MAX_INPUT_VALUE : number_keys);
    }
  }, [parking_space, number_keys, fire_extinguisher_count, stalls_count, garage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Content>
        <StyledCheckbox register={register('options')} value={'number_of_keys'}>
          <CheckBox>
            <Text>{checklist('numberKey')}</Text>
            <StyledInput
              type={'number'}
              register={register('number_keys')}
              variant={'outline'}
              fieldStyleCss={inputFieldCss}
              inputStyleCss={inputStyleCss}
              error={errors.number_keys}
            />
          </CheckBox>
        </StyledCheckbox>
      </Content>
      <StyledLabel>{checklist('external')}</StyledLabel>
      <Content>
        <StyledCheckbox register={register('options')} value={'parking'}>
          <CheckBox>
            <Text>{checklist('parking')}</Text>
            <StyledInput
              type={'number'}
              maxLength={2}
              register={register('parking_space')}
              variant={'outline'}
              fieldStyleCss={inputFieldCss}
              inputStyleCss={inputStyleCss}
              error={errors.parking_space}
            />
          </CheckBox>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'garage'}>
          <CheckBox>
            <Text>{checklist('garage')}</Text>
            <StyledInput
              type={'number'}
              maxLength={2}
              register={register('garage')}
              variant={'outline'}
              fieldStyleCss={inputFieldCss}
              inputStyleCss={inputStyleCss}
              error={errors.garage}
            />
          </CheckBox>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'mailbox'}>
          <Text>{checklist('mailbox')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'exterior_area'}>
          <Text>{checklist('exteriorArea')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'snowshoveling'}>
          <Text>{checklist('snowshoveling')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'lawn_moving'}>
          <Text>{checklist('lawnMowing')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'exterior_doors'}>
          <Text>{checklist('exteriorDoors')}</Text>
        </StyledCheckbox>
      </Content>
      <StyledLabel>{checklist('safety')}</StyledLabel>
      <Content>
        <StyledCheckbox register={register('options')} value={'home_alarm'}>
          <Text>{checklist('homeAlarm')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'fuse_box'}>
          <Text>{checklist('fuseBox')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'fire_extinguisher'}>
          <CheckBox>
            <Text>{checklist('fireExtinguisher')}</Text>
            <StyledInput
              type={'number'}
              maxLength={2}
              register={register('fire_extinguisher_count')}
              variant={'outline'}
              fieldStyleCss={inputFieldCss}
              inputStyleCss={inputStyleCss}
              error={errors.fire_extinguisher_count}
            />
          </CheckBox>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'main_stopcock'}>
          <Text>{checklist('mainStopcock')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'fireplaces'}>
          <Text>{checklist('fireplaces')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'smoke_detectors'}>
          <Text>{checklist('smoke')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'escape_routes'}>
          <Text>{checklist('escapeRoutes')}</Text>
        </StyledCheckbox>
      </Content>
      <StyledLabel>{checklist('inventory')}</StyledLabel>
      <Content>
        <StyledCheckbox register={register('options')} value={'stove_guard'}>
          <Text>{checklist('stoveGuard')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'washer'}>
          <Text>{checklist('washer')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'panel_heaters'}>
          <Text>{checklist('panelHeaters')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'furniture'}>
          <Text>{checklist('furniture')}</Text>
        </StyledCheckbox>
      </Content>
      <StyledLabel>{checklist('generally')}</StyledLabel>
      <Content>
        <StyledCheckbox register={register('options')} value={'review_rules'}>
          <Text>{checklist('reviewOfHouse')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'common_area'}>
          <Text>{checklist('commonArea')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'condition'}>
          <Text>{checklist('condition')}</Text>
        </StyledCheckbox>
        <StyledCheckbox register={register('options')} value={'stalls'}>
          <CheckBox>
            <Text>{checklist('stalls')}</Text>
            <StyledInput
              maxLength={2}
              type={'number'}
              register={register('stalls_count')}
              variant={'outline'}
              fieldStyleCss={inputFieldCss}
              inputStyleCss={inputStyleCss}
              error={errors.stalls_count}
            />
          </CheckBox>
        </StyledCheckbox>
      </Content>
      <ActionsButton>
        <Button type={'submit'}>{a('save')}</Button>
      </ActionsButton>
    </form>
  );
};

export default View;

import React, { FC, useEffect, useState } from 'react';
import format from 'date-fns/format';
import AltinFormT from 'domain/entity/altinn/AltinForm';
import { altinnSlice } from 'data/store/altinnReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Button from 'components/controls/Button';
import Icon from 'components/common/Icon';
import backIcon from 'components/svg/back-icon.svg?sprite';
import {
  Wrapper,
  Item,
  DateText,
  Name,
  ButtonContainer,
  backIconCss,
  BackButton,
  Title,
  Top,
  ErrorMessage
} from './styled';

const Forms: FC = () => {
  const { g } = useGlobalTranslation();
  const { t: actionsTranslate } = useGlobalTranslation('actions');
  const { t: altinnTranslate } = useGlobalTranslation('altinn');
  const [selectedForm, setSelectedForm] = useState<AltinFormT | undefined>();
  const { credentials, altinnForms, error, pendingForm, successMessage } = useAppSelector(({ altinn }) => altinn);
  const { selectedHouse } = useAppSelector(({ house }) => house);
  const dispatch = useAppDispatch();
  const { sendAltinnForm, hiddenAltinnForms } = altinnSlice.actions;

  const handleSendFormClick = () => {
    if (credentials && selectedForm && selectedHouse) {
      dispatch(sendAltinnForm({ credential: credentials, formId: selectedForm.id, houseId: selectedHouse.id }));
    }
  };

  useEffect(() => {
    if (successMessage) {
      alert(g(successMessage));
    }
  }, [successMessage]);

  return (
    <Wrapper>
      <Top>
        <BackButton variant={'text'} color={'black01'} onClick={() => dispatch(hiddenAltinnForms())}>
          <Icon icon={backIcon} css={backIconCss} />
          {actionsTranslate('back')}
        </BackButton>
        <Title>{altinnTranslate('title.chooseForm')}</Title>
      </Top>
      {error && <ErrorMessage>{g(error)}</ErrorMessage>}
      {altinnForms?.map((form) => (
        <Item key={form.id} isSelected={form.id === selectedForm?.id} onClick={() => setSelectedForm(form)}>
          <Name>{form.name}</Name>
          <DateText>
            {altinnTranslate('lastChanged')} {format(new Date(form.lastChangedDate), 'HH:mm dd.MM.yyyy')}
          </DateText>
        </Item>
      ))}
      {selectedForm && (
        <ButtonContainer>
          <Button isLoading={pendingForm} onClick={handleSendFormClick}>
            {actionsTranslate('sendToAltin')}
          </Button>
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

export default Forms;

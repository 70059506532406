import * as Yup from 'yup';

const schema = Yup.object().shape({
  houseId: Yup.string().required(''),
  cost_type: Yup.string().required(''),
  description: Yup.string().required(''),
  repeat: Yup.string().required(''),
  date: Yup.string().required(''),
  sum: Yup.string().required(''),
  supplierId: Yup.string().required(''),
  supplier_name: Yup.string().required(''),
  supplier_address: Yup.string().required(''),
  supplier_city: Yup.string().required(''),
  supplier_postcode: Yup.string().required(''),
  franchise: Yup.string().required()
});

export default schema;

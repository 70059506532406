import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTE_DASHBOARD } from 'constant/routes';
import StepEnum from 'domain/entity/registration/StepEnum';
import { useAppSelector } from 'hooks/redux';
import View from './View';

const Registration = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { step } = useAppSelector((state) => state.registration);

  if (token && step === StepEnum.general) {
    return <Redirect to={ROUTE_DASHBOARD} />;
  }

  return <View />;
};

export default Registration;

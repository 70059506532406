import { CSSProperties } from 'react';

type FieldT = {
  type: string;
  name: string;
  text: string;
  required?: boolean;
  serverError?: boolean;
  style?: CSSProperties;
};

export const ADD_SUPPLIER_FIELDS: FieldT[] = [
  { type: 'text', name: 'name', text: 'name' },
  { type: 'text', name: 'address', text: 'address' },
  { type: 'text', name: 'postcode', text: 'postcode' },
  { type: 'text', name: 'city', text: 'city' }
];

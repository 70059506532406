import React, { useEffect, useMemo, useState } from 'react';
import SubscriptionT from 'domain/entity/subscription/SubscriptionT';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { subscriptionSlice } from 'data/store/subscriptionReducer';
import { Button, Spinner } from 'components/ui';
import { Prices, Thanks } from './components';
import { StyledContainer } from './styled';
import { useHistory } from 'react-router';

const Subscription = () => {
  const { t, g } = useGlobalTranslation('subscription');
  const [activeSubscription, setActiveSubscription] = useState<SubscriptionT | null>(null);
  const [portalUrl, setPortalUrl] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { subscriptionInfoFetching, subscriptionPortalFetching, subscriptionFetching } = subscriptionSlice.actions;
  const { pending, subscriptions, prices, portal, payment } = useAppSelector((state) => state.subscription);
  const { token } = useAppSelector((state) => state.auth);

  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subscribed = urlParams.get('subscribed');
    if (subscribed && ['success', 'fail'].includes(subscribed)) {
      if (subscribed === 'success') {
        //@ts-ignore
        fbq('track', 'Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
      }
      history.replace('/profile/subscription');
    }
    return () => {};
  }, [window.location.search]);

  useEffect(() => {
    if (token) {
      dispatch(subscriptionInfoFetching());
    }
  }, []);

  useEffect(() => {
    if (activeSubscription && token) {
      dispatch(subscriptionPortalFetching());

      if (portal?.url) {
        setPortalUrl(portal.url);
      }
    }
  }, [activeSubscription]);

  useEffect(() => {
    const activeSub = subscriptions.find(
      (sub) => sub.status === 'active' || sub.status === 'trialing' || sub.status === 'past_due'
    );
    if (activeSub && token) {
      setActiveSubscription(activeSub);
    }
  }, [subscriptions]);

  useEffect(() => {
    if (portal?.url && token) {
      setPortalUrl(portal.url);
    }
  }, [portal]);

  useEffect(() => {
    if (payment && token) {
      window.location.replace(payment.url);
    }
  }, [payment]);

  const goToPortal = () => {
    if (portalUrl) {
      window.location.replace(portalUrl);
    }
  };

  const onClickSubscribe = async (priceId: string) => {
    dispatch(subscriptionFetching({ priceId }));
  };

  const subscriptionEndDate = useMemo(
    () => (activeSubscription && new Date(activeSubscription?.endDate * 1000).toLocaleDateString()) || '',
    [activeSubscription]
  );

  return (
    <StyledContainer>
      {(pending && <Spinner blue />) || (
        <>
          {(activeSubscription && (
            <>
              <Thanks date={subscriptionEndDate} />
              {/*{ todo: fix styles for message. and move message to translations }*/}
              {(portal &&
                ((portal.url && <Button onClick={goToPortal}>{t('thanks.goToStripePortal')}</Button>) || (
                  <div style={{ textAlign: 'center' }}>{portal.message && t(portal.message)}</div>
                ))) || <Spinner blue />}
            </>
          )) || <Prices prices={prices || []} onContinueClick={onClickSubscribe} />}
        </>
      )}
    </StyledContainer>
  );
};

export default Subscription;

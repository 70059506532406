import styled, { css } from 'styled-components';
import Button from 'components/controls/Button';
import Popup from 'components/common/Popup';

export const StyledPopup = styled(Popup)`
  bottom: 40px;
  right: 50px;
  position: fixed;
  width: max-content;
`;

export const StyledItem = styled(Button)`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  padding: 15px;
  transition: background-color 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray08};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.gray08};
    opacity: 0.6;
  }
`;

export const StyledSelect = styled(Button)`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledText = styled.p`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
  margin: 0;
  letter-spacing: 0.15px;
  font-weight: 500;
  font-size: 16px;
  color: inherit;
`;

export const iconCss = css`
  width: 24px;
  fill: currentColor;
`;

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import UserT from 'domain/entity/auth/UserT';
import { RootState } from './store';
import { ProfileFormT } from '../../domain/entity/profile/ProfileFormData';
import { SupplierState } from './supplierReducer';
import { logout } from './authReducer';

export interface ProfileState {
  user?: UserT;
  pending?: boolean;
  error: string;
  isDeletedSuccess: boolean;
  isUpdatedProfile: boolean;
}

const initialState: ProfileState = {
  user: undefined,
  pending: undefined,
  isUpdatedProfile: false,
  error: '',
  isDeletedSuccess: false
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileFetching(state: ProfileState) {
      state.pending = true;
    },
    profileDataReset(state: ProfileState) {
      state.pending = false;
      state.user = undefined;
    },
    profileFetchingSuccess(state: ProfileState, { payload }: PayloadAction<UserT>) {
      state.pending = false;
      state.user = { ...state.user, ...payload };
      state.error = '';
    },
    profileFetchingError(state: ProfileState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    profileUpdate(state: ProfileState, _: PayloadAction<ProfileFormT>) {
      state.pending = true;
      state.isUpdatedProfile = true;
    },
    profileUpdateSuccess(state: ProfileState, { payload }: PayloadAction<UserT>) {
      state.pending = false;
      state.isUpdatedProfile = false;
      state.user = { ...state.user, ...payload, is_register_finished: true };
      state.error = '';
    },
    profileDelete(state: ProfileState) {
      state.pending = true;
      state.isDeletedSuccess = true;
    },
    profileDeleteSuccess(state: ProfileState) {
      state.pending = false;
      state.isDeletedSuccess = false;
    },
    profileDeleteError(state: ProfileState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
      state.isDeletedSuccess = false;
    },
    profileDeleteReset(state: ProfileState) {
      state.isDeletedSuccess = false;
    },
    difiDataReset(state: ProfileState) {
      if (state.user) {
        state.user = {
          ...state.user,
          difiData: undefined
        };
      }
    }
  }
});

const selectProfile = (state: RootState): ProfileState => state.profile;

export const profileSelector = createSelector([selectProfile], (profile) => profile);

export const {
  profileFetching,
  profileFetchingSuccess,
  profileFetchingError,
  profileUpdate,
  profileDataReset,
  profileUpdateSuccess,
  profileDelete,
  profileDeleteSuccess,
  profileDeleteError,
  difiDataReset
} = profileSlice.actions;

export default profileSlice.reducer;

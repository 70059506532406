import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReleaseDepositFormData } from 'domain/entity/rentContract/RentContract';
import { contractSlice } from 'data/store/rentContractReducer';
import { modalsSlice } from 'data/store/modalReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Span } from 'components/layouts/Modals/NewRentContract/styled';
import Button from 'components/controls/Button';
import { Text, ActionsButton } from './styled';

export const radios = [
  { id: 0, value: 'TENANT', label: 'inputs.return' },
  { id: 1, value: 'SPLIT', label: 'inputs.split' },
  { id: 2, value: 'DISPUTE', label: 'inputs.dispute' }
];

const ConfirmedRelease: FC = () => {
  const { setError, handleSubmit, watch } = useFormContext<ReleaseDepositFormData>();
  const { currentContract } = useAppSelector((state) => state.contract);
  const { t, g } = useGlobalTranslation();
  const dispatch = useAppDispatch();
  const { releaseDeposit } = contractSlice.actions;
  const { closeReleaseDeposit } = modalsSlice.actions;
  const isCategory: 'SPLIT' | 'TENANT' | 'DISPUTE' = watch('category');

  const onSubmit = (data: ReleaseDepositFormData) => {
    if (
      isCategory !== 'SPLIT' ||
      Number(data.amount_tenant) + Number(data.amount_landlord) === Number(currentContract?.deposit_sum)
    ) {
      dispatch(releaseDeposit({ formData: data, contractId: currentContract?.id || NaN }));
      dispatch(closeReleaseDeposit());
      return;
    }

    setError('amount_landlord', { message: `` });
  };

  const categoryToText = {
    SPLIT: g('releaseDepositText.split'),
    TENANT: g('releaseDepositText.tenant'),
    DISPUTE: g('releaseDepositText.dispute')
  };

  return (
    <>
      <Span />
      <Text>{categoryToText[isCategory]}</Text>
      <ActionsButton>
        <Button type={'button'} variant={'fill'} onClick={handleSubmit(onSubmit)}>
          {t('confirm')}
        </Button>
      </ActionsButton>
    </>
  );
};

export default ConfirmedRelease;

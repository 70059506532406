import { authSlice } from 'data/store/authReducer';
import { useAppDispatch } from './redux';

const useAppLogout = () => {
  const { logout } = authSlice.actions;
  const dispatch = useAppDispatch();

  return () => {
    dispatch(logout());
  };
};

export default useAppLogout;

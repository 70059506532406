// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  PUBLIC_ROUTES,
  ROUTE_PROFILE_ACCOUNT_SETTINGS,
  ROUTE_ALTINN_INDEX,
  ROUTE_CONTACT_US,
  ROUTE_DASHBOARD,
  ROUTE_PROFILE_INDEX,
  ROUTE_PROFILE_LANDLORD,
  ROUTE_PROFILE_LANDLORD_EDIT,
  ROUTE_PROFILE_SUBSCRIPTION,
  ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL,
  ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD,
  ROUTE_HOUSE_INFO,
  ROUTE_HOUSE_INFO_EDIT,
  ROUTE_RENT_CONTRACT,
  ROUTE_RENT_CONTRACT_INFO,
  ROUTE_HOME_INDEX,
  ROUTE_FURNITURE,
  ROUTE_SEPARATE_INCOME_BILAGS,
  ROUTE_FURNITURE_ITEM,
  ROUTE_SEPARATE_INCOME_BILAGS_ITEM,
  ROUTE_SUPPLIERS,
  ROUTE_SUPPLIERS_ADD_NEW,
  ROUTE_SUPPLIER_INFO_EDIT,
  ROUTE_SUPPLIER_INFO,
  ROUTE_TRASH_FOLDER,
  ROUTE_TRASH_FOLDER_ITEM,
  ROUTE_LOGIN,
  ROUTE_ALTINN_SUBSCRIPTION,
  ROUTE_REGISTER_CONTRACT,
  ROUTE_TENANT_NOTIFICATION,
  ROUTE_REDIRECT_TO_SIGNICAT,
  ROUTE_NOT_FOUND_PAGE,
  ROUTE_LOGOUT_DIFI,
  ROUTE_PROCESS_DIFI_TOKEN,
  ROUTE_LOGIN_DIFI,
  ROUTE_TEST_SKATTEETATEN,
  ROUTE_LOGOUT_DIFI_REDIRECT,
  ROUTE_ERROR_LIST
} from 'constant/routes';
import { NotFound } from './components';
import { PublicLayout } from 'components/layouts';
import PrivateRoute from './PrivateRoute';
import { AccountSettings, Dashboard, Home, Subscription } from 'containers';
import AltinnPage from 'containers/Altinn/Main';
import Profile from 'containers/Profile';
import ContactUs from 'containers/ContactUs';
import { useHistory } from 'react-router';
import Landlord from '../containers/Landlord/Landlord';
import { LandlordEdit } from '../containers/Landlord/components/LandlordEdit';
import {
  ChangeEmail as AccountSettingsChangeEmail,
  ChangePassword as AccountSettingsChangePassword
} from '../containers/AccountSettings/containers';
import HouseInfo from '../containers/HouseInfo';
import HouseInfoEdit from '../containers/HouseInfo/containers/HouseInfoEdit';
import RentContracts from '../containers/RentContracts';
import { RentContractInfo } from '../containers/RentContracts/containers/RentContractInfo';
import FurnitureAndHome from '../containers/FurnitureAndHome';
import SeparateIncomeBilags from '../containers/SeparateIncomeBilags';
import FurnitureAndHomeItem from '../containers/FurnitureAndHome/FurnitureAndHomeItem';
import { SeparateIncomeBilagItem } from '../containers/SeparateIncomeBilags/SeparateIncomeBilagItem';
import Suppliers from '../containers/Suppliers';
import { SupplierInfo } from '../containers/Suppliers/containers/SupplierInfo';
import SupplierInfoEdit from '../containers/Suppliers/containers/SupplierInfo/SupplierInfoEdit';
import AddSupplier from '../containers/Suppliers/containers/AddSupplier';
import { TrashFolder } from '../containers/TrashFolder';
import { TrashFolderItem } from '../containers/TrashFolder/TrashFolderItem';
import { useAppSelector } from '../hooks/redux';
import AltinnSubscription from '../containers/Altinn/AltinnSubscription';
import RegisterContract from '../containers/RegisterContract';
import TenantNotifications from '../containers/TenantNotifications';
import RedirectToSignicat from '../containers/RedirectToSignicatContainer';
import DIFIToken from '../containers/DIFIToken';
import LoginByUrlAndRedirectDifi from '../containers/LoginAndRedirectDifi';
import OldAltinnPage from '../containers/AltinnOld/Main';
import LogoutDifi from '../containers/LogoutDifi';
import LogoutDifiRedirect from '../containers/LogoutDifiRedirect';
import LoggerList from '../containers/LoggerList';
const env = process.env;
//const env = runtimeEnv();

const RootRouter = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { location, push } = useHistory();
  const SCATTETATEN_ENABLED = env.REACT_APP_SCATTETATEN_ENABLED;

  useEffect(() => {
    if (location.pathname === '/' && token) {
      push(ROUTE_DASHBOARD);
    }
    if (location.pathname === '/' && !token) {
      push(ROUTE_LOGIN);
    }
    if (location.pathname === ROUTE_PROFILE_INDEX) {
      push(ROUTE_CONTACT_US);
    }
  }, [location]);

  return (
    <Switch>
      <Route path={ROUTE_PROCESS_DIFI_TOKEN} component={DIFIToken} />
      <Route path={ROUTE_LOGIN_DIFI} component={LoginByUrlAndRedirectDifi} />
      <Route path={ROUTE_LOGOUT_DIFI} component={LogoutDifi} />
      <Route path={ROUTE_LOGOUT_DIFI_REDIRECT} component={LogoutDifiRedirect} />
      <Route path={[...PUBLIC_ROUTES]} component={PublicLayout} exact />
      <Route path={ROUTE_REGISTER_CONTRACT} component={RegisterContract} exact />
      <Route path={ROUTE_TENANT_NOTIFICATION} component={TenantNotifications} exact />
      <Route path={ROUTE_REDIRECT_TO_SIGNICAT} component={RedirectToSignicat} exact />
      <PrivateRoute path={ROUTE_PROFILE_SUBSCRIPTION} exact component={Subscription} />
      <PrivateRoute path={ROUTE_ALTINN_SUBSCRIPTION} exact component={AltinnSubscription} />
      <PrivateRoute path={ROUTE_ERROR_LIST} component={LoggerList} />
      <PrivateRoute
        path={ROUTE_ALTINN_INDEX}
        exact
        component={SCATTETATEN_ENABLED === 'true' ? AltinnPage : OldAltinnPage}
      />
      {SCATTETATEN_ENABLED === 'false' && <PrivateRoute path={ROUTE_TEST_SKATTEETATEN} exact component={AltinnPage} />}
      <PrivateRoute path={ROUTE_PROFILE_INDEX} exact component={Profile} />
      <PrivateRoute path={ROUTE_PROFILE_LANDLORD} exact component={Landlord} />
      <PrivateRoute path={ROUTE_PROFILE_LANDLORD_EDIT} exact component={LandlordEdit} />
      <PrivateRoute path={ROUTE_PROFILE_ACCOUNT_SETTINGS} exact component={AccountSettings} />
      <PrivateRoute path={ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL} exact component={AccountSettingsChangeEmail} />
      <PrivateRoute
        path={ROUTE_PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD}
        exact
        component={AccountSettingsChangePassword}
      />
      <PrivateRoute path={ROUTE_CONTACT_US} exact component={ContactUs} />
      <PrivateRoute path={ROUTE_DASHBOARD} component={Dashboard} />
      <PrivateRoute path={ROUTE_HOME_INDEX} exact component={Home} />
      <PrivateRoute path={ROUTE_HOUSE_INFO} exact component={HouseInfo} />
      <PrivateRoute path={ROUTE_HOUSE_INFO_EDIT} exact component={HouseInfoEdit} />
      <PrivateRoute path={ROUTE_RENT_CONTRACT} exact component={RentContracts} />
      <PrivateRoute path={ROUTE_RENT_CONTRACT_INFO} exact component={RentContractInfo} />
      <PrivateRoute path={ROUTE_FURNITURE} exact component={FurnitureAndHome} />
      <PrivateRoute path={ROUTE_SUPPLIERS} exact component={Suppliers} />
      <PrivateRoute path={ROUTE_SUPPLIERS_ADD_NEW} exact component={AddSupplier} />
      <PrivateRoute path={ROUTE_SUPPLIER_INFO} exact component={SupplierInfo} />
      <PrivateRoute path={ROUTE_SUPPLIER_INFO_EDIT} exact component={SupplierInfoEdit} />
      <PrivateRoute path={ROUTE_FURNITURE_ITEM} exact component={FurnitureAndHomeItem} />
      <PrivateRoute path={ROUTE_SEPARATE_INCOME_BILAGS} exact component={SeparateIncomeBilags} />
      <PrivateRoute path={ROUTE_SEPARATE_INCOME_BILAGS_ITEM} exact component={SeparateIncomeBilagItem} />
      <PrivateRoute path={ROUTE_TRASH_FOLDER} exact component={TrashFolder} />
      <PrivateRoute path={ROUTE_TRASH_FOLDER_ITEM} exact component={TrashFolderItem} />

      <Route path={ROUTE_NOT_FOUND_PAGE} component={NotFound} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default RootRouter;

import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
`;

export const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  color: ${({ theme }) => theme.colors.red01};
`;

export const StyledDescriptionContainer = styled.div`
  grid-column: 1/3;
`;

export const ActionsButton = styled.div<{ isEdit?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: space-around;
  grid-template-columns: ${({ isEdit }) => (isEdit ? '278px' : 'repeat(2, 278px)')};
  margin-top: 48px;
`;

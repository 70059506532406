import React, { FC, useState } from 'react';
import { StepEnum } from './View/data';
import View from './View';
import Topbar from '../../components/layouts/MainLayout/Topbar';
import { useAppSelector } from '../../hooks/redux';
import { useHistory } from 'react-router';
import { ROUTE_DASHBOARD } from '../../constant/routes';
import { Background, backgroundLogoCss } from './styled';
import backgroundLogoSvg from 'components/svg/background_logo.svg?sprite';
import Icon from '../../components/common/Icon';

const RegisterContract: FC = () => {
  const [step, setStep] = useState<StepEnum>(StepEnum.LANDLORD_INFORMATION);
  const history = useHistory();
  const { token } = useAppSelector((state) => state.auth);

  if (token && step === StepEnum.LANDLORD_INFORMATION) {
    history.push(`${ROUTE_DASHBOARD}?createRentContract=true`);
  }

  return (
    <>
      <Background></Background>
      <Topbar withoutNavigation isHideLogo={false} />
      <View setStep={setStep} step={step} />
    </>
  );
};

export default RegisterContract;

import styled, { css } from 'styled-components';
import { ThemeT } from 'context/Theme';
import Checkbox from 'components/controls/Checkbox/Checkbox';

export const Actions = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-top: 15px;
`;

const errorCheckBox = (theme: ThemeT) => css`
  color: ${theme.colors.red01};
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  transition: color 0.2s;

  ${({ theme, errorMessage }) => errorMessage && errorCheckBox(theme)}
`;

export const LinkCheckBox = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue01};
  cursor: pointer;
`;

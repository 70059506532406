import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from './redux';
import { profileSelector } from '../data/store/profileReducer';
import { APP_SUBSCRIPTION_DAYS_LIMIT, APP_SUBSCRIPTION_DAYS_LIMIT_NOTIFY } from '../constant/global';
import useGlobalTranslation from './useGlobalTranlation';
import { confirmationSlice } from '../data/store/confirmationReducer';
import { useHistory } from 'react-router';
import { ROUTE_DASHBOARD, ROUTE_PROFILE_SUBSCRIPTION } from '../constant/routes';
import { useCallback } from 'react';

const useSubscribeCheck = () => {
  const { user } = useAppSelector(profileSelector);
  const { g } = useGlobalTranslation();
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const daysFromFirstBilag = dayjs().diff(dayjs(user?.firstBilagDate), 'day');
  return useCallback(() => {
    if (!user?.subscriptionActive && daysFromFirstBilag > APP_SUBSCRIPTION_DAYS_LIMIT) {
      dispatch(
        setConfirmationData({
          show: true,
          cancelButtonProps: {
            onClick: () => {
              history.push(ROUTE_DASHBOARD);
              dispatch(resetConfirmationData());
            },
            children: 'Ok'
          },
          confirmButtonProps: {
            onClick: () => {
              history.push(ROUTE_PROFILE_SUBSCRIPTION);
              dispatch(resetConfirmationData());
            },
            children: g('subscribe')
          },
          text: g('errors.endOf30Days')
        })
      );
      return false;
    } else {
      if (!user?.subscriptionActive && daysFromFirstBilag >= APP_SUBSCRIPTION_DAYS_LIMIT_NOTIFY) {
        dispatch(
          setConfirmationData({
            show: true,
            cancelButtonProps: {
              onClick: () => {
                dispatch(resetConfirmationData());
              },
              children: 'Ok'
            },
            confirmButtonProps: {
              onClick: () => {
                history.push(ROUTE_PROFILE_SUBSCRIPTION);
                dispatch(resetConfirmationData());
              },
              children: g('subscribe')
            },
            text: g('errors.endOf28Days')
          })
        );
      }
    }
    return true;
  }, [history, g, dispatch, user]);
};

export default useSubscribeCheck;

import React, { Dispatch, FC, SetStateAction } from 'react';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';
import getDay from 'date-fns/getDay';
import { Day, DaysContainer, DaysWeekContainer, DayWeek } from './styled';

export type PropsT = {
  selectedMonth: Date;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  onClick?: (value: Date) => void;
};

const Days: FC<PropsT> = (props) => {
  const { selectedMonth, selectedDate, setSelectedDate, onClick } = props;
  const firstDateOfMonth = startOfMonth(selectedMonth);
  const lastDateOfMonth = endOfMonth(selectedMonth);
  const startMonth =
    getDay(firstDateOfMonth) === 0
      ? subDays(startOfWeek(firstDateOfMonth), 6)
      : addDays(startOfWeek(firstDateOfMonth), 1);
  const endMonth = addDays(lastDayOfWeek(lastDateOfMonth), 1);
  const dateOfMonth = eachDayOfInterval({
    start: startMonth,
    end: endMonth
  });

  const checkIfDateInThisMonth = (date: Date): boolean => {
    return isAfter(date, subDays(firstDateOfMonth, 1)) && isBefore(date, lastDateOfMonth);
  };

  return (
    <>
      <DaysWeekContainer>
        {Array(7)
          .fill(0)
          .map((value, index) => (
            <DayWeek key={dateOfMonth[index].toString()}>{format(dateOfMonth[index], 'iii')}</DayWeek>
          ))}
      </DaysWeekContainer>
      <DaysContainer>
        {dateOfMonth?.map((date) => (
          <Day
            type="button"
            variant="text"
            $isToday={isSameDay(date, new Date())}
            $isThisMonth={checkIfDateInThisMonth(date)}
            $isSelectedDay={isSameDay(date, selectedDate)}
            key={date.toString()}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDate(date);
              if (onClick) onClick(date);
            }}
          >
            {format(date, 'dd')}
          </Day>
        ))}
      </DaysContainer>
    </>
  );
};

export default Days;

import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import infoIcon from 'components/svg/warning.svg?sprite';
import {
  Wrapper,
  Title,
  Content,
  Label,
  StyledRadioButton,
  StyledButton,
  infoIconCss,
  Actions,
  CalculationsButton,
  Span
} from './styled';
import { StepT } from 'components/layouts/Modals/Depreciation';
import Select from '../../../../../FormControls/Select';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux';
import { SelectOptionT } from '../../../../../controls/Select';
import { useFormContext } from 'react-hook-form';
import useGlobalTranslation from '../../../../../../hooks/useGlobalTranlation';
import Input from '../../../../../controls/Input/Input';
import DateField from '../../../../../controls/DateField';
import FileField from '../../../../../controls/FileField';
import { DepreciationFormDataT } from '../../../../../../domain/entity/depreciation/DepreciationFormData';
import getYear from 'date-fns/getYear';
import Icon from '../../../../../common/Icon';
import Button from '../../../../../controls/Button';
import { depreciationSlice } from '../../../../../../data/store/depreciationReducer';
import HouseData from '../../../../../../domain/entity/house/HouseData';
import { TAX_DEPRECIATION_AMOUNT } from '../../../../../../helpers/depreciation';

export const radios = [
  { value: '1', label: 'lessThan3' },
  { value: '2', label: 'moreThan3' }
];

type PropsT = {
  setStep: Dispatch<SetStateAction<StepT>>;
  isDisplay?: boolean;
};

const Form: FC<PropsT> = (props) => {
  const { setStep, isDisplay } = props;
  const { t: f } = useGlobalTranslation('fields');
  const { t } = useGlobalTranslation('depreciation');
  const { t: a } = useGlobalTranslation('actions');
  const { g } = useGlobalTranslation();

  const types = [
    { value: 1, label: f('typeDepreciation.newHouseholdItem') },
    { value: 2, label: f('typeDepreciation.householdItemsInPersonalUse') }
  ];

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useFormContext<DepreciationFormDataT>();
  const { houseList, selectedHouse } = useAppSelector((state) => state.house);
  const { calculation, pending } = useAppSelector((state) => state.depreciation);
  const houseOptions: SelectOptionT[] = houseList?.map(({ name, id }) => ({ value: id, label: name })) || [];
  const type = watch('type');
  const year = watch('year');
  const sum = watch('sum');

  const dispatch = useAppDispatch();
  const { createDepreciation, createDepreciationPersonalUse, fetchDepreciationPersonalCalculation } =
    depreciationSlice.actions;
  const lifetimeVariant = watch('lifetimeVariant');
  const startYear = getYear(new Date()) - 2;
  const yearOptions = new Array(13)
    .fill(0)
    .map((_, index) => ({
      value: startYear + index,
      label: String(startYear + index)
    }))
    .filter((years) => !(selectedHouse?.depreciationYears || []).includes(years.value));

  const onSubmit = (data: DepreciationFormDataT) => {
    if (Number(data.type) === 1) {
      dispatch(
        createDepreciation({
          data: { ...data, lifetime: lifetimeVariant === radios[1].value ? data.lifetime : 0 },
          currentHouse: selectedHouse as HouseData
        })
      );
    }
    if (Number(data.type) === 2) {
      dispatch(createDepreciationPersonalUse({ data, currentHouse: selectedHouse as HouseData }));
    }
  };

  useEffect(() => {
    if (selectedHouse?.id && year) {
      dispatch(fetchDepreciationPersonalCalculation({ year, houseId: selectedHouse?.id }));
    }
  }, [type, year, selectedHouse?.id]);

  useEffect(() => {
    setValue('current_total_revenue', calculation?.totalSum);
  }, [calculation]);

  return (
    <Wrapper isDisplay={isDisplay}>
      <Title>{g('depreciation')}</Title>
      <Span />
      <Content>
        <Select
          control={control}
          name={'houseId'}
          defaultValue={selectedHouse?.id}
          options={houseOptions}
          label={f('houseName')}
          isRequired
        />
        <Select
          control={control}
          name={'type'}
          defaultValue={types[0].value}
          options={types}
          label={f('type')}
          isRequired
        />
      </Content>
      {type === 1 && (
        <>
          <Content>
            <Input
              register={register('name')}
              withoutPrompt
              variant={'outline'}
              label={f('item')}
              required
              error={errors.name}
            />
            <Input
              register={register('sum')}
              withoutPrompt
              type={'number'}
              variant={'outline'}
              label={f('amountNOK')}
              required
              error={errors.sum}
            />
            <DateField
              required
              value={getValues('date')}
              register={register('date')}
              variant={'outline'}
              label={f('date')}
              withoutPrompt
              error={errors.date}
            />
          </Content>
          <Content>
            <Label>{f('lifetime')}</Label>
            {radios.map(({ value, label }) => (
              <StyledRadioButton
                key={value}
                control={control}
                label={f(label)}
                {...register('lifetimeVariant')}
                value={value}
              />
            ))}
          </Content>
          {lifetimeVariant === radios[1].value && sum >= TAX_DEPRECIATION_AMOUNT && (
            <Content>
              <Input
                register={register('lifetime')}
                withoutPrompt
                type={'number'}
                variant={'outline'}
                label={f('lifetimeYears')}
                required
                error={errors.sum}
              />
            </Content>
          )}
          <FileField register={register('documents')} isMulti />
        </>
      )}
      {type === 2 && (
        <Content>
          <Select
            control={control}
            name={'year'}
            defaultValue={
              yearOptions.find(({ value }) => value === new Date().getFullYear())?.value || yearOptions[0].value
            }
            options={yearOptions}
            label={f('Year')}
            isRequired
          />
          <div />
          <Input
            disabled
            value={calculation?.totalSum || '0'}
            variant={'outline'}
            label={g('inputs.currentTotalRevenueNok')}
          />
          <Input
            disabled
            value={calculation?.totalSum ? getValues('depreciation') : '0'}
            variant={'outline'}
            label={g('inputs.depreciationPercents')}
          />
          <Input
            disabled
            value={(calculation?.totalSum || 0) * 0.15}
            variant={'outline'}
            label={g('inputs.taxDeductionNok')}
          />
        </Content>
      )}
      <StyledButton variant={'text'} onClick={() => setStep('info')}>
        {((type === 1 && lifetimeVariant === '1') || sum < TAX_DEPRECIATION_AMOUNT) &&
          g('depreciationText.variantFirst')}
        {type === 1 && lifetimeVariant === '2' && sum >= TAX_DEPRECIATION_AMOUNT && g('depreciationText.variantSecond')}
        {type === 2 && g('depreciationText.variantEnd')}
        <Icon icon={infoIcon} color={'blue01'} css={infoIconCss} />
      </StyledButton>
      {type === 1 && lifetimeVariant === '2' && sum >= TAX_DEPRECIATION_AMOUNT && (
        <CalculationsButton variant={'text'} onClick={() => setStep('calculations')}>
          {t('seeCalculation')}
        </CalculationsButton>
      )}
      <Actions>
        <Button disabled={pending} isLoading={pending} onClick={handleSubmit(onSubmit)}>
          {a('save')}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default Form;

import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ROUTE_DASHBOARD } from 'constant/routes';
import { authSlice } from 'data/store/authReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { getErrMsg } from 'helpers/locale';
import { Toast } from 'components';
import { Input, Form } from 'components/ui';
import mailCheckIcon from 'components/svg/mail_check_icon.svg?sprite';
import schema from './validation';
import { StyledContainer, StyledTitle, StyledSubtitle, StyledTextContainer, StyledButton } from './styled';

type ResetPasswordSubmit = {
  email: string;
};

const ResetPassword = () => {
  if (localStorage.getItem('_auth')) {
    return <Redirect to={ROUTE_DASHBOARD} />;
  }

  const { t, g } = useGlobalTranslation('resetPassword');

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<ResetPasswordSubmit>({
    resolver: yupResolver(schema)
  });
  const dispatch = useAppDispatch();
  const [sent, setSent] = useState(false);
  const [open, setOpen] = useState(false);

  const { resetPasswordFetching } = authSlice.actions;
  const { pendingReset, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (pendingReset !== undefined && !pendingReset && !error) {
      setSent(true);
    } else {
      setSent(false);
    }
  }, [pendingReset]);

  useEffect(() => {
    setOpen(sent);
  }, [sent]);

  const onSubmit = (data: ResetPasswordSubmit) => {
    dispatch(resetPasswordFetching(data));
  };

  return (
    <StyledContainer>
      <StyledTextContainer>
        <StyledTitle>{t('title')}</StyledTitle>
        <StyledSubtitle>{t('subtitle')}</StyledSubtitle>
      </StyledTextContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type={'email'}
          register={register('email')}
          error={errors?.email || error}
          errorMessage={getErrMsg(errors, 'email')}
          serverError={g(error || '')}
          label={g('email')}
        />
        <StyledButton disabled={pendingReset}>{t('button')}</StyledButton>
      </Form>
      <Toast
        icon={mailCheckIcon}
        title={t('success.title')}
        subtitle={t('success.subtitle', { email: getValues('email') })}
        {...{ open, setOpen }}
      />
    </StyledContainer>
  );
};

export default ResetPassword;

import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { call, put } from 'redux-saga/effects';
import { fetchProfile } from '../driver/auth/Auth';
import {
  profileDeleteError,
  profileDeleteSuccess,
  profileFetchingError,
  profileFetchingSuccess,
  profileUpdateSuccess
} from '../store/profileReducer';
import { deleteProfileApi, updateProfile } from '../driver/profile/Profile';
import { logout } from '../store/authReducer';
import {
  fetchSubscriptionInfo,
  fetchSubscriptionPortalUrl,
  requestSubscription
} from '../driver/subscription/Subscription';
import {
  subscriptionFetchingSuccess,
  subscriptionInfoFetchingSuccess,
  subscriptionPortalFetchingSuccess
} from '../store/subscriptionReducer';
import { ProfileFormT } from '../../domain/entity/profile/ProfileFormData';
import { registrationSuccess } from '../store/registrationReducer';
import StepEnum from '../../domain/entity/registration/StepEnum';
import ChangeEmailT from '../../domain/entity/profile/ChangeEmailT';
import { requestChangeEmail, requestChangePassword } from '../driver/accountSettings/accountSettings';
import { accountSettingsChangeError, accountSettingsChangeSuccess } from '../store/accountSettingsReducer';
import ChangePasswordT from '../../domain/entity/profile/ChangePasswordT';

export function* fetchProfileSaga({ payload }: PayloadAction<{ token: string }>) {
  try {
    const response = yield call(fetchProfile);
    yield put(profileFetchingSuccess(response));
  } catch (e) {
    yield put(profileFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* deleteProfileSaga() {
  try {
    yield call(deleteProfileApi);
    yield put(logout());
    yield put(profileDeleteSuccess());
  } catch (e) {
    yield put(profileDeleteError(e?.response?.data?.message || e?.message));
  }
}

export function* fetchSubscriptionSaga({ payload }: PayloadAction<{ priceId: string }>) {
  try {
    const response = yield call(requestSubscription, payload);
    yield put(subscriptionFetchingSuccess(response));
  } catch (e) {}
}

export function* fetchSubscriptionInfoSaga() {
  try {
    const response = yield call(fetchSubscriptionInfo);
    yield put(subscriptionInfoFetchingSuccess(response));
  } catch (e) {}
}

export function* fetchSubscriptionPortalSaga() {
  try {
    const response = yield call(fetchSubscriptionPortalUrl);
    yield put(subscriptionPortalFetchingSuccess(response));
  } catch (e) {}
}

export function* updateProfileSaga({ payload }: PayloadAction<ProfileFormT>) {
  try {
    const response = yield call(updateProfile, payload);
    yield put(profileUpdateSuccess(response));
    yield put(registrationSuccess(StepEnum.finish));
  } catch (e) {
    yield put(profileFetchingError(e?.response?.data?.message || e?.message));
  }
}

export function* accountSettingsChangeEmailSaga({ payload }: PayloadAction<ChangeEmailT>) {
  try {
    yield call(requestChangeEmail, payload);
    yield put(accountSettingsChangeSuccess());
  } catch (e) {
    yield put(accountSettingsChangeError(e?.response?.data?.message || e?.message));
  }
}

export function* accountSettingsChangePasswordSaga({ payload }: PayloadAction<ChangePasswordT>) {
  try {
    yield call(requestChangePassword, payload);
    yield put(accountSettingsChangeSuccess());
  } catch (e) {
    yield put(accountSettingsChangeError(e?.response?.data?.message || e?.message));
  }
}

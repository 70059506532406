import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TenancyTermsFormData } from 'domain/entity/rentContract/RentContract';
import { tenancyTerms } from 'data/validations/contractValidation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Select from 'components/FormControls/Select';
import Input from 'components/controls/Input/Input';
import DateField from 'components/controls/DateField';
import { StepEnum } from 'components/layouts/Modals/NewRentContract/View/data';
import { useData } from 'components/layouts/Modals/NewRentContract/View';
import {
  StyledCheckbox,
  StyledButton,
  TwoActionsButton,
  Content,
  Span
} from 'components/layouts/Modals/NewRentContract/styled';
import { closeDeposit } from '../../../../../../data/store/rentContractReducer';
import { confirmationSlice } from '../../../../../../data/store/confirmationReducer';
import { formatDateForField } from '../../../../../../helpers/date';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const TenancyTerms: FC<PropsT> = (props) => {
  const { data, setIsDirty, setValues } = useData();
  const formData = useForm<TenancyTermsFormData>({
    resolver: yupResolver(tenancyTerms),
    defaultValues: data
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isDirty }
  } = formData;
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { resetConfirmationData, setConfirmationData } = confirmationSlice.actions;
  const dispatch = useAppDispatch();
  const { error, currentContract } = useAppSelector((state) => state.contract);
  const [showedModalDate, setShowedModalDate] = useState(false);
  const { setStep } = props;
  const watchType = watch('type');
  const watchStartDate = watch('start_date');

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (!showedModalDate && currentContract && formatDateForField(currentContract.start_date) !== watchStartDate) {
      dispatch(
        setConfirmationData({
          show: true,
          cancelButtonProps: {
            onClick: () => {
              dispatch(resetConfirmationData());
              setValue('start_date', data.start_date);
            },
            children: t('alerts.changeDate.buttons.no')
          },
          confirmButtonProps: {
            onClick: () => {
              dispatch(resetConfirmationData());
              setShowedModalDate(true);
            },
            children: t('alerts.changeDate.buttons.yes')
          },
          text: t('alerts.changeDate.message')
        })
      );
    }
  }, [watchStartDate]);

  return (
    <FormProvider {...formData}>
      <Span />
      <Content>
        <Select
          control={control}
          name={'type'}
          label={f('type')}
          options={[
            { value: 'running', label: f('running') },
            { value: 'time-bound', label: f('timeBound') }
          ]}
          isRequired
          error={errors.type}
        />
        <StyledCheckbox register={register('taxable')}>{f('taxable')}</StyledCheckbox>
        <DateField
          register={register('start_date')}
          value={getValues('start_date')}
          variant={'outline'}
          label={f('startDate')}
          withoutPrompt
          required
          error={errors.start_date}
        />
        {watchType === 'time-bound' && (
          <DateField
            register={register('end_date')}
            value={getValues('end_date')}
            variant={'outline'}
            label={f('endDate')}
            withoutPrompt
            required
            error={errors.end_date}
          />
        )}
        <DateField
          register={register('first_payment_date')}
          value={getValues('first_payment_date')}
          variant={'outline'}
          label={f('dateOfFirstPayment')}
          withoutPrompt
          required
          error={errors.first_payment_date}
        />
        <Input
          register={register('month_resignation')}
          type={'number'}
          withoutPrompt
          variant={'outline'}
          label={f('numberOfMonthResignation')}
          required
          error={errors.month_resignation}
        />
        <Input
          register={register('month_sum')}
          type={'number'}
          withoutPrompt
          variant={'outline'}
          label={f('monthlyRentNOK')}
          required
          error={errors.month_sum}
        />
      </Content>
      <TwoActionsButton>
        <StyledButton
          type={'button'}
          variant={'outline'}
          onClick={() => {
            setValues(getValues());
            setStep(StepEnum.INFORM_ABOUT_TENANT);
          }}
        >
          {f('back')}
        </StyledButton>
        <StyledButton
          type={'button'}
          variant={'fill'}
          onClick={handleSubmit((formData) => {
            setValues(formData);
            setStep(StepEnum.INCLUDING);
          })}
        >
          {t('next')}
        </StyledButton>
      </TwoActionsButton>
    </FormProvider>
  );
};

export default TenancyTerms;

import styled, { css } from 'styled-components';
import BaseButton from '../../../../components/controls/Button';

export const Wrapper = styled.div``;

export const Button = styled(BaseButton)`
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 1.2px;
  gap: 0;
  text-transform: initial;
  margin: 2.25rem 0;
  padding: 0;
`;

export const backIconCss = css`
  width: 10px;
  fill: currentColor;
  margin: 0 1.25rem 0 0;
`;

export const Content = styled.div`
  margin-top: 20px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};
`;

export const LeftBlock = styled.div`
  display: grid;
  font-size: 16px;
  line-height: 1.5;
`;

export const Title = styled.span`
  font-weight: 500;
`;

export const Sum = styled.span<{ color: 'green01' | 'red01' | 'orange01' }>`
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const DateText = styled.span`
  color: ${({ theme }) => theme.colors.gray01};
`;

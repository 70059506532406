// Global modules
import React from 'react';

// Styled
import { StyledWrapper } from './styled';

const NotFound = () => {
  return <StyledWrapper>404</StyledWrapper>;
};

export default NotFound;

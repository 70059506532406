import React, { FC } from 'react';
import { Label, StyledContainer, StyledText } from './styled';

interface PropsT {
  label: string;
}

const TextInfo: FC<PropsT> = ({ label, children }) => {
  return (
    <StyledContainer>
      <Label>{label}</Label>
      <StyledText>{children}</StyledText>
    </StyledContainer>
  );
};

export default TextInfo;

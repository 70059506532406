import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Topbar from 'components/layouts/MainLayout/Topbar';
import Icon from 'components/common/Icon';
import welcome from 'components/svg/welcome.svg?sprite';
import { Wrapper, iconCss, Title, Message, Content, ContentIcon } from './styled';

const TenantNotifications: FC = () => {
  const { g } = useGlobalTranslation();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const status = urlParams.get('status');

  const statusObject: Record<string, { title: string; message?: string }> = {
    success: {
      title: 'tenantNotification.success.title'
    },
    fail: {
      title: 'tenantNotification.fail.title',
      message: 'tenantNotification.fail.message'
    },
    cancel: {
      title: 'tenantNotification.cancel.title',
      message: 'tenantNotification.cancel.message'
    }
  };

  if (!status) {
    return <></>;
  }

  return (
    <>
      <Topbar withoutNavigation isHideLogo={false} />
      <Wrapper>
        <div>
          <Title>{g(statusObject[status].title)}</Title>
          {statusObject[status]?.message && <Message>{g(statusObject[status].message || '')}</Message>}
          <Content>
            <ContentIcon>
              <Icon icon={welcome} css={iconCss} />
            </ContentIcon>
          </Content>
        </div>
      </Wrapper>
    </>
  );
};

export default TenantNotifications;

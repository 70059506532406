import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';
import { ROUTE_LOGIN, ROUTE_REGISTRATION } from 'constant/routes';
import { modalsSlice } from 'data/store/modalReducer';
import StepEnum from 'domain/entity/registration/StepEnum';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Button from 'components/controls/Button';
import Icon from 'components/common/Icon';
import closeIcon from 'components/svg/close_icon.svg?sprite';

type WrapperProps = {
  $display: boolean;
};

const StyledWrapper = styled(Button)<WrapperProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.black01};
  visibility: ${({ $display }) => ($display && 'visible') || 'hidden'};
  width: max-content;
  justify-content: center;
  align-items: center;
  justify-self: end;
  right: 2rem;
  cursor: pointer;
  position: absolute;
  top: 2rem;
  z-index: 9;
  opacity: ${({ $display }) => ($display && 1) || 0};
  transition: all ${({ theme }) => theme.transition};

  ${mq.greaterSm} {
    position: relative;
  }
`;

const closeIconCss = css`
  width: 24px;
  fill: currentColor;
`;

const CloseButton = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { openRegistrationModal } = modalsSlice.actions;
  const { step } = useAppSelector((state) => state.registration);

  const handleCloseClick = () => {
    if (location.pathname === ROUTE_REGISTRATION && step !== StepEnum.general) {
      dispatch(openRegistrationModal());
      return;
    }
    history.push(ROUTE_LOGIN);
  };

  return (
    <StyledWrapper $display={location.pathname !== ROUTE_LOGIN} variant={'text'} onClick={handleCloseClick}>
      <Icon icon={closeIcon} css={closeIconCss} />
    </StyledWrapper>
  );
};

export default CloseButton;

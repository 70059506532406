import { FETCH_ALL_DOCUMENTS, UPLOAD_DOCUMENTS } from 'constant/apiRoutes';
import { API_URL, httpWithAuth } from 'data/driver/Api';
import isAfter from 'date-fns/isAfter';
import HouseData from 'domain/entity/house/HouseData';
import {
  Bilag,
  CommonBilag,
  DepreciationBilag,
  DepreciationPersonalBilag,
  IncomeBilag
} from 'domain/entity/documnets/CommonBilag';
import formatISO from 'date-fns/formatISO';
import { startOfYear } from 'date-fns';
import { formatDateForApi } from '../../../helpers/date';
import { EXTERNAL_GENERATE_PDF } from '../../../constant/routes';

const convertBilag = (bilag: Bilag): CommonBilag => ({
  type: 'bilag',
  bilag: {
    ...bilag,
    date: bilag.date,
    created_at: bilag.created_at,
    updated_at: bilag.updated_at,
    isRepeated: (bilag.parentBilagId && true) || false
  }
});

const convertIncomeBilag = (bilag: IncomeBilag): CommonBilag => ({
  type: 'income-bilag',
  bilag: {
    ...bilag,
    tenant_birthday: bilag.tenant_birthday,
    start_date: bilag.start_date,
    end_date: bilag.end_date,
    created_at: bilag.created_at,
    updated_at: bilag.updated_at,
    taxable: bilag.contractId === null ? bilag.separatedTaxable : bilag.taxable
  }
});

const convertDepreciationBilag = (bilag: DepreciationBilag): CommonBilag => ({
  type: 'depreciation-bilag',
  bilag: {
    ...bilag,
    date: bilag.date,
    created_at: bilag.created_at,
    updated_at: bilag.updated_at
  }
});

const convertDepreciationPersonalBilag = (bilag: DepreciationPersonalBilag): CommonBilag => ({
  type: 'depreciation-personal-bilag',
  bilag: {
    ...bilag,
    date: bilag.date,
    created_at: bilag.created_at,
    updated_at: bilag.updated_at
  }
});

export const fetchAllDocumentsApi = async (data: {
  houseId: HouseData['id'];
  date_from?: string;
  date_to?: string;
}) => {
  const { houseId, date_from, date_to } = data;
  const {
    data: { bilags = [], incomeBilags = [], depreciationBilags = [], depreciationPersonalBilags = [] }
  } = await httpWithAuth.get(FETCH_ALL_DOCUMENTS, {
    params: {
      houseId,
      date_from: date_from ? formatDateForApi(date_from) : formatISO(startOfYear(new Date())),
      date_to: formatDateForApi(date_to || '')
    }
  });

  const bilagsArchived = bilags.filter(({ archived }: { archived: boolean }) => archived);
  const bilagsNonArchived = bilags.filter(({ archived }: { archived: boolean }) => !archived).map(convertBilag);

  const convertedBilags = [
    ...depreciationPersonalBilags.map(convertDepreciationPersonalBilag),
    ...bilagsNonArchived,
    ...incomeBilags.map(convertIncomeBilag),
    ...depreciationBilags.map(convertDepreciationBilag)
  ].sort((b1, b2) => (isAfter(new Date(b1), new Date(b2)) ? 0 : 1));

  return [...bilagsArchived.map(convertBilag), ...convertedBilags];
};

export const fetchGenerateDocumentApi = async (contractId: number) => {
  return await httpWithAuth.get(EXTERNAL_GENERATE_PDF(contractId));
};

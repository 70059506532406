import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemeT } from 'context/Theme';
import { Button } from '../../../../ui';

type LinkProps = {
  $active: boolean;
  $isComing?: boolean;
};

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 0;
`;

export const StyledLink = styled(Link)<LinkProps>`
  user-select: none;
  border-radius: 3px;
  padding-left: 13px;
  height: 3rem;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  background-color: ${({ $active, theme }) => ($active && theme.colors.gray05) || 'transparent'};
  color: ${({ $active, theme, $isComing }) =>
    (!$isComing && $active && theme.colors.blue01) || ($isComing && theme.colors.gray03) || theme.colors.black01};
  ${({ $isComing }) => $isComing && 'pointer-events: none;'}
  &:hover {
    opacity: 0.6;
  }
`;

export const StyledTrashLink = styled(Link)<LinkProps>`
  user-select: none;
  border-radius: 3px;
  padding-left: 13px;
  height: 3rem;
  margin: 0;
  font-weight: 500;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ $active, theme }) => ($active && theme.colors.gray05) || 'transparent'};
  color: ${({ $active, theme, $isComing }) =>
    (!$isComing && $active && theme.colors.blue01) || ($isComing && theme.colors.gray03) || theme.colors.black01};
  ${({ $isComing }) => $isComing && 'pointer-events: none;'}
  &:hover {
    opacity: 0.6;
  }
`;

export const StyledDeleteLink = styled(Button)<LinkProps>`
  border-radius: 3px;
  padding-left: 13px;
  height: 3rem;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  text-transform: none;
  background-color: transparent;
  user-select: none;
  color: ${({ theme }) => theme.colors.red01};
  &:hover {
    opacity: 0.6;
    background-color: transparent;
  }
`;

export const StyledText = styled.div<LinkProps>`
  margin: 1.125rem 0.75rem;
  letter-spacing: 0.15px;
  font-weight: ${({ $active }) => ($active && 500) || 400};
  color: ${({ $active, theme }) => ($active && theme.colors.blue01) || 'inherit'};
`;

export const StyledScroll = styled.div`
  height: 100%;
  overflow-y: auto;
`;

type IconContainerPropsT = {
  color: keyof ThemeT['colors'];
};

export const IconContainer = styled.span<IconContainerPropsT>`
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const iconCss = css`
  width: 24px;
  fill: currentColor;
`;
export const trashIconCss = css`
  width: 24px;
  fill: currentColor;
  margin-right: 0.75rem;
`;

export const Divider = styled.div`
  margin: 5px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};
`;

import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { ROUTE_RENT_CONTRACT } from 'constant/routes';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant/externalLinks';
import INITIAL_REGISTRATION_FORM_DATA, { RegistrationFormT } from 'domain/entity/registration/RegistrationFormData';
import { registrationSlice } from 'data/store/registrationReducer';
import { profileSlice } from 'data/store/profileReducer';
import { houseSlice } from 'data/store/houseReducer';
import { contractSlice } from 'data/store/rentContractReducer';
import { registrationSchema } from 'data/validations/registrationValidation';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useData } from 'containers/RegisterContract/View';
import { StepEnum } from 'containers/RegisterContract/View/data';
import { StyledSignUpLink } from 'containers/Registration/View/styled';
import { Input } from 'components/ui';
import {
  StyledButton,
  ActionsButton,
  Content,
  Title,
  StyledContent,
  StyledCheckbox,
  Link,
  HiddenLabel
} from './styled';

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const CreatePassword: FC<PropsT> = (props) => {
  const { dataRentContract, dataHouseInfo, dataLandlord } = useData();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const formData = useForm<RegistrationFormT>({
    resolver: yupResolver(registrationSchema),
    defaultValues: { ...INITIAL_REGISTRATION_FORM_DATA, email: dataLandlord.email }
  });
  const dispatch = useAppDispatch();
  const { registration } = registrationSlice.actions;
  const { profileUpdate, profileFetching } = profileSlice.actions;
  const { createHouseRegistration } = houseSlice.actions;
  const { createContract } = contractSlice.actions;
  const { checkEmailError, error: registrationError } = useAppSelector((state) => state.registration);
  const { token } = useAppSelector((state) => state.auth);
  const { selectedHouse, error: houseError } = useAppSelector((state) => state.house);
  const { error: profileError } = useAppSelector((state) => state.profile);
  const { currentContract, error: contractError, pending } = useAppSelector((state) => state.contract);
  const { register: registrationFields, formState: registrationFormState, handleSubmit } = formData;
  const { t } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { t: a } = useGlobalTranslation('actions');
  const { g } = useGlobalTranslation('login');
  const { setStep } = props;

  const onSubmit = async (data: RegistrationFormT) => {
    setIsLoading(true);
    await dispatch(registration(data));
  };

  useEffect(() => {
    setTimeout(() => {
      if (token) {
        const profileInfo = { ...dataLandlord, email: undefined };
        dispatch(profileUpdate(profileInfo));
        dispatch(createHouseRegistration(dataHouseInfo));
      }
    }, 1000);
  }, [token]);

  useEffect(() => {
    if (selectedHouse) {
      dispatch(profileFetching());
      dispatch(
        createContract({
          ...dataRentContract,
          houseId: selectedHouse.id
        })
      );
    }
    setIsLoading(false);
  }, [selectedHouse]);

  useEffect(() => {
    if (currentContract) {
      history.push(`${ROUTE_RENT_CONTRACT}/${currentContract.id}`);
    }
  }, [currentContract]);

  useEffect(() => {
    if (contractError || houseError || profileError || registrationError) {
      setIsLoading(false);
    }
  }, [contractError, houseError, profileError, registrationError]);

  return (
    <FormProvider {...formData}>
      <Content>
        <Title>{f('createPassword')}</Title>
        <StyledContent>
          <HiddenLabel>
            <Input register={registrationFields('email')} />
          </HiddenLabel>
          <Input
            maxLength={50}
            type="password"
            label={f('createPassword')}
            withoutPrompt
            variant={'outline'}
            register={registrationFields('password')}
            error={registrationFormState.errors['password']}
          />
          <Input
            maxLength={50}
            type="password"
            label={f('repeatCreatedPassword')}
            withoutPrompt
            variant={'outline'}
            register={registrationFields('repeatPassword')}
            error={
              registrationFormState.errors['password'] ||
              registrationFormState.errors['repeatPassword'] ||
              (checkEmailError === 'errors.isOldAppUser' && registrationError)
            }
            errorMessage={
              registrationFormState.errors?.['password']?.message ||
              registrationFormState.errors?.['repeatPassword']?.message
            }
            serverError={
              (checkEmailError === 'errors.isOldAppUser' && (
                <>
                  {g('errors.isOldAppUserStart')}{' '}
                  <StyledSignUpLink to={'#'}>{g('errors.isOldAppUserClick')}</StyledSignUpLink>{' '}
                  {g('errors.isOldAppUserEnd')}
                </>
              )) ||
              registrationError
            }
          />
          <StyledCheckbox
            withPrompt
            register={formData.register('isAgreePolicy')}
            value={'true'}
            errorMessage={
              registrationFormState.errors?.['isAgreePolicy'] &&
              g(`errors.${registrationFormState.errors?.['isAgreePolicy']?.message}`)
            }
          >
            {g('iAgree')}{' '}
            <Link href={TERMS_OF_USE_URL} target="_blank">
              {g('terms')}
            </Link>{' '}
            {g('and')}{' '}
            <Link href={PRIVACY_POLICY_URL} target="_blank">
              {g('policy')}
            </Link>
          </StyledCheckbox>
        </StyledContent>
        <ActionsButton>
          <StyledButton
            isLoading={isLoading || pending}
            disabled={isLoading || pending}
            type={'button'}
            variant={'fill'}
            onClick={handleSubmit(onSubmit)}
          >
            {t('next')}
          </StyledButton>
          <StyledButton type={'button'} variant={'outline'} onClick={() => setStep(StepEnum.RENT_CONTRACT)}>
            {a('back')}
          </StyledButton>
        </ActionsButton>
      </Content>
    </FormProvider>
  );
};

export default CreatePassword;

export const TAX_DEPRECIATION_AMOUNT = 15000;

type CalcsProps = {
  lifetime: number;
  sum: number;
  expensed?: number;
};

const calculateYearDepreciation = ({ lifetime = 0, sum = 0, expensed = 0 }: CalcsProps): CalcsProps => {
  return {
    lifetime: lifetime - 1,
    sum: sum - expensed,
    expensed
  };
};

export const calculateYears = ({ lifetime, sum }: CalcsProps, calculations: CalcsProps[] = []): CalcsProps[] => {
  const newExpensed = (sum >= TAX_DEPRECIATION_AMOUNT && ~~((sum / 100) * 20)) || sum;
  const newCalculations = [...calculations, ...((lifetime > 0 && [{ lifetime, sum, expensed: newExpensed }]) || [])];

  if (lifetime > 0 && sum >= 15000) {
    return calculateYears(calculateYearDepreciation({ lifetime, sum, expensed: newExpensed }), newCalculations);
  }
  return newCalculations;
};

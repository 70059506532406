import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import { IncomeBilagFormData } from '../../domain/entity/IncomeBilag/IncomeBilagFormData';
import HouseData from '../../domain/entity/house/HouseData';
import { IncomeBilag } from '../../domain/entity/documnets/CommonBilag';

export interface IncomeBilagState {
  pending?: boolean;
  error?: string;
  deletePending?: boolean;
}

const initialState: IncomeBilagState = {
  pending: false,
  error: ''
};

export const incomeBilagSlice = createSlice({
  name: 'incomeBilag',
  initialState,
  reducers: {
    createIncomeBilag(
      state: IncomeBilagState,
      _: PayloadAction<{ data: IncomeBilagFormData; currentHouse?: HouseData }>
    ) {
      state.pending = true;
      state.error = '';
    },
    createIncomeBilagSuccess(state: IncomeBilagState) {
      state.pending = false;
      state.error = '';
    },
    createIncomeBilagError(state: IncomeBilagState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    deleteIncomeBilag(state: IncomeBilagState, _: PayloadAction<number>) {
      state.deletePending = true;
      state.error = '';
    },
    deleteIncomeBilagSucces(state: IncomeBilagState) {
      state.deletePending = false;
      state.error = '';
    },
    deleteIncomeBilagError(state: IncomeBilagState, { payload }: PayloadAction<string>) {
      state.deletePending = false;
      state.error = payload;
    },
    changeRentIncomeBilag(state: IncomeBilagState, _: PayloadAction<{ id: IncomeBilag['id']; sum: string }>) {
      state.pending = true;
      state.error = '';
    },
    changeRentIncomeBilagSuccess(state: IncomeBilagState) {
      state.pending = false;
      state.error = '';
    },
    changeRentIncomeBilagError(state: IncomeBilagState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    }
  }
});

const selectIncomeBilagBilag = (state: RootState): IncomeBilagState => state.incomeBilag;

export const incomeBilagSelector = createSelector([selectIncomeBilagBilag], (incomeBilag) => incomeBilag);

export const {
  createIncomeBilagError,
  createIncomeBilag,
  createIncomeBilagSuccess,
  deleteIncomeBilag,
  deleteIncomeBilagError,
  deleteIncomeBilagSucces,
  changeRentIncomeBilag,
  changeRentIncomeBilagError,
  changeRentIncomeBilagSuccess
} = incomeBilagSlice.actions;

export default incomeBilagSlice.reducer;

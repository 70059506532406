import { httpWithAuth } from '../Api';
import {
  PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL,
  PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD
} from '../../../constant/apiRoutes';
import ChangePasswordT from '../../../domain/entity/profile/ChangePasswordT';
import ChangeEmailT from '../../../domain/entity/profile/ChangeEmailT';

export const requestChangeEmail = async (newEmail: ChangeEmailT) => {
  return await httpWithAuth.post(PROFILE_ACCOUNT_SETTINGS_CHANGE_EMAIL, newEmail);
};

export const requestChangePassword = async (newPasswords: ChangePasswordT) => {
  return await httpWithAuth.post(PROFILE_ACCOUNT_SETTINGS_CHANGE_PASSWORD, newPasswords);
};

import styled, { css } from 'styled-components';
import { mq } from '../../../../constant/mediaqueries';

export const StyledPicture = styled.img`
  margin: 0;
  border-radius: 3px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  ${mq.greaterXs} {
    max-height: 800px;
  }
`;

export const modalContentCss = css`
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: initial;
  height: max-content;
  box-shadow: none;
  background-color: transparent;
  ${mq.greaterXs} {
    && {
      margin-top: 4%;
      margin-bottom: 4%;
    }
  }
`;

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b7b7b7;
  max-width: 30rem;
`;
export const StyledSuccessContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #b7b7b7;
  max-width: 30rem;
  position: absolute;
  background-color: #ffffff;
  top: 0;
  padding: 3rem 0 0 0;

  ${mq.greaterSm} {
    position: relative;
    height: 100%;
  }
`;

export const StyledTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 3rem;
`;

export const StyledTitle = styled.div`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.black01};
  text-align: center;
  font-weight: 500;
`;

export const StyledSubtitle = styled.div`
  margin: 1.25rem 0;
`;

export const StyledLink = styled(Link)`
  text-transform: lowercase;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
`;

export const checkboxIconCss = css`
  width: 48px;
  margin: 2rem;
`;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enLocale from './en/locale.json';
import noLocale from './no/locale.json';

const resources = {
  no: noLocale,
  nb: noLocale,
  en: enLocale
};
const options = {
  order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path'],
  lookupQuerystring: 'lng'
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    supportedLngs: ['en', 'no', 'nb'],
    interpolation: {
      escapeValue: false
    },
    debug: false
  });

export default i18n;

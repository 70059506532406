import { AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';
import { createInjectorsEnhancer } from 'redux-injectors';

import createReducer, { rootReducer } from './rootReducer';
import rootSaga from 'data/sagas';

export default function configureAppStore(initialState = {}) {
  const reduxSagaMonitorOptions: SagaMiddlewareOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const { run: runSaga } = sagaMiddleware;

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga
    })
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware] as const,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers
  });

  sagaMiddleware.run(rootSaga);
  return store as unknown as Store<typeof rootReducer, AnyAction>;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureAppStore>;
export type AppDispatch = AppStore['dispatch'];

import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { mq } from '../../../../constant/mediaqueries';
import BaseButton from '../../../../components/controls/Button';

export const StyledContainer = styled.div`
  margin: 1.25rem 0;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  ${mq.greaterXl} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem 0;
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0 0 0 0.5rem;
`;
export const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 1.5rem 0 0;
  font-size: 0.875rem;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray04};
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const Button = styled(BaseButton)`
  text-transform: initial;
  font-size: 14px;
  line-height: 1.29;
`;

export const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.colors.red01};
`;

export const iconButtonCss = css`
  fill: currentColor;
  width: 24px;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.gray03};
  font-size: 0.9rem;
  margin: 15px 0 0;
`;

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';

export interface PictureViewerState {
  show: boolean;
  src?: string;
}

const initialState: PictureViewerState = {
  show: false,
  src: undefined
};

export const pictureViewerSlice = createSlice({
  name: 'pictureViewer',
  initialState,
  reducers: {
    setPictureViewerData(state: PictureViewerState, { payload }: PayloadAction<PictureViewerState>) {
      state.show = true;
      state.src = payload.src;
    },
    resetPictureViewerData(state: PictureViewerState) {
      state.show = false;
      state.src = undefined;
    }
  }
});

const selectPictureViewer = (state: RootState): PictureViewerState => state.pictureViewer;

export const pictureViewerSelector = createSelector([selectPictureViewer], (PictureViewer) => PictureViewer);

export const { setPictureViewerData, resetPictureViewerData } = pictureViewerSlice.actions;

export default pictureViewerSlice.reducer;

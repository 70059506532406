import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';
import { ROUTE_ALTINN_SUBSCRIPTION } from 'constant/routes';
import { HELP_INSTRUCTIONS_URL } from 'constant/externalLinks';
import { altinnSlice } from 'data/store/altinnReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import Button from 'components/controls/Button';
import homeIcon from 'components/svg/home.svg?sprite';
import checkIcon from 'components/svg/check-all.svg?sprite';
import List, { ListTypeT } from './List';
import Forms from './Forms';
import { Header, houseIconCss, Footer, checkIconCss, Link, Content, ItemTitle, ItemSum } from './styled';
import SimpleRow from 'components/controls/SimpleRow';
import SimplePage from 'components/common/SimplePage';

const MAX_LOGGED_TIME = 5;

const OldAltinnPage = () => {
  const { g } = useGlobalTranslation();
  const history = useHistory();
  const { t: actionsTranslate } = useGlobalTranslation('actions');
  const { t: altinnTranslate } = useGlobalTranslation('altinn');
  const [typeList, setTypeList] = useState<ListTypeT>();
  const { selectedHouse } = useAppSelector(({ house }) => house);
  const { user } = useAppSelector(({ profile }) => profile);
  const { altinnData, pending, loginDate, showAltinnForm, credentials } = useAppSelector(({ altinn }) => altinn);
  const { fetchAltinnData, openAltinnModal, logoutAltinn, fetchAltinnFormData, hiddenAltinnForms } =
    altinnSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedHouse) dispatch(fetchAltinnData());
  }, [selectedHouse?.id]);

  useEffect(() => {
    return () => {
      dispatch(logoutAltinn());
    };
  }, []);

  useEffect(() => {
    dispatch(hiddenAltinnForms());
    setTypeList(undefined);
  }, [selectedHouse]);

  useEffect(() => {
    if (!user?.subscriptionActive) {
      history.push(ROUTE_ALTINN_SUBSCRIPTION);
    }
  }, []);

  if (!selectedHouse || pending) {
    return null;
  }

  const handleButtonClick = () => {
    if (credentials && loginDate) {
      const loggedTime = (new Date().getTime() - loginDate) / 1000 / 60;

      if (loggedTime <= MAX_LOGGED_TIME) {
        dispatch(fetchAltinnFormData(credentials));
        return;
      }
    }
    dispatch(
      setConfirmationData({
        text: g('altinComingSoon'),
        onClose: () => dispatch(resetConfirmationData()),
        confirmButtonProps: {
          onClick: () => dispatch(resetConfirmationData()),
          children: g('cancel')
        },
        show: true
      })
    );
  };

  return (
    <SimplePage
      header={
        <Header>
          <div>
            <Icon icon={homeIcon} css={houseIconCss} />
            {selectedHouse.name}
          </div>
          {g('year')} {format(subYears(new Date(), 1), 'yyyy')}
        </Header>
      }
    >
      {!typeList && !showAltinnForm && (
        <>
          <Content>
            <SimpleRow
              onClick={() => setTypeList('bilags')}
              name={<ItemTitle>{g('expensesBilag')}</ItemTitle>}
              info={
                <ItemSum color={'red01'}>
                  {altinnData?.find(({ houseId }) => houseId === selectedHouse.id)?.bilagsSum} NOK
                </ItemSum>
              }
            />
            <SimpleRow
              onClick={() => setTypeList('incomeBilags')}
              name={<ItemTitle>{g('incomeBilag')}</ItemTitle>}
              info={
                <ItemSum color={'green01'}>
                  {altinnData?.find(({ houseId }) => houseId === selectedHouse.id)?.incomebilagsSum} NOK
                </ItemSum>
              }
            />
          </Content>
          <Footer>
            <Button onClick={handleButtonClick}>
              <Icon icon={checkIcon} css={checkIconCss} />
              {actionsTranslate('readyToSend')}
            </Button>
            <Link href={HELP_INSTRUCTIONS_URL} target={'_blank'}>
              {altinnTranslate('links.instructionsForSending')}
            </Link>
          </Footer>
        </>
      )}
      {altinnData && typeList && (
        <List
          type={typeList}
          setType={setTypeList}
          altinnData={altinnData?.find(({ houseId }) => houseId === selectedHouse.id)}
        />
      )}
      {showAltinnForm && <Forms />}
    </SimplePage>
  );
};

export default OldAltinnPage;

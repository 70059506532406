import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTE_SEPARATE_INCOME_BILAGS } from 'constant/routes';
import { InfoFieldT } from 'domain/entity/common';
import { documentsSelector } from 'data/store/documentReducer';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { useAppSelector } from 'hooks/redux';
import { BackButton } from 'components/ui';
import SimplePage from 'components/common/SimplePage';
import PictureList from 'components/controls/PictureList';
import InfoBlock from './InfoBlock';
import { StyledHr } from './styled';

export const SeparateIncomeBilagItem: FC = () => {
  const { g } = useGlobalTranslation();
  const { currentDocument } = useAppSelector(documentsSelector);
  const history = useHistory();

  useEffect(() => {
    if (!currentDocument) {
      history.goBack();
    }
  }, [currentDocument]);

  if (currentDocument?.type !== 'income-bilag') {
    return null;
  }

  const generalFields: InfoFieldT[] = [
    {
      value:
        (currentDocument && `${currentDocument.bilag.tenant_first_name} ${currentDocument.bilag.tenant_last_name}`) ||
        '',
      label: 'inputs.fullName',
      type: 'text'
    },
    {
      value: (currentDocument && currentDocument.bilag.start_date) || '',
      label: 'inputs.start',
      type: 'date'
    },
    {
      value: (currentDocument && currentDocument.bilag.tenant_birthday) || '',
      label: 'inputs.birth',
      type: 'date'
    },
    {
      value: (currentDocument && currentDocument.bilag.end_date) || '',
      label: 'inputs.end',
      type: 'date'
    },
    {
      value: (currentDocument && currentDocument.bilag.tenant_phone) || '',
      label: 'inputs.phone',
      type: 'text'
    },
    {
      value: (currentDocument && currentDocument.bilag.sum) || '',
      label: 'inputs.rentalPayment',
      type: 'currency'
    },
    {
      value: (currentDocument && currentDocument.bilag.tenant_email) || '',
      label: 'inputs.email',
      type: 'text'
    }
  ];
  const moreDetailsFields: InfoFieldT[] = [
    {
      value: (currentDocument && currentDocument.bilag.start_date) || '',
      label: 'inputs.dateOfPayment',
      type: 'date'
    },
    {
      value: (currentDocument && currentDocument.bilag.deposit_sum) || '',
      label: 'inputs.deposit',
      type: 'currency'
    },
    {
      value: (currentDocument && currentDocument.bilag.taxable) || false,
      label: 'inputs.taxable',
      type: 'boolean'
    }
  ];

  return (
    <SimplePage
      header={
        <BackButton route={ROUTE_SEPARATE_INCOME_BILAGS}>{currentDocument && currentDocument.bilag.title}</BackButton>
      }
    >
      <InfoBlock heading={g('generalInfo')} fields={generalFields} />
      <InfoBlock heading={g('moreSeparatedIncomeBilagDetails')} fields={moreDetailsFields} />
      <StyledHr />
      <PictureList documents={currentDocument?.bilag.documents || []} />
    </SimplePage>
  );
};

import React, { FC } from 'react';
import { SignedUserInfo } from '../../components';
import useGlobalTranslation from '../../hooks/useGlobalTranlation';
import { InfoFields } from './components';
import SimplePage from '../../components/common/SimplePage';

const AccountSettings: FC = () => {
  const { g } = useGlobalTranslation('landlord');

  return (
    <SimplePage variant={'profile'} header={<SignedUserInfo sign={g('sidebar.landlord')} />}>
      <InfoFields />
    </SimplePage>
  );
};

export default AccountSettings;

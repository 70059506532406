import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import {
  CheckContract,
  Contract,
  ContractFormData,
  ReleaseDepositFormData,
  ResponseCountryListT
} from '../../domain/entity/rentContract/RentContract';
import { updateSomethingInSomethingsArray } from '../../helpers/arrays';
import { contractSignDepositAccountSaga } from '../sagas/contract';
import { SelectOptionT } from '../../components/controls/Select';
import i18n from 'i18next';

export interface ContractState {
  contractList?: Contract[];
  currentContract?: Contract;
  newContractId?: number;
  pending?: boolean;
  pendingSignBankId?: boolean;
  error?: string;
  countryOptions?: SelectOptionT[];
  noCountryOptions?: SelectOptionT[];
  nationalityOptions?: SelectOptionT[];
  noNationalityOptions?: SelectOptionT[];
  isLoadingOptions: boolean;
  errorLoadingList: string;
  isLoadingCheckList: boolean;
}

const initialState: ContractState = {
  contractList: [],
  currentContract: undefined,
  pending: false,
  pendingSignBankId: false,
  error: '',
  countryOptions: [],
  nationalityOptions: [],
  isLoadingOptions: false,
  errorLoadingList: '',
  isLoadingCheckList: false
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    createContract(state: ContractState, _: PayloadAction<ContractFormData>) {
      state.pending = true;
      state.pendingSignBankId = true;
      state.error = '';
    },
    createContractSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.pending = false;
      state.pendingSignBankId = false;
      state.currentContract = payload;
      state.newContractId = payload.id;
      state.error = '';
    },
    createContractError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.pendingSignBankId = false;
      state.error = payload;
    },
    updateContract(state: ContractState, _: PayloadAction<{ formData: ContractFormData; contractId: number }>) {
      state.pending = true;
      state.error = '';
    },
    updateContractSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.pending = false;
      state.currentContract = payload;
      state.error = '';
    },
    updateContractError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    resetNewContractId(state: ContractState) {
      state.newContractId = undefined;
    },
    createContractSignBankId(state: ContractState, _: PayloadAction<ContractFormData>) {
      state.pendingSignBankId = true;
      state.error = '';
    },
    createContractSignBankIdSuccess(state: ContractState) {
      state.pendingSignBankId = false;
      state.error = '';
    },
    createContractSignBankIdError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pendingSignBankId = false;
      state.error = payload;
    },
    signContractByBankID(state: ContractState, _: PayloadAction<{ id: Contract['id']; checkList?: boolean }>) {
      state.pending = true;
      state.error = '';
    },
    regenerateSignContractByBankID(
      state: ContractState,
      _: PayloadAction<{ id: Contract['id']; checkList?: boolean }>
    ) {
      state.pending = true;
      state.error = '';
    },
    signContractDepositAccount(state: ContractState, _: PayloadAction<Contract['id']>) {
      state.pending = true;
      state.error = '';
    },
    signContractByBankIDSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.pending = false;
      state.currentContract = payload;
      state.error = '';
    },
    signContractByBankIDError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    fetchContracts(state: ContractState) {
      state.pending = true;
      state.error = '';
    },
    fetchFinishContract(state: ContractState, _: PayloadAction<number>) {
      state.pending = true;
      state.error = '';
    },
    fetchFinishContractSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.currentContract = payload;
      state.pending = false;
      state.error = '';
    },
    fetchContractsSuccess(state: ContractState, { payload }: PayloadAction<Contract[]>) {
      state.contractList = payload;
      state.pending = false;
      state.error = '';
    },
    updateContracts(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.contractList = updateSomethingInSomethingsArray(state.contractList || [], payload);
    },
    setCurrentContract(state: ContractState, { payload }: PayloadAction<number | undefined>) {
      state.currentContract = payload ? state.contractList?.filter(({ id }) => id === payload)[0] : undefined;
      state.pending = false;
      state.error = '';
    },
    setPdfContract(state: ContractState, { payload }: PayloadAction<number>) {
      state.currentContract = state.contractList?.filter(({ id }) => id === payload)[0];
      state.pending = false;
      state.error = '';
    },
    deleteAllIncomeBilags(state: ContractState, { payload }: PayloadAction<number>) {
      state.pending = true;
      state.error = '';
    },
    deleteAllIncomeBilagsSuccess(state: ContractState) {
      state.pending = false;
      state.error = '';
    },
    deleteAllIncomeBilagsError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    closeDeposit(state: ContractState, { payload }: PayloadAction<number>) {
      state.pending = true;
      state.error = '';
    },
    closeDepositSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.currentContract = payload;
      state.pending = false;
      state.error = '';
    },
    closeDepositError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    releaseDeposit(state: ContractState, _: PayloadAction<{ formData: ReleaseDepositFormData; contractId: number }>) {
      state.pending = true;
      state.error = '';
    },
    releaseDepositSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.currentContract = payload;
      state.pending = false;
      state.error = '';
    },
    releaseDepositError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    clearError(state: ContractState) {
      state.error = '';
    },
    deletePayContract(state: ContractState) {
      if (state.currentContract) state.currentContract = { ...state.currentContract, payUrl: undefined };
    },
    getCountryList(state: ContractState) {
      state.isLoadingOptions = true;
    },
    getCountryListSuccess(state: ContractState, { payload }: PayloadAction<ResponseCountryListT[]>) {
      state.isLoadingOptions = false;
      state.countryOptions = payload.map(({ alpha_2_code, en_short_name, no_short_name }) => ({
        value: alpha_2_code,
        label: en_short_name
      }));
      state.noCountryOptions = payload.map(({ alpha_2_code, en_short_name, no_short_name }) => ({
        value: alpha_2_code,
        label: no_short_name || en_short_name
      }));
      state.nationalityOptions = payload.map(({ alpha_2_code, nationality, no_nationality }) => ({
        value: alpha_2_code,
        label: nationality
      }));
      state.noNationalityOptions = payload.map(({ alpha_2_code, nationality, no_nationality }) => ({
        value: alpha_2_code,
        label: no_nationality || nationality
      }));
    },
    getCountryListError(state: ContractState, { payload }: PayloadAction<string>) {
      state.isLoadingOptions = false;
      state.errorLoadingList = payload;
    },
    createCheckList(state: ContractState, _: PayloadAction<{ formData: CheckContract; contractId: number }>) {
      state.isLoadingCheckList = true;
    },
    createCheckListSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.contractList = state.contractList?.map((contract) => {
        if (contract.id === payload.id) {
          return payload;
        }
        return contract;
      });
      state.currentContract = payload;
      state.isLoadingCheckList = false;
    },
    createCheckListError(state: ContractState, { payload }: PayloadAction<string>) {
      state.isLoadingCheckList = false;
      state.error = payload;
    },
    fetchContract(state: ContractState, _: PayloadAction<number>) {
      state.pending = true;
      state.error = '';
    },
    fetchContractSuccess(state: ContractState, { payload }: PayloadAction<Contract>) {
      state.currentContract = payload;
      state.pending = false;
      state.error = '';
    },
    fetchContractError(state: ContractState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    }
  }
});

const selectContract = (state: RootState): ContractState => state.contract;

export const ContractSelector = createSelector([selectContract], (contract) => contract);

export const {
  setCurrentContract,
  fetchFinishContract,
  fetchFinishContractSuccess,
  createContractError,
  createContractSuccess,
  createContract,
  fetchContracts,
  fetchContractsSuccess,
  updateContracts,
  deleteAllIncomeBilags,
  deleteAllIncomeBilagsError,
  deleteAllIncomeBilagsSuccess,
  createContractSignBankId,
  createContractSignBankIdSuccess,
  createContractSignBankIdError,
  signContractByBankID,
  signContractByBankIDSuccess,
  signContractByBankIDError,
  signContractDepositAccount,
  closeDeposit,
  closeDepositError,
  closeDepositSuccess,
  releaseDeposit,
  releaseDepositError,
  releaseDepositSuccess,
  getCountryList,
  getCountryListSuccess,
  getCountryListError,
  createCheckList,
  createCheckListSuccess,
  createCheckListError,
  regenerateSignContractByBankID,
  updateContract,
  updateContractSuccess,
  updateContractError,
  fetchContract,
  fetchContractSuccess,
  fetchContractError
} = contractSlice.actions;

export default contractSlice.reducer;

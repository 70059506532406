import styled from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const ActionsButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-template-columns: repeat(1, 278px);
  margin-top: 48px;

  ${mq.lowerSm} {
    margin: 20px 0;
  }
`;

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
  justify-content: center;
  margin: 0 15px;

  ${mq.lowerSm} {
    grid-template-columns: 1fr;
  }
`;

export const InputContainer = styled(StyledContent)`
  gap: 0 24px;
`;

export const Title = styled.p`
  text-align: start;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black01};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.xlg};
  margin: 0 15px 15px;

  ${mq.lowerSm} {
    margin: 15px;
  }
`;

export const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
`;

export const InputContent = styled.div`
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 24px;
  justify-content: center;
`;

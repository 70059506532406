import styled from 'styled-components';
import { mq } from 'constant/mediaqueries';

export const StyledContainer = styled.div`
  margin: 1.25rem 0;
  display: grid;
  column-gap: 2rem;
  max-width: 1092px;
  grid-template-columns: 1fr;
  ${mq.greaterLg} {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
`;

import format from 'date-fns/format';
import { Bilag, IncomeBilag } from '../../../../../domain/entity/documnets/CommonBilag';
import { IncomeBilagFormData } from '../../../../../domain/entity/IncomeBilag/IncomeBilagFormData';

export const getIncomeBilagToIncomeBilagData = (bilag: IncomeBilag): IncomeBilagFormData => {
  return {
    id: bilag.id,
    sum: bilag.sum,
    documents: bilag.documents,
    end_date: format(new Date(bilag.end_date), 'dd.MM.yyyy'),
    start_date: format(new Date(bilag.start_date), 'dd.MM.yyyy'),
    houseId: bilag.houseId,
    other: bilag.other,
    deposit_sum: bilag.deposit_sum,
    separatedTaxable: bilag.separatedTaxable,
    tenant_email: bilag.tenant_email,
    tenant_first_name: bilag.tenant_first_name,
    tenant_birthday: format(new Date(bilag.tenant_birthday || ''), 'dd.MM.yyyy'),
    tenant_phone: bilag.tenant_phone,
    tenant_last_name: bilag.tenant_last_name
  };
};

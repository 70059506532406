import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import CredentialT from 'domain/entity/auth/CredentialT';
import CredentialsResponseT from 'data/responseType/CredentialsResponseType';
import { RootState } from './store';

export interface AuthState {
  credentials: CredentialT;
  token?: string;
  code?: string;
  codeConfirmed?: boolean | string;
  pending?: boolean;
  pendingReset?: boolean;
  error?: string;
  authProviders?: any[];
  idDifiToken?: string;
  difiToken?: {
    access_token: string;
    expires_in: number;
    id_token: string;
    scope: string;
    token_type: string;
  };
}

const initialState: AuthState = {
  credentials: {
    email: null,
    password: null
  },
  token: undefined,
  code: undefined,
  codeConfirmed: undefined,
  pending: undefined,
  pendingReset: undefined,
  error: undefined,
  difiToken: undefined
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    tokenFetching(state: AuthState, { payload }: PayloadAction<CredentialT>) {
      state.pending = true;
    },
    tokenFetchingSuccess(state: AuthState, { payload }: PayloadAction<CredentialsResponseT>) {
      state.pending = false;
      state.token = payload.accessToken;
      state.error = undefined;
    },
    tokenFetchingError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    resetPasswordFetching(state: AuthState, { payload }: PayloadAction<{ email: string }>) {
      state.pendingReset = true;
      state.credentials = { ...state.credentials, email: payload.email };
      state.error = undefined;
    },
    resetPasswordFetchingSuccess(state: AuthState) {
      state.pendingReset = false;
      state.error = undefined;
    },
    resetPasswordFetchingError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pendingReset = false;
      state.error = payload;
    },
    resetPasswordCheckFetching(state: AuthState, { payload }: PayloadAction<{ code: string }>) {
      state.pendingReset = true;
      state.codeConfirmed = undefined;
      state.code = payload.code;
      state.error = undefined;
    },
    resetPasswordCheckFetchingSuccess(state: AuthState, { payload }: PayloadAction<{ success: boolean }>) {
      state.pendingReset = false;
      state.codeConfirmed = payload.success;
      state.error = undefined;
    },
    resetPasswordCheckFetchingError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pendingReset = false;
      state.codeConfirmed = undefined;
      state.code = undefined;
      state.error = payload;
    },
    sendNewPasswordFetching(
      state: AuthState,
      _: PayloadAction<{
        password: string;
        repeat: string;
        code?: string;
      }>
    ) {
      state.pending = true;
      state.error = undefined;
    },
    sendNewPasswordFetchingSuccess(state: AuthState) {
      state.pending = false;
      state.error = undefined;
    },
    sendNewPasswordFetchingError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    changeEmailFetching(state: AuthState, { payload }: PayloadAction<{ code: string }>) {
      state.pending = true;
      state.code = payload.code;
      state.codeConfirmed = undefined;
      state.error = undefined;
    },
    changeEmailFetchingSuccess(state: AuthState) {
      state.pending = false;
      state.codeConfirmed = true;
      state.code = undefined;
      state.error = undefined;
    },
    changeEmailFetchingError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.codeConfirmed = false;
      state.code = undefined;
      state.error = payload;
    },
    checkEmail(state: AuthState) {
      state.pending = true;
    },
    checkEmailSuccess(state: AuthState) {
      state.pending = false;
    },
    checkEmailError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.codeConfirmed = false;
      state.code = undefined;
      state.error = payload;
    },
    logout(state: AuthState) {
      state.token = undefined;
      state.code = undefined;
    },
    fetchAuthProviders(state: AuthState) {
      state.pending = true;
    },
    fetchAuthProvidersSuccess(state: AuthState, { payload }: PayloadAction<any[]>) {
      state.authProviders = payload;
      state.pending = false;
    },
    fetchAuthProvidersError(state: AuthState, { payload }: PayloadAction<string>) {
      state.authProviders = [];
      state.pending = false;
      state.error = payload;
    },
    fetchDifiToken(state: AuthState, _: PayloadAction<{ code: string; state: string }>) {
      state.pending = true;
    },
    fetchDifiTokenSuccess(state: AuthState, { payload }: PayloadAction<AuthState['difiToken']>) {
      state.difiToken = payload;
      state.pending = false;
    },
    fetchDifiTokenError(state: AuthState, { payload }: PayloadAction<string>) {
      state.difiToken = undefined;
      state.pending = false;
      state.error = payload;
    },
    logoutDifi(state: AuthState) {
      state.pending = true;
    },
    logoutDifiSuccess(state: AuthState, { payload }: PayloadAction<string>) {
      state.difiToken = undefined;
      state.idDifiToken = payload;
      state.pending = false;
    },
    logoutDifiError(state: AuthState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    },
    resetIdDifiToken(state: AuthState) {
      state.idDifiToken = undefined;
    }
  }
});

const selectAuth = (state: RootState): AuthState => state.auth;

export const authSelector = createSelector([selectAuth], (auth) => auth);

export const {
  tokenFetching,
  tokenFetchingSuccess,
  tokenFetchingError,
  resetPasswordFetching,
  resetPasswordFetchingSuccess,
  resetPasswordFetchingError,
  resetPasswordCheckFetching,
  resetPasswordCheckFetchingSuccess,
  resetPasswordCheckFetchingError,
  sendNewPasswordFetching,
  sendNewPasswordFetchingSuccess,
  sendNewPasswordFetchingError,
  changeEmailFetching,
  changeEmailFetchingSuccess,
  changeEmailFetchingError,
  checkEmail,
  checkEmailSuccess,
  checkEmailError,
  logout,
  fetchAuthProviders,
  fetchAuthProvidersError,
  fetchAuthProvidersSuccess,
  fetchDifiTokenSuccess,
  fetchDifiTokenError,
  fetchDifiToken,
  logoutDifiError,
  logoutDifiSuccess,
  logoutDifi
} = authSlice.actions;

export default authSlice.reducer;

import React, { FC } from 'react';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import ConfirmationModal from 'components/common/ConfirmationModal';

const MoveToTrash: FC = () => {
  const { moveToTrash } = useAppSelector((state) => state.modals);
  const { closeMoveToTrash } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const { t } = useGlobalTranslation('moveToTrash');

  return (
    <ConfirmationModal
      isConfirm
      isOpen={!!moveToTrash?.show}
      title={t('title')}
      text={t('text')}
      cancelButtonProps={{
        onClick: () => dispatch(closeMoveToTrash()),
        children: t('cancel')
      }}
      confirmButtonProps={{
        onClick: (onClose) => {
          if (moveToTrash?.functionConfirm) {
            moveToTrash.functionConfirm();
          }
          onClose();
        },
        children: t('submit')
      }}
    />
  );
};

export default MoveToTrash;

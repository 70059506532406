import styled from 'styled-components';
import { InfoFields } from 'components/common';

export const StyledHeading = styled.h4`
  margin: 1.75rem 0 0 0;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray03};
  letter-spacing: 0.15px;
  text-transform: uppercase;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoFields = styled(InfoFields)`
  margin: 0 0 1.75rem 0;
`;

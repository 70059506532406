import React, { FC } from 'react';
import { ThemeT } from 'context/Theme';
import { CSSProp } from 'styled-components';
import { Wrapper, Inner, Svg } from './styled';

export type IconT = {
  viewBox: string;
  id: string;
};

export type Props = {
  icon: IconT;
  css?: CSSProp;
  title?: string;
  color?: keyof ThemeT['colors'];
};

const Icon: FC<Props> = (props) => {
  const { icon, css, title, color } = props;

  if (!icon || !icon.viewBox || !icon.id) {
    return null;
  }

  try {
    const [p1, p2, p3, p4] = icon.viewBox.split(' ');
    const ratio = (Number(p4) - Number(p2)) / (Number(p3) - Number(p1));

    return (
      <Wrapper $color={color} $css={css} title={title} aria-hidden="true">
        <Inner style={{ paddingBottom: `${100 * ratio}%` }}>
          <Svg viewBox={icon.viewBox}>
            <use xlinkHref={`#${icon.id}`} />
          </Svg>
        </Inner>
      </Wrapper>
    );
  } catch {
    return null;
  }
};

export default Icon;

import * as Yup from 'yup';

export const firstStepSchema = Yup.object().shape({
  userSSN: Yup.string().required(''),
  systemPassword: Yup.string().required(''),
  systemUserName: Yup.string().required(''),
  authMethod: Yup.string().required('')
});

export const confirmationStepSchema = Yup.object().shape({
  pin: Yup.string().required('')
});

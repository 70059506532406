// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { useHistory } from 'react-router';
import React, { FC, useEffect } from 'react';
import { ROUTE_HOME_INDEX, ROUTE_LOGOUT_DIFI } from 'constant/routes';
import { setCredentials } from 'data/driver/Api';
import { authSlice } from 'data/store/authReducer';
import { profileSlice } from 'data/store/profileReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
const env = process.env;
//const env = runtimeEnv();

const LogoutDifi: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { user } = useAppSelector(({ profile }) => profile);
  const { idDifiToken } = useAppSelector(({ auth }) => auth);
  const { logoutDifi, resetIdDifiToken, tokenFetchingSuccess } = authSlice.actions;
  const { profileFetching } = profileSlice.actions;
  const URL_LOGOUT = env.REACT_APP_LOGOUT_DIFI_URL;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    if (user?.difiData && !urlToken) {
      dispatch(logoutDifi());
    }
    if (!user?.difiData && urlToken) {
      setCredentials(urlToken || '');
      dispatch(tokenFetchingSuccess({ accessToken: urlToken || '' }));
      dispatch(profileFetching());
      history.push(ROUTE_LOGOUT_DIFI);
    }
    if (!user?.difiData && !urlToken) {
      history.push(ROUTE_HOME_INDEX);
    }
  }, [user]);

  useEffect(() => {
    if (idDifiToken) {
      dispatch(resetIdDifiToken());
      window.location.replace(
        `${URL_LOGOUT}?id_token_hint=${idDifiToken}&post_logout_redirect_uri=${window.location.origin}/logout-difi`
      );
    }
  }, [idDifiToken]);

  return null;
};

export default LogoutDifi;

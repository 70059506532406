import styled, { css } from 'styled-components';
import { hidden, mq } from 'constant/mediaqueries';

type OpenPropsT = {
  isOpen: boolean;
};

export const StyledContainer = styled.div`
  top: 0;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white01};
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 100;
`;

export const StyledChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  width: 100%;
`;

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: max-content;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;

  ${mq.greaterXs} {
    justify-content: flex-start;
    z-index: 9;
    height: 3.75rem;
  }
`;

export const StyledTabsDesktop = styled.div`
  margin: 0;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${mq.greaterXs} {
    margin: 0 0 0 1rem;
  }
  ${hidden.lowerXs}
`;

export const StyledTabsMobile = styled.div`
  margin: 10px;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${hidden.greaterXs}
`;

const styledLogoWrapper = css`
  ${mq.lowerXs} {
    display: none;
  }
`;

export const StyledLogoWrapper = styled.div<{ isHideLogo?: boolean }>`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.75rem 0;
  flex-shrink: 0;

  ${mq.greaterSm} {
    width: 17rem;
    background-color: ${({ theme }) => theme.colors.white01};
  }

  ${({ isHideLogo }) => isHideLogo && styledLogoWrapper}
`;

export const StyledBurgerWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  ${hidden.greaterSm}
`;

export const StyledLangWrapper = styled.div`
  margin-right: 3rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  ${mq.lowerSm} {
    margin-right: 1rem;
  }
`;

const closeSidebarCss = css`
  left: 10px;
  transform: rotate(180deg);
`;

export const StyledButtonClose = styled.span<OpenPropsT>`
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 245px;
  top: 49px;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.white01};
  color: ${({ theme }) => theme.colors.gray01};
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));
  z-index: 150;
  transition: background-color 0.4s, all 0.5s;

  ${({ isOpen }) => isOpen && closeSidebarCss}

  ${mq.lowerSm} {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue01};
    color: ${({ theme }) => theme.colors.white01};
  }
`;

export const arrowIconCss = css`
  position: relative;
  left: -1px;
  width: 8px;
  fill: currentColor;
`;

export const iconCss = css`
  width: 24px;
  position: relative;
  fill: currentColor;
  padding: 0;
`;

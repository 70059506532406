import { call, put } from 'redux-saga/effects';
import {
  createSupplierApi,
  deleteSupplierApi,
  fetchSupplierApi,
  updateSupplierApi
} from 'data/driver/supplier/Supplier';
import {
  fetchSupplierError,
  fetchSupplierSuccess,
  SupplierUpdateSuccess,
  createSupplierSuccess,
  createSupplierError,
  setCurrentSupplier,
  deleteSupplierSuccess
} from 'data/store/supplierReducer';
import { Supplier } from '../../domain/entity/supplier/SuplierData';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { SupplierCreateFormT } from '../../domain/entity/supplier/SupplierCreateFormData';

export function* createSupplierSaga({ payload }: PayloadAction<SupplierCreateFormT>) {
  try {
    const response = yield call(createSupplierApi, payload);
    yield put(setCurrentSupplier(response));
    yield put(createSupplierSuccess());
  } catch (e) {
    yield put(createSupplierError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}
export function* deleteSupplierSaga({ payload }: PayloadAction<number>) {
  try {
    const response = yield call(deleteSupplierApi, payload);
    yield put(deleteSupplierSuccess(payload));
  } catch (e) {
    yield put(createSupplierError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* fetchSupplierSaga() {
  try {
    const response = yield call(fetchSupplierApi);
    yield put(fetchSupplierSuccess(response));
  } catch (e) {
    yield put(fetchSupplierError(e?.response?.data?.message || e?.message || 'unknownError'));
  }
}

export function* SupplierUpdateSaga({ payload }: PayloadAction<Supplier>) {
  try {
    yield call(updateSupplierApi, payload);
    yield put(SupplierUpdateSuccess(payload));
  } catch (e) {
    yield put(fetchSupplierError(e?.response?.data?.message || e?.message));
  }
}

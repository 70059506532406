import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ROUTE_DASHBOARD, ROUTE_HOME_INDEX, ROUTE_HOUSE_INFO } from 'constant/routes';
import { houseSlice } from 'data/store/houseReducer';
import { modalsSlice } from 'data/store/modalReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import Popup from 'components/common/Popup';
import arrowIcon from 'components/svg/arrow_down.svg?sprite';
import homeIcon from 'components/svg/home.svg?sprite';
import { StyledHomeText, StyledSelectHome, StyledHomeItem, homeIconCss, arrowIconCss, HouseName } from './styled';

const SelectHome = () => {
  const { houseList, selectedHouse } = useAppSelector((state) => state.house);
  const { setSelectedHouse } = houseSlice.actions;
  const { openRentContract } = modalsSlice.actions;
  const { search } = useLocation<{ createRentContract?: string }>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (search.includes('createRentContract')) {
      dispatch(openRentContract());
    }
  }, [selectedHouse]);

  return (
    <Popup
      isWithoutFullWidth
      button={(onClick, isOpen) => (
        <StyledSelectHome variant={'text'} onClick={onClick}>
          <StyledHomeText>
            <Icon icon={homeIcon} css={homeIconCss} />
            <HouseName>{selectedHouse?.name}</HouseName>
          </StyledHomeText>
          <Icon icon={arrowIcon} css={arrowIconCss(isOpen)} />
        </StyledSelectHome>
      )}
      content={(onClose) =>
        houseList
          ?.filter(({ id }) => id !== selectedHouse?.id)
          .map((house) => (
            <StyledHomeItem
              key={house.id}
              type={'button'}
              variant={'text'}
              color={'black01'}
              onClick={() => {
                dispatch(setSelectedHouse(house));
                onClose();
              }}
            >
              <HouseName>{house.name}</HouseName>
            </StyledHomeItem>
          ))
      }
    />
  );
};

export default SelectHome;

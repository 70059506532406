import { httpWithAuth } from 'data/driver/Api';
import { serialize } from 'object-to-formdata';
import { UPLOAD_DOCUMENTS } from 'constant/apiRoutes';

export const uploadFileApi = async (documents: (File | string)[]) => {
  const documentUrl: string[] = [];

  for (const file of documents) {
    if (typeof file !== 'string') {
      const { data } = await httpWithAuth.post(UPLOAD_DOCUMENTS, serialize({ file }));

      documentUrl.push(data.url);
    }
    if (typeof file === 'string') {
      documentUrl.push(file);
    }
  }

  return documentUrl;
};

export type BilagFormDataT = {
  id?: number;
  houseId: number;
  cost_type: string;
  description: string;
  repeat: string;
  date: string;
  sum: string;
  franchise: number;
  documents: (File | string)[];
  supplierId: number;
  supplier_name: string | null;
  supplier_address: string | null;
  supplier_city: string | null;
  supplier_postcode: string | null;
  archived?: boolean;
};

export const InitialBilagValues: BilagFormDataT = {
  houseId: NaN,
  cost_type: '',
  description: '',
  repeat: '',
  date: '',
  sum: '',
  franchise: 100,
  documents: [],
  supplierId: 0,
  supplier_name: null,
  supplier_address: null,
  supplier_city: null,
  supplier_postcode: null
};

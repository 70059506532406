import { CREATE_SUPPLIER, DELETE_SUPPLIER, FETCH_SUPPLIER } from 'constant/apiRoutes';
import { httpWithAuth } from 'data/driver/Api';
import { Supplier } from '../../../domain/entity/supplier/SuplierData';
import { SupplierCreateFormT } from '../../../domain/entity/supplier/SupplierCreateFormData';

export const createSupplierApi = async (formData: SupplierCreateFormT) => {
  const { data } = await httpWithAuth.post(CREATE_SUPPLIER, formData);

  return data;
};
export const deleteSupplierApi = async (id: number) => {
  const { data } = await httpWithAuth.delete(DELETE_SUPPLIER(id));

  return data;
};

export const fetchSupplierApi = async () => {
  const { data } = await httpWithAuth.get(FETCH_SUPPLIER);

  return data;
};
export const updateSupplierApi = async (formData: Supplier) => {
  const { name, address, city, postcode, archived } = formData;
  const { data } = await httpWithAuth.put(`${FETCH_SUPPLIER}/${formData.id}`, {
    name,
    address,
    city,
    postcode,
    archived
  });

  return data;
};

import { DELETE_HOUSE, FETCH_HOUSE } from 'constant/apiRoutes';
import { HouseCreateFormT } from 'domain/entity/house/HouseCreateFormData';
import { httpWithAuth } from 'data/driver/Api';
import HouseData from '../../../domain/entity/house/HouseData';

export const createHouseApi = async (formData: HouseCreateFormT) => {
  const { data } = await httpWithAuth.post(FETCH_HOUSE, formData);

  return data;
};
export const updateHouseApi = async (formData: HouseData) => {
  const { name, address, city, holding_number, share_number, property_number, lease_number, section_number, postcode } =
    formData;
  const { data } = await httpWithAuth.put(`${FETCH_HOUSE}/${formData.id}`, {
    name,
    address,
    city,
    holding_number,
    share_number,
    property_number,
    lease_number,
    section_number,
    postcode
  });

  return data;
};

export const fetchHouseListApi = async (): Promise<HouseData[]> => {
  const { data } = await httpWithAuth.get(FETCH_HOUSE);

  return data;
};

export const deleteHouseApi = async (id: number): Promise<{ id: number }> => {
  const { data } = await httpWithAuth.delete(DELETE_HOUSE(id));
  return data;
};

//TODO: fix
import React, { Dispatch, FC, forwardRef, SetStateAction, useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { Props as InputPropsT } from 'components/controls/Input/Input';
import {
  errorIconCss,
  StyledField,
  StyledHead,
  StyledInput,
  StyledInputContainer,
  StyledLabel,
  StyledPrompt,
  StyledServerError
} from '../Input/styled';
import { useFormContext } from 'react-hook-form';
import { Tagged } from 'libphonenumber-js/types';
import Icon from '../../common/Icon';
import errorIcon from 'components/svg/error.svg?sprite';

type PropsT = Omit<InputPropsT, 'value'> & {
  setCountry: Dispatch<SetStateAction<string>>;
  value: Tagged<string, 'E164Number'>;
};

const InputField = forwardRef<HTMLInputElement, PropsT>((props, ref) => {
  const {
    label,
    className,
    error = false,
    onBlur,
    errorMessage,
    serverError,
    required,
    withoutPrompt,
    inputStyleCss,
    fieldStyleCss,
    wrapperStyle,
    value,
    variant = 'default',
    prompt,
    additionalInformation,
    isSuccess = false,
    onChange,
    setCountry,
    ...restProps
  } = props;
  const { g } = useGlobalTranslation();

  useEffect(() => {
    const inputValue = document.getElementById('phone')?.getAttribute('value');
    const splitPhone = inputValue?.split(' ') || [];
    const [code] = splitPhone;
    if (code) {
      setCountry(code);
    }
  }, [value]);

  return (
    <div className={className} style={wrapperStyle}>
      <StyledField>
        <StyledHead htmlFor={props.type}>
          <StyledLabel isError={error}>
            {label} {required && '*'}
          </StyledLabel>
        </StyledHead>
        <StyledInputContainer {...{ error, isSuccess, fieldStyleCss, variant }}>
          <StyledInput
            id={'phone'}
            onChange={(event) => {
              const splitPhone = event.target.value.split(' ');
              const [code] = splitPhone;
              if (code) {
                setCountry(code);
              }
              if (onChange) {
                onChange(event);
              }
            }}
            value={value}
            {...restProps}
            ref={ref}
            onBlur={(event) => {
              if (onBlur) {
                onBlur(event);
              }
              const [code] = event.target.value.split(' ');
              setCountry(code);
            }}
          />
        </StyledInputContainer>
      </StyledField>
      {!withoutPrompt && (
        <StyledPrompt error={error && !!(errorMessage || serverError)}>
          <Icon icon={errorIcon} css={errorIconCss} />
          {(errorMessage && g(`validation.${errorMessage}`)) ||
            (serverError && <StyledServerError>{serverError}</StyledServerError>)}
        </StyledPrompt>
      )}
      {prompt && prompt}
    </div>
  );
});

type PhoneField = Omit<InputPropsT, 'onChange' | 'onBlur'> & {
  namePhone?: string;
  namePhoneCode?: string;
  initialValue?: string;
};

const PhoneField: FC<PhoneField> = (props) => {
  const { namePhone = 'phone', namePhoneCode = 'phone_code', initialValue = '+47' } = props;
  const { setValue, clearErrors } = useFormContext<{ countryCode: string; phone: string }>();
  const [phone, setPhone] = useState<Tagged<string, 'E164Number'> | undefined>(
    (!initialValue && '+47') || (initialValue.includes('+') ? initialValue : `+${initialValue}`)
  );
  const [country, setCountry] = useState('');

  useEffect(() => {
    if (country) {
      setValue(namePhone, phone?.replace(country, ''));
      setValue(namePhoneCode, country);
    } else {
      setValue(namePhone, phone);
    }
  }, [phone, country]);

  return (
    <PhoneInput
      // @ts-ignore
      value={phone}
      onChange={(value) => {
        if (phone && isValidPhoneNumber(phone)) {
          clearErrors(namePhone);
        }
        setPhone(value);
      }}
      onBlur={() => {
        if (phone && isValidPhoneNumber(phone)) {
          clearErrors(namePhone);
        }
      }}
      {...props}
      setCountry={setCountry}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputComponent={InputField}
    />
  );
};

export default PhoneField;

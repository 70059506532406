import styled, { css } from 'styled-components';
import { mq } from 'constant/mediaqueries';

type TextProps = {
  active: boolean;
};

export const StyledContainer = styled.div`
  display: flex;
  width: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.gray02};
  img {
    display: none;
  }

  ${mq.greaterXs} {
    img {
      display: block;
    }
  }
`;
export const StyledText = styled.div<TextProps>`
  padding: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 400;
  color: ${({ active, theme }) => theme.colors[(active && 'black01') || 'gray01']};
  letter-spacing: 0.15px;
  user-select: none;
  cursor: pointer;
`;

export const StyledBorder = styled.span`
  background-color: ${({ theme }) => theme.colors.gray01};
  width: 1px;
  height: 1.125rem;
`;

export const worldIconCss = css`
  width: 18px;
  fill: currentColor;
`;

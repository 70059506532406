import React from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { ROUTE_PROFILE_SUBSCRIPTION } from 'constant/routes';
import { ThemeT } from 'context/Theme';
import { useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import { Button, Spinner } from 'components/ui';
import checkIcon from 'components/svg/check_icon.svg?sprite';
import { StyledCheckRow, StyledContainer, StyledDescription, StyledTitle, checkIconCss } from './styled';

const AltinnSubscription = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t, g } = useGlobalTranslation('subscription');
  const { pending } = useAppSelector((state) => state.subscription);

  return (
    <StyledContainer>
      {(pending && <Spinner blue />) || (
        <>
          <StyledTitle>{t('title2')}</StyledTitle>
          {t('text')}
          <StyledDescription>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-1')}
            </StyledCheckRow>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-2')}
            </StyledCheckRow>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-3')}
            </StyledCheckRow>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-4')}
            </StyledCheckRow>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-5')}
            </StyledCheckRow>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-6')}
            </StyledCheckRow>
            <StyledCheckRow>
              <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
              {t('description.row-7')}
            </StyledCheckRow>
          </StyledDescription>
          <Button onClick={() => history.push(ROUTE_PROFILE_SUBSCRIPTION)}>{g('subscribe')}</Button>
        </>
      )}
    </StyledContainer>
  );
};

export default AltinnSubscription;

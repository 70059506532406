import styled, { css } from 'styled-components';
import { hexColorOpacity } from '../../../../../helpers/color';
import Button from '../../../../../components/controls/Button';
import { Link as RouteLink } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';

interface ColumnT {
  alignItems?: 'flex-start' | 'flex-end';
}

export const Column = styled.div<ColumnT>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems && alignItems) || 'initial'};
`;

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray03};
  letter-spacing: 0.15px;
  line-height: 1.5em;
`;

export const StyledTextButton = styled.button`
  padding: 0.5rem 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue01};
  border: none;
  background-color: transparent;

  &:disabled {
    color: ${({ theme }) => theme.colors.gray01};
  }
`;

export const StyledLinkButton = styled.a`
  padding: 1rem 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
`;

export const Block = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  margin-top: 10px;
  padding: 15px 10px 5px;
  border-radius: 15px;

  ${mq.lowerXs} {
    border: none;
  }
`;

export const Line = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: start;
  align-items: center;

  ${mq.lowerXs} {
    justify-content: center;
  }
`;

export const ErrorButton = styled(Button)`
  height: min-content;
  padding: 5px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.red01};
  font-size: 10px;

  &:hover {
    background-color: ${({ theme }) => hexColorOpacity(theme.colors.red01, 0.7)};
  }
`;

export const PopupErrorContent = styled.div`
  padding: 5px;
  border-radius: 3px;
  max-width: 300px;
`;

export const Text = styled.span`
  display: block;
  margin-bottom: 10px;
`;

export const StyledModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 15px;

  ${mq.lowerXs} {
    flex-direction: column;
    align-items: center;
  }
`;

export const MainText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray03};
  text-transform: uppercase;

  ${mq.lowerXs} {
    text-align: center;
    line-height: 133%;
    text-transform: initial;
  }
`;

export const StyledHr = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray07};
  width: 100%;
  margin: 0 0 0.25rem 0;
`;

type LinkPropsT = {
  disabled?: boolean;
};

export const Link = styled.a<LinkPropsT>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
  padding: 0.5rem 0;
`;

export const StyledRouteLink = styled(RouteLink)<LinkPropsT>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
  padding: 0.5rem 0;
`;

export const ContentPopup = styled.span`
  padding: 8px 12px;
  background-color: #ffffff;
`;

export const LinkContainer = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: start;

  ${mq.lowerXs} {
    margin-top: 10px;
    grid-auto-flow: row;
  }
`;

export const SignLinkContainer = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: start;
`;

export const copyIconCss = css`
  width: 24px;
  position: relative;
  top: 2px;
`;

export const CopyButton = styled.button`
  display: block;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  padding: 2px;
  margin-left: 5px;
`;

export const PopupContent = styled.span`
  font-size: 14px;
  max-width: 350px;
  color: ${({ theme }) => theme.colors.gray03};
  background-color: #fff;
  padding: 8px 12px;
`;

export const Status = styled.div`
  width: max-content;
  padding: 1px 12px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.gray07};
  color: ${({ theme }) => theme.colors.gray03};
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  text-transform: initial;
`;

export const StatusContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: start;
  align-items: center;
  color: inherit;
`;

import React, { FC, useState } from 'react';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { ApplicationModal } from '../../../../../components/common/ApplicationModal';

interface PropsI {
  tenantSignature?: string;
  landlordSignature?: string;
  isOpen: boolean;
  onClose: () => void;
}

const SignatureModal: FC<PropsI> = ({ tenantSignature, landlordSignature, isOpen, onClose }) => {
  const { g } = useGlobalTranslation();

  return (
    <ApplicationModal
      isOpen={isOpen}
      cancelButtonProps={{ children: g('signManualModal.button'), onClick: onClose }}
      title={g('signManualModal.title')}
      text={g('signManualModal.text')}
    />
  );
};

export default SignatureModal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledContainer, StyledTitle, StyledPrice, StyledSubtitle, StyledSale } from './styled';

type Props = {
  title: string;
  amount: string;
  subtitle?: string | null;
  checked: boolean;
  sale?: string | null;
  onClick?: () => void;
};

const PriceCard = ({ title, amount, subtitle, sale, onClick, checked = false }: Props) => {
  const { t } = useTranslation('common');
  return (
    <StyledContainer checked={checked} onClick={onClick}>
      {sale && <StyledSale>{t('sale', { sale })}</StyledSale>}
      <StyledTitle>{title}</StyledTitle>
      <StyledPrice>{amount}</StyledPrice>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
    </StyledContainer>
  );
};

export default PriceCard;

import React, { FC } from 'react';
import { StyledWrapper, StyledLink, StyledIcon, StyledText } from './styled';
import { useLocation } from 'react-router-dom';

type Props = {
  to: { pathname: string };
};

const Tab: FC<Props> = ({ children, to }) => {
  const { pathname } = useLocation();
  const active = pathname.includes(to.pathname);
  return (
    <StyledWrapper>
      <StyledLink $active={active} {...{ to }}>
        <StyledText $active={active}>{children}</StyledText>
      </StyledLink>
    </StyledWrapper>
  );
};

export default Tab;

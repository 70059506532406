import React, { FC } from 'react';
import ConfirmationModal from '../ConfirmationModal';
import { useAppSelector } from '../../../hooks/redux';

const Confirmation: FC = () => {
  const { show, cancelButtonProps, confirmButtonProps, title, text, onClose, isConfirm } = useAppSelector(
    (state) => state.confirmation
  );

  return (
    (text && (
      <ConfirmationModal
        isOpen={show}
        {...{ cancelButtonProps, confirmButtonProps, title, text, onClose, isConfirm }}
      />
    )) || <></>
  );
};

export default Confirmation;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTE_PROFILE_LANDLORD } from 'constant/routes';

const Profile = () => {
  const { replace } = useHistory();

  useEffect(() => {
    replace(ROUTE_PROFILE_LANDLORD);
  }, []);

  return <></>;
};

export default Profile;

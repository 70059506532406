import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { DepositAccountFormData } from 'domain/entity/rentContract/RentContract';
import { depositAccount } from 'data/validations/contractValidation';
import Input from 'components/controls/Input/Input';
import Icon from 'components/common/Icon';
import infoIcon from 'components/svg/warning.svg?sprite';
import DateField from 'components/controls/DateField';
import { StepEnum } from 'components/layouts/Modals/NewRentContract/View/data';
import { useData } from 'components/layouts/Modals/NewRentContract/View';
import { StyledRadioButton } from '../../../Depreciation/View/Form/styled';
import {
  Content,
  RadiosContent,
  TwoActionsButton,
  StyledButton,
  Span,
  SubTitle,
  TextButton,
  infoIconCss
} from 'components/layouts/Modals/NewRentContract/styled';
import { StyledLink, StyledTextContainer, StyledText } from './styled';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux';
import { contractSlice } from '../../../../../../data/store/rentContractReducer';
import { ROUTE_PROFILE_SUBSCRIPTION } from '../../../../../../constant/routes';
import { modalsSlice } from '../../../../../../data/store/modalReducer';

export const radios = [
  { id: 0, value: '0', label: 'withoutDepositAccount' },
  { id: 1, value: '1', label: 'openDepositAccount' },
  { id: 2, value: '2', label: 'addDepositAccount' }
];

type PropsT = {
  index?: number;
  setStep: Dispatch<SetStateAction<StepEnum>>;
};

const DepositAccount: FC<PropsT> = (props) => {
  const { data, setIsDirty, setValues } = useData();
  const dispatch = useAppDispatch();
  const { currentContract, pending } = useAppSelector((state) => state.contract);
  const { user } = useAppSelector(({ profile }) => profile);
  const { closeRentContract } = modalsSlice.actions;
  const { updateContract } = contractSlice.actions;
  const formData = useForm<DepositAccountFormData>({
    resolver: yupResolver(depositAccount),
    defaultValues: data
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors, isDirty }
  } = formData;
  const { t } = useGlobalTranslation();
  const { g } = useGlobalTranslation();
  const { t: f } = useGlobalTranslation('fields');
  const { setStep } = props;
  const isDepositAccount = watch('is_deposit_account_variant');
  const depositAmount = watch('deposit_sum');

  useEffect(() => {
    if (Number(data.month_sum) * 6 < depositAmount) {
      setError('deposit_sum', { message: 'Max' });
    } else {
      clearErrors('deposit_sum');
    }
  }, [depositAmount]);

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    setValue('deposit_account', isDepositAccount === '2' ? data.deposit_account : '');
    setValue('deposit_count_payment_day', isDepositAccount === '1' ? data.deposit_count_payment_day : undefined);
  }, [isDepositAccount]);

  return (
    <FormProvider {...formData}>
      <Span />
      <RadiosContent>
        {radios.map(({ value, label, id }) => (
          <StyledRadioButton
            key={id}
            control={control}
            label={f(label)}
            {...register('is_deposit_account_variant')}
            value={value}
          />
        ))}
      </RadiosContent>
      {isDepositAccount === '1' && user?.subscriptionActive && (
        <TextButton
          onClick={() => {
            setValues(getValues());
            setStep(StepEnum.HELP_DEPOSIT);
          }}
          variant={'text'}
        >
          {f('openDepAccountWebsite')}
          <Icon icon={infoIcon} color={'blue01'} css={infoIconCss} />
        </TextButton>
      )}
      {isDepositAccount === '1' && !user?.subscriptionActive && (
        <>
          <SubTitle>{f('buySubscribeOpenDeposit.title')}</SubTitle>
          <StyledTextContainer>
            <StyledText>{f('buySubscribeOpenDeposit.message1')}</StyledText>
            <StyledLink href={ROUTE_PROFILE_SUBSCRIPTION} target={'_blank'}>
              {g('sidebar.subscription')}
            </StyledLink>
            <StyledText>{f('buySubscribeOpenDeposit.message2')}</StyledText>
          </StyledTextContainer>
        </>
      )}
      {(isDepositAccount === '1' || isDepositAccount === '2') && (
        <Content>
          {isDepositAccount === '2' && (
            <Input
              required
              register={register('deposit_account')}
              withoutPrompt
              variant={'outline'}
              label={f('depositAccountIBAN')}
              error={errors.deposit_account}
            />
          )}
          <Input
            register={register('deposit_sum')}
            withoutPrompt
            variant={'outline'}
            label={f('deposit_amount')}
            error={errors.deposit_sum}
            required
          />
          {isDepositAccount === '2' && (
            <DateField
              register={register('deposit_payment_deadline')}
              value={getValues('deposit_payment_deadline')}
              variant={'outline'}
              label={f('payment_deadline')}
              withoutPrompt
              error={errors.deposit_payment_deadline}
              required
            />
          )}
          {isDepositAccount === '1' && (
            <Input
              type={'number'}
              register={register('deposit_count_payment_day')}
              withoutPrompt
              variant={'outline'}
              label={f('deposit_count_payment_day')}
              error={errors.deposit_count_payment_day}
              required
            />
          )}
        </Content>
      )}
      <TwoActionsButton>
        <StyledButton
          type={'button'}
          variant={'outline'}
          onClick={() => {
            setValues(getValues());
            setStep(StepEnum.INCLUDING);
          }}
        >
          {f('back')}
        </StyledButton>
        <StyledButton
          disabled={pending}
          isLoading={pending}
          type={'button'}
          variant={'fill'}
          onClick={handleSubmit((formData) => {
            if (Number(data.month_sum) * 6 < depositAmount) {
              setError('deposit_sum', { message: 'Max' });
            }

            if (currentContract) {
              dispatch(updateContract({ formData: { ...data, ...formData }, contractId: currentContract.id }));
            }

            if (!currentContract && Number(data.month_sum) * 6 > depositAmount) {
              setValues(formData);
              setStep(StepEnum.SAVE_RENT_CONTRACT);
            }
          })}
        >
          {currentContract ? t('save') : t('next')}
        </StyledButton>
      </TwoActionsButton>
    </FormProvider>
  );
};

export default DepositAccount;

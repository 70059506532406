import styled, { css } from 'styled-components';
import { mq } from '../../../constant/mediaqueries';

export const notOkIconCss = css`
  width: 12px;
`;

export const StyledContainer = styled.div`
  margin: 1.25rem 0;
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr;

  ${mq.greaterMd} {
    grid-template-columns: 1fr 1fr;
  }
`;

import styled, { css } from 'styled-components';
import { hexColorOpacity } from 'helpers/color';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const bigSearchIconCss = css`
  width: 40px;
`;

export const Title = styled.p`
  margin: 5px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => hexColorOpacity(theme.colors.black01, 0.6)};
`;

export const Text = styled(Title)`
  color: ${({ theme }) => theme.colors.black01};
`;

import React, { FC, ReactNode } from 'react';
import { InfoFieldT } from 'domain/entity/common';
import { StyledContainer, StyledHeading, StyledInfoFields } from './styled';

interface PropsI {
  heading?: ReactNode;
  fields: InfoFieldT[];
}

const InfoBlock: FC<PropsI> = ({ heading, fields }) => {
  return (
    <StyledContainer>
      {heading && <StyledHeading>{heading}</StyledHeading>}
      <StyledInfoFields {...{ fields }} />
    </StyledContainer>
  );
};

export default InfoBlock;

import styled, { css } from 'styled-components';
import BaseCheckbox from '../../../../components/controls/Checkbox/Checkbox';
import BaseButton from '../../../../components/controls/Button';
import RadioButton from '../../../../components/controls/RadioButton';

export const Wrapper = styled.div`
  display: grid;
  gap: 15px 24px;
  margin-top: 20px;
`;

export const WrapperNextStep = styled.div`
  display: grid;
  gap: 15px;
  min-width: 320px;
  margin-top: 10px;
`;

export const CheckBox = styled(BaseCheckbox)`
  justify-content: flex-start;
`;

export const Link = styled.a`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue01};
`;

export const TextInformation = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.18px;
  color: ${({ theme }) => theme.colors.gray01};
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const modalContent = css`
  max-width: 400px;
`;

export const ErrorMessage = styled.div`
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.red01};
`;

export const RadioContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  border-radius: 5px;
  transition: border 0.4s;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.blue01};
  }
`;

export const StyledRadioButton = styled(RadioButton)`
  padding: 17px 12px 17px 15px;
`;

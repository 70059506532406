import { http } from 'data/driver/Api';

export const makeDownloadFromUrl = (name: string, url: string, subTitle = 'contract') => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = `${name} ${subTitle}`;
  http
    .get(url.replace(/^http:\/\//, 'https://'), {
      responseType: 'blob'
    })
    .then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      link.click();
    });
};

import React, { FC } from 'react';
import { ROUTE_HOUSE_INFO_EDIT } from 'constant/routes';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import SimplePage from 'components/common/SimplePage';
import editIcon from 'components/svg/edit_icon.svg?sprite';
import { InfoFields } from './components';
import { StyledHeader, StyledLink, StyledLinkText } from './styled';

const HouseInfo: FC = () => {
  const { g } = useGlobalTranlation();

  return (
    <SimplePage
      header={
        <>
          <StyledHeader>{g('sidebar.houseInfo')}</StyledHeader>
          <StyledLink to={ROUTE_HOUSE_INFO_EDIT}>
            <Icon icon={editIcon} color={'blue01'} /> <StyledLinkText>{g('edit')}</StyledLinkText>
          </StyledLink>
        </>
      }
    >
      <InfoFields />
    </SimplePage>
  );
};

export default HouseInfo;

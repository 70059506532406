import styled from 'styled-components';
import { hidden, mq } from 'constant/mediaqueries';
import { ThemeT } from 'context/Theme';

export const StyledContainer = styled.div`
  box-sizing: content-box;
  width: 100%;
  padding: 5px 50px 20px;
  margin-bottom: 80px;
  display: grid;
  gap: 20px;

  ${mq.lowerXs} {
    position: absolute;
  }
  ${mq.greaterXxXl} {
    gap: 40px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 380px;
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 12px;

  ${mq.greaterXxXl} {
    grid-auto-flow: row;
    grid-template-columns: initial;
    align-content: start;
    height: max-content;
  }
`;

export const StyledBlock = styled.div`
  display: grid;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  padding-top: 16px;
  padding-bottom: 12px;
  border-radius: 3px;

  ${mq.greaterXxXl} {
    height: 145px;
    gap: 0;
  }
`;

export const StyledBlockBlue = styled(StyledBlock)`
  border: none;
  grid-auto-flow: column;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.colors.blue01};

  ${mq.greaterXxXl} {
    grid-auto-flow: row;
  }
`;

export const SimpleBlock = styled.div`
  display: grid;
  justify-items: center;
  gap: 15px;

  ${mq.greaterXxXl} {
    gap: 0;
  }
`;

export const StyledDivider = styled.div`
  height: 100%;
  width: 1px;
  background: rgba(26, 76, 135, 0.5);

  ${hidden.greaterXxXl}
`;

type TextPropsT = {
  color?: keyof ThemeT['colors'];
  fontWeight?: number;
};

export const StyledCost = styled.div<TextPropsT>`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
  color: ${({ theme, color = 'black01' }) => theme.colors[color]};

  ${mq.greaterXxXl} {
    font-size: 28px;
  }
`;

export const StyledText = styled.div<TextPropsT>`
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: ${({ theme, color = 'gray03' }) => theme.colors[color]};
  text-align: center;

  ${mq.greaterXxXl} {
    font-size: 16px;
  }
`;

export const StyledTextHidden = styled(StyledText)`
  display: none;

  ${mq.greaterXxXl} {
    display: block;
  }
`;

export const StyledSmallText = styled.span`
  font-size: 18px;
`;

export const StyledContent = styled.div`
  display: grid;
  gap: 10px;

  ${mq.greaterXxXl} {
    gap: 47px;
    align-content: start;
    grid-auto-flow: row;
    grid-column: 2;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SimpleBlockHidden = styled(SimpleBlock)`
  ${hidden.greaterXxXl}
`;

export const HiddenModal = styled.div`
  ${hidden.greaterSm}
`;

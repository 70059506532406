import React, { Dispatch, FC, SetStateAction } from 'react';
import format from 'date-fns/format';
import { AltinnDataT } from 'domain/entity/altinn/AltinnBilag';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { EmptyList } from 'components/ui';
import Icon from 'components/common/Icon';
import backIcon from 'components/svg/arrow-left-light.svg?sprite';
import { Wrapper, Button, backIconCss, Content, Item, LeftBlock, Title, Sum, DateText } from './styled';

export type ListTypeT = 'bilags' | 'incomeBilags' | 'formsAltinn' | undefined;

type PropsT = {
  type: ListTypeT;
  altinnData?: AltinnDataT;
  setType: Dispatch<SetStateAction<ListTypeT>>;
};

const List: FC<PropsT> = (props) => {
  const { setType, altinnData, type } = props;
  const { g } = useGlobalTranslation();
  const bilagsList = type === 'bilags' ? altinnData?.bilagsList : altinnData?.incomebilagsList;
  const sortedBilagList = bilagsList
    ?.slice()
    .sort(({ date: a }, { date: b }) => new Date(b).valueOf() - new Date(a).valueOf());

  return (
    <Wrapper>
      <Button variant={'text'} color={'black01'} onClick={() => setType(undefined)}>
        <Icon icon={backIcon} css={backIconCss} />
        {type === 'bilags' ? g('expensesBilag') : g('incomeBilag')}
      </Button>
      <Content>
        {(sortedBilagList &&
          sortedBilagList?.length > 0 &&
          sortedBilagList?.map(({ id, sum, name, date }) => (
            <Item key={id}>
              <LeftBlock>
                <Title>{name}</Title>
                <Sum color={type === 'bilags' ? 'red01' : 'green01'}>{sum} NOK</Sum>
              </LeftBlock>
              <DateText>{format(new Date(date), 'dd.MM.yyyy')}</DateText>
            </Item>
          ))) || <EmptyList variant={'universal'} />}
      </Content>
    </Wrapper>
  );
};

export default List;

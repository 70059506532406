import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 100% !important;
`;

export const StyledLinkText = styled.div`
  color: ${({ theme }) => theme.colors.blue01};
  font-weight: 500;
  letter-spacing: 1.2px;
  margin: 0 0 0 0.5rem;
`;

export const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  font-size: 0.875rem;
  justify-content: center;
  align-items: center;
  margin: 0 0.75rem;
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 1092px;
  width: 100%;
  ${mq.greaterXxXl} {
    width: auto;
    max-width: 100%;
  }
`;

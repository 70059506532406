import styled from 'styled-components';
import { mq } from '../../constant/mediaqueries';
import { StyledCell } from '../Dashboard/Table/styled';
import { ThemeT } from '../../context/Theme';
import SimpleRow from '../../components/controls/SimpleRow';
import { containerMixin, pageHeaderMixin } from '../../mixins';

export const StyledContainer = styled.div`
  ${containerMixin}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  ${pageHeaderMixin};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type StyledSumPropsT = {
  color?: keyof ThemeT['colors'];
};

export const StyledSum = styled.div<StyledSumPropsT>`
  margin: 0.5rem 0 0 0;
  letter-spacing: 0.15px;
  color: ${({ theme, color }) => (color ? theme.colors[color] : 'inherit')};
  font-weight: 400;
  white-space: nowrap;
`;

export const StyledSimpleRow = styled(SimpleRow)`
  padding: 0.75rem 1.5rem;
`;

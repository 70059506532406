import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { mq } from 'constant/mediaqueries';
import Button from 'components/controls/Button';
import { Form } from 'components/ui';
import Checkbox from 'components/controls/Checkbox/Checkbox';

type ContainerPropsT = {
  step: number;
};

export const StyledContainer = styled.div<ContainerPropsT>`
  flex: 1;
  width: 100%;
  max-width: ${({ step }) => (step === 2 ? '36rem' : '27rem')};
  display: grid;
  grid-template-columns: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;

export const StyledTitle = styled.div`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.black01};
  text-align: center;
  font-weight: 500;
`;

export const Step = styled.div`
  margin-top: 15px;
  display: grid;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black01};
  font-size: 1.3rem;
  line-height: 1.4;
`;

type FormContentPropsT = {
  column?: number;
};

const formContentMedia = css`
  ${mq.lowerXs} {
    grid-template-columns: 1fr;

    & > div {
      grid-column: 1/2 !important;
    }
  }
`;

export const StyledFormContent = styled.div<FormContentPropsT>`
  display: grid;
  grid-template-columns: ${({ column = 1 }) => `repeat(${column}, 1fr)`};
  gap: ${({ column = 1 }) => (column !== 1 ? '15px' : 0)};

  ${({ column = 1 }) => column !== 1 && formContentMedia}
`;

export const StyledSignUpContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray03};
  margin: 2rem 0;
  line-height: 1.5rem;
`;

export const StyledSignUpLink = styled(Link)`
  margin: 0 0.25rem;
  color: ${({ theme }) => theme.colors.blue01};
  text-decoration: underline;
`;

export const StyledButton = styled(Button)`
  justify-self: center;
  margin-top: 48px;
`;

export const StyledForm = styled(Form)`
  display: grid;
`;

export const StyledSuccessImg = styled.span`
  color: ${({ theme }) => theme.colors.green01};
  padding: 0 5px;
`;

export const StyledButtonUnderline = styled(Button)`
  font-weight: 400;
  text-decoration: underline;
  text-transform: initial;
`;

export const checkIconCss = css`
  width: 20px;
  fill: currentColor;
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  letter-spacing: 0.15px;
`;

export const LinkCheckBox = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue01};
  cursor: pointer;
`;

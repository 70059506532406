import styled from 'styled-components';
import { containerMixin, pageHeaderMixin } from '../../../mixins';
import { mq } from '../../../constant/mediaqueries';

export const StyledHeader = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: ${({ variant }) => (variant === 'profile' && '0') || '1.5rem 0 0 0'};
  font-size: ${({ variant }) => (variant === 'profile' && 'initial') || '1.25rem'};
  font-weight: 500;
  line-height: 1.5em;
  min-height: 2rem;
  flex-wrap: wrap;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2.6rem 1rem;
  width: 100%;

  ${mq.greaterXl} {
    padding: 0 50px 20px;
  }

  ${mq.lowerSm} {
    padding: 0 1rem 1rem;
  }
`;
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1092px;
`;

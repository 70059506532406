import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import ImageReader from 'utils/ImageReader';
import addIcon from 'components/svg/image-plus.svg?sprite';
import pdf from 'components/svg/pdf.svg?sprite';
import Icon from 'components/common/Icon';
import deleteIcon from 'components/svg/delete.svg?sprite';
import {
  Wrapper,
  Label,
  addIconCss,
  Input,
  Image,
  ImageContainer,
  FilesList,
  deleteIconCss,
  DeleteButton,
  Fade,
  iconCss
} from './styled';

type PropsT = {
  initialFiles?: string[];
  initialFilesAsFile?: File[];
  register?: UseFormRegisterReturn;
  isMulti?: boolean;
};

const FileField: FC<PropsT> = (props) => {
  const { register, isMulti, initialFiles, initialFilesAsFile } = props;
  const { t: f } = useGlobalTranslation('fields');
  const [files, setFiles] = useState<string[]>(initialFiles || []);
  const context = useFormContext();
  const fileWatch = context.watch(register?.name);

  useEffect(() => {
    initialFilesAsFile?.map((item) => {
      ImageReader.readAsDataURL(item).then((base64) =>
        setFiles((prevState) => {
          if (prevState.length < 10 && (item.type.includes('image') || item.type.includes('pdf'))) {
            return [...prevState, base64];
          }
          return prevState;
        })
      );
    });
  }, [initialFilesAsFile]);

  return (
    <Wrapper>
      <FilesList>
        {files.map((url, index) => (
          <ImageContainer key={url}>
            {!(url.includes('application/pdf') || url.includes('.pdf')) && <Image src={url} />}
            {(url.includes('application/pdf') || url.includes('.pdf')) && <Icon icon={pdf} css={iconCss} />}
            <Fade isBackground={!(url.includes('application/pdf') || url.includes('.pdf'))} />
            <DeleteButton
              onClick={() => {
                const newFiles = files.filter((_, idx) => index !== idx);
                const newValue = Object.assign(
                  [],
                  Array.from(context.getValues(register?.name)).filter((_, idx) => index !== idx)
                );
                setFiles(newFiles);
                context.setValue(register?.name, newValue);
              }}
            >
              <Icon icon={deleteIcon} css={deleteIconCss} />
            </DeleteButton>
          </ImageContainer>
        ))}
        {files?.length < 10 && (
          <Label>
            <Input
              type={'file'}
              onChange={async (event) => {
                Array.from(event.target.files || []).map((item) => {
                  ImageReader.readAsDataURL(item).then((base64) =>
                    setFiles((prevState) => {
                      if (prevState.length < 10 && (item.type.includes('image') || item.type.includes('pdf'))) {
                        return [...prevState, base64];
                      }
                      return prevState;
                    })
                  );
                });
                const newFiles = [
                  ...fileWatch,
                  ...Array.from(event.target.files || []).filter(
                    (item) => item.type.includes('image') || item.type.includes('pdf')
                  )
                ].splice(0, 10);
                context.setValue(register?.name, newFiles);
              }}
              multiple={isMulti}
            />
            <Icon icon={addIcon} css={addIconCss} />
            {f('addFile')}
          </Label>
        )}
      </FilesList>
    </Wrapper>
  );
};

export default FileField;

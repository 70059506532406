import React, { FC, useEffect, useState } from 'react';
import Header from './Header';
import Days from './Days';
import Months from './Months';
import { Wrapper } from './styled';
import { Control } from 'react-hook-form';
import { ProfileFormT } from 'domain/entity/profile/ProfileFormData';
import Years from './Years';

export type PropsT = {
  onChange?: (value: string) => void;
  onClick?: (value: string) => void;
  onClose?: () => void;
  value?: string;
  name?: string;
};

const DatePicker: FC<PropsT> = (props) => {
  const { onChange, value, onClose, onClick } = props;
  const [day, month, year] = value?.split('.') || [];
  const date = value
    ? new Date(
        isNaN(Number(year)) ? 1990 : Number(year),
        isNaN(Number(month)) ? 0 : Number(month) - 1,
        isNaN(Number(day)) ? 1 : Number(day)
      )
    : new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(date);
  const [selectedMonth, setSelectedMonth] = useState<Date>(date);
  const [selectedYear, setSelectedYear] = useState<Date>(date);
  const [variant, setVariant] = useState<'months' | 'days' | 'year'>('days');

  useEffect(() => {
    setSelectedMonth(selectedDate);
    if (onChange) onChange(selectedDate.toString());
  }, [selectedDate]);

  return (
    <Wrapper>
      <Header
        variant={variant}
        setViewVariant={setVariant}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedYears={selectedYear}
        setSelectedYears={setSelectedYear}
      />
      {variant === 'days' && (
        <Days
          onClick={(value) => {
            if (onClick) onClick(value.toString());
            if (onClose) onClose();
          }}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedMonth={selectedMonth}
        />
      )}
      {variant === 'months' && (
        <Months selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} setViewVariant={setVariant} />
      )}
      {variant === 'year' && (
        <Years
          selectedYear={selectedYear}
          setSelectedMonth={setSelectedMonth}
          selectedMonth={selectedMonth}
          setViewVariant={setVariant}
        />
      )}
    </Wrapper>
  );
};

export default DatePicker;

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from './store';
import HouseData from '../../domain/entity/house/HouseData';
import { CommonBilag } from '../../domain/entity/documnets/CommonBilag';

export interface ErrorListState {
  pending?: boolean;
  list?: any[];
  error?: string;
}

const initialState: ErrorListState = {
  pending: false,
  list: [],
  error: ''
};

export const errorListSlice = createSlice({
  name: 'loggerList',
  initialState,
  reducers: {
    fetchAllData(state: ErrorListState) {
      state.pending = true;
    },
    fetchAllDataSuccess(state: ErrorListState, { payload }: PayloadAction<any[]>) {
      state.pending = false;
      state.list = payload;
    },
    fetchAllDataError(state: ErrorListState, { payload }: PayloadAction<string>) {
      state.pending = false;
      state.error = payload;
    }
  }
});

const selectErrorList = (state: RootState): ErrorListState => state.errorList;

export const errorListSelector = createSelector([selectErrorList], (errorList) => errorList);

export const { fetchAllData, fetchAllDataError, fetchAllDataSuccess } = errorListSlice.actions;

export default errorListSlice.reducer;

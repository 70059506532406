import styled, { css } from 'styled-components';
import { hidden, mq } from 'constant/mediaqueries';
import { Logo } from 'components/ui';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${mq.greaterSm} {
    flex-direction: row;
  }
`;

export const StyledLeft = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: max-content;
  padding: 2.5rem 1.5rem 0 1.5rem;
  img {
    width: 100%;
    max-width: 3.6rem;
  }
  background-color: ${({ theme }) => theme.colors.white01};

  ${mq.greaterSm} {
    position: fixed;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray06};
    width: 50%;
    height: 100vh;
    padding: 2rem;
    img {
      max-width: 36rem;
    }
  }
`;
export const StyledRight = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colors.white01};

  ${mq.greaterSm} {
    margin-left: 50%;
    position: relative;
    padding: 3rem;
    width: 50%;
    height: 100vh;
  }
`;

export const StyledBgImage = styled.span`
  display: none;

  ${mq.greaterSm} {
    display: grid;
    grid-template-columns: 100%;
  }
`;

export const welcomeIconCss = css`
  width: 36rem;

  ${mq.lowerLg} {
    width: 26rem;
  }

  ${mq.lowerMd} {
    width: 20rem;
  }
`;

export const StyledLogoWrapper = styled.div`
  display: flex;

  ${hidden.greaterSm}
`;

export const StyledLogo = styled(Logo)`
  width: 48px !important;
  height: 48px !important;

  ${mq.greaterXs} {
    img {
      width: 36px !important;
      height: 36px !important;
    }
  }
`;

export const StyledLangSwitcherContainer = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;

import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { ROUTE_SUPPLIERS } from 'constant/routes';
import { deleteSupplier } from 'data/store/supplierReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import useGlobalTranlation from 'hooks/useGlobalTranlation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Icon from 'components/common/Icon';
import { BackButton } from 'components/ui';
import deleteIcon from 'components/svg/delete.svg?sprite';
import editIcon from 'components/svg/edit_icon.svg?sprite';
import SimplePage from 'components/common/SimplePage';
import SupplierInfoFields from './SupplierInfoFields';
import { DeleteButton, iconButtonCss, StyledLink, StyledLinkText, IconColumn } from './styled';
import { APP_SUPPLIER_COMPANY_ID } from '../../../../constant/global';

export const SupplierInfo: FC = () => {
  const { g } = useGlobalTranlation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;

  const { currentSupplier } = useAppSelector(({ supplier }) => supplier);
  const { name, id } = currentSupplier || {};
  const onClickDelete = (id: number) => {
    dispatch(
      setConfirmationData({
        show: true,
        cancelButtonProps: {
          onClick: () => {
            dispatch(resetConfirmationData());
          },
          children: g('alerts.deleteSupplier.no')
        },
        confirmButtonProps: {
          onClick: () => {
            dispatch(deleteSupplier(id));
            history.push(ROUTE_SUPPLIERS);
            dispatch(resetConfirmationData());
          },
          children: g('alerts.deleteSupplier.yes')
        },
        text: g('alerts.deleteSupplier.message'),
        title: g('alerts.deleteSupplier.title')
      })
    );
  };
  return (
    <SimplePage
      header={
        <>
          <BackButton route={ROUTE_SUPPLIERS}>{name}</BackButton>
          {id !== APP_SUPPLIER_COMPANY_ID && (
            <IconColumn>
              {currentSupplier && id && !currentSupplier.archived && (
                <DeleteButton variant={'text'} onClick={() => onClickDelete(id)}>
                  <Icon icon={deleteIcon} css={iconButtonCss} />
                  {g('alerts.deleteHouse.buttons.yes')}
                </DeleteButton>
              )}
              <StyledLink to={`${ROUTE_SUPPLIERS}/edit/${id}`}>
                <Icon icon={editIcon} color={'blue01'} /> <StyledLinkText>{g('edit')}</StyledLinkText>
              </StyledLink>
            </IconColumn>
          )}
        </>
      }
    >
      <SupplierInfoFields />
    </SimplePage>
  );
};

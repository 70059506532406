import React, { FC, useState } from 'react';
import Modal from 'components/common/Modal';
import trashIcon from 'components/svg/delete.svg?sprite';
import stopCircleIcon from 'components/svg/stop-circle.svg?sprite';
import rentIcon from 'components/svg/rentIcon.svg?sprite';
import editIcon from 'components/svg/pencil.svg?sprite';
import Icon, { IconT } from 'components/common/Icon';
import { PropsT as ButtonPropsT } from 'components/controls/Button';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { modalsSlice } from 'data/store/modalReducer';
import format from 'date-fns/format';
import { bilagSlice } from 'data/store/bilagReducer';
import { incomeBilagSlice } from 'data/store/incomeBilagReducer';
import HouseData from 'domain/entity/house/HouseData';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { depreciationSlice } from 'data/store/depreciationReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import PictureList from 'components/controls/PictureList';
import { numberWithSpaces } from 'helpers/number';
import Calculations from 'components/common/Calculations';
import { StepT } from '../Depreciation';
import {
  Title,
  Item,
  Label,
  Text,
  modalContentCss,
  Content,
  Divider,
  Actions,
  DeleteButton,
  iconButtonCss,
  Block,
  Button
} from './styles';

type PropsT = {
  index?: string;
};

type ButtonT = ButtonPropsT & { title: string; icon?: IconT };

type ItemT = {
  title: string;
  items: { name?: string; text?: string; grid?: number }[];
  files?: string[];
  actions: {
    buttons: ButtonT[];
    handleDeleteOnClick?: () => void;
  };
};

const InfoBilag: FC<PropsT> = () => {
  const { g } = useGlobalTranslation();
  const { t: a } = useGlobalTranslation('actions');
  const { t } = useGlobalTranslation('titles');
  const { t: f } = useGlobalTranslation('fields');
  const { t: deleteBilag } = useGlobalTranslation('deleteInPersonal');
  const [step, setStep] = useState<StepT>('info');
  const { closeInfoBilag, hiddenInfoBilag, openChangeRent, openMoveToTrash } = modalsSlice.actions;
  const { deleteManualBilag, startStopBilag } = bilagSlice.actions;
  const { deleteIncomeBilag } = incomeBilagSlice.actions;
  const { deleteDepreciationBilag, deleteDepreciationPersonalUseBilag } = depreciationSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const { openManualBilag, openIncomeBilag } = modalsSlice.actions;
  const dispatch = useAppDispatch();
  const {
    bilagInfo: { currentBilag, show }
  } = useAppSelector((state) => state.modals);
  const { selectedHouse } = useAppSelector((state) => state.house);

  const manualButtonsBilag = (id: number, isRepeat: boolean, isStopRepeat: boolean, isArchived: boolean): ButtonT[] => {
    const editBilagButton = {
      title: a('editBilag'),
      icon: editIcon,
      onClick: () => {
        dispatch(hiddenInfoBilag());
        dispatch(openManualBilag());
      }
    };

    if (isRepeat) {
      const generationButton = isStopRepeat
        ? {
            title: a('startAutomaticGeneration'),
            icon: stopCircleIcon,
            onClick: () => dispatch(startStopBilag({ id, isStopped: false, currentHouse: selectedHouse as HouseData }))
          }
        : {
            title: a('stopAutomaticGeneration'),
            icon: stopCircleIcon,
            onClick: () => dispatch(startStopBilag({ id, isStopped: true, currentHouse: selectedHouse as HouseData }))
          };

      return [generationButton, editBilagButton];
    }

    if (isArchived) {
      return [
        {
          title: a('finishCreateBilag'),
          icon: editIcon,
          onClick: () => {
            dispatch(hiddenInfoBilag());
            dispatch(openManualBilag());
          }
        }
      ];
    }

    return [editBilagButton];
  };

  const DATA = (): ItemT | undefined => {
    if (currentBilag?.type === 'bilag' && currentBilag?.bilag) {
      const isRepeat = !(currentBilag.bilag.repeat === 'never');
      const isStoppedAutomatic = currentBilag.bilag.isRepeatStopped;
      const isArchived = currentBilag.bilag.archived;
      return {
        title: currentBilag.bilag.supplier.name,
        items: [
          { name: g('inputs.address'), text: currentBilag.bilag.supplier.address },
          { name: 'empty' },
          { name: g('inputs.description'), text: currentBilag.bilag.description, grid: 2 },
          { name: g('inputs.amount'), text: `${numberWithSpaces(currentBilag.bilag.sum || 0)} NOK` },
          { name: g('inputs.date'), text: format(new Date(currentBilag.bilag.date || ''), 'dd.MM.yyyy') }
        ],
        files: currentBilag.bilag.documents,
        actions: {
          buttons: manualButtonsBilag(currentBilag.bilag.id, isRepeat, isStoppedAutomatic, isArchived),
          handleDeleteOnClick: () => {
            if (!currentBilag.bilag.archived) {
              dispatch(openMoveToTrash(() => dispatch(deleteManualBilag(currentBilag.bilag.id || NaN))));
            }
            if (currentBilag.bilag.archived) {
              dispatch(
                setConfirmationData({
                  show: true,
                  title: deleteBilag('title'),
                  onClose: () => dispatch(resetConfirmationData()),
                  text: deleteBilag('text'),
                  isConfirm: true,
                  cancelButtonProps: {
                    children: deleteBilag('cancel'),
                    onClick: () => dispatch(resetConfirmationData())
                  },
                  confirmButtonProps: {
                    color: 'red01',
                    children: deleteBilag('submit'),
                    onClick: () => {
                      if (selectedHouse) {
                        dispatch(deleteManualBilag(currentBilag.bilag.id || NaN));
                        dispatch(resetConfirmationData());
                      }
                    }
                  }
                })
              );
            }
          }
        }
      };
    }

    if (currentBilag?.type === 'income-bilag' && currentBilag?.bilag && !currentBilag?.bilag.contractId) {
      const { tenant_first_name, tenant_last_name, sum, start_date, end_date } = currentBilag.bilag;
      const fullName = `${tenant_first_name} ${tenant_last_name}`;
      return {
        title: fullName,
        items: [
          { name: g('tenant'), text: fullName },
          { name: g('inputs.amount'), text: `${numberWithSpaces(sum || 0)} NOK` },
          { name: g('inputs.startDate'), text: format(new Date(start_date), 'dd.MM.yyyy') },
          { name: g('inputs.endDate'), text: format(new Date(end_date), 'dd.MM.yyyy') }
        ],
        files: currentBilag.bilag.documents,
        actions: {
          buttons: [
            {
              title: a('editBilag'),
              icon: editIcon,
              onClick: () => {
                dispatch(hiddenInfoBilag());
                dispatch(openIncomeBilag());
              }
            }
          ],
          handleDeleteOnClick: () => {
            dispatch(openMoveToTrash(() => dispatch(deleteIncomeBilag(currentBilag.bilag.id))));
          }
        }
      };
    }

    if (currentBilag?.type === 'income-bilag' && currentBilag?.bilag && currentBilag?.bilag.contractId) {
      const { sum, start_date, title } = currentBilag.bilag;
      return {
        title,
        items: [
          { name: g('tenant'), text: title },
          { name: g('inputs.dateOfPayment'), text: format(new Date(start_date), 'dd.MM.yyyy') },
          { name: g('inputs.amount'), text: `${numberWithSpaces(sum || 0)} NOK` }
        ],
        files: currentBilag.bilag.documents,
        actions: {
          buttons: currentBilag?.bilag.canEditSum
            ? [{ title: a('changeRentThisMonth'), icon: rentIcon, onClick: () => dispatch(openChangeRent()) }]
            : [],
          handleDeleteOnClick: () => {
            dispatch(openMoveToTrash(() => dispatch(deleteIncomeBilag(currentBilag.bilag.id))));
          }
        }
      };
    }

    if (currentBilag?.type === 'depreciation-bilag') {
      const { sum, lifetime, date, name, id, expensed } = currentBilag.bilag;
      return {
        title: name,
        items: [
          { name: g('inputs.item'), text: name },
          { name: g('inputs.lifetime'), text: f(lifetime ? 'moreThan3' : 'lessThan3') },
          { name: g('inputs.amount'), text: `${numberWithSpaces(sum || 0)} NOK` },
          { name: g('inputs.date'), text: format(new Date(date || ''), 'dd.MM.yyyy') },
          lifetime !== 0
            ? { name: g('inputs.taxDeduction'), text: `${numberWithSpaces(expensed || 0)} NOK` }
            : { name: 'empty' }
        ],
        files: currentBilag.bilag.documents,
        actions: {
          buttons: lifetime !== 0 ? [{ title: a('seeCalculation'), onClick: () => setStep('calculations') }] : [],
          handleDeleteOnClick: () => {
            dispatch(openMoveToTrash(() => dispatch(deleteDepreciationBilag(id))));
          }
        }
      };
    }

    if (currentBilag?.type === 'depreciation-personal-bilag') {
      const { sum, date, totalSum, id } = currentBilag.bilag;
      return {
        title: t('inPersonalUse'),
        items: [
          { name: g('inputs.currentTotalRevenue'), text: `${numberWithSpaces(totalSum)} NOK` },
          { name: g('inputs.depreciation'), text: '15%' },
          { name: g('inputs.taxDeduction'), text: `${numberWithSpaces(sum || 0)} NOK` },
          { name: g('inputs.date'), text: format(new Date(date || ''), 'dd.MM.yyyy') }
        ],
        files: [],
        actions: {
          buttons: [],
          handleDeleteOnClick: () => {
            dispatch(
              setConfirmationData({
                show: true,
                title: deleteBilag('title'),
                onClose: () => dispatch(resetConfirmationData()),
                text: deleteBilag('text'),
                isConfirm: true,
                cancelButtonProps: {
                  children: deleteBilag('cancel'),
                  onClick: () => dispatch(resetConfirmationData())
                },
                confirmButtonProps: {
                  color: 'red01',
                  children: deleteBilag('submit'),
                  onClick: () => {
                    if (selectedHouse) {
                      dispatch(deleteDepreciationPersonalUseBilag({ year: id, house: selectedHouse }));
                      dispatch(resetConfirmationData());
                    }
                  }
                }
              })
            );
          }
        }
      };
    }
  };

  if (!currentBilag) {
    return null;
  }

  const data = DATA();

  return (
    <Modal contentCss={modalContentCss} isOpen={show} onClose={() => dispatch(closeInfoBilag())}>
      {step === 'info' && (
        <>
          <Title>{data?.title}</Title>
          <Content>
            {data?.items.map(({ name, text, grid }) => {
              return name !== 'empty' ? (
                <Item key={name} grid={grid}>
                  <Label>{name}</Label>
                  <Text>{text}</Text>
                </Item>
              ) : null;
            })}
          </Content>
          {Array.isArray(data?.files) && data?.files && (
            <>
              <Divider />
              <PictureList documents={data.files} />
            </>
          )}
          <Actions>
            <Block>
              {data?.actions.buttons.map(({ title, icon, ...restProps }) => (
                <Button key={title} variant={'text'} {...restProps}>
                  {icon && <Icon icon={icon} css={iconButtonCss} />}
                  {title}
                </Button>
              ))}
            </Block>
            <Block>
              {data?.actions.handleDeleteOnClick && (
                <DeleteButton variant={'text'} onClick={data?.actions.handleDeleteOnClick}>
                  <Icon icon={trashIcon} css={iconButtonCss} />
                  {currentBilag?.type === 'depreciation-personal-bilag' ||
                  (currentBilag?.type === 'bilag' && currentBilag?.bilag.archived)
                    ? a('deleteBilag')
                    : a('moveToTrash')}
                </DeleteButton>
              )}
            </Block>
          </Actions>
        </>
      )}
      {step === 'calculations' && currentBilag.type === 'depreciation-bilag' && currentBilag.bilag.lifetime !== 0 && (
        <Calculations
          setStep={() => setStep('info')}
          lifetime={currentBilag.bilag.lifetime}
          sum={currentBilag.bilag.sum || 0}
        />
      )}
    </Modal>
  );
};

export default InfoBilag;

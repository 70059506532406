import { CHANGE_RENT_INCOME_BILAG, CREATE_INCOME_BILAG, EDIT_INCOME_BILAG } from 'constant/apiRoutes';
import { IncomeBilagFormData } from 'domain/entity/IncomeBilag/IncomeBilagFormData';
import { httpWithAuth } from 'data/driver/Api';
import { formatDateForApi } from 'helpers/date';
import { IncomeBilag } from '../../../domain/entity/documnets/CommonBilag';

export const createIncomeBilagApi = async (formData: IncomeBilagFormData) => {
  const { data } = await httpWithAuth.post(CREATE_INCOME_BILAG, {
    ...formData,
    documents: formData.documents,
    start_date: formatDateForApi(formData.start_date || ''),
    end_date: formatDateForApi(formData.end_date || ''),
    tenant_birthday: formatDateForApi(formData.tenant_birthday || '')
  });

  return data;
};

export const editIncomeBilagApi = async (formData: IncomeBilagFormData): Promise<IncomeBilag> => {
  const { data } = await httpWithAuth.put(`${EDIT_INCOME_BILAG(formData.id)}`, {
    ...formData,
    documents: formData.documents,
    start_date: formatDateForApi(formData.start_date || ''),
    end_date: formatDateForApi(formData.end_date || ''),
    tenant_birthday: formatDateForApi(formData.tenant_birthday || ''),
    id: undefined
  });

  return data;
};

export const deleteIncomeBilagApi = async (id: number) => {
  await httpWithAuth.delete(`${CREATE_INCOME_BILAG}/${id}`);
};

export const changeRentIncomeBilagApi = async ({ id, sum }: { id: number; sum: string }) => {
  const { data } = await httpWithAuth.put(CHANGE_RENT_INCOME_BILAG(id), { sum });

  return data;
};

import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { PriceT } from 'domain/entity/subscription/PriceT';
import { ThemeT } from 'context/Theme';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import { PriceCard } from 'containers/Subscription/components';
import { Button } from 'components/ui';
import Icon from 'components/common/Icon';
import checkIcon from 'components/svg/check_icon.svg?sprite';
import { formatPrice } from './helpers';
import {
  StyledCheckRow,
  StyledContainer,
  StyledDescription,
  StyledPriceGrid,
  StyledSubtitle,
  StyledTitle,
  checkIconCss
} from './styled';

type Props = {
  prices: PriceT[];
  onContinueClick: (priceId: string) => void;
};

const Prices = ({ prices, onContinueClick }: Props) => {
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);
  const theme = useTheme();
  const { t, g } = useGlobalTranslation('subscription');

  const pricesInfo = useMemo(() => {
    if (prices) {
      const monthPrice = prices.find((p) => p.interval === 'month');
      const yearPrice = prices.find((p) => p.interval === 'year');
      return prices.map((p) => {
        return {
          title: g(p.interval),
          amount: formatPrice(p.unitAmount, p.currency),
          subtitle:
            (p.interval === 'year' &&
              formatPrice(p.unitAmount / 12, p.currency) + ' per ' + g('month').toLowerCase()) ||
            null,
          sale:
            (p.interval === 'year' &&
              monthPrice &&
              yearPrice &&
              Math.floor((yearPrice.unitAmount / (monthPrice.unitAmount * 12) - 1) * -100).toFixed(0)) ||
            null,
          priceId: p.priceId
        };
      });
    }
    return [];
  }, [prices, g]);

  return (
    <StyledContainer>
      <StyledTitle>{t('title2')}</StyledTitle>
      <StyledSubtitle>{t('subtitle')}</StyledSubtitle>
      <StyledDescription>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-1')}
        </StyledCheckRow>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-2')}
        </StyledCheckRow>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-3')}
        </StyledCheckRow>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-4')}
        </StyledCheckRow>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-5')}
        </StyledCheckRow>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-6')}
        </StyledCheckRow>
        <StyledCheckRow>
          <Icon icon={checkIcon} css={checkIconCss(theme as ThemeT)} />
          {t('description.row-7')}
        </StyledCheckRow>
      </StyledDescription>
      <StyledPriceGrid>
        {pricesInfo.map((priceInfo) => (
          <PriceCard
            {...priceInfo}
            checked={priceInfo.priceId === selectedPriceId}
            key={priceInfo.title}
            onClick={() => setSelectedPriceId(priceInfo.priceId)}
          />
        ))}
      </StyledPriceGrid>
      {selectedPriceId && <Button onClick={() => onContinueClick(selectedPriceId)}>{g('continue')}</Button>}
    </StyledContainer>
  );
};

export default Prices;
